/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
import React, { Suspense, lazy } from 'react'
import { Redirect } from 'react-router-dom'
import {
  ReportsNormalizedReportsListPageAccessControl,
  ReportsPickupAnalyticsAccessControl,
  InstacartManagedReportsListPage,
  InstacartManagedReportsListPageAccessControl,
} from '@retailer-platform/domain-reports'
import RetailerRoute, { type RetailerRouteComponentProps } from '../../components/RetailerRoute'
import DocumentTitle from '../../components/DocumentTitle'
import RetailerSwitch from '../../components/RetailerSwitch'
import { InventoryFilesAccessControl } from './files/InventoryFiles.configuration'
import { TlogsContainerAccessControl } from './tlogs/TlogsContainer.configuration'
import { AlcoholTlogsAccessControl } from './alcohol/AlcoholTlogs.configuration'
import { RetailerScopeWrapper } from '../../../gin-and-tonic/containers/retailer-scope-wrapper/RetailerScopeWrapper'

const Loading = () => null

const ReportsPickupAnalyticsPage = lazy(
  () =>
    // eslint-disable-next-line workspaces/no-relative-imports
    import(
      '../../../../../../domains/reports/src/pages/pickup-analytics/ReportsPickupAnalyticsPage'
    )
)

const ReportsNormalizedReportsListPage = lazy(
  () =>
    // eslint-disable-next-line workspaces/no-relative-imports
    import(
      '../../../../../../domains/reports/src/pages/normalized-reports-list/ReportsNormalizedReportsListPage'
    )
)

const InventoryFiles = lazy(
  () => import(/* webpackChunkName: "inventory-files" */ './files/InventoryFiles')
)

const Tlogs = lazy(() => import(/* webpackChunkName: "tlogs" */ './tlogs/TlogsContainer'))

const AlcoholTlogs = lazy(
  () => import(/* webpackChunkName: "alcohol-tlogs" */ './alcohol/AlcoholTlogs')
)

class Reports extends React.Component<RetailerRouteComponentProps> {
  render() {
    return (
      <Suspense fallback={<Loading />}>
        <RetailerSwitch>
          <RetailerRoute
            route="files"
            exact
            render={props => (
              <RetailerScopeWrapper retailerSelectionOptional={true}>
                <DocumentTitle titleId="reports.files.title">
                  <InventoryFiles {...props} />
                </DocumentTitle>
              </RetailerScopeWrapper>
            )}
            accessControl={InventoryFilesAccessControl}
          />
          <RetailerRoute
            route="tlogs"
            exact
            render={props => (
              <RetailerScopeWrapper formattedMessageTitle="reports.tlogs.title">
                <DocumentTitle titleId="reports.tlogs.title">
                  <Tlogs {...props} />
                </DocumentTitle>
              </RetailerScopeWrapper>
            )}
            accessControl={TlogsContainerAccessControl}
          />
          <RetailerRoute
            route="alcohol-tlogs"
            exact
            render={props => (
              <RetailerScopeWrapper formattedMessageTitle={'alcoholTlogs.title'}>
                <DocumentTitle titleId="alcoholTlogs.title">
                  <AlcoholTlogs {...props} />
                </DocumentTitle>
              </RetailerScopeWrapper>
            )}
            accessControl={AlcoholTlogsAccessControl}
          />
          <RetailerRoute
            route="pickupAnalytics"
            exact
            component={ReportsPickupAnalyticsPage}
            accessControl={ReportsPickupAnalyticsAccessControl}
            scopePicker={{
              formattedMessageTitle: 'reportsDomain.pickupAnalytics.pageTitle',
            }}
          />

          <RetailerRoute
            route="reports-normalized-reports-list"
            exact
            component={ReportsNormalizedReportsListPage}
            // @ts-expect-error
            accessControl={ReportsNormalizedReportsListPageAccessControl}
            scopePicker={{
              titles: ['reportsDomain.breadcrumbs.root'],
            }}
          />
          <RetailerRoute
            route="instacart-managed-reports-list"
            exact
            component={InstacartManagedReportsListPage}
            // @ts-ignore-next-line
            accessControl={InstacartManagedReportsListPageAccessControl}
          />

          <Redirect
            from="/partners/:partner_id/warehouses/:warehouse_id/reports"
            to="/partners/:partner_id/warehouses/:warehouse_id/reports/files"
          />
        </RetailerSwitch>
      </Suspense>
    )
  }
}

export default Reports
