import {
  type ReactNode,
  useMemo,
  isValidElement,
  type FunctionComponent,
  type ReactElement,
} from 'react'

interface Props {
  row: ReactNode
  inputValue: string | undefined
  isToggled: boolean
}

export const DashSelectFastMenuListOption: FunctionComponent<React.PropsWithChildren<Props>> = ({
  row,
  inputValue,
  isToggled,
}) => {
  const rowIsFocused = useMemo(() => {
    if (!isValidElement(row)) return []

    return row.props.isFocused
  }, [row])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useMemo(() => row, [rowIsFocused, inputValue, isToggled])

  return result as ReactElement
}
