import { type CSSProperties } from 'react'
import { dashPixels } from '../../foundation/spacing-units/spacingUnits.types'
import { dashFontSizes } from '../../foundation/typography'
import { dashColors } from '../../foundation/colors'
import { dashSpacing } from '../../foundation/spacing'

export const DASH_TABLE_Z_INDEX = 0
export const DASH_TABLE_ROW_HEIGHT = dashPixels.PX40
export const DASH_TABLE_FOOTER_HEIGHT = dashPixels.PX52
export const DASH_TABLE_BORDER_CSS = `1px solid ${dashColors.LIGHT_NEUTRAL}`
export const DASH_TABLE_MINIMUM_WIDTH = dashPixels.PX640

export const dashTableRowStyles: CSSProperties = {
  height: DASH_TABLE_ROW_HEIGHT,
  fontSize: dashFontSizes.SMALL,
  verticalAlign: 'middle',
  borderBottom: DASH_TABLE_BORDER_CSS,
  color: dashColors.DARKER_NEUTRAL,
}

const sharedCellStyles = {
  verticalAlign: 'middle',
  backgroundColor: dashColors.WHITE,
}

export const dashTableCellStyles: CSSProperties = {
  ...sharedCellStyles,
  overflow: 'hidden',
  whiteSpace: 'normal',
  overflowWrap: 'break-word',
}

export const dashTableHeaderCellStyles: CSSProperties = {
  ...sharedCellStyles,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  position: 'sticky',
  top: 0,
  // Need to use box shadow instead of border, because our element is sticky and the
  // table borders-collapse is set to collapse
  boxShadow: `0 1px ${dashColors.LIGHT_NEUTRAL}`,
  paddingRight: dashSpacing.MEDIUM,
}

export const dashTableFooterCellStyles: CSSProperties = {
  ...sharedCellStyles,
  height: DASH_TABLE_FOOTER_HEIGHT,
  fontWeight: 'bold',
  backgroundColor: dashColors.WHITE,
  borderTop: DASH_TABLE_BORDER_CSS,
  minWidth: DASH_TABLE_MINIMUM_WIDTH,
}
