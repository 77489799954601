import React, { type FunctionComponent, useMemo } from 'react'
import { Alert, Text } from '@retailer-platform/shared-components'
import { useDomainMessages } from '../../../../utils/domain/intl'
import {
  type FormSpecialHours,
  type SpecialHoursUpdateMode,
} from '../../../edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'
import { StoreHoursBulkSpecialStoreHourItem } from './StoreHoursBulkSpecialStoreHourItem'

interface StoreHoursBulkSpecialStoreHoursProps {
  specialStoreHours: FormSpecialHours[]
  mode?: SpecialHoursUpdateMode
}

export const StoreHoursBulkSpecialStoreHours: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkSpecialStoreHoursProps>
> = ({ specialStoreHours, mode }) => {
  const i18n = useDomainMessages({
    defaultHeaderMessage: 'storeHoursBulkDomain.list.modal.header.specialHours',
    replaceHeaderMessage: 'storeHoursBulkDomain.list.modal.header.specialHours.replace',
    appendHeaderMessage: 'storeHoursBulkDomain.list.modal.header.specialHours.append',
    noSpecialHoursSet: 'storeHoursBulkDomain.list.modal.noSpecialHoursSet',
    replacingSpecialHoursWarning: 'storeHoursBulkDomain.list.modal.replacingSpecialHoursWarning',
  })

  const specialStoreHoursList = useMemo(
    () =>
      specialStoreHours.map((storeHour, index) => (
        <StoreHoursBulkSpecialStoreHourItem
          key={index}
          description={storeHour.description}
          date={storeHour.date}
          openTime={storeHour.openTime}
          closeTime={storeHour.closeTime}
          openMode={storeHour.openMode}
        />
      )),
    [specialStoreHours]
  )

  return (
    <React.Fragment>
      <Text css={{ padding: '5px 0' }} size="medium" weight="bold">
        {i18n[`${mode}HeaderMessage`] || i18n.defaultHeaderMessage}
      </Text>
      {specialStoreHoursList}
      {specialStoreHoursList.length === 0 && (
        <Text css={{ opacity: '0.6', fontStyle: 'italic' }}>{i18n.noSpecialHoursSet}</Text>
      )}
      {mode === 'replace' && (
        <Alert variant="warning">
          <Text key={'errors-warnings-w-replacing-special-hours'} weight="semibold">
            {i18n.replacingSpecialHoursWarning}
          </Text>
        </Alert>
      )}
    </React.Fragment>
  )
}
