import React, { type FunctionComponent, useMemo } from 'react'
import styled from '@emotion/styled'
import isNumber from 'lodash/isNumber'
import isFinite from 'lodash/isFinite'
import { colors, spacing, Text, Tooltip } from '@retailer-platform/shared-components'
import { InformationIcon, ArrowUpIcon, ArrowDownIcon, SubtractIcon } from '@instacart/ids-core'
import { DEFAULT_NO_VALUE_PLACEHOLDER } from '../utils/PickingPerformanceOverview.constants'
import {
  Metric,
  LabelContainer,
  Label,
  LabelSecondary,
  SubLabelContainer,
  SubLabel,
  Trend,
  TrendDirectionContainer,
  TrendValue,
  IconWrapper,
} from './PerformanceMetric.styles'

const TooltipDisclaimer = styled.div({
  marginTop: spacing.X8,
})

export enum TrendDirection {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  UNCHANGED = 'unchanged',
}

interface Props {
  metric: number
  metricDenominator?: number
  metricSymbol?: string
  trendValue?: number
  trendDirection?: TrendDirection
  title: string
  tooltip: string
  tooltipDisclaimer?: string
  noValuePlaceholder?: string
  invertTrendColor?: boolean
}

export const PerformanceMetric: FunctionComponent<React.PropsWithChildren<Props>> = ({
  metric,
  metricDenominator,
  metricSymbol,
  trendValue,
  trendDirection,
  title,
  tooltip,
  tooltipDisclaimer,
  noValuePlaceholder = DEFAULT_NO_VALUE_PLACEHOLDER,
  invertTrendColor = false,
}) => {
  const { trendColor, TrendIcon } = useMemo(() => {
    const positiveColor = colors.HIGHLIGHT.REGULAR
    const negativeColor = colors.DETRIMENTAL.REGULAR
    switch (trendDirection) {
      case 'negative':
        return {
          trendColor: invertTrendColor ? positiveColor : negativeColor,
          TrendIcon: ArrowDownIcon,
        }
      case 'positive':
        return {
          trendColor: invertTrendColor ? negativeColor : positiveColor,
          TrendIcon: ArrowUpIcon,
        }
      default:
        return { trendColor: colors.GRAYSCALE.X30, TrendIcon: SubtractIcon }
    }
  }, [invertTrendColor, trendDirection])

  // isFinite is used on the trendValue to hide results that are Infinity or NaN,
  // since it is possible to have a pastValue of 0
  return (
    <Metric>
      <LabelContainer>
        <Label>
          {`${metric ?? noValuePlaceholder}${metricSymbol && isNumber(metric) ? metricSymbol : ''}`}
          {isNumber(metricDenominator) && <LabelSecondary>/{metricDenominator}</LabelSecondary>}
        </Label>
        {isFinite(trendValue) && (
          <Trend trendColor={trendColor}>
            <TrendDirectionContainer>
              <TrendIcon />
            </TrendDirectionContainer>
            <TrendValue>{trendValue}%</TrendValue>
          </Trend>
        )}
      </LabelContainer>
      <SubLabelContainer>
        <SubLabel>{title}</SubLabel>
        <Tooltip
          target={
            <IconWrapper>
              <InformationIcon size={16} color={'systemGrayscale30'} />
            </IconWrapper>
          }
          placement="bottom"
        >
          <Text>{tooltip}</Text>
          {tooltipDisclaimer && (
            <TooltipDisclaimer>
              <Text weight={'bold'}>{tooltipDisclaimer}</Text>
            </TooltipDisclaimer>
          )}
        </Tooltip>
      </SubLabelContainer>
    </Metric>
  )
}
