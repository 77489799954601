import React from 'react'
import { components } from 'react-select'
import styled from '@emotion/styled'
import { type OptionProps } from 'react-select/src/components/Option'
import { useRequiredContext } from '../../../contexts/utils'
import { DashSelectContext } from '../utils/DashSelectContext'
import { type BaseOption } from '../utils/select.types'
import { selectOptionTestId } from '../utils/select.utils'
import { Flex } from '../../flex/Flex'
import { DashText } from '../../dash-text/DashText'
import { DASH_SELECT_OPTION_HEIGHT } from '../utils/styles'
import { DashSelectOptionIcon } from './DashSelectOptionIcon'

const { Option } = components

const OptionFlex = styled(Flex)`
  padding-left: 10px;
  padding-right: 2px;
`

const textOverrideStyling = {
  height: DASH_SELECT_OPTION_HEIGHT,
  display: 'flex',
  alignItems: 'center',
}

interface Props<TOption> extends OptionProps<TOption> {
  children: React.ReactNode
}

export const DashSelectOption = <TOption extends BaseOption>(props: Props<TOption>) => {
  const {
    children,
    data,
    selectProps: { getOptionValue },
    ...rest
  } = props

  const { testId } = useRequiredContext(DashSelectContext)
  const optionValue = getOptionValue && getOptionValue(data)

  return (
    <Option data={data} selectProps={props.selectProps} {...rest}>
      <OptionFlex
        fillHeight
        testId={selectOptionTestId(testId, optionValue)}
        align="center"
        wrap="nowrap"
      >
        {data.icon && <DashSelectOptionIcon name={data.icon} color={data.iconColor} />}
        <DashText
          testId="select-option-label"
          size="small"
          color="darker-neutral"
          elementType="span"
          overflowEllipsis
          overrideStyle={textOverrideStyling}
        >
          {children}
        </DashText>
      </OptionFlex>
    </Option>
  )
}
