// prettier-ignore
const PLACEMENT_MANAGER_EN_US = {
  // TODO: Remove pageHeadings and generate them dynamically similar to breadcrumbs in a follow up PR
  'campaignManagement.placementManager.pageHeadings.list': 'List Placements',
  'campaignManagement.placementManager.pageHeadings.create': 'Create Placement',
  'campaignManagement.placementManager.pageHeadings.edit': 'Edit Placement',
  'campaignManagement.placementManager.pageHeadings.details': 'Placement Details',
  'campaignManagement.placementManager.list.gridHeadings.id': 'ID',
  'campaignManagement.placementManager.list.gridHeadings.rank': 'Rank',
  'campaignManagement.placementManager.list.gridHeadings.status': 'Status',
  'campaignManagement.placementManager.list.gridHeadings.actions': 'Actions',
  'campaignManagement.placementManager.list.gridHeadings.title': 'User facing title',
  'campaignManagement.placementManager.list.gridHeadings.placementType': 'Placement type',
  'campaignManagement.placementManager.list.gridHeadings.noResults': 'No placements to display',
  'campaignManagement.placementManager.list.actionDropdown.details': 'View Placement',
  'campaignManagement.placementManager.list.actionDropdown.edit': 'Edit Placement',
  'campaignManagement.placementManager.list.actionDropdown.rankUp': 'Increase rank',
  'campaignManagement.placementManager.list.actionDropdown.rankDown': 'Decreate rank',
  'campaignManagement.placementManager.list.actionDropdown.delete': 'Disable Placement',
  'campaignManagement.placementManager.breadcrumbs.managerStatus.list': 'List',
  'campaignManagement.placementManager.breadcrumbs.managerStatus.details': 'Details',
  'campaignManagement.placementManager.breadcrumbs.managerStatus.edit': 'Edit',
  'campaignManagement.placementManager.breadcrumbs.managerStatus.create': 'Create',
  'campaignManagement.placementManager.create.selectPlacementType.prompt': 'Select placement Variant',
  'campaignManagement.placementManager.create.sectionHeadings.supportedPlacements': 'Supported Placements',
  'campaignManagement.placementManager.create.footer.discard': 'Discard changes',
  'campaignManagement.placementManager.create.footer.create': 'Publish Placement',
  'campaignManagement.placementManager.create.footer.nextStep': 'Continue',
  'campaignManagement.placementManager.create.footer.previousStep': 'Go back',
  'campaignManagement.placementManager.delete.deleteSuccess': 'Successfully disabled placement',
  'campaignManagement.placementManager.delete.deleteError': 'Error updating placement',
  'campaignManagement.placementManager.delete.delete': 'Delete',
  'campaignManagement.placementManager.delete.confirm.delete.confirmHeader': 'Confirm Placement Disable',
  'campaignManagement.placementManager.delete.confirm.delete.content': 'Are you sure you want to disable this placement? This will immediately hide it from the user experience.',
  'campaignManagement.placementManager.delete.confirm.delete.confirmLabel': 'Delete',
  'campaignManagement.placementManager.delete.confirm.delete.cancelLabel': 'Cancel',
  'campaignManagement.placementManager.update.notify.updateSuccess': 'Placement updated successfully',
  'campaignManagement.placementManager.update.notify.updateError': 'Placement updated failed',
}

const PLATFORMS = {
  'campaignManagement.platform.MOBILE': 'Mobile',
  'campaignManagement.platform.DESKTOP': 'Desktop',
  'campaignManagement.platform.WEB': 'Web',
  'campaignManagement.platform.IOS': 'iOS',
  'campaignManagement.platform.ANDROID': 'Android',
}

const CONTENT_PAGE_BUILDER_EN_US = {
  'campaignManagement.contentPageBuilder.content': 'Content',
  'campaignManagement.contentPageBuilder.pageSettings': 'Page Settings',
  'campaignManagement.contentPageBuilder.insertContent': 'Insert content to your page:',
  'campaignManagement.contentPageBuilder.addPlacement': 'Insert a static slot',
  'campaignManagement.contentPageBuilder.addSlot': 'Insert a dynamic slot',
  'campaignManagement.contentPageBuilder.staticSlot': 'Static Slot',
  'campaignManagement.contentPageBuilder.dynamicSlot': 'Dynamic Slot',
  'campaignManagement.contentPageBuilder.staticSlot.subTitle':
    'Display the same creative for all your users, across all device types',
  'campaignManagement.contentPageBuilder.dynamicSlot.subTitle':
    'Dynamically switches creatives based on visibility conditions from Campaigns & Offers tooling',
  'campaignManagement.contentPageBuilder.removePlacement': 'Remove creative',
  'campaignManagement.contentPageBuilder.notAvailable': 'Not available at this time.',
  'campaignManagement.contentPageBuilder.addCreative': 'Add Creative',
  'campaignManagement.contentPageBuilder.required': 'Required',
  'campaignManagement.contentPageBuilder.internalName': 'Name',
  'campaignManagement.contentPageBuilder.admin.bannerName': 'Banner Name',
  'campaignManagement.contentPageBuilder.admin.placementDetails': 'Placement details',
  'campaignManagement.contentPageBuilder.admin.remainingRetailers': '+ {remaining_retailers} more',
  'campaignManagement.contentPageBuilder.internalName.required': 'Name is required',
  'campaignManagement.contentPageBuilder.rouletteName': 'Roulette name',
  'campaignManagement.contentPageBuilder.rouletteDescription':
    "Only users who are in the 'variant' bucket will see this creative.",
  'campaignManagement.contentPageBuilder.title': 'Title',
  'campaignManagement.contentPageBuilder.title.required': 'Title is required',
  'campaignManagement.contentPageBuilder.storefronts': 'Sites & banners',
  'campaignManagement.contentPageBuilder.storefronts.placeholder': 'Sites & banners',
  'campaignManagement.contentPageBuilder.storefronts.required': 'Sites & banners are required',
  'campaignManagement.contentPageBuilder.storefronts.description':
    'Limit the usage of this creative',
  'campaignManagement.contentPageBuilder.storefronts.tips':
    'If you select multiple sites & banners, only destinations that are shared across the selected sites & banners will be displayed in the destination results below.',
  'campaignManagement.contentPageBuilder.creativePlacement': 'Creative Placement',
  'campaignManagement.contentPageBuilder.edit': 'Edit',
  'campaignManagement.contentPageBuilder.delete': 'Delete',
  'campaignManagement.contentPageBuilder.createdBy': 'Created By',
  'campaignManagement.contentPageBuilder.platformExclusive': 'Placement Exclusive',
  'campaignManagement.contentPageBuilder.designNew': 'Design new',
  'campaignManagement.contentPageBuilder.addExisting': 'Add existing',
  'campaignManagement.contentPageBuilder.attach': 'Attach',
  'campaignManagement.contentPageBuilder.chooseFromExisting': 'Choose from existing creative',
  'campaignManagement.contentPageBuilder.icons': 'Icons',
  'campaignManagement.contentPageBuilder.image': 'Image',
  'campaignManagement.contentPageBuilder.banners': 'Banners',
  'campaignManagement.contentPageBuilder.itemLists': 'Product carousels',
  'campaignManagement.contentPageBuilder.richText': 'Rich Text',
  'campaignManagement.contentPageBuilder.page': 'Page',
  'campaignManagement.contentPageBuilder.pageStatus.draft': 'Draft',
  'campaignManagement.contentPageBuilder.pageStatus.active': 'Active',
  'campaignManagement.contentPageBuilder.preview': 'Preview',
  'campaignManagement.contentPageBuilder.reset': 'Reset',
  'campaignManagement.contentPageBuilder.publish': 'Publish',
  'campaignManagement.contentPageBuilder.publishing': 'Publishing',
  'campaignManagement.contentPageBuilder.setActive': 'Set As Active',
  'campaignManagement.contentPageBuilder.selectDraft': 'Select A Draft',
  'campaignManagement.contentPageBuilder.saveAsDraft': 'Save As Draft',
  'campaignManagement.contentPageBuilder.deleteDraft': 'Delete Draft',
  'campaignManagement.contentPageBuilder.createPageToSave':
    'Finish entering required page settings to save the page.',
  'campaignManagement.contentPageBuilder.saving': 'Saving Changes',
  'campaignManagement.contentPageBuilder.changesSaved': 'Changed Saved',
  'campaignManagement.contentPageBuilder.pageSettings.heading': 'Page Details',
  'campaignManagement.contentPageBuilder.pageSettings.id': 'Page Id',
  'campaignManagement.contentPageBuilder.pageSettings.name': 'Name',
  'campaignManagement.contentPageBuilder.pageSettings.slug': 'Slug',
  'campaignManagement.contentPageBuilder.pageSettings.confirmSlugChange':
    'Are you sure you would like to change the slug from {oldSlug} to {newSlug}?',
  'campaignManagement.contentPageBuilder.pageSettings.confirmSlugChange2':
    'Changing the slug may break any URL links you have for this page.',
  'campaignManagement.contentPageBuilder.pageSettings.saveChanges': 'Save Changes',
  'campaignManagement.contentPageBuilder.pageSettings.cancelChanges': 'Cancel Changes',
  'campaignManagement.contentPageBuilder.pageSettings.slugValidation':
    'Only alphanumeric, underscore, and dash characters are allowed in a page slug.',
  'campaignManagement.contentPageBuilder.pageSettings.requiredField': 'Required',
  'campaignManagement.contentPageBuilder.pageSettings.preCreateInformationHeading': 'Get Started',
  'campaignManagement.contentPageBuilder.pageSettings.preCreateInformationBody':
    'Give your page a name and a slug to start editing your site.',
  'campaignManagement.contentPageBuilder.pageSettings.seoCanonicalUrl': 'Canonical URL (Optional)',
  'campaignManagement.contentPageBuilder.pageSettings.metaTitle': 'Meta Title',
  'campaignManagement.contentPageBuilder.pageSettings.metaDescription': 'Meta Description',
  'campaignManagement.contentPageBuilder.pageSettings.metaLocale': 'Meta Locale',
  'campaignManagement.contentPageBuilder.pageSettings.seoNoIndexLabel': 'Hide from search results',
  'campaignManagement.contentPageBuilder.pageSettings.seoPreviewSectionHeading':
    'Search results preview',
  'campaignManagement.contentPageBuilder.pageSettings.seoPreviewInfoHeading': 'Note',
  'campaignManagement.contentPageBuilder.pageSettings.seoPreviewInfoBody':
    'This preview uses the typical character limits for Google search result pages on desktop. Search engines do experiment with their character limits, and may decide to show different content.',
  'campaignManagement.contentPageBuilder.placementPicker.banners': 'Banners',
  'campaignManagement.contentPageBuilder.placementPicker.itemLists': 'Carousels',
  'campaignManagement.contentPageBuilder.placementPicker.itemGrids': 'Grids',
  'campaignManagement.contentPageBuilder.placementPicker.retailerLists': 'Retailer List',
  'campaignManagement.contentPageBuilder.placementPicker.standardProductGrid':
    'Coupons Product Grid',
  'campaignManagement.contentPageBuilder.placementPicker.shoppableProductCarousel':
    'Featured Collection Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.flyerItemLists': 'Flyer product carousels',
  'campaignManagement.contentPageBuilder.placementPicker.standardProductCarousel':
    'Standard Product Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.couponsProductCarousel':
    'Coupons Product Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.featuredProductCarousel':
    'Sponsored Product Ads Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.orderStatusBanner': 'Order Status Banner',
  'campaignManagement.contentPageBuilder.placementPicker.recipesCarousel': 'Recipes Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.recipeCategoriesCarousel':
    'Recipe Categories Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.flyerProductCarousel':
    'Flyer Product Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.heroBannerSingle': 'Primary Banner Single',
  'campaignManagement.contentPageBuilder.placementPicker.heroBannerSingle.slot':
    'Primary Banner Single / Dynamic',
  'campaignManagement.contentPageBuilder.placementPicker.heroBannerCarousel':
    'Primary Banner Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.heroBannerCarousel.slot':
    'Primary Banner Carousel / Dynamic ',
  'campaignManagement.contentPageBuilder.placementPicker.heroBannerCarouselSplit':
    'Primary Split Banner Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.heroBannerCarouselSplit.slot':
    'Primary Split Banner Carousel / Dynamic ',
  'campaignManagement.contentPageBuilder.placementPicker.landingHeroGroupLabel': 'Hero',
  'campaignManagement.contentPageBuilder.placementPicker.landingBodyGroupLabel': 'Body',
  'campaignManagement.contentPageBuilder.placementPicker.landingHeroLabel': 'Hero',
  'campaignManagement.contentPageBuilder.placementPicker.landingCoreGroupLabel': 'Core',
  'campaignManagement.contentPageBuilder.placementPicker.landingFooterLabel': 'Footer',
  'campaignManagement.contentPageBuilder.placementPicker.recipesGroupLabel': 'Recipes',
  'campaignManagement.contentPageBuilder.placementPicker.cardListLabel': 'Card Lists',
  'campaignManagement.contentPageBuilder.placementPicker.secondaryBannerSingle':
    'Secondary Banner Single',
  'campaignManagement.contentPageBuilder.placementPicker.secondaryBannerSingle.slot':
    'Secondary Banner Single / Dynamic',
  'campaignManagement.contentPageBuilder.placementPicker.secondaryBannerCarousel':
    'Secondary Banner Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.secondaryBannerCarousel.slot':
    'Secondary Banner Carousel / Dynamic',
  'campaignManagement.contentPageBuilder.placementPicker.secondaryBannerCarouselSplit':
    'Secondary Split Banner Carousel',
  'campaignManagement.contentPageBuilder.placementPicker.secondaryBannerCarouselSplit.slot':
    'Secondary Split Banner Carousel / Dynamic',
  'campaignManagement.contentPageBuilder.placementPicker.richTextGroupLabel': 'Text Container',
  'campaignManagement.contentPageBuilder.placementPicker.richTextLabel': 'Text Container',
  'campaignManagement.contentPageBuilder.placementPicker.imageGroupLabel': 'Image',
  'campaignManagement.contentPageBuilder.placementPicker.imageLabel': 'Image',
  'campaignManagement.contentPageBuilder.placementPicker.couponRedemptionModalGroupLabel':
    'Coupon Redemption Modal Container',
  'campaignManagement.contentPageBuilder.placementPicker.couponRedemptionModalLabel':
    'Coupon Redemption Modal',
  'campaignManagement.contentPageBuilder.placementPicker.flyoutModalGroupLabel':
    'Flyout Modal Container',
  'campaignManagement.contentPageBuilder.placementPicker.flyoutModalLabel': 'Flyout Modal',
  'campaignManagement.contentPageBuilder.placementPicker.globalGroupLabel': 'Global',
  'campaignManagement.contentPageBuilder.placementPicker.announcementBannerLabel':
    'Announcement Banner',
  'campaignManagement.contentPageBuilder.placementPicker.userRecipes': 'Saved Recipes',
  'campaignManagement.contentPageBuilder.bannerSlot.badgeText': 'Dynamic content',
  'campaignManagement.contentPageBuilder.heroBannerSingleLabel': 'Single Banner • Primary',
  'campaignManagement.contentPageBuilder.heroBannerCarouselLabel': 'Banner • Primary',
  'campaignManagement.contentPageBuilder.heroBannerCarouselSplitLabel': 'Primary Split Banner',
  'campaignManagement.contentPageBuilder.secondaryBannerSingleLabel': 'Single Banner • Secondary',
  'campaignManagement.contentPageBuilder.secondaryBannerCarouselLabel': 'Banner • Secondary',
  'campaignManagement.contentPageBuilder.secondaryBannerCarouselSplitLabel':
    'Secondary Split Banner',
  'campaignManagement.contentPageBuilder.globalLabel': 'Global',
  'campaignManagement.contentPageBuilder.gridItemListCouponsLabel': 'Product Grid • Coupons',
  'campaignManagement.contentPageBuilder.itemListLabel': 'Product Carousel • Standard',
  'campaignManagement.contentPageBuilder.itemListShoppableLabel': 'Product Carousel • Featured',
  'campaignManagement.contentPageBuilder.imageTileCarouselLabel': 'Image Tile Carousel',
  'campaignManagement.contentPageBuilder.quickLinkCarouselLabel': 'Quick Link Carousel',
  'campaignManagement.contentPageBuilder.itemListCouponsLabel': 'Product Carousel • Coupons',
  'campaignManagement.contentPageBuilder.itemListFeatureLabel': 'Ads Carousel • Sponsored',
  'campaignManagement.contentPageBuilder.orderStatusBanner': 'Order Status • Banner',
  'campaignManagement.contentPageBuilder.recipesCarousel': 'Recipes Carousel',
  'campaignManagement.contentPageBuilder.recipeCategoriesCarousel': 'Recipe Categories Carousel',
  'campaignManagement.contentPageBuilder.flyerItemListLabel': 'Product Carousel • Flyer Items',
  'campaignManagement.contentPageBuilder.bannerLabel': 'Banner',
  'campaignManagement.contentPageBuilder.richTextLabel': 'Rich Text Container',
  'campaignManagement.contentPageBuilder.userRecipes': 'Saved Recipes',
  'campaignManagement.contentPageBuilder.landingHeroLabel': 'Hero',
  'campaignManagement.contentPageBuilder.landingFooterLabel': 'Footer',
  'campaignManagement.contentPageBuilder.landingHeaderLabel': 'Header',
  'campaignManagement.contentPageBuilder.landingModuleLabel': 'Module',
  'campaignManagement.contentPageBuilder.landingRetailerListLabel': 'Retailer List',
  'campaignManagement.contentPageBuilder.landingFaqs': "FAQ's",
  'campaignManagement.contentPageBuilder.landingValueProps': 'Value Props',
  'campaignManagement.contentPageBuilder.notifications.publishSuccess': 'Draft version published.',
  'campaignManagement.contentPageBuilder.notifications.publishFailure':
    'An error occurred while publishing the draft version.',
  'campaignManagement.contentPageBuilder.notifications.createPageSuccess':
    'New page created, now editing a draft.',
  'campaignManagement.contentPageBuilder.notifications.createPageFailure':
    'An error occurred while creating a new page.',
  'campaignManagement.contentPageBuilder.notifications.createPageDuplicateSlugFailure':
    'Error. Slug is not unique.',
  'campaignManagement.contentPageBuilder.notifications.createDraftFailure':
    'An error occurred while creating a page draft version.',
  'campaignManagement.contentPageBuilder.notifications.deletePlacementSuccess': 'Deleted creative.',
  'campaignManagement.contentPageBuilder.notifications.deletePlacementFailure':
    'An error occurred while deleting creative.',
  'campaignManagement.contentPageBuilder.notifications.updateRankingsSuccess':
    'Updated creative rankings.',
  'campaignManagement.contentPageBuilder.notifications.updateRankingsFailure':
    'An error occurred while updating creative rankings.',
  'campaignManagement.contentPageBuilder.notifications.setActiveSuccess':
    'Successfully set {draftName} as active.',
  'campaignManagement.contentPageBuilder.notifications.setActiveFailed':
    'Unable to set {draftName} as active, try again.',
  'campaignManagement.contentPageBuilder.notifications.loadPageFailure':
    'An error occurred while fetching the page and creatives.',
  'campaignManagement.contentPageBuilder.removeBtn.removePlacementConfirmationTitle':
    'Remove creative',
  'campaignManagement.contentPageBuilder.removeBtn.removePlacementConfirmationBody':
    'Are you sure you want to remove this creative?',
  'campaignManagement.contentPageBuilder.removeBtn.automaticBannersRemovalNote':
    'By removing this creative, all banners within it will be removed as well.',
  'campaignManagement.contentPageBuilder.removeBtn.removeBannerConfirmationTitle': 'Remove banner',
  'campaignManagement.contentPageBuilder.removeBtn.removeBannerConfirmationBody':
    'Are you sure you want to remove this banner?',
  'campaignManagement.contentPageBuilder.removeBtn.automaticCarouselRemovalNote':
    'By removing this banner, the entire banner carousel will be removed as well.',
  'campaignManagement.contentPageBuilder.removeBtn.removeSlotConfirmationTitle':
    'Delete dynamic slot?',
  'campaignManagement.contentPageBuilder.removeBtn.removeSlotConfirmationBody':
    'This slot may feature content from an active campaign. Deleting will remove this mention of the campaign from your page. All creative will still exist in the Campaigns tool.',
  'campaignManagement.contentPageBuilder.removePlacementConfirmation.confirmLabel': 'Remove',
  'campaignManagement.contentPageBuilder.removePlacementConfirmation.cancelLabel': "Don't Remove",
  'campaignManagement.contentPageBuilder.deleteDraftConfirmationTitle': 'Delete draft',
  'campaignManagement.contentPageBuilder.deleteDraftConfirmationBody':
    'Are you sure you want to delete this draft?',
  'campaignManagement.contentPageBuilder.deleteDraftConfirmationNote':
    'All changes since the last published version will be deleted.',
  'campaignManagement.contentPageBuilder.deleteDraftConfirmation.confirmLabel': 'Delete',
  'campaignManagement.contentPageBuilder.deleteDraftConfirmation.cancelLabel': "Don't Delete",
  'campaignManagement.contentPageBuilder.staticSlot.addSuccessful': 'Creative added successfully.',
  'campaignManagement.contentPageBuilder.staticSlot.editingExistingError':
    'Edit mode is active on current banner. Banner modification is temporarily disabled.',
  'campaignManagement.contentPageBuilder.staticSlot.legacyCarouselError':
    'Legacy carousel detected with pre-existing banners. New banner attachment feature is disabled.',
  'campaignManagement.contentPageBuilder.staticSlot.notSupportedError':
    'The "Add Existing" feature is currently unavailable for this particular placement.',
  'campaignManagement.contentPageBuidler.dynamicSlot.heading': '{slotType} / Dynamic',
  'campaignManagement.contentPageBuilder.creativeEducation.featuredCollection': 'Image: 1600x900px',
  'campaignManagement.contentPageBuilder.creativeEducation.imageTileCarousel': 'Image: 80x80px',
  'campaignManagement.contentPageBuilder.creativeEducation.heroBanner': 'Image: 1600x900px',
  'campaignManagement.contentPageBuilder.pageVersionError': `This page version has already been published. Please refresh the page to view the latest updates.`,
}

const CONTENT_PAGE_BUILDER_BANNER_CAROUSEL_ORDER_PREVIEW_EN_US = {
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.primaryBanner.heading':
    'Banner • Hero',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.primaryBannerSplit.heading':
    'Banner • Hero Split',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.primaryBannerSplit.placementFormHeading':
    'Banner • Hero Split / Banner',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBanner.heading':
    'Banner • Secondary Single',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBannerSplit.heading':
    'Banner • Secondary Split',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.primaryBanner.placementFormHeading':
    'Banner • Hero / Banner',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBanner.placementFormHeading':
    'Banner • Secondary Single / Banner',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBannerSplit.placementFormHeading':
    'Banner • Secondary Split / Banner',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.addBannerButton': 'Add card',

  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.existingBannersHeading':
    'Existing cards',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.bannerIndex': 'Banner',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.noEditCreative':
    'This banner currently cannot be edited. Please navigate to the Creative Manager to edit it.',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.duration': 'Duration:',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.ongoing': 'Ongoing',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.noStartDate': 'No Start Date',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.noEndDate': 'No End Date',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.createdBy': 'Created by',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.editBannerButton': 'Edit card',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.deleteBannerButton':
    'Delete card',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.variantSelector.imageTextBtn':
    'Image, Text, Button',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.variantSelector.imageOnly':
    'Image Only',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBannerCarousel.enableAutoScroll':
    'Enable auto scroll',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBannerSplit.alertTitle':
    'Avoid empty space',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.secondaryBannerSplit.alertMsg':
    'With split banners, upload two at a time to avoid wasted space on your page.',
  'campaignManagement.contentPageBuilder.bannerCarouselOrderPreview.deleteSuccess':
    'Banner was removed successfully.',
}

const CONTENT_PAGE_BUILDER_ANNOUNCEMENT_BANNER_EN_US = {
  'campaignManagement.contentPageBuilder.announcementBannerForm.show': 'Show',
  'campaignManagement.contentPageBuilder.announcementBannerForm.hide': 'Hide',
  'campaignManagement.contentPageBuilder.announcementBannerForm.heading': 'Announcement Banner',
  'campaignManagement.contentPageBuilder.announcementBannerForm.removePlacementLabel': 'Remove',
  'campaignManagement.contentPageBuilder.announcementBannerForm.savePlacementLabel': 'Done',
  'campaignManagement.contentPageBuilder.announcementBannerForm.globalPlacement': 'Global Creative',
  'campaignManagement.contentPageBuilder.announcementBannerForm.alertMsg':
    'Announcement banners are global creatives. This means they will persist above the header in all pages of your storefront.',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.image': 'Image',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.altText': 'Alt Text',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariantTitle':
    'Image or Icon',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.icon': 'Icon',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.largeScreen':
    'Large Screen (8:1)',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.largeScreen.tips':
    'For desktops and laptops',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.smallScreen':
    'Small Screen (4:1)',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.smallScreen.tips':
    'For common phones and tablets',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.recommendedSize':
    'Recommended image resolution:',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.desktopSize':
    '120 px by 48 px',
  'campaignManagement.contentPageBuilder.announcementBannerForm.uploadVariant.mobileSize':
    '72 px by 28 px',
  'campaignManagement.contentPageBuilder.announcementBannerForm.title': 'Title',
  'campaignManagement.contentPageBuilder.announcementBannerForm.internalName': 'Name',
  'campaignManagement.contentPageBuilder.announcementBannerForm.headerText': 'Header Text',
  'campaignManagement.contentPageBuilder.announcementBannerForm.headerTextColor':
    'Header Text Color',
  'campaignManagement.contentPageBuilder.announcementBannerForm.titleRequired': 'Title is required',
  'campaignManagement.contentPageBuilder.announcementBannerForm.titleInfo':
    'Recommended character limit: 60',
  'campaignManagement.contentPageBuilder.announcementBannerForm.button': 'Button',
  'campaignManagement.contentPageBuilder.announcementBannerForm.labelText': 'Label Text',
  'campaignManagement.contentPageBuilder.announcementBannerForm.labelInfo':
    'Recommended character limit: 10',
  'campaignManagement.contentPageBuilder.announcementBannerForm.buttonColor': 'Button Color',
  'campaignManagement.contentPageBuilder.announcementBannerForm.buttonTextColor':
    'Button Text Color',
  'campaignManagement.contentPageBuilder.announcementBannerForm.buttonTextColorRequired':
    'Button text color is required',
  'campaignManagement.contentPageBuilder.announcementBannerForm.buttonColorRequired':
    'Button color is required',
  'campaignManagement.contentPageBuilder.announcementBannerForm.destination': 'Destination',
  'campaignManagement.contentPageBuilder.announcementBannerForm.destinationTitle': 'URL',
  'campaignManagement.contentPageBuilder.announcementBannerForm.destinationPlaceholder':
    'Enter URL',
  'campaignManagement.contentPageBuilder.announcementBannerForm.backgroundColor':
    'Background Color',
  'campaignManagement.contentPageBuilder.announcementBannerForm.closeButton': 'Close Button',
  'campaignManagement.contentPageBuilder.announcementBannerForm.dismissButtonColor':
    'Dismiss Button Color',
  'campaignManagement.contentPageBuilder.announcementBannerForm.localization': 'Localization',
  'campaignManagement.contentPageBuilder.announcementBannerForm.addLocale': 'Add Locale',
  'campaignManagement.contentPageBuilder.announcementBannerForm.noLocalesAdded': 'No Locales Added',
  'campaignManagement.contentPageBuilder.announcementBannerForm.notify.placementSaved':
    'Announcement banner has been successfully stored',
  'campaignManagement.contentPageBuilder.announcementBannerForm.notify.placementDeleted':
    'Announcement banner has been successfully removed',
  'campaignManagement.contentPageBuilder.announcementBannerForm.notify.placementSavedFail':
    'Failed to save the announcement banner',
  'campaignManagement.contentPageBuilder.announcementBannerForm.notify.placementDeletedFail':
    'Failed to delete the announcement banner',
  'campaignManagement.contentPageBuilder.announcementBannerForm.custom': 'Custom',
  'campaignManagement.contentPageBuilder.announcementBannerForm.customColorInput': 'Custom Color',
}

const CONTENT_PAGE_BUILDER_STOREFRONT_BANNER_EN_US = {
  'campaignManagement.contentPageBuilder.storefrontBannerForm.heading': 'Storefront Banner',
  'campaignManagement.creativeManager.table.variant.carousel_card': 'Carousel Card',
  'campaignManagement.creativeManager.table.variant.product_carousel': 'Product Carousel',
  'campaignManagement.creativeManager.table.variant.storefront_banner': 'Storefront Banner',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.placementId': 'Placement ID',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.savePlacementLabel': 'Done',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.title': 'Title',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.internalName': 'Name',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.titleText': 'Title Text',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.titleTextColor': 'Title Text Color',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.titleInfo':
    'Recommended character limit: 60',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.subTitleText': 'Sub Title Text',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.subTitleTextColor':
    'Sub Title Text Color',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.subTitleInfo':
    'Recommended character limit: 60',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.backgroundColor': 'Background Color',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.button': 'Button',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.labelText': 'Label Text',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.labelInfo':
    'Recommended character limit: 10',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.buttonColor': 'Button Color',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.buttonTextColor': 'Button Text Color',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.destination': 'Destination',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.destinationTitle': 'URL',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.destinationPlaceholder': 'Enter URL',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.notify.placementSaved':
    'Storefront banner has been successfully stored',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment': 'Experiment',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.name': 'Experiment Name',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.variant':
    'Experiment Variant',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.method':
    'Experiment Method',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.method.assign': 'Assign',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.method.lookup': 'Lookup',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.method.preview': 'Preview',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.experiment.method.experimentMethodUnspecified':
    'ExperimentMethodUnspecified',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.status': 'Status',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.status.enabled': 'Enabled',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.status.rank': 'Rank',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.status.includedRetailerIds':
    'Included Retailer IDs',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.status.startsAt':
    'Starts At',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.status.endsAt': 'Ends at',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.android': 'Android',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.ios': 'IOS',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.web': 'Web',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.platform.mobile': 'Mobile',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.variant.text': 'Text',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.variant.carouselCard':
    'Carousel Card',
  'campaignManagement.contentPageBuilder.storefrontBannerForm.notify.placementSavedFail':
    'Failed to save the storefront banner',
}

const CONTENT_PAGE_BUILDER_RICH_TEXT_EDITOR = {
  'campaignManagement.contentPageBuilder.richTextEditor.heading': 'Text Container',
  'campaignManagement.contentPageBuilder.richTextEditor.placeholderText': 'Enter some text...',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.paragraph': 'Paragraph',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.heading1': 'Heading 1',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.heading2': 'Heading 2',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.heading3': 'Heading 3',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.bulletList': 'Bulleted List',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.numberList': 'Numbered List',
  'campaignManagement.contentPageBuilder.richTextEditor.toolbar.strikethrough': 'Strikethrough',
}

const CONTENT_PAGE_BUILDER_COUPON_REDEMPTION_MODAL_EDITOR = {
  'campaignManagement.contentPageBuilder.couponRedemptionModalEditor.ctaActionTitle':
    'Banner Action',
  'campaignManagement.contentPageBuilder.couponRedemptionModalEditor.ctaActionGroupTitle':
    'Redemption Action',
  'campaignManagement.contentPageBuilder.couponRedemptionModalEditor.ctaActionNavigateToCouponRedemption':
    'Navigate to Coupon Redemption',
  'campaignManagement.contentPageBuilder.couponRedemptionModalEditor.ctaNavigateToCouponRedemptionCouponCode':
    'Coupon Code',
  'campaignManagement.contentPageBuilder.couponRedemptionModalEditor.ctaNavigateToCouponRedemptionValidateDays':
    'Validate Days',
  'campaignManagement.contentPageBuilder.couponRedemptionModalEditor.ctaNavigateToCouponRedemptionLandingUrl':
    'Landing Url',
}

const CONTENT_PAGE_BUILDER_FLYOUT_MODAL_EDITOR = {
  'campaignManagement.contentPageBuilder.flyoutModalEditor.ctaActionTitle': 'Banner Action',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.ctaActionGroupTitle': 'Navigate Action',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.ctaActionNavigateToFlyoutModal':
    'Navigate to Flyout Modal',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.ctaActionNavigateToFlyoutModalSlug':
    'Flyout Modal Page slug',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCtaTitle': 'Primary CTA',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCtaGroupTitle':
    'Primary CTA Action',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.redeemPartnershipOffer':
    'Redeem Partnership Offer',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.redeemPartnershipOffer.partnershipOfferName':
    'Partnership Offer Name',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.redeemPartnershipOffer.partnershipBenefitName':
    'Partnership Benefit Name',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.ctaName': 'CTA text',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.actionType': 'CTA Type',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.actionType.primary':
    'primary',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.ctaStyle': 'CTA Style',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.ctaStyle.plus': 'plus',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.NavigateToUrl':
    'Navigate to URL',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.NavigateToUrl.url': 'URL',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.primaryCta.NavigateToUrl.openInNewTab':
    'Open in new tab',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.flyoutSlugLinkTitle': 'Link to Slug',
  'campaignManagement.contentPageBuilder.flyoutModalEditor.flyoutSlugNotFound':
    'Slug provided but could not be found',
}

const CONTENT_PAGE_BUILDER_LANDING_HERO_EDITOR = {
  'campaignManagement.contentPageBuilder.landingHeroEditor.heading': 'Hero',
  'campaignManagement.contentPageBuilder.landingHeroEditor.backgroundColorTitle':
    'Background Color',
  'campaignManagement.contentPageBuilder.landingHeroEditor.heroType': 'Banner Type',
  'campaignManagement.contentPageBuilder.landingHeroEditor.heroTypeImageForward': 'Image Forward',
  'campaignManagement.contentPageBuilder.landingHeroEditor.heroTypeTextForward': 'Text Forward',
  'campaignManagement.contentPageBuilder.landingHeroEditor.disclaimerTitle': 'Disclaimer',
  'campaignManagement.contentPageBuilder.landingHeroEditor.buttonActionGroupTitle': 'Button Action',
  'campaignManagement.contentPageBuilder.landingHeroEditor.buttonActionTitle': 'Banner Action',
  'campaignManagement.contentPageBuilder.landingHeroEditor.buttonActionNavigateToAuth':
    'Navigate to Auth',
  'campaignManagement.contentPageBuilder.landingHeroEditor.buttonActionNavigateToUrl':
    'Navigate to URL',
  'campaignManagement.contentPageBuilder.landingHeroEditor.primaryImage': 'Primary Image',
  'campaignManagement.contentPageBuilder.landingHeroEditor.secondaryImage': 'Secondary Image',
  'campaignManagement.contentPageBuilder.landingHeroEditor.secondaryImageAlt': 'For secondary logo',
  'campaignManagement.contentPageBuilder.landingHeroEditor.ctaNavigateToAuthSignupPathTitle':
    'Signup Path',
  'campaignManagement.contentPageBuilder.landingHeroEditor.ctaNavigateToAuthLoginPathTitle':
    'Login Path',
  'campaignManagement.contentPageBuilder.landingHeroEditor.flyoutSlugLinkTitle': 'Link to Slug',
  'campaignManagement.contentPageBuilder.landingHeroEditor.flyoutSlugNotFound':
    'Slug provided but could not be found',
}

const CONTENT_PAGE_BUILDER_LANDING_RETAILER_LIST_EDITOR = {
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.groupHeader': 'Retailer List',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.title': 'Title',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.subtitle': 'Subtitle',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContextLabel':
    'Shop Context',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.all': 'All',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.alcohol': 'Alcohol',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.beauty': 'Beauty',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.convenience':
    'Convenience',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.ebt': 'Ebt',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.grocery': 'Grocery',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.local': 'Local',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.pets': 'Pets',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.pharmacy':
    'Pharmacy',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.retail': 'Retail',
  'campaignManagement.contentPageBuilder.landingRetailerListEditor.shopContext.wholesale':
    'Wholesale',
}

const CONTENT_PAGE_BUILDER_LANDING_FOOTER_EDITOR = {
  'campaignManagement.contentPageBuilder.landingFooterEditor.heading': 'Footer',
}

const CONTENT_PAGE_BUILDER_LANDING_MODULE_EDITOR = {
  'campaignManagement.contentPageBuilder.landingModuleEditor.heading': 'Module',
}

const CONTENT_PAGE_BUILDER_LANDING_HEADER_EDITOR = {
  'campaignManagement.contentPageBuilder.landingHeaderEditor.heading': 'Header',
  'campaignManagement.contentPageBuilder.landingHeroEditor.enableSearch': 'Enable Search?',
}

const CONTENT_PAGE_BUILDER_LANDING_FAQ_EDITOR = {
  'campaignManagement.contentPageBuilder.landingFaqEditor.groupHeader': 'FAQs',
  'campaignManagement.contentPageBuilder.placementPicker.landingFaqsList': 'FAQ List',
  'campaignManagement.contentPageBuilder.placementPicker.landingFaqsListTitle': 'FAQ List Title',
  'campaignManagement.contentPageBuilder.placementPicker.landingFaqsAddNew': 'Add New',
  'campaignManagement.contentPageBuilder.placementPicker.landingFaqsDelete': 'Delete FAQ #',
  'campaignManagement.contentPageBuilder.placementPicker.questionFieldTitle': 'Question',
  'campaignManagement.contentPageBuilder.placementPicker.answerFieldTitle': 'Answer',
  'campaignManagement.contentPageBuilder.placementPicker.landingFaqAccordionTitle': 'FAQ #',
}

const CONTENT_PAGE_BUILDER_LANDING_VALUE_PROP_EDITOR = {
  'campaignManagement.contentPageBuilder.landingValuePropEditor.groupHeader': 'Value Props',
  'campaignManagement.contentPageBuilder.placementPicker.landingValuePropListTitle': 'Value Props',
  'campaignManagement.contentPageBuilder.placementPicker.landingValuePropTitle': 'Title',
  'campaignManagement.contentPageBuilder.placementPicker.landingValuePropDetails': 'Details',
  'campaignManagement.contentPageBuilder.placementPicker.landingValuePropImage': 'Image',
  'campaignManagement.contentPageBuilder.placementPicker.landingValuePropEyebrow': 'Eyebrow',
  'campaignManagement.contentPageBuilder.placementPicker.propListType': 'Prop List Type',
  'campaignManagement.contentPageBuilder.placementPicker.landingValuePropValuePropItem':
    'Value Prop #',
}

const CONTENT_PAGE_BUILDER_SLOT_PLACEMENT_EN_US = {
  'campaignManagement.contentPageBuilder.campaignSlotForm.heading': 'Dynamic slot',
  'campaignManagement.contentPageBuilder.campaignSlotForm.slotDescription':
    'This slot type is reserved for campaign content. You can add and update creatives directly in the Campaigns & Offers tool.',
  'campaignManagement.contentPageBuilder.campaignSlotForm.slotName': 'Name this slot',
  'campaignManagement.contentPageBuilder.campaignSlotForm.readDocumentation': 'Read Documentation',
  'campaignManagement.contentPageBuilder.campaignSlotForm.secondaryBannerCarousel.enableAutoScroll':
    'Enable Auto Scroll',
}

const CONTENT_PAGE_BUILDER_BANNER_PLACEMENT = {
  'campaignManagement.contentPageBuilder.richTextEditor.heading': 'Text Container',
  'campaignManagement.contentPageBuilder.bannerPlacement.button': 'Button',
  'campaignManagement.contentPageBuilder.bannerPlacement.buttonText': 'Button Text',
  'campaignManagement.contentPageBuilder.bannerPlacement.buttonColor': 'Button Color',
  'campaignManagement.contentPageBuilder.bannerPlacement.buttonTextColor': 'Button Text Color',
  'campaignManagement.contentPageBuilder.bannerPlacement.headerText': 'Header Text',
  'campaignManagement.contentPageBuilder.bannerPlacement.headerTextColor': 'Header Color',
  'campaignManagement.contentPageBuilder.bannerPlacement.subtitleText': 'Subtitle Text',
  'campaignManagement.contentPageBuilder.bannerPlacement.subtitleTextColor': 'Subtitle Text Color',
  'campaignManagement.contentPageBuilder.bannerPlacement.backgroundColorOverlay':
    'Background Color Overlay',
  'campaignManagement.contentPageBuilder.bannerPlacement.altText': 'Alt Text',
  'campaignManagement.contentPageBuilder.bannerPlacement.largeScreen': 'Large Screen',
  'campaignManagement.contentPageBuilder.bannerPlacement.smallScreen': 'Small Screen',
  'campaignManagement.contentPageBuilder.bannerPlacement.largeScreen.tips':
    'For desktops and laptops',
  'campaignManagement.contentPageBuilder.bannerPlacement.smallScreen.tips':
    'For common phones and tablets',
  'campaignManagement.contentPageBuilder.bannerPlacement.recommendedSize':
    'Recommended image resolution:',
  'campaignManagement.contentPageBuilder.bannerPlacement.colorOverlaySectionHeading':
    'Color overlay',
  'campaignManagement.contentPageBuilder.bannerPlacement.colorOverlayDescription':
    'Choose a background color for the area behind your text and button. This option changes the image aspect ratio.',
  'campaignManagement.contentPageBuilder.bannerPlacement.colorOverlayTitle':
    'Text and button over a colored overlay',
}

const CONTENT_PAGE_BUILDER_RECIPE_PLACEMENTS = {
  'campaignManagement.contentPageBuilder.recipes.placementForm.recipeCategoryLabel':
    'Recipe Category',
  'campaignManagement.contentPageBuilder.recipes.placementForm.recipeCategoriesLabel':
    'Recipe Categories',
  'campaignManagement.contentPageBuilder.recipes.placementForm.recipeCategoriesDescription':
    'The categories to show in this carousel',
}

const CONTENT_PAGE_BUILDER_SHOPPABLE_ITEM_LIST = {
  'campaignManagement.contentPageBuilder.shoppable.shoppable': 'Featured',
  'campaignManagement.contentPageBuilder.shoppable.collection': 'Collection',
  'campaignManagement.contentPageBuilder.shoppable.internalName': 'Name',
  'campaignManagement.contentPageBuilder.shoppable.internalNameRequired': 'Name is required',
  'campaignManagement.contentPageBuilder.shoppable.shoppableTitle': 'Featured Carousel Title',
  'campaignManagement.contentPageBuilder.shoppable.title': 'Title',
  'campaignManagement.contentPageBuilder.shoppable.titleRequired': 'Title is required',
  'campaignManagement.contentPageBuilder.shoppable.subTitle': 'Subtitle',
  'campaignManagement.contentPageBuilder.shoppable.cta': 'Call To Action',
  'campaignManagement.contentPageBuilder.shoppable.ctaRequired': 'Call To Action is required',
  'campaignManagement.contentPageBuilder.shoppable.createdBy': 'Created By',
  'campaignManagement.contentPageBuilder.shoppable.editButton': 'Edit',
  'campaignManagement.contentPageBuilder.shoppable.removePlacement': 'Remove creative',
  'campaignManagement.contentPageBuilder.shoppable.notAvailable': 'Not available at this time.',
}

const CONTENT_PAGE_BUILDER_QUICK_LINK_CAROUSEL = {
  'campaignManagement.contentPageBuilder.quickLink.pillDetail': 'Pill details',
  'campaignManagement.contentPageBuilder.quickLink.selectStyle': 'Select a style',
  'campaignManagement.contentPageBuilder.quickLink.textLabel': 'Text label',
  'campaignManagement.contentPageBuilder.quickLink.textOnly': 'Text Only',
  'campaignManagement.contentPageBuilder.quickLink.textOnly.subtitle': 'Displays text label only',
  'campaignManagement.contentPageBuilder.quickLink.iconTitle': 'Icon & Title',
  'campaignManagement.contentPageBuilder.quickLink.iconTitle.subtitle':
    'Displays an icon next to the label',
}

const CONTENT_PAGE_BUILDER_RETAILER_FORWARD_LIST = {
  'campaignManagement.contentPageBuilder.retailerForwardList.moment': 'Campaign Moment',
  'campaignManagement.contentPageBuilder.retailerForwardList.momentCheckLabel':
    'Only show retailers participating in campaign moment',
  'campaignManagement.contentPageBuilder.retailerForwardList.imageRow': 'Desktop Image',
  'campaignManagement.contentPageBuilder.retailerForwardList.mobileImageRow': 'Mobile Image',
}

const CONTENT_PAGE_BUILDER_PRODUCTS_ITEM_LIST = {
  'campaignManagement.contentPageBuilder.products': 'Standard',
  'campaignManagement.contentPageBuilder.products.collection': 'Collection',
  'campaignManagement.contentPageBuilder.products.internalName': 'Name',
  'campaignManagement.contentPageBuilder.products.internalNameRequired': 'Name is required',
  'campaignManagement.contentPageBuilder.products.productsTitle': 'Products Title',
  'campaignManagement.contentPageBuilder.products.title': 'Title',
  'campaignManagement.contentPageBuilder.products.titleRequired': 'Title is required',
  'campaignManagement.contentPageBuilder.products.editButton': 'Edit',
}

const HOME_PAGE_EN_US = {
  'campaignManagement.themePages.homepage.title': 'Homepage Manager',
}

const PLACEMENT_TOOLING_EN_US = {
  'campaignManagement.placementTooling.destination.createNewCollection': 'Create a new collection',
  'campaignManagement.placementTooling.placementSaveSuccess': 'Changes saved.',
  'campaignManagement.placementTooling.placementSaveFailed':
    'There was an error while saving changes to the creative.',
  'campaignManagement.placementTooling.required': 'Required',
  'campaignManagement.placementTooling.save': 'Done',
  'campaignManagement.placementTooling.publish': 'Publish',
  'campaignManagement.placementTooling.discard': 'Cancel',
  'campaignManagement.placementTooling.show': 'Show',
  'campaignManagement.placementTooling.hide': 'Hide',
  'campaignManagement.placementTooling.notify.placementSaved':
    'Creative has been successfully stored',
  'campaignManagement.placementTooling.notify.placementDeleted':
    'Creative has been successfully removed',
  'campaignManagement.placementTooling.notify.localeDeleted':
    'locale has been successfully removed',
  'campaignManagement.placementTooling.notify.bannerDeleted':
    'banner has been successfully removed',
  'campaignManagement.placementTooling.notify.placementSavedFail': 'Unable to store the creative',
  'campaignManagement.placementTooling.notify.placementDeletedFail':
    'Unable to remove the creative',
  'campaignManagement.placementTooling.itemListPlacementForm.heading':
    'Product Carousel • Standard',
  'campaignManagement.placementTooling.itemListShoppablePlacementForm.heading':
    'Product Carousel • Featured',
  'campaignManagement.placementTooling.itemListCouponsPlacementForm.heading':
    'Product Carousel • Coupons',
  'campaignManagement.placementTooling.itemListFeaturedPlacementForm.heading':
    'Ads Carousel • Sponsored',
  'campaignManagement.placementTooling.gridItemListCouponsPlacementForm.heading':
    'Product Grid • Coupons',
  'campaignManagement.placementTooling.gridItemListCouponsPreview.placeholder': 'Products',
  'campaignManagement.placementTooling.flyerItemListPlacementForm.heading':
    'Product Carousel • Flyers',
  'campaignManagement.placementTooling.imageTileCarouselPlacementForm.heading':
    'Image Tile Carousel',
  'campaignManagement.placementTooling.retailerForwardListPlacementForm.heading': 'Retailer List',
  'campaignManagement.placementTooling.quickLinkCarouselPlacementForm.heading':
    'Quick Link Carousel',
  'campaignManagement.placementTooling.flyerItemListPlacementForm.tagSelectPlaceholder':
    'Flyer Tag',
  'campaignManagement.placementTooling.itemListPlacementForm.selectACollection':
    'Select a collection',
  'campaignManagement.placementTooling.universalValueCarouselPlacementForm.heading':
    'Universal Value Carousel',
  'campaignManagement.placementTooling.OrderStatus.heading': 'Order Status',
  'campaignManagement.placementTooling.OrderStatus.placeholder':
    "We'll let you know when we assign a personal shopper to your order",
  'campaignManagement.placementTooling.recipesCarousel.heading': 'Recipes Carousel',
  'campaignManagement.placementTooling.recipeCategoriesCarousel.heading':
    'Recipe Category Carousel',
  'campaignManagement.placementTooling.recipesCarouselPlacementForm.categoryPlaceholder':
    'Category',
  'campaignManagement.placementTooling.userRecipes.heading': 'Saved Recipes',
  'campaignManagement.placementTooling.localization': 'Localization',
  'campaignManagement.placementTooling.localization.addLocale': 'Add Locale',
  'campaignManagement.placementTooling.localization.noLocalesAdded': 'No Locales Added',
  'campaignManagement.placementTooling.localization.optional': 'Optional',
  'campaignManagement.placementTooling.localization.description':
    'Add a locale to create a version in a new language',
  'campaignManagement.placementTooling.themeColor.black': 'Black',
  'campaignManagement.placementTooling.themeColor.white': 'White',
  'campaignManagement.placementTooling.themeColor.brandPrimaryRegular': 'Primary Regular',
  'campaignManagement.placementTooling.themeColor.brandPrimaryDark': 'Primary Dark',
  'campaignManagement.placementTooling.themeColor.brandSecondaryRegular': 'Secondary Regular',
  'campaignManagement.placementTooling.themeColor.brandSecondaryLight': 'Secondary Light',
  'campaignManagement.placementTooling.themeColor.brandSecondaryDark': 'Secondary Dark',
  'campaignManagement.placementTooling.themeColor.brandTertiaryRegular': 'Brand Tertiary Regular',
  'campaignManagement.placementTooling.themeColor.brandPrimaryExtraDark':
    'Brand Primary Extra Dark',
  'campaignManagement.placementTooling.themeColor.brandPrimaryLight': 'Brand Primary Light',
  'campaignManagement.placementTooling.themeColor.brandSecondaryExtraDark':
    'Brand Secondary Extra Dark',
  'campaignManagement.placementTooling.themeColor.brandHighlightRegular': 'Brand Highlight Regular',
  'campaignManagement.placementTooling.themeColor.brandHighlightDark': 'Brand Highlight Dark',
  'campaignManagement.placementTooling.themeColor.brandHighlightLight': 'Brand Highlight Light',
  'campaignManagement.placementTooling.themeColor.brandLoyaltyRegular': 'Brand Loyalty Regular',
  'campaignManagement.placementTooling.themeColor.brandLoyaltyDark': 'Brand Loyalty Dark',
  'campaignManagement.placementTooling.themeColor.brandLoyaltyLight': 'Brand Loyalty Light',
  'campaignManagement.placementTooling.themeColor.brandExpressRegular': 'Brand Express Regular',
  'campaignManagement.placementTooling.themeColor.brandExpressDark': 'Brand Express Dark',
  'campaignManagement.placementTooling.themeColor.brandExpressExtraDark':
    'Brand Express Extra Dark',
  'campaignManagement.placementTooling.themeColor.brandExpressLight': 'Brand Express Light',
  'campaignManagement.placementTooling.themeColor.brandTertiaryLight': 'Brand Tertiary Light',
  'campaignManagement.placementTooling.themeColor.brandTertiaryDark': 'Brand Tertiary Dark',
  'campaignManagement.placementTooling.themeColor.systemSuccessExtraDark':
    'System Success Extra Dark',
  'campaignManagement.placementTooling.themeColor.systemDetrimentalRegular':
    'System Detrimental Regular',
  'campaignManagement.placementTooling.themeColor.systemDetrimentalDark': 'System Detrimental Dark',
  'campaignManagement.placementTooling.themeColor.systemDetrimentalExtraDark':
    'System Detrimental Extra Dark',
  'campaignManagement.placementTooling.themeColor.systemDetrimentalLight':
    'System Detrimental Light',
  'campaignManagement.placementTooling.themeColor.systemGrayscale99': 'System Grayscale 99',
  'campaignManagement.placementTooling.themeColor.systemGrayscale90': 'System Grayscale 90',
  'campaignManagement.placementTooling.themeColor.systemGrayscale80': 'System Grayscale 80',
  'campaignManagement.placementTooling.themeColor.systemGrayscale70': 'System Grayscale 70',
  'campaignManagement.placementTooling.themeColor.systemGrayscale60': 'System Grayscale 60',
  'campaignManagement.placementTooling.themeColor.systemGrayscale50': 'System Grayscale 50',
  'campaignManagement.placementTooling.themeColor.systemGrayscale40': 'System Grayscale 40',
  'campaignManagement.placementTooling.themeColor.systemGrayscale30': 'System Grayscale 30',
  'campaignManagement.placementTooling.themeColor.systemGrayscale20': 'System Grayscale 20',
  'campaignManagement.placementTooling.themeColor.systemGrayscale10': 'System Grayscale 10',
  'campaignManagement.placementTooling.themeColor.systemGrayscale00': 'System Grayscale 00',
  'campaignManagement.placementTooling.themeColor.plusExtraLight': 'Plus Extra Light',
  'campaignManagement.placementTooling.themeColor.plusLight': 'Plus Light',
  'campaignManagement.placementTooling.themeColor.plusRegular': 'Plus Regular',
  'campaignManagement.placementTooling.themeColor.plusDark': 'Plus Dark',
  'campaignManagement.placementTooling.themeColor.plusExtraDark': 'Plus Extra Dark',
  'campaignManagement.placementTooling.themeColor.brandBusinessBlueberry':
    'Brand Business Blueberry',
  'campaignManagement.placementTooling.themeColor.brandBusinessElderberry':
    'Brand Business Elderberry',
  'campaignManagement.placementTooling.themeColor.brandMaxDark': 'Brand Max Dark',
  'campaignManagement.placementTooling.themeColor.brandLime': 'Brand Lime',
  'campaignManagement.placementTooling.themeColor.brandKale': 'Brand Kale',
  'campaignManagement.placementTooling.themeColor.brandCashew': 'Brand Cashew',
  'campaignManagement.placementTooling.themeColor.brandLemon': 'Brand Lemon',
  'campaignManagement.placementTooling.themeColor.brandPlum': 'Brand Plum',
}

const PLACEMENT_FORM_EN_US = {
  'campaignManagement.placementForm.bannerVariantTitle': 'Select banner variant',
  'campaignManagement.placementForm.fieldOptional': '(optional)',
  'campaignManagement.placementForm.buttonTitle': 'Button',
  'campaignManagement.placementForm.textTitle': 'Text',
  'campaignManagement.placementForm.altTextTitle': 'Alt text',
  'campaignManagement.placementForm.collection.icOwned': 'Instacart created',
  'campaignManagement.placementForm.collection.retailerCreated': 'Retailer created',
  'campaignManagement.placementForm.destinationTitle': 'Destination',
  'campaignManagement.placementForm.destinationDescription': 'Select a destination type',
  'campaignManagement.placementForm.admin.destinationDescription': 'Select a destination type',
  'campaignManagement.placementForm.collectionRequired': 'Please select a collection',
  'campaignManagement.placementForm.urlRequired': 'Please provide a valid URL',
  'campaignManagement.placementForm.bannerNameTitle': 'Banner name',
  'campaignManagement.placementForm.bannerNameRequired': 'Banner Name is required',
  'campaignManagement.placementForm.altText': 'Alt Text',
  'campaignManagement.placementForm.altTextRequired': 'Alt Text is required',
  'campaignManagement.placementForm.imageRequired': 'Screen Image is required',
  'campaignManagement.placementForm.recommendedSize': 'Recommended image resolution:',
  'campaignManagement.placementForm.tileImage': 'Tile Image',
  'campaignManagement.placementForm.coverImage': 'Cover Image',
  'campaignManagement.placementForm.largeImageRequired': 'Large Screen Image is required',
  'campaignManagement.placementForm.smallImageRequired': 'Small Screen Image is required',
  'campaignManagement.placementForm.destinationType': 'Destination Type',
  'campaignManagement.placementForm.flyer': 'Flyer',
  'campaignManagement.placementForm.flyerTag': 'Flyer Tags',
  'campaignManagement.placementForm.allFlyers': 'All Flyers',
  'campaignManagement.placementForm.destination.whereToLink': 'Where would you like to link to?',
  'campaignManagement.placementForm.destination.chooseDestination':
    'Where would you like to link to?',
  'campaignManagement.placementForm.destination.filterByPageType': 'Filter by page type',
  'campaignManagement.placementForm.destination.defaultPage': 'Default & content pages',
  'campaignManagement.placementForm.destination.collectionPage': 'Departments & collections',
  'campaignManagement.placementForm.destination.flyerPage': 'Flyers',
  'campaignManagement.placementForm.destination.loginSignUp': 'Log in / Sign up',
  'campaignManagement.placementForm.destination.selectDestinationType': 'Select destination type',
  'campaignManagement.placementForm.destination.searchCollectionPage':
    'Search departments & collections',
  'campaignManagement.placementForm.destination.searchContentPage':
    'Search default & content pages',
  'campaignManagement.placementForm.destination.searchFlyerPage': 'Search flyers',
  'campaignManagement.placementForm.destination.selectedNoDestination':
    'You’ve selected <b>No Destination</b>. Your creative will not be clickable.',
  'campaignManagement.placementForm.destination.whichWebAddress': 'What’s the web address (URL)?',
  'campaignManagement.placementForm.destination.openUrlIn': 'Open URL in',
  'campaignManagement.placementForm.destination.sameTab': 'Same tab (web)',
  'campaignManagement.placementForm.destination.sameTab.subtitle':
    'Opens in a webview for native mobile',
  'campaignManagement.placementForm.destination.newTab': 'New tab (web)',
  'campaignManagement.placementForm.destination.newTab.subtitle':
    'Opens in a external browser for native mobile',
  'campaignManagement.placementForm.allFlyers.description':
    'Show listing when multiple flyers are available, otherwise show the latest flyer.',
  'campaignManagement.placementForm.destinationUrlTips': `Without "https://" in the URL, it will be treated as a relative link.`,
  'campaignManagement.placementForm.openInSameTab': 'Open URL in Same Tab',
  'campaignManagement.placementForm.openInNewTab': 'Open URL in New Tab',
  'campaignManagement.placementForm.loginPath': 'Login Path',
  'campaignManagement.placementForm.signUpPath': 'Sign up Path',
  'campaignManagement.placementForm.visibilityConditionsRowTitle': 'Visibility Conditions',
  'campaignManagement.placementForm.visibilityConditionsRow.platform': 'Platform',
  'campaignManagement.placementForm.visibilityConditionsRow.platform.android': 'Android',
  'campaignManagement.placementForm.visibilityConditionsRow.platform.ios': 'IOS',
  'campaignManagement.placementForm.visibilityConditionsRow.platform.web': 'Web',
  'campaignManagement.placementForm.visibilityConditionsRow.platform.mobile': 'Mobile',
  'campaignManagement.placementForm.visibilityConditionsRow.userAuthenticationStatus':
    'Login state',
  'campaignManagement.placementForm.visibilityConditionsRow.userAuthenticationStatus.all':
    'All login states',
  'campaignManagement.placementForm.visibilityConditionsRow.userAuthenticationStatus.authenticated':
    'Logged in',
  'campaignManagement.placementForm.visibilityConditionsRow.userAuthenticationStatus.unauthenticated':
    'Logged out',
  'campaignManagement.placementForm.visibilityConditionsRow.matchesAnyRetailerLocationId':
    'Store locations',
  'campaignManagement.placementForm.visibilityConditionsRow.matchesAnyRetailerLocationId.allStores': `All store locations`,
  'campaignManagement.placementForm.visibilityConditionsRow.matchesAnyRetailerLocationId.storesSelected': `store locations`,
  'campaignManagement.placementForm.visibilityConditionsRow.matchesAnyRetailerLocationId.selectAll': `Select all stores`,
  'campaignManagement.placementForm.bannerAssets.header': 'Banner Assets',
  'campaignManagement.placementForm.bannerAssets.desktopImage.title': 'Desktop Image',
  'campaignManagement.placementForm.bannerAssets.partnershipImage.title':
    'Desktop partnership image',
  'campaignManagement.placementForm.bannerAssets.partnershipMobileImage.title':
    'Mobile partnership image',
  'campaignManagement.placementForm.bannerAssets.bannerBackgroundColor.title':
    'Banner background color',
  'campaignManagement.placementForm.bannerAssets.mobileImage.title': 'Mobile Image',
  'campaignManagement.placementForm.bannerAssets.desktopImage.subtitle':
    'Recommended size: 584 x 212',
  'campaignManagement.placementForm.bannerAssets.mobileImage.subtitle':
    'Recommended size: 335 x 140',
  'campaignManagement.placementForm.bannerAssets.partnershipImage.subtitle':
    'For co-marketing partnership logos only. Recommended asset size: 190 x 28px. PNG on transparent background.',
  'campaignManagement.placementForm.text.textStyle.label': 'Select a text style',
  'campaignManagement.placementForm.text.textStyle.radio.titleOnlyOption': 'Title Only',
  'campaignManagement.placementForm.text.textStyle.radio.titleOnlySubtitle':
    'Displays two lines of title',
  'campaignManagement.placementForm.text.textStyle.radio.titleAndSubtitleOption':
    'Title and Subtitle',
  'campaignManagement.placementForm.text.textStyle.radio.titleAndSubtitleSubtitle':
    'Displays one line of title and one of subtitle',
  'campaignManagement.placementForm.text.titleLine1': 'Title line 1',
  'campaignManagement.placementForm.text.titleLine2': 'Title line 2',
  'campaignManagement.placementForm.text.subtitle': 'Subtitle',
  'campaignManagement.placementForm.text.highlightHelpText':
    'Note: To support the max highlight on your text, you will need to add a prefix of <highlight> and suffix of </highlight> around the text to be highlighted.',
  'campaignManagement.placementForm.text.highlightHelpText.example': 'Example:',
  'campaignManagement.placementForm.text.textColor': 'Text color',
  'campaignManagement.placementForm.text.highlightInput':
    'You are getting <highlight>$10</highlight> off.',
  'campaignManagement.placementForm.text.disclaimerText': 'Disclaimer text',
  'campaignManagement.placementForm.text.disclaimerText.placeholder': 'Optional',
  'campaignManagement.placementForm.characterCount': 'Character count: ',
  'campaignManagement.placementForm.button.buttonTextLabel': 'Button text',
  'campaignManagement.placementForm.button.textColor': 'Text color',
  'campaignManagement.placementForm.button.buttonColor': 'Button color',
  'campaignManagement.placementForm.admin.destination.icDomain': 'Instacart domain',
  'campaignManagement.placementForm.admin.destination.externalUrl': 'External URL',
  'campaignManagement.placementForm.admin.destination.none': 'None',
  'campaignManagement.placementForm.admin.destination.urlLabel': 'Destination URL',
  'campaignManagement.placementForm.admin.destination.testLabel': 'Test URL (.com By Default)',
  'campaignManagement.placementForm.admin.destination.testLabelExternal': 'Test URL',
  'campaignManagement.placementForm.admin.destination.disclaimerText1':
    'Not all URL redirects are supported by the Universal Value Carousel. ',
  'campaignManagement.placementForm.admin.destination.disclaimerTitle': 'Disclaimer: ',
  'campaignManagement.placementForm.admin.destination.disclaimerLink':
    'Please reference this document',
  'campaignManagement.placementForm.admin.destination.disclaimerText2':
    " for the supported URLs and the do's/don'ts for the field above.",
  'campaignManagement.placementForm.admin.destination.redirectBehavior': 'Redirect behavior',
  'campaignManagement.placementForm.admin.eyebrow.header': 'Eyebrow styling',
  'campaignManagement.placementForm.admin.eyebrow.label': 'Select an eyebrow style',
  'campaignManagement.placementForm.admin.eyebrow.option.retailer': 'Retailer Logos',
  'campaignManagement.placementForm.admin.eyebrow.option.partnership': 'Partnership Image',
  'campaignManagement.placementForm.admin.eyebrow.option.retailer.description':
    'Automatically pulls in retailer logos from the destination',
  'campaignManagement.placementForm.admin.eyebrow.option.retailer.label': 'Retailer Ids',
  'campaignManagement.placementForm.admin.eyebrow.option.retailer.minilabel':
    'Comma separated list of ids of retailer logos to display (ex: 1,2,3)',
  'campaignManagement.placementForm.admin.eyebrow.option.customRetailer': 'Custom Retailer',
  'campaignManagement.placementForm.admin.eyebrow.option.customRetailer.description':
    'Choose which retailer logos to display',
  'campaignManagement.placementForm.admin.eyebrow.option.partnership.description':
    'Upload logo for the program / partnership',
  'campaignManagement.placementForm.admin.eyebrow.option.none': 'No Eyebrow',
  'campaignManagement.placementForm.admin.eyebrow.option.none.description':
    'No logos displayed on the top left',
  'campaignManagement.placementForm.admin.destination.icDomain.description':
    'Destination is an instacart.com/.ca page',
  'campaignManagement.placementForm.admin.destination.externalUrl.label':
    "What's the web address (URL)?",
  'campaignManagement.placementForm.admin.destination.externalUrl.description':
    'Destination is a 3rd party website',
  'campaignManagement.placementForm.admin.destination.none.description':
    'There will be no redirect for your creative',
  'campaignManagement.placementForm.admin.destination.openURL.sameTab': 'Same tab (web)',
  'campaignManagement.placementForm.admin.destination.openURL.newTab': 'New tab (web)',
  'campaignManagement.placementForm.admin.destination.openURL.sameTabDescription':
    'Opens in a webview for native mobile',
  'campaignManagement.placementForm.admin.destination.openURL.newTabDescription':
    'Opens in a external browser for native mobile',
  'campaignManagement.placementForm.admin.destination.openURL.label': 'Open URL in',
  'campaignManagement.placementForm.rank.title': 'Banner Rank',
  'campaignManagement.placementForm.rank.label': 'Rank',
  'campaignManagement.placementForm.rank.description':
    'This will dictate where your banner gets placed within the UVC on the Home Page.\nThe default rank for non-incentive banners is 5.',
  'campaignManagement.placementForm.rank.placeholder': 'Enter a number',
}

const CREATIVE_MANAGER_EN_US = {
  'campaignManagement.creativeManager.untitled': 'Untitled',
  'campaignManagement.creativeManager.hubTitle': 'Global placements',
  'campaignManagement.creativeManager.hubTableTitle': 'Global placements',
  'campaignManagement.creativeManager.creativeTitle': 'Creatives',
  'campaignManagement.creativeManager.creativeTableTitle': 'Creatives',
  'campaignManagement.creativeManager.newCreative': 'New creative',
  'campaignManagement.creativeManager.edit': 'Edit',
  'campaignManagement.creativeManager.duplicate': 'Duplicate',
  'campaignManagement.creativeManager.delete': 'Delete',
  'campaignManagement.creativeManager.cancel': 'Cancel',
  'campaignManagement.creativeManager.set-active': 'Set Active',
  'campaignManagement.creativeManager.confirm-delete-name':
    'Are you sure you want to delete {name} ?',
  'campaignManagement.creativeManager.confirm-delete-confirmation': 'Delete confirmation',
  'campaignManagement.creativeManager.confirmEditName':
    'This creative is used in an active campaign. Any edits to the creative will take effect immediately. Are you sure you want to edit {name}?',
  'campaignManament.creativeManager.confirmEditHeader': 'Edit Creative in an Active Campaign',
  'campaignManagement.creativeManager.selector.title':
    "Select the type of creative you'd like to add",
  'campaignManagement.creativeManager.selector.primaryCta': 'Launch creative builder',
  'campaignManagement.creativeManager.selector.secondaryCta': 'Cancel',

  'campaignManagement.creativeManager.table.column.placementName': 'Creative name',
  'campaignManagement.creativeManager.table.column.placementType': 'Creative type',
  'campaignManagement.creativeManager.table.column.creativeName': 'Creative name',
  'campaignManagement.creativeManager.table.column.creativeType': 'Creative type',
  'campaignManagement.creativeManager.table.column.status': 'Status',
  'campaignManagement.creativeManager.table.column.location': 'Location',
  'campaignManagement.creativeManager.table.column.banner': 'Banner',
  'campaignManagement.creativeManager.table.column.site': 'Site',
  'campaignManagement.creativeManager.table.column.dateModified': 'Date modified',
  'campaignManagement.creativeManager.table.variant.hero': 'Primary Carousel',
  'campaignManagement.creativeManager.table.variant.hero_single': 'Primary Banner',
  'campaignManagement.creativeManager.table.variant.secondary': 'Secondary Carousel',
  'campaignManagement.creativeManager.table.variant.hero_split': 'Primary Split',
  'campaignManagement.creativeManager.table.variant.secondary_split': 'Secondary Split',
  'campaignManagement.creativeManager.table.variant.over_header': 'Over Header',
  'campaignManagement.creativeManager.table.variant.carousel_card': 'Carousel Card',
  'campaignManagement.creativeManager.table.variant.value_carousel_card': 'Value Carousel Card',
  'campaignManagement.creativeManager.table.variant.flyout': 'Flyout',
  'campaignManagement.creativeManager.table.variant.image_tile': 'Image Tile',
  'campaignManagement.creativeManager.table.variant.image_tile_card': 'Image Tile',
  'campaignManagement.creativeManager.table.variant.quick_link_pill': 'Quick Link',
  'campaignManagement.creativeManager.table.variant.shoppable_display_item_list':
    'Featured Collection Carousel',
  'campaignManagement.creativeManager.table.variant.item_list_coupons': 'Coupons Product Carousel',
  'campaignManagement.creativeManager.table.variant.item_list_flyer': 'Flyer Product Carousel',
  'campaignManagement.creativeManager.table.variant.secondary_banner': 'Secondary Banner',
  'campaignManagement.creativeManager.table.variant.primary_banner': 'Primary Banner',
  'campaignManagement.creativeManager.table.variant.primary_split_banner': 'Primary Split Banner',
  'campaignManagement.creativeManager.table.variant.item_list': 'Standard Product Carousel',

  'campaignManagement.creativeManager.notification.title':
    'Once you are ready to assign your creative to a page, head over to the Campaigns & Offers tool',
  'campaignManagement.creativeManager.notification.content':
    'Choose “Add from existing” when selecting the creative type and search for your desired creative by name.',
  'campaignManagement.creativeManager.notification.buttonText': 'Campaigns & Offers',

  'campaignManagement.creativeManager.placementPicker.announcementBannerLabel':
    'Announcement banner',
  'campaignManagement.creativeManager.placementPicker.shoppableProductCarousel':
    'Featured collection carousel',
  'campaignManagement.creativeManager.placementPicker.imageTileCarousel':
    'Image Tile Carousel (single)',
  'campaignManagement.creativeManager.placementPicker.universalValueCarousel':
    'Universal Value Carousel',
  'campaignManagement.creativeManager.placementPicker.quickLinkCarousel':
    'Quick Link Carousel (single)',
  'campaignManagement.creativeManager.placementPicker.flyoutLabel': 'Fly out creative',
  'campaignManagement.creativeManager.placementPicker.heroBannerSingle': 'Primary banner',
  'campaignManagement.creativeManager.placementPicker.secondaryBannerSingle': 'Secondary banner',
  'campaignManagement.creativeManager.placementPicker.secondaryBannerCarousel':
    'Secondary carousel banner (single)',
  'campaignManagement.creativeManager.placementPicker.heroBannerCarousel':
    'Primary carousel banner (single)',
  'campaignManagement.creativeManager.placementPicker.heroBannerCarouselSplit':
    'Primary split banner (single)',
  'campaignManagement.creativeManager.placementPicker.secondaryBannerCarouselSplit':
    'Secondary split banner (single)',

  'campaignManagement.creativeManager.filters.search': 'Search',
  'campaignManagement.creativeManager.filters.creativeTypes': 'Creative types',
  'campaignManagement.creativeManager.filters.sites': 'Sites',
  'campaignManagement.creativeManager.filters.banners': 'Banners',
  'campaignManagement.creativeManager.slotCreativePicker.modalHeader':
    'Add a creative to {slotDisplayName}',
  'campaignManagement.creativeManager.slotCreativePicker.noResults.heading':
    'No creative available',
  'campaignManagement.creativeManager.slotCreativePicker.noResults.subheading':
    'There is no existing creative for this slot on the page. You can create a new creative now.',
  'campaignManagement.creativeManager.slotCreativePicker.noResults.cta': 'Create a new creative',
  'campaignManagement.creativeManager.slotCreativePicker.creativesAvailable':
    '{creativesAvailableCount} creatives available for {slotDisplayName} on {pageTypeDisplayName}',
  'campaignManagement.creativeManager.slotCreativePicker.searchPlaceholder':
    'Search by creative name',
  'campaignManagement.creativeManager.slotCreativePicker.destination.page':
    'Default & content page',
  'campaignManagement.creativeManager.slotCreativePicker.destination.collection':
    'Department & collection',
  'campaignManagement.creativeManager.slotCreativePicker.destination.url': 'URL',
  'campaignManagement.creativeManager.slotCreativePicker.destination.none': 'No destination',
  'campaignManagement.creativeManager.slotCreativePicker.destination.filterPlaceholder':
    'Destination',
  'campaignManagement.creativeManager.slotCreativePicker.createNew': 'Create new',
  'campaignManagement.creativeManager.slotCreativePicker.addSelected': 'Add selected',
  'campaignManagement.creativeManager.slotCreativePicker.gridHeader.creative': 'Creative',
  'campaignManagement.creativeManager.slotCreativePicker.gridHeader.destination':
    'Destination type',
  'campaignManagement.creativeManager.slotCreativePicker.gridHeader.dateModified': 'Date modified',
  'campaignManagement.creativeManager.slotCreativePicker.gridHeader.author': 'Author',
  'campaignManagement.creativeManager.readonlyPlacementDetails.creativeName': 'Creative name',
  'campaignManagement.creativeManager.readonlyPlacementDetails.lastUpdated': 'Last updated',
  'campaignManagement.creativeManager.readonlyPlacementDetails.altText': 'Alt text',
  'campaignManagement.creativeManager.readonlyPlacementDetails.desktopImage': 'Desktop image',
  'campaignManagement.creativeManager.readonlyPlacementDetails.mobileImage': 'Phone & tablet image',
  'campaignManagement.creativeManager.readonlyPlacementDetails.image': 'Image',
  'campaignManagement.creativeManager.readonlyPlacementDetails.bannerVariant': 'Banner variant',
  'campaignManagement.creativeManager.readonlyPlacementDetails.slotType': 'Slot type',
  'campaignManagement.creativeManager.readonlyPlacementDetails.destination': 'Destination',
  'campaignManagement.creativeManager.readonlyPlacementDetails.destination.none': 'No Destination',
  'campaignManagement.creativeManager.readonlyPlacementDetails.createdBy': 'Created by',
}

const ADMIN_CREATIVE_MANAGER_EN_US = {
  'campaignManagement.admin.creativeManager.nav.title': 'Creative Manager',
  'campaignManagement.admin.creativeManager.enabled': 'Enabled',
  'campaignManagement.admin.creativeManager.disabled': 'Disabled',
  'campaignManagement.admin.creativeManager.cancel': 'Cancel',
  'campaignManagement.admin.creativeManager.edit': 'Edit',
  'campaignManagement.admin.creativeManager.delete': 'Delete',
  'campaignManagement.admin.creativeManager.visibility': 'Visibility',
  'campaignManagement.admin.creativeManager.modal.delete-content':
    'Are you sure you want to delete {name} ?',
  'campaignManagement.admin.creativeManager.modal.delete-confirm': 'Delete confirmation',
  'campaignManagement.admin.creativeManager.table.column.name': 'Name',
  'campaignManagement.admin.creativeManager.table.column.platform': 'Platform',
  'campaignManagement.admin.creativeManager.table.column.locale': 'Locale',
  'campaignManagement.admin.creativeManager.table.column.type': 'Type',
  'campaignManagement.admin.creativeManager.table.column.date': 'Date',
  'campaignManagement.admin.creativeManager.table.column.enabled': 'Enabled',
  'campaignManagement.admin.creativeManager.table.column.status': 'Status',
  'campaignManagement.admin.creativeManager.table.column.actions': 'Actions',
  'campaignManagement.admin.creativeManager.createNew': 'New Creative',
  'campaignManagement.admin.creativeManager.noEndDate': 'No End Date',
}

const ADMIN_REDEMPTION_URLS_EN_US = {
  'campaignManagement.admin.redemptionUrls.title': 'Redemption URL jobs',
  'campaignManagement.admin.redemptionUrls.create': 'Create URLs',
  'campaignManagement.admin.redemptionUrls.refresh': 'Refresh results',
  'campaignManagement.admin.redemptionUrls.form.submit': 'Submit',
  'campaignManagement.admin.redemptionUrls.form.label.count': 'Count',
  'campaignManagement.admin.redemptionUrls.form.placeholder.count': 'number of urls to generate',
  'campaignManagement.admin.redemptionUrls.form.label.name': 'Name',
  'campaignManagement.admin.redemptionUrls.form.placeholder.name': 'internal name of the job',
  'campaignManagement.admin.redemptionUrls.form.label.url': 'URL',
  'campaignManagement.admin.redemptionUrls.form.placeholder.url': 'url to the landing page',
  'campaignManagement.admin.redemptionUrls.form.label.omitUuid': 'Make a generic url',
  'campaignManagement.admin.redemptionUrls.form.label.userSegment': 'User Segment',
  'campaignManagement.admin.redemptionUrls.form.placeholder.userSegment':
    'comma separated values e.g - new, churned',
  'campaignManagement.admin.redemptionUrls.form.label.churnedDays': 'Churned Days',
  'campaignManagement.admin.redemptionUrls.form.placeholder.churnedDays':
    'how many days for churned user?',
  'campaignManagement.admin.redemptionUrls.form.label.discountPolicyId': 'Discount Policy ID',
  'campaignManagement.admin.redemptionUrls.form.placeholder.discountPolicyId':
    'if generic url, which discount policy id',
  'campaignManagement.admin.redemptionUrls.form.error': 'this field is required',
  'campaignManagement.admin.redemptionUrls.table.column.name': 'Name',
  'campaignManagement.admin.redemptionUrls.table.column.status': 'Status',
  'campaignManagement.admin.redemptionUrls.table.column.createdBy': 'Created by',
  'campaignManagement.admin.redemptionUrls.table.column.link': 'Download',
  'campaignManagement.admin.redemptionUrls.table.column.status.complete': 'Complete',
  'campaignManagement.admin.redemptionUrls.table.column.status.inProgress': 'In Progress',
  'campaignManagement.admin.redemptionUrls.table.column.status.invalidType': 'Invalid type',
  'campaignManagement.admin.redemptionUrls.table.noResults': 'No Redemption URL jobs to display',
  'campaignManagement.admin.redemptionUrls.table.error': 'There was an error fetching the URL jobs',
}

const ADMIN_MULTI_RETAILER_PAGES_EN_US = {
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.backgroundLabel': 'Background',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.backgroundColorLabel':
    'Background color',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.titleColorLabel': 'Title color',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.titleLabel': 'Title',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.imageLabel': 'Image',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.bannerLabel': 'Header Banner',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.bannerType': 'Banner Type',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.bannerTypeLabel':
    'Select a banner type',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.bannerType.textWithImage':
    'Text with Image',
  'campaignManagement.admin.multiRetailerPages.fullWidthBanner.bannerType.textOnly': 'Text Only',
  'campaignManagement.admin.multiRetailerPages.itemListHomeItemForward.cta': 'At {retailer_name}',
  'campaignManagement.admin.multiRetailerPages.itemListHomeItemForward.image': 'Image',
  'campaignManagement.admin.multiRetailerPages.titleRow.subtitle':
    'This is the title of the carousel that is visible to the user',
  'campaignManagement.admin.multiRetailerPages.disclaimerRow.label': 'Disclaimer',
  'campaignManagement.admin.multiRetailerPages.disclaimerRow.subtitle':
    'Disclaimer text for the carousel. e.g "Sponsored"',
  'campaignManagement.admin.multiRetailerPages.collectionRow.label': 'Retailer and Collection',
  'campaignManagement.admin.multiRetailerPages.collectionRow.label.recommended':
    'Auto-select best available',
  'campaignManagement.admin.multiRetailerPages.collectionRow.label.custom': 'Custom',
  'campaignManagement.admin.multiRetailerPages.collectionRow.label.select': 'Select collection',
  'campaignManagement.admin.multiRetailerPages.canonicalUrl.label.subtitle':
    'URL of a page that Google chooses as the most representative from a set of duplicate pages',
  'campaignManagement.admin.multiRetailerPages.instacartCurated': 'Instacart',
  'campaignManagement.admin.multiRetailerPages.retailerCurated': 'Retailer',
  'campaignManagement.admin.multiRetailerPages.retailerId': 'Retailer Id',
  'campaignManagement.admin.multiRetailerPages.collectionDescription': 'Select retailer',
  'campaignManagement.admin.multiRetailerPages.placementPicker.homeItemForward':
    'Item Forward Carousel',
  'campaignManagement.admin.multiRetailerPages.placementPicker.retailerForwardList':
    'Retailer Forward List',
  'campaignManagement.admin.multiRetailerPages.pageTypeLabel': 'Shopping page builder',
  'campaignManagement.admin.multiRetailerPages.pageTitle': 'Shopping page builder',
  'campaignManagement.admin.multiRetailerPages.title': 'Shopping page builder',
  'campaignManagement.admin.multiRetailerPages.newCTA': 'Create new shopping page',
  'campaignManagement.admin.multiRetailerPages.delete': 'Delete',
  'campaignManagement.admin.multiRetailerPages.layouts-list.columns.name.header':
    'Shopping page name',
  'campaignManagement.admin.multiRetailerPages.layouts-list.columns.createdOn.header': 'Created on',
  'campaignManagement.admin.multiRetailerPages.layouts-list.columns.status.header': 'Status',
  'campaignManagement.admin.multiRetailerPages.layouts-list.columns.status.active': 'Active',
  'campaignManagement.admin.multiRetailerPages.layouts-list.columns.status.draft': 'Draft',
  'campaignManagement.admin.multiRetailerPages.includeSponsoredProductsDescription':
    'Include sponsored products?',
  'campaignManagement.admin.multiRetailerPages.sponsoredProducts.label.affirmative': 'Yes',
  'campaignManagement.admin.multiRetailerPages.sponsoredProducts.label.negative': 'No',
  'campaignManagement.admin.multiRetailerPages.uvcSlot.displayName':
    'Universal Value Carousel Slot',
  'campaignManagement.admin.multiRetailerPages.uvcSlot.internalName': 'Slot name',
  'campaignManagement.admin.multiRetailerPages.uvcSlot.internalName.description':
    'Target UVC creatives to this placement with Slot ID: universal_value_carousel:multi_retailer_page',
}

const ERROR_MESSAGE_EN_US = {
  'campaignManagement.errorMsg.formValidation':
    'An error occurred during validation. Please check your selection.',
}

const EN_US = {
  'campaignManagementDomain.title': 'Campaign Management',
  'campaignManagement.calendar.title': 'Calendar',
  'campaignManagement.themePages.listPage.title': 'Theme page manager',
  'campaignManagement.themePages.listPage.noResults': 'No Theme Pages to show',
  'campaignManagement.themePages.listPage.createButton': 'Create Theme Page',
  'campaignManagement.themePages.listPage.itemMenu.edit': 'Edit Theme Page',
  'campaignManagement.themePages.listPage.itemMenu.delete': 'Delete Theme Page',
  'campaignManagement.themePages.listPage.itemMenu.addItemLists': 'Manage Placements',
  'campaignManagement.themePages.detailsPage.title': 'Theme Page Details',
  'campaignManagement.themePages.createPage.title': 'Create Theme Page',
  'campaignManagement.themePages.editPage.title': 'Edit Theme Page',
  'campaignManagement.themePages.columns.slug.header': 'Slug',
  'campaignManagement.themePages.columns.active.header': 'Active',
  'campaignManagement.themePages.columns.createdAt.header': 'Created At',
  'campaignManagement.themePages.columns.actions.header': 'Actions',
  'campaignManagement.themePages.status.active': 'True',
  'campaignManagement.themePages.status.inactive': 'False',
  'campaignManagement.themePages.status.active.definition': 'Theme page placement is enabled',
  'campaignManagement.themePages.status.inactive.definition': 'Theme page placement is disabled',
  'campaignManagement.themePages.details.placementSection.title': 'Placement Content',
  'campaignManagement.themePages.details.placementSection.id': 'Theme page id',
  'campaignManagement.themePages.details.placementSection.slug': 'Slug',
  'campaignManagement.themePages.details.headerSection.title': 'Header Content',
  'campaignManagement.themePages.details.headerSection.bannerId': 'Banner Id',
  'campaignManagement.themePages.details.headerSection.placementId': 'Placement Id',
  'campaignManagement.themePages.details.headerSection.bannerTitle': 'Banner Title',
  'campaignManagement.themePages.details.headerSection.titleColorHex': 'Title Color Hex',
  'campaignManagement.themePages.details.headerSection.subTitle': 'Banner Sub-Title',
  'campaignManagement.themePages.details.headerSection.subTitleColorHex': 'Sub Title Color Hex',
  'campaignManagement.themePages.details.headerSection.showRetailerPicker': 'Show Retailer Picker',
  'campaignManagement.themePages.details.headerSection.showSearchBar': 'Show Search Bar',
  'campaignManagement.themePages.details.headerSection.createdAt': 'Created At',
  'campaignManagement.themePages.details.headerSection.updatedAt': 'Updated At',
  'campaignManagement.themePages.details.imageSection.title': 'Banner Image',
  'campaignManagement.themePages.details.imageSection.imageDesktop': 'Desktop Image',
  'campaignManagement.themePages.details.imageSection.imageMobile': 'Mobile Image',
  'campaignManagement.themePages.details.imageSection.imageAltText': 'Image Alt Text',
  'campaignManagement.themePages.details.imageSection.backgroundColorHex': 'Background Color Hex',
  'campaignManagement.themePages.details.imageSection.disclaimerUrl': 'Disclaimer URL',
  'campaignManagement.themePages.create.mainSectionTitle': 'Theme Page Details',
  'campaignManagement.themePages.create.imageSectionTitle': 'Banner Image',
  'campaignManagement.themePages.create.slugLabel': 'Slug',
  'campaignManagement.themePages.create.bannerTitle': 'Banner Title',
  'campaignManagement.themePages.create.titleColorHex': 'Title Color Hex',
  'campaignManagement.themePages.create.backgroundColorHex': 'Background Color Hex',
  'campaignManagement.themePages.create.showSearchbar': 'Show Search Bar',
  'campaignManagement.themePages.create.showRetailerPicker': 'Show Retailer Picker',
  'campaignManagement.themePages.create.subTitle': 'Banner Sub-Title',
  'campaignManagement.themePages.create.subTitleColorHex': 'Sub-Title Color Hex',
  'campaignManagement.themePages.create.desktopImage': 'Desktop Image',
  'campaignManagement.themePages.create.mobileImage': 'Mobile Image',
  'campaignManagement.themePages.create.imageAltText': 'Image Alt Text',
  'campaignManagement.themePages.create.disclaimerUrl': 'Disclaimer URL',
  'campaignManagement.themePages.create.submitCreate': 'Create',
  'campaignManagement.themePages.create.submitUpdate': 'Update',
  'campaignManagement.themePages.create.submitCancel': 'Cancel',
  'campaignManagement.themePages.create.placeholder.slug': 'theme_page_slug',
  'campaignManagement.themePages.create.placeholder.title': 'Theme Page Title',
  'campaignManagement.themePages.create.placeholder.subTitle':
    'This is the subtitle of the theme page that will be displayed on the header section',
  'campaignManagement.themePages.create.placeholder.imageAltText': 'Text describing the image',
  'campaignManagement.themePages.create.placeholder.disclaimerUrl':
    'CTA link attached to heading banner to direct customers to a disclaimer page',
  'campaignManagement.themePages.update.updateSuccess': 'Theme page updated successfully',
  'campaignManagement.themePages.update.updateFailed': 'Failed to update',
  'campaignManagement.themePages.create.createSuccess': 'Theme page created successfully',
  'campaignManagement.themePages.create.createFailed': 'Failed to create',
  'campaignManagement.themePages.create.slug.validation':
    'Slug can only contain lowercase alphabets, numbers, and underscores',
  'campaignManagement.themePages.notApplicable': 'N/A',
  'campaignManagement.themePages.fieldRequired': 'This field is required',
  ...ADMIN_MULTI_RETAILER_PAGES_EN_US,
  ...ADMIN_REDEMPTION_URLS_EN_US,
  ...PLACEMENT_MANAGER_EN_US,
  ...HOME_PAGE_EN_US,
  ...PLATFORMS,
  ...CONTENT_PAGE_BUILDER_EN_US,
  ...PLACEMENT_TOOLING_EN_US,
  ...CONTENT_PAGE_BUILDER_BANNER_CAROUSEL_ORDER_PREVIEW_EN_US,
  ...CONTENT_PAGE_BUILDER_ANNOUNCEMENT_BANNER_EN_US,
  ...CONTENT_PAGE_BUILDER_STOREFRONT_BANNER_EN_US,
  ...CONTENT_PAGE_BUILDER_RICH_TEXT_EDITOR,
  ...CONTENT_PAGE_BUILDER_SLOT_PLACEMENT_EN_US,
  ...CONTENT_PAGE_BUILDER_BANNER_PLACEMENT,
  ...CONTENT_PAGE_BUILDER_RECIPE_PLACEMENTS,
  ...PLACEMENT_FORM_EN_US,
  ...CREATIVE_MANAGER_EN_US,
  ...ADMIN_CREATIVE_MANAGER_EN_US,
  ...ERROR_MESSAGE_EN_US,
  ...CONTENT_PAGE_BUILDER_COUPON_REDEMPTION_MODAL_EDITOR,
  ...CONTENT_PAGE_BUILDER_FLYOUT_MODAL_EDITOR,
  ...CONTENT_PAGE_BUILDER_LANDING_HERO_EDITOR,
  ...CONTENT_PAGE_BUILDER_LANDING_FOOTER_EDITOR,
  ...CONTENT_PAGE_BUILDER_LANDING_HEADER_EDITOR,
  ...CONTENT_PAGE_BUILDER_LANDING_RETAILER_LIST_EDITOR,
  ...CONTENT_PAGE_BUILDER_LANDING_MODULE_EDITOR,
  ...CONTENT_PAGE_BUILDER_SHOPPABLE_ITEM_LIST,
  ...CONTENT_PAGE_BUILDER_LANDING_FAQ_EDITOR,
  ...CONTENT_PAGE_BUILDER_LANDING_VALUE_PROP_EDITOR,
  ...CONTENT_PAGE_BUILDER_PRODUCTS_ITEM_LIST,
  ...CONTENT_PAGE_BUILDER_QUICK_LINK_CAROUSEL,
  ...CONTENT_PAGE_BUILDER_RETAILER_FORWARD_LIST,
} as const

export default EN_US
