import { type OptionData } from './types'

export function getUniqueMoments<T extends OptionData>(moments: T[]): T[] {
  const seen = new Set<string>()
  return moments
    .slice()
    .reverse()
    .filter(moment => {
      const key = moment.id ?? ''
      if (seen.has(key)) return false
      seen.add(key)
      return true
    })
    .reverse()
}
