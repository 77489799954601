// based on collection type API response
export interface Collection {
  id?: string
  name?: string
  slug?: string
  collectionType?: string
  startDate?: string
  endDate?: string
  updatedAt?: string
}

export interface AdditionalOption {
  label: string
  value: string
}

export enum Author {
  RETAILER = 'RETAILER',
  INSTACART = 'INSTACART',
}

export enum CollectionType {
  RETAILER_COLLECTION = 'retailer_collection',
  DEPARTMENT = 'department',
  HUB = 'hub',
  RETAILER_DEPARTMENT = 'retailer_department',
  HOLIDAY = 'holiday',
}

export interface OptionData extends Collection {
  label: string
  value: string
}
