import React, { type FunctionComponent, useMemo, useCallback } from 'react'
import { NewSelect, type NewSelectProps } from '@retailer-platform/shared-components'
import { type DomainMessages, useDomainMessages } from '../../utils/domain/intl'
import { type ContentTypes, IntegrationConfigurations } from '../../integrationConfigurations'

export type SelectIntegrationProps = {
  value?: ContentTypes
  onChange?: (str?: ContentTypes) => void
} & Omit<NewSelectProps<{ label: string; value: string }>, 'value' | 'onChange'>

export const SelectIntegration: FunctionComponent<
  React.PropsWithChildren<SelectIntegrationProps>
> = ({ value, onChange, ...rest }) => {
  const contentTypeTranslationMap = useMemo(
    () =>
      Object.keys(IntegrationConfigurations).reduce((acc, contentType) => {
        acc[contentType] = `approvalManagementLibrary.contentTypes.${contentType}` as DomainMessages
        return acc
      }, {} as Record<string, DomainMessages>),
    []
  )

  const i18n = useDomainMessages({
    allTools: 'approvalManagementLibrary.searchList.allTools',
    ...contentTypeTranslationMap,
  })

  const options = useMemo(
    () =>
      Object.keys(IntegrationConfigurations).map(contentType => ({
        label: i18n[contentType as keyof typeof i18n],
        value: contentType,
      })),
    [i18n]
  )

  const onChangeLocal = useCallback(
    (val?: string) => {
      onChange?.(val as ContentTypes)
    },
    [onChange]
  )

  return (
    <NewSelect
      data-testid="select-integration"
      options={options}
      isClearable={true}
      value={value}
      onChange={onChangeLocal}
      placeholder={i18n.allTools}
      {...rest}
    />
  )
}
