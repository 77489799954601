import React, { type ReactElement, type CSSProperties } from 'react'
import { type DashTableDatum, type DashTableFooterProps } from '../../utils/dashTable.types'
import { dashTableFooterCellStyles, DASH_TABLE_Z_INDEX } from '../../utils/dashTable.styles'
import { Flex } from '../../../flex/Flex'
import { DashTableFooterPaginator } from './components/paginator/DashTableFooterPaginator'
import { DashTableFooterPageDetails } from './components/DashTableFooterPageDetails'
import { DashTableFooterPageSizePicker } from './components/DashTableFooterPageSizePicker'

interface Props<TData extends DashTableDatum> extends DashTableFooterProps<TData> {}

// Lets the footer sit on top of the table content
const styleOverrides: CSSProperties = {
  zIndex: DASH_TABLE_Z_INDEX + 1,
}

// Note that we aren't using a <tfoot /> for this component because we
// want our footer to span the whole width of the table
export function DashTableFooter<TData extends DashTableDatum>(
  footerProps: Props<TData>
): ReactElement {
  return (
    <Flex
      fillWidth
      wrap="nowrap"
      overrideStyle={{ ...dashTableFooterCellStyles, ...styleOverrides }}
      justify="space-between"
    >
      <DashTableFooterPageSizePicker {...footerProps} />
      <DashTableFooterPageDetails {...footerProps} />
      <DashTableFooterPaginator {...footerProps} />
    </Flex>
  )
}
