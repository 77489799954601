import React from 'react'
import styled from '@emotion/styled'
import { Button, colors, spacing, fontSize } from '@retailer-platform/shared-components'
import { InformationIcon } from '@instacart/ids-core'
import { useGoToShifts } from '@retailer-platform/domain-shifts'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import {
  RetailerLocationSelect,
  DateSelectRange,
  dateRangeOption,
} from '@retailer-platform/dashboard/legacy'
import { useDomainMessages } from '../../utils/domain/intl'
import { type PickupAnalyticsBehaviour } from './utils/pickupAnalytics.hooks'

interface Props extends PickupAnalyticsBehaviour {}

const FilterBar = styled.div({
  height: 56,
  paddingLeft: spacing.X24,
  paddingRight: spacing.X24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})

const FilterFields = styled.div({
  display: 'flex',
})

const Spacer = styled.div({ flex: 1 })

const DataAvailabilityNotice = styled.div({
  fontSize: fontSize.X10,
  textAlign: 'left',
  maxWidth: 200,
  paddingLeft: spacing.X12,
  paddingRight: spacing.X12,
  color: colors.GRAYSCALE.X50,
  display: 'flex',
  alignItems: 'center',

  '> *:first-child': {
    marginRight: spacing.X4,
  },
})

export const PickupAnalyticsFilters: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  retailerLocationSelectValue,
  setRetailerLocationSelectValue,
  dateSelectRangeValue,
  setDateSelectRangeValue,
}) => {
  const messages = useDomainMessages({
    scheduleShifts: 'reportsDomain.pickupAnalytics.cta.toShiftManagement',
    dataAvailabilityNotice: 'reportsDomain.pickupAnalytics.dataAvailabilityNotice',
  })

  const goToShiftManagement = useGoToShifts()
  const showDataAvailabilityNotice =
    dateSelectRangeValue?.dateRange === dateRangeOption.YESTERDAY.value

  return (
    <FilterBar>
      <FilterFields>
        <div css={{ marginRight: spacing.X16 }}>
          <RetailerLocationSelect
            value={retailerLocationSelectValue}
            onChange={setRetailerLocationSelectValue}
          />
        </div>
        <DateSelectRange
          dateRangeOptions={[
            dateRangeOption.YESTERDAY,
            dateRangeOption.LAST_WEEK,
            dateRangeOption.LAST_MONTH,
            dateRangeOption.LAST_90_DAYS,
            dateRangeOption.CUSTOM,
          ]}
          value={dateSelectRangeValue}
          onChange={setDateSelectRangeValue}
        />
      </FilterFields>
      {showDataAvailabilityNotice && (
        <DataAvailabilityNotice data-testid="data-availability-notice">
          <InformationIcon size="25" color="systemGrayscale50" /> {messages.dataAvailabilityNotice}
        </DataAvailabilityNotice>
      )}
      <Spacer />
      <Button onClick={goToShiftManagement}>{messages.scheduleShifts}</Button>
    </FilterBar>
  )
}
