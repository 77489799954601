import React, {
  type ReactNode,
  isValidElement,
  type ReactNodeArray,
  type ReactElement,
} from 'react'
import { components } from 'react-select'
import { flattenDeep, compact } from 'lodash'

const { Group } = components

const groupOptionHeader = (child: ReactElement) => {
  const { children, label, ...rest } = child.props

  if (!label) return null

  return <Group {...rest} label={label} />
}

const groupOptionChildren = (child: ReactElement) => {
  const { children } = child.props

  return children
}

export const flattenGroupedChildren = (children: ReactNodeArray): ReactNodeArray => {
  const group = children.map((child: ReactNode) => {
    if (!isValidElement(child)) return []

    return [groupOptionHeader(child), groupOptionChildren(child)]
  })

  return compact(flattenDeep(group))
}
