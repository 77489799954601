import { FeatureToggle } from '../../access-control/featureToggles'
import { Permission } from '../../access-control/permissions'
import { type DomainAccessControlConfig } from '../../utils/domain/accessControl'

export const ReportsNormalizedReportsListPageAccessControl: DomainAccessControlConfig = [
  {
    permissions: Permission.Reports,
    notWarehouseFeatureToggles: [FeatureToggle.InstacartManagedReportsV2],
    notFeatureToggles: [FeatureToggle.InstacartManagedReportsV2],
  },
]
