import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { components, type MenuProps } from 'react-select'
import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import {
  MenuActionButton,
  MenuActionsApplyContainer,
} from '@retailer-platform/domain-aggregate-store-performance'
import { useDashMessages } from '../../../../intl/intl.hooks'

const StyledMenuActionsApplyContainer = styled(MenuActionsApplyContainer)({
  boxShadow: 'none',
})

export const Menu = (props: MenuProps<SimpleOption>) => {
  const messages = useDashMessages({
    clear: 'components.multiStepSelect.clear',
    apply: 'components.multiStepSelect.apply',
  })

  return (
    <components.Menu {...props}>
      <Fragment>
        {props.children}
        <StyledMenuActionsApplyContainer>
          <MenuActionButton variant="secondary" onClick={props.selectProps.onClear}>
            {messages.clear}
          </MenuActionButton>
          <MenuActionButton onClick={props.selectProps.onSave}>{messages.apply}</MenuActionButton>
        </StyledMenuActionsApplyContainer>
      </Fragment>
    </components.Menu>
  )
}
