import {
  ItemIcon,
  TrendingIcon,
  PromotionIcon,
  SparkleIcon,
  StoreIcon,
  KeyIcon,
  GraphIcon,
} from '@instacart/ids-core'
import { cloneDeep, sortBy } from 'lodash'
import { useMemo, type FunctionComponent } from 'react'
import { colors } from '@retailer-platform/shared-components'
import { retailerCollectionsLegacyDepartmentsAislesAccessControl } from '@retailer-platform/domain-retailer-collections'
import {
  onboardingAccessControl,
  useCheckLaunchOnboardingProgress,
} from '@retailer-platform/domain-onboarding'
import { navigationViewAccessControl } from '@retailer-platform/domain-storefront'
import {
  ReportsNormalizedReportsListPageAccessControl,
  ReportsPickupAnalyticsAccessControl,
  InstacartManagedReportsListPageAccessControl,
} from '@retailer-platform/domain-reports'
import { usePartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import {
  NavEntryPoint,
  type NavSupportedEnvironment,
} from '../../../legacy/components/Nav/nav.types'
import { useNavContext } from '../nav/utils/NavContext'
import { AlcoholTlogsAccessControl } from '../../../legacy/routes/reports/alcohol/AlcoholTlogs.configuration'
import { TlogsContainerAccessControl } from '../../../legacy/routes/reports/tlogs/TlogsContainer.configuration'
import { type AccessControlConfig } from '../../../legacy/components/AccessControl/accessControl.utils'
import { AdminNavEntryPointV2 } from '../nav/utils/adminNavConfig'
import { GeneratedReportsSectionAccessControl } from '../../../legacy/sections/generated-reports/GeneratedReportsSection.configuration'
import { StoreGroupsSectionNavigationControl } from '../../../legacy/sections/store-groups/StoreGroupsSection.configuration'
import {
  OrdersDeliveryAccessControl,
  OrdersPickupAccessControl,
  OrdersScanAndPayAccessControl,
} from '../../../legacy/sections/orders/OrdersSection.configuration'
import {
  type RPPDomainNavigationEntriesByAttachType,
  type RPPDomainAdminNavigationEntriesByAttachType,
} from '../../../utils/core/RPPCore.types'
import usePartnerAccessControl from '../../../legacy/components/AccessControl/usePartnerAccessControl'
import { InventoryFilesAccessControl } from '../../../legacy/routes/reports/files/InventoryFiles.configuration'
import { PartnerType } from '../../../legacy/common/types'
import { CatalogSectionAccessControl } from '../../../legacy/sections/catalog/CatalogSection.configuration'

export type NavMenuHierarchy = {
  hierarchy: NavMenuHierarchyL1[]
  navBarColor: string
  navBarColorHover: string
  type: 'admin' | 'retailer'
  homeRoute: string
}

export type NavMenuHierarchyL1 = {
  accessControl?: AccessControlConfig
  hasL2Children?: boolean
  name: string
  description?: string
  descriptionLink?: string
  icon: (props) => JSX.Element
  children: NavMenuHierarchyL2[]
  navEntryPoint?: NavEntryPoint | AdminNavEntryPointV2
  environment?: NavSupportedEnvironment
}

export type NavMenuHierarchyL2 = {
  name: string
  route?: string
  href?: string
  subRoutes?: string[]
  accessControl?: AccessControlConfig
  children?: NavMenuHierarchyL2[]
  navEntryPoint?: NavEntryPoint
  newTab?: boolean
  position?: number
  isNew?: boolean
  visible?: boolean
  NavItemWrapper?: FunctionComponent<React.PropsWithChildren<unknown>>
  environment?: NavSupportedEnvironment
}

const useNavHierarchyAdminData = () => {
  const navMenuHierarchyAdmin: NavMenuHierarchy = {
    navBarColor: colors.GRAYSCALE.X80,
    navBarColorHover: colors.GRAYSCALE.X60,
    type: 'admin',
    homeRoute: 'app-admin',
    hierarchy: [
      {
        name: 'navV2.folder.analytics.title',
        icon: GraphIcon,
        navEntryPoint: AdminNavEntryPointV2.Analytics,
        children: [],
      },
      {
        name: 'navV2.folder.catalog.title',
        icon: ItemIcon,
        navEntryPoint: AdminNavEntryPointV2.CatalogAdmin,
        children: [],
      },
      {
        name: 'navV2.folder.merchandising.title',
        icon: PromotionIcon,
        navEntryPoint: AdminNavEntryPointV2.Merchandising,
        children: [],
      },
      {
        name: 'navV2.folder.marketing.title',
        icon: SparkleIcon,
        navEntryPoint: AdminNavEntryPointV2.Marketing,
        children: [],
      },
      {
        name: 'navV2.folder.operations.title',
        icon: StoreIcon,
        navEntryPoint: AdminNavEntryPointV2.Operations,
        children: [],
      },
    ],
  }
  return navMenuHierarchyAdmin
}

const useNavHierarchyRetailerData = () => {
  const { hasLaunchOnboarding } = useCheckLaunchOnboardingProgress()
  const { partnerType } = usePartnerContext()

  const navMenuHierarchyRetailer: NavMenuHierarchy = {
    navBarColor: colors.PRIMARY_TDS.DARK,
    navBarColorHover: colors.PRIMARY_TDS.REGULAR,
    type: 'retailer',
    homeRoute:
      partnerType === PartnerType.IDP ? 'instacart-developer-platform-get-started' : 'dashboard',
    hierarchy: [
      {
        hasL2Children: true,
        name: 'navV2.folder.analytics.title',
        description: 'navV2.folder.analytics.description',
        descriptionLink:
          'https://partner-docs.instacart.com/instacart_platform_portal/analytics/overview',
        icon: TrendingIcon,
        children: [
          {
            name: 'navV2.folder.analytics.storePerformance.title',
            navEntryPoint: NavEntryPoint.AnalyticsStorefrontPerformance,
            children: [],
          },
          {
            name: 'navV2.folder.analytics.merchandising.title',
            navEntryPoint: NavEntryPoint.AnalyticsMerchandising,
            children: [],
          },
          {
            name: 'navV2.folder.analytics.marketing.title',
            navEntryPoint: NavEntryPoint.AnalyticsMarketing,
            children: [],
          },
          {
            name: 'navV2.folder.analytics.reportsAndLogs.title',
            navEntryPoint: NavEntryPoint.AnalyticsReportsAndLogs,
            children: [
              {
                name: 'reportsDomain.navV2.label',
                route: 'reports-normalized-reports-list',
                // @ts-ignore-next-line
                accessControl: ReportsNormalizedReportsListPageAccessControl,
                position: 2,
              },
              {
                name: 'reportsDomain.instacartManagedReportsList.nav',
                route: 'instacart-managed-reports-list',
                // @ts-ignore-next-line
                accessControl: InstacartManagedReportsListPageAccessControl,
                position: 2,
              },
              {
                name: 'generatedReports.title',
                route: 'generated-reports',
                accessControl: GeneratedReportsSectionAccessControl,
                position: 3,
              },
              {
                name: 'reports.tlogs.title',
                route: 'tlogs',
                accessControl: TlogsContainerAccessControl,
                position: 4,
              },
              {
                name: 'alcoholTlogs.title',
                route: 'alcohol-tlogs',
                accessControl: AlcoholTlogsAccessControl,
                position: 5,
              },
            ],
          },
        ],
      },
      {
        name: 'navV2.folder.catalog.title',
        description: 'navV2.folder.catalog.description',
        descriptionLink:
          'https://partner-docs.instacart.com/instacart_platform_portal/catalog/overview',
        icon: ItemIcon,
        navEntryPoint: NavEntryPoint.Catalog,
        children: [
          {
            name: 'catalog.requestNewProduct',
            route: 'new-product-request',
            position: 1,
            accessControl: CatalogSectionAccessControl,
          },
          {
            name: 'reports.files.title',
            route: 'files',
            accessControl: InventoryFilesAccessControl,
            position: 5,
          },
        ],
      },
      {
        name: 'navV2.folder.merchandising.title',
        description: 'navV2.folder.merchandising.description',
        descriptionLink:
          'https://partner-docs.instacart.com/instacart_platform_portal/merchandising/overview',
        icon: PromotionIcon,
        navEntryPoint: NavEntryPoint.Merchandising,
        children: [
          {
            name: 'catalog.department.legacy',
            route: 'departments',
            accessControl:
              retailerCollectionsLegacyDepartmentsAislesAccessControl as AccessControlConfig,
            position: 2,
          },
          {
            name: 'storefrontDomain.navigation.page-title',
            route: 'storefront-navigation-header',
            accessControl: navigationViewAccessControl as unknown as AccessControlConfig,
            position: 3,
          },
        ],
      },
      {
        name: 'navV2.folder.marketing.title',
        description: 'navV2.folder.marketing.description',
        descriptionLink:
          'https://partner-docs.instacart.com/instacart_platform_portal/marketing/overview',
        icon: SparkleIcon,
        navEntryPoint: NavEntryPoint.Marketing,
        children: [],
      },
      {
        name: 'navV2.folder.operations.title',
        description: 'navV2.folder.operations.description',
        descriptionLink:
          'https://partner-docs.instacart.com/instacart_platform_portal/store_operations/overview',
        icon: StoreIcon,
        navEntryPoint: NavEntryPoint.Operations,
        hasL2Children: true,
        children: [
          {
            name: 'navV2.folder.operations.general.title',
            navEntryPoint: NavEntryPoint.OperationsGeneral,
            children: [
              {
                name: 'settings.storeGroups.title',
                route: 'store-groups',
                accessControl: StoreGroupsSectionNavigationControl,
                position: 0,
              },
              {
                name: 'onboarding.storeManagement',
                route: 'onboarding-store-management',
                accessControl: onboardingAccessControl as AccessControlConfig,
                visible: hasLaunchOnboarding,
                position: 1,
              },
            ],
          },
          {
            name: 'navV2.folder.operations.customerSupport.title',
            navEntryPoint: NavEntryPoint.OperationsCustomerSupport,
            children: [
              {
                name: 'delivery.title',
                route: 'delivery',
                accessControl: OrdersDeliveryAccessControl,
                position: 0,
              },
              {
                name: 'pickup.title',
                route: 'pickup',
                accessControl: OrdersPickupAccessControl,
                position: 1,
              },
              {
                name: 'scanandpay.title',
                route: 'scanandpay',
                accessControl: OrdersScanAndPayAccessControl,
                position: 3,
              },
            ],
          },
          {
            name: 'navV2.folder.operations.labor.title',
            navEntryPoint: NavEntryPoint.OperationsLabor,
            children: [
              {
                name: 'reportsDomain.pickupAnalytics.title',
                route: 'pickupAnalytics',
                accessControl: ReportsPickupAnalyticsAccessControl,
                position: 0,
              },
            ],
          },
        ],
      },
      {
        name: 'navV2.folder.developer.title',
        description: 'navV2.folder.developer.description',
        icon: KeyIcon,
        navEntryPoint: NavEntryPoint.Developer,
        children: [],
      },
    ],
  }

  return navMenuHierarchyRetailer
}

const indexRoutes = (
  l1: NavMenuHierarchyL1,
  currentNode: NavMenuHierarchyL1 | NavMenuHierarchyL2,
  cachedRouteToRoot: { [key: string]: NavMenuHierarchyL1 }
) => {
  if ('route' in currentNode && currentNode.route) {
    cachedRouteToRoot[currentNode.route] = l1
  }

  if ('subRoutes' in currentNode && currentNode.subRoutes) {
    currentNode.subRoutes.forEach(route => {
      cachedRouteToRoot[route] = l1
    })
  }

  if (currentNode.children) {
    currentNode.children.forEach(child => {
      indexRoutes(l1, child, cachedRouteToRoot)
    })
  }
}

const attachDomains = (
  currentNode: NavMenuHierarchyL1 | NavMenuHierarchyL2,
  navigationEntries:
    | Partial<RPPDomainNavigationEntriesByAttachType>
    | Partial<RPPDomainAdminNavigationEntriesByAttachType>
) => {
  currentNode.children?.forEach(e => {
    attachDomains(e, navigationEntries)
  })

  if (currentNode.navEntryPoint) {
    const newChildren: NavMenuHierarchyL2[] =
      navigationEntries[currentNode.navEntryPoint]?.map(e => ({
        name: e.labelId,
        position: e.positionNavV2,
        ...e,
      })) || []

    currentNode.children = sortBy(
      (currentNode.children || []).concat(newChildren),
      child => child.position || 0
    )
  }
}

// recurse through the nav hierarchy and remove any nav entries that do not have a route or children
const clearEmptyBranches = (currentNode: NavMenuHierarchyL1 | NavMenuHierarchyL2) => {
  if (currentNode.children) {
    currentNode.children = currentNode.children.filter(e => {
      clearEmptyBranches(e)
      return e.children?.length || e.route || e.href
    })
  }
}

// recurse through the nav hierarchy and remove any nav entries that the user does not have access to
const removeInsufficientAccessNavEntries = (
  currentNode: NavMenuHierarchyL1 | NavMenuHierarchyL2,
  hasAccess: (accessControl: AccessControlConfig) => boolean
) => {
  if (currentNode.children) {
    currentNode.children = currentNode.children.filter(e => {
      removeInsufficientAccessNavEntries(e, hasAccess)
      return (
        (!e.accessControl || hasAccess(e.accessControl)) && (e.visible === undefined || e.visible)
      )
    })
  }
}

const useNavHierarchy = (useNavHierarchyHook: () => NavMenuHierarchy) => {
  const { navigationEntries } = useNavContext()
  const hasAccess = usePartnerAccessControl()

  const navMenuHierarchy = useNavHierarchyHook()

  const [indexedL1ByRoutes, navHierarchyWithAttachedDomains] = useMemo(() => {
    const indexedL1ByRoutes: { [key: string]: NavMenuHierarchyL1 } = {}
    const clonedHierarchy = cloneDeep(navMenuHierarchy)

    clonedHierarchy.hierarchy.forEach(l1 => {
      attachDomains(l1, navigationEntries)
      removeInsufficientAccessNavEntries(l1, hasAccess)
      clearEmptyBranches(l1)
      indexRoutes(l1, l1, indexedL1ByRoutes)
    })

    // filter out any L1 entries that have no children
    clonedHierarchy.hierarchy = clonedHierarchy.hierarchy.filter(e => e.children.length)

    return [indexedL1ByRoutes, clonedHierarchy]
  }, [navMenuHierarchy, navigationEntries, hasAccess])

  return { navMenuHierarchy: navHierarchyWithAttachedDomains, indexedL1ByRoutes }
}

export const useNavHierarchyAdmin = () => useNavHierarchy(useNavHierarchyAdminData)
export const useNavHierarchyRetailer = () => useNavHierarchy(useNavHierarchyRetailerData)
