import React, { useCallback, useEffect } from 'react'
import { type ModalProps, useBooleanState } from '@retailer-platform/shared-components'
import { ApprovedIcon, BugIcon } from '@instacart/ids-core'
import { type ApolloError } from 'apollo-client'
import {
  SubmitFeedbackModal,
  type SubmitFeedbackModalProps,
} from '../submit-feedback-modal/SubmitFeedbackModal'
import { useAddReviewMutation, useGetUserPermittedActionsForIDsLazyQuery } from '../../api'
import { GenericModal, LoadingModal } from '../generic-modal/GenericModal'
import { useDomainMessages } from '../../utils/domain/intl'
import { ErrorAlert } from '../generic-error-page-alert/ErrorPageAlert'
import { useRefetchList } from '../search-approval-request-list/components/refetch-list-provider/RefetchListProvider'

export type SubmitFeedbackFlowProps = {
  arId: string
  modalProps?: Partial<SubmitFeedbackModalProps>
}

type OnSubmitFunc = SubmitFeedbackModalProps['onSubmit']

export const useSubmitFeedbackFlow = (props: SubmitFeedbackFlowProps) => {
  const [isModalOpen, openModal, closeModal] = useBooleanState()
  const [triggerMutation, { data, loading, error }] = useAddReviewMutation()

  const baseReturn = {
    modal: null,
    isModalOpen: isModalOpen,
    openModal,
    closeModal,
  }

  const onSubmit = useCallback<OnSubmitFunc>(
    async ({ action, comment }) => {
      triggerMutation({
        variables: {
          input: {
            arId: props.arId,
            action,
            userComment: comment,
          },
        },
      })
    },
    [props.arId, triggerMutation]
  )

  // Refetch the list of approval requests after a review is added
  const refetchContext = useRefetchList()
  useEffect(() => {
    if (data?.approvalsManagementAddReview) {
      refetchContext?.refetch()
    }
  }, [data?.approvalsManagementAddReview, refetchContext])

  // Fetch the user's permitted actions for the approval request
  const [
    triggerUserActionsQuery,
    {
      data: dataUserActions,
      loading: loadingUserActions,
      error: errorUserActions,
      called: calledUserActions,
    },
  ] = useGetUserPermittedActionsForIDsLazyQuery({
    variables: { arIds: [props.arId] },
  })

  // Trigger the user actions query when the modal is opened
  useEffect(() => {
    if (isModalOpen && !calledUserActions) {
      triggerUserActionsQuery()
    }
  }, [triggerUserActionsQuery, calledUserActions, isModalOpen])

  if (loadingUserActions) {
    const loadingModal = <LoadingModal isOpen={isModalOpen} onRequestClose={closeModal} />

    return {
      ...baseReturn,
      modal: loadingModal,
    }
  } else if (errorUserActions) {
    const errorModal = (
      <LoadingActionsFailureModal
        error={errorUserActions}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
      />
    )

    return {
      ...baseReturn,
      modal: errorModal,
    }
  }

  const addReviewModal = (
    <SubmitFeedbackModal
      data-testid="add-review-modal"
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      onSubmit={onSubmit}
      loading={loading}
      userPermittedActions={dataUserActions?.approvalsManagementGetUserPermittedActions?.find(
        ar => ar.approvalRequestId === props.arId
      )}
      {...props.modalProps}
    />
  )

  const successModal = data?.approvalsManagementAddReview && (
    <ReviewSubmittedSuccessModal isOpen={isModalOpen} onRequestClose={closeModal} />
  )

  const failureModal = error && (
    <ReviewSubmittedFailureModal isOpen={isModalOpen} onRequestClose={closeModal} error={error!} />
  )

  const modal =
    data?.approvalsManagementAddReview || error
      ? data?.approvalsManagementAddReview && !error
        ? successModal
        : failureModal
      : addReviewModal

  return {
    ...baseReturn,
    modal,
  }
}

const ReviewSubmittedSuccessModal = (props: ModalProps) => {
  const i18n = useDomainMessages({
    title: 'approvalManagementLibrary.addReviewModal.success.title',
    bodyHeadline: 'approvalManagementLibrary.addReviewModal.success.bodyHeadline',
    body: 'approvalManagementLibrary.addReviewModal.success.body',
    ok: 'approvalManagementLibrary.addReviewModal.success.ok',
  })

  return (
    <GenericModal
      {...props}
      data-testid="review-success-modal"
      i18n={i18n}
      imageComponent={<ApprovedIcon size={64} color="brandPrimaryRegular" />}
    />
  )
}

const ReviewSubmittedFailureModal = (props: ModalProps & { error: ApolloError }) => {
  const i18n = useDomainMessages({
    title: 'approvalManagementLibrary.addReviewModal.failure.title',
    bodyHeadline: 'approvalManagementLibrary.addReviewModal.failure.bodyHeadline',
    body: 'approvalManagementLibrary.addReviewModal.failure.body',
    ok: 'approvalManagementLibrary.actions.ok',
  })

  const alert = <ErrorAlert error={props.error} />

  return (
    <GenericModal
      {...props}
      data-testid="review-failure-modal"
      i18n={i18n}
      imageComponent={<BugIcon size={64} color="systemDetrimentalRegular" />}
      additionalContent={alert}
    />
  )
}

const LoadingActionsFailureModal = (props: ModalProps & { error: ApolloError }) => {
  const i18n = useDomainMessages({
    title: 'approvalManagementLibrary.general.unexpectedFailure',
    bodyHeadline:
      'approvalManagementLibrary.addReviewModal.userPermittedActions.failedToLoad.bodyHeadline',
    body: 'approvalManagementLibrary.addReviewModal.userPermittedActions.failedToLoad.body',
    ok: 'approvalManagementLibrary.actions.ok',
  })

  const alert = <ErrorAlert error={props.error} />

  return (
    <GenericModal
      {...props}
      data-testid="loading-actions-failure-modal"
      i18n={i18n}
      imageComponent={<BugIcon size={64} color="systemDetrimentalRegular" />}
      additionalContent={alert}
    />
  )
}
