import { type CSSProperties } from 'react'
import styled from '@emotion/styled'
import { type Styles } from 'react-select'
import {
  spacing,
  borderRadius,
  colors,
  fontWeight,
  fontSize,
  Button,
} from '@retailer-platform/shared-components'

export const DROPDOWN_WIDTH = '375px'

interface SelectState {
  hasValue: boolean
  isDisabled: boolean
  isFocused: boolean
  isMulti: boolean
  isSelected: boolean
  isRtl: boolean
  menuIsOpen: boolean
}

interface CustomStyles {
  clearIndicator?: (state: SelectState) => CSSProperties
  container?: (state: SelectState) => CSSProperties
  control?: (state: SelectState) => CSSProperties
  dropdownIndicator?: (state: SelectState) => CSSProperties
  group?: (state: SelectState) => CSSProperties
  groupHeading?: (state: SelectState) => CSSProperties
  indicatorsContainer?: (state: SelectState) => CSSProperties
  indicatorSeparator?: (state: SelectState) => CSSProperties
  input?: (state: SelectState) => CSSProperties
  loadingIndicator?: (state: SelectState) => CSSProperties
  loadingMessage?: (state: SelectState) => CSSProperties
  menu?: (state: SelectState) => CSSProperties
  menuList?: (state: SelectState) => CSSProperties
  menuPortal?: (state: SelectState) => CSSProperties
  multiValue?: (state: SelectState) => CSSProperties
  multiValueLabel?: (state: SelectState) => CSSProperties
  multiValueRemove?: (state: SelectState) => CSSProperties
  noOptionsMessage?: (state: SelectState) => CSSProperties
  option?: (state: SelectState) => CSSProperties
  placeholder?: (state: SelectState) => CSSProperties
  singleValue?: (state: SelectState) => CSSProperties
  valueContainer?: (state: SelectState) => CSSProperties
}

export const getDropdownStyles = (customStyles: CustomStyles): Styles => {
  const baseStyles: Styles = {
    clearIndicator: styles => ({ ...styles, ...customStyles?.clearIndicator }),
    container: (styles, state) => ({
      ...styles,
      height: '38px',
      ...customStyles?.container?.(state),
    }),
    control: (styles, state) => ({
      ...styles,
      color: colors.GRAYSCALE.X70,
      height: '38px',
      borderWidth: '2px',
      borderColor:
        state.isSelected || state.isFocused ? colors.SECONDARY.DARK : colors.GRAYSCALE.X10,
      borderRadius: borderRadius.X8,
      backgroundColor: colors.GRAYSCALE.X10,
      alignContent: 'center',
      lineHeight: '24px',
      fontSize: fontSize.X15,
      fontWeight: fontWeight.SEMIBOLD,
      padding: '5px 0',
      boxShadow: 'none',
      ':hover': {
        borderColor: colors.SECONDARY.DARK,
        boxShadow: 'none',
      },
      ...customStyles?.control?.(state),
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: colors.SECONDARY.DARK,
      ...customStyles?.dropdownIndicator?.(state),
    }),
    group: (styles, state) => ({ ...styles, ...customStyles?.group?.(state) }),
    groupHeading: (styles, state) => ({
      ...styles,
      color: colors.GRAYSCALE.X70,
      fontWeight: fontWeight.SEMIBOLD,
      fontSize: fontSize.X15,
      lineHeight: '22px',
      margin: `${spacing.X8} ${spacing.X20}`,
      padding: 0,
      textTransform: 'none',
      ...customStyles?.groupHeading?.(state),
    }),
    indicatorSeparator: (styles, state) => ({
      ...styles,
      display: 'none',
      ...customStyles?.indicatorSeparator?.(state),
    }),
    loadingMessage: (styles, state) => ({ ...styles, ...customStyles?.loadingMessage?.(state) }),
    menu: (styles, state) => ({
      ...styles,
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.16)',
      borderRadius: '12px',
      border: 'none',
      overflow: 'hidden',
      width: DROPDOWN_WIDTH,
      ...customStyles?.menu?.(state),
    }),
    menuList: (styles, state) => ({ ...styles, ...customStyles?.menuList?.(state) }),
    menuPortal: (styles, state) => ({ ...styles, ...customStyles?.menuPortal?.(state) }),
    multiValue: (styles, state) => ({ ...styles, ...customStyles?.multiValue?.(state) }),
    multiValueLabel: (styles, state) => ({ ...styles, ...customStyles?.multiValueLabel?.(state) }),
    multiValueRemove: (styles, state) => ({
      ...styles,
      ...customStyles?.multiValueRemove?.(state),
    }),
    noOptionsMessage: (styles, state) => ({
      ...styles,
      ...customStyles?.noOptionsMessage?.(state),
    }),
    option: (styles, state) => ({
      ...styles,
      padding: `${spacing.X8} ${spacing.X20}`,
      backgroundColor: colors.GRAYSCALE.X0,
      ':hover': {
        backgroundColor: colors.GRAYSCALE.X20,
      },
      ...customStyles?.option?.(state),
    }),
    placeholder: (styles, state) => ({
      ...styles,
      color: colors.GRAYSCALE.X70,
      ...customStyles?.placeholder?.(state),
    }),
    singleValue: (styles, state) => ({ ...styles, ...customStyles?.singleValue?.(state) }),
    valueContainer: (styles, state) => ({ ...styles, ...customStyles?.valueContainer?.(state) }),
  }

  return baseStyles
}

export const OptionContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: colors.GRAYSCALE.X50,
  fontWeight: fontWeight.REGULAR,
  fontSize: fontSize.X15,
  lineHeight: '22px',
})

export const MenuActionButton = styled(Button)({
  height: '40px',
  width: '48%',
  margin: 0,
})

export const MenuTitle = styled.div({
  color: colors.GRAYSCALE.X70,
  margin: `${spacing.X28} ${spacing.X20} ${spacing.X8}`,
})

export const MenuActionsApplyContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: `${spacing.X12} ${spacing.X20}`,
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.16)',
})
