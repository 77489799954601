import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Alert, spacing, Text } from '@retailer-platform/shared-components'
import { LoadingGenericBase } from '@instacart/ids-customers'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { useGetRetailerStoreHours } from '../../../../utils/useGetRetailerStoreHours.hooks'
import { SUPPORTED_RETAILER_SERVICE_TYPES } from '../../../../utils/useFormToApiDataMapping.hooks'
import { type FormData } from '../../../edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'
import { StoreHoursBulkSpecialStoreHours } from './StoreHoursBulkSpecialStoreHours'
import { StoreHoursBulkRegularStoreHours } from './StoreHoursBulkRegularStoreHours'

interface StoreHoursBulkStoreHoursDetailsProps {
  retailerLocationId: string
}

const StoreHoursBody = styled.div({
  display: 'flex',
})
const LoadingContainer = styled.div({
  padding: spacing.X120,
})

export const StoreHoursBulkStoreHoursDetailsDumb = ({ storeHours }: { storeHours: FormData }) => {
  const i18n = useDomainMessages({
    deliveryHeader: 'storeHoursBulkDomain.list.modal.header.deliveryHours',
  })

  return (
    <>
      <Text css={{ paddingBottom: '10px' }} size="large" weight="bold">
        {i18n.deliveryHeader}
      </Text>
      <StoreHoursBody>
        {storeHours && (
          <div css={{ flex: '45' }}>
            <StoreHoursBulkRegularStoreHours regularStoreHours={storeHours.delivery.regular} />
          </div>
        )}
        {storeHours && (
          <div css={{ flex: '55' }}>
            <StoreHoursBulkSpecialStoreHours specialStoreHours={storeHours.delivery.special} />
          </div>
        )}
      </StoreHoursBody>
    </>
  )
}

export const StoreHoursBulkStoreHoursDetails: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkStoreHoursDetailsProps>
> = ({ retailerLocationId }) => {
  const { retailerStoreHours, loading, error } = useGetRetailerStoreHours(
    retailerLocationId,
    SUPPORTED_RETAILER_SERVICE_TYPES
  )

  let childComponent: React.ReactElement
  if (loading) {
    childComponent = (
      <LoadingContainer>
        <LoadingGenericBase />
      </LoadingContainer>
    )
  } else if (error) {
    childComponent = <Alert variant="danger">{error.message}</Alert>
  } else {
    childComponent = <StoreHoursBulkStoreHoursDetailsDumb storeHours={retailerStoreHours} />
  }

  return childComponent
}
