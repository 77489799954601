import {
  type InstacartCustomersSharedV1PaginationInput,
  type ListMomentsRequestFilterSetInput,
  useListMomentsQuery,
} from '../api'

export const useListMoments = ({
  filters,
  pagination = null,
  skip = false,
  onListMomentsError,
}: {
  filters?: ListMomentsRequestFilterSetInput
  pagination?: InstacartCustomersSharedV1PaginationInput
  skip
  onListMomentsError?: () => void
}) => {
  const { data, loading, error, refetch } = useListMomentsQuery({
    onError: () => {
      onListMomentsError?.()
    },
    variables: {
      filters: filters,
      pagination: pagination,
    },
    skip: skip,
    fetchPolicy: 'cache-and-network',
  })

  const moments = data?.retailerCampaignServiceListMoments?.moments

  return {
    response: moments === null || moments === undefined ? [] : moments,
    loading,
    error,
    refetch,
  }
}
