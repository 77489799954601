import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Switch } from 'ic-snacks'
import { type ToggleHeaderProps } from '../utils/select.types'
import { uniqueId } from '../../../common/utils/ids'
import { dashColors } from '../../foundation/colors'
import { DashText } from '../../dash-text/DashText'
import { type OnChange } from '../../../../utils/react/event.types'
import { Flex } from '../../flex/Flex'

const Container = styled(Flex)`
  background-color: ${dashColors.LIGHTER_NEUTRAL};
`

type ToggleHeaderWithoutHandler = Omit<ToggleHeaderProps, 'handleToggle'>

interface Props extends ToggleHeaderWithoutHandler {
  onToggle: OnChange
}

export const DashSelectToggleableHeader: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onToggle,
  toggleActive,
  toggleHeading,
}) => (
  <Container
    testId="select-toggleable-header"
    justify="space-between"
    align="center"
    padding={['xxsmall', 'xsmall']}
    wrap="nowrap"
  >
    <DashText size="small" color="neutral">
      {toggleHeading}
    </DashText>
    <Switch
      id={`select-toggleable-header-${uniqueId()}`}
      onChange={onToggle}
      isSelected={toggleActive}
    />
  </Container>
)
