import { useMemo, useCallback, useEffect, useState } from 'react'
import moment, { type Moment } from 'moment'
import { StringParam, useQueryParam } from 'use-query-params'
import { usePartnerContext } from '@retailer-platform/dashboard/utils'
import { DateRangePreset } from '../components/date-range-preset-select/ReportsNormalizedReportsListDateRangePresetSelect'
import { type ReportTypeEnum, usePaginatedSearchNormalizedReportsQuery } from '../../../api'
interface Props {
  partnerId: string
  retailerId: string
}

const offsetsDays = {
  [DateRangePreset.Today]: 0,
  [DateRangePreset.Yesterday]: 1,
  [DateRangePreset.Last7Days]: 7,
  [DateRangePreset.Last30Days]: 30,
  [DateRangePreset.Last6Months]: 180,
}

export interface MomentRange {
  startDate: Moment
  endDate: Moment
}

const format = 'YYYY-MM-DD'

export const useReportsNormalizedReportsList = (props: Props) => {
  const { warehouses } = usePartnerContext()
  const [retailerIds, setRetailerIds] = useState<string[]>(
    props.retailerId && props.retailerId !== '0' ? [props.retailerId] : []
  )

  const [reportType, setReportType] = useQueryParam<ReportTypeEnum, ReportTypeEnum>('reportType')
  const [reportName, setReportName] = useQueryParam('reportName', StringParam)
  const [manualStartDate, setManualStartDate] = useQueryParam('startDate', StringParam)
  const [manualEndDate, setManualEndDate] = useQueryParam('endDate', StringParam)
  const [dateRangePreset = DateRangePreset.Last7Days, setDateRangePreset] = useQueryParam<
    DateRangePreset,
    DateRangePreset
  >('dateRangePreset')

  // isHeavyQuery is used to skip querying the api when query is likely to time out.
  const isHeavyQuery = warehouses.length > 5 && retailerIds.length === 0 && !reportType

  const warehouseOptions = warehouses.map(warehouse => ({
    label: warehouse.name,
    value: String(warehouse.id),
  }))

  const manualDateRange: MomentRange = useMemo(
    () => ({
      startDate: manualStartDate && moment(manualStartDate, format),
      endDate: manualEndDate && moment(manualEndDate, format),
    }),
    [manualStartDate, manualEndDate]
  )

  const setDateRange = useCallback(
    ({ startDate, endDate }: MomentRange) => {
      if (startDate) setManualStartDate(startDate.format(format))
      if (endDate) setManualEndDate(endDate.format(format))
    },
    [setManualStartDate, setManualEndDate]
  )

  useEffect(() => {
    if (dateRangePreset === DateRangePreset.Custom) return

    setManualStartDate(undefined)
    setManualEndDate(undefined)
  }, [dateRangePreset, setManualStartDate, setManualEndDate])

  const dateRange = useMemo(() => {
    if (dateRangePreset === DateRangePreset.Custom) return manualDateRange

    return {
      startDate: moment().subtract(offsetsDays[dateRangePreset], 'days'),
      endDate: null,
    }
  }, [dateRangePreset, manualDateRange])

  const formattedDates = useMemo(
    () => ({
      startDate: dateRange.startDate?.format(format),
      endDate: dateRange.endDate?.format(format),
    }),
    [dateRange]
  )

  const retailerIdsFilterValues =
    retailerIds.length === 0 ? warehouses.map(w => String(w.id)) : retailerIds

  const { loading, refetch, loadMore, canLoadMore, apiResult, error } =
    usePaginatedSearchNormalizedReportsQuery({
      variables: {
        ...props,
        ...formattedDates,
        reportType,
        // TODO: temp solution to get around the current auth. Will remove after updating the BE
        retailerId: props.retailerId === '0' ? String(warehouses[0].id) : props.retailerId,
        retailerIds: retailerIdsFilterValues,
      },
      skip: !formattedDates.startDate || isHeavyQuery,
    })

  let filteredResults = apiResult
  if (apiResult && reportName) {
    // If the reportName query parameter is present,
    // filter the results by the reportName value.
    filteredResults = apiResult.filter(report => report.name.includes(reportName))
  }

  if (filteredResults) {
    filteredResults = filteredResults.map(report => ({
      ...report,
      banner: warehouses.find(warehouse => Number(warehouse.id) === Number(report.retailerId))
        ?.name,
    }))
  }

  // A higher-order function to clear the 'reportName' query parameter value.
  // This is to provide a nicer user experience. The reason it helps is so that the
  // user does not unknowingly filter by the report name after changing one of their
  // filters (since there is no way to clear the reportName filter on the UI).
  const useClearReportName = otherFunction =>
    useCallback(
      (...args) => {
        setReportName(null)
        return otherFunction(...args)
      },
      [otherFunction]
    )

  return {
    apiResult: filteredResults,
    error,
    loading,
    refetch,
    loadMore,
    canLoadMore,
    dateRangePreset,
    setDateRangePreset: useClearReportName(setDateRangePreset),
    dateRange,
    setDateRange: useClearReportName(setDateRange),
    reportType,
    setReportType: useClearReportName(setReportType),
    warehouseOptions,
    retailerIds,
    setRetailerIds,
    isHeavyQuery,
  }
}
