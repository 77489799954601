import { SearchIcon } from '@instacart/ids-core'
import {
  useDebouncedState,
  DecoratedInput,
  LoadingDots,
} from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useEffect } from 'react'

type SearchTextBarProps = {
  css?: React.CSSProperties
  placeholder?: string
  onChange?: (str: string) => void
  value?: string
}

export const SearchTextBar: FunctionComponent<React.PropsWithChildren<SearchTextBarProps>> = ({
  placeholder,
  onChange,
  value,
  css,
  ...rest
}) => {
  const [term, debouncedTerm, setTerm, isChangePending] = useDebouncedState(value, 500)

  // Propagate value once the debounced value changes
  useEffect(() => {
    if (debouncedTerm == null || !onChange) return
    onChange(debouncedTerm)
  }, [debouncedTerm, onChange])

  return (
    <DecoratedInput
      decorationComponent={isChangePending ? LoadingDotsContainer : SearchIcon}
      placeholder={placeholder}
      data-testid="search-text-input"
      id="search-text-input"
      value={term}
      onChange={evt => {
        setTerm(evt.target.value)
      }}
      style={css}
      {...rest}
    />
  )
}

const LoadingDotsContainer = ({ className }: { className?: string }) => (
  <div className={className} css={{ marginTop: -2 }} data-test-id="role-search-loading">
    <LoadingDots height={6} />
  </div>
)
