import { type GroupedOptionsType, type CommonProps } from 'react-select/src/types'
import { useMemo } from 'react'
import { type SelectComponents } from 'react-select/src/components'
import { DashSelectOption } from '../components/DashSelectOption'
import { DashSelectMenu } from '../components/DashSelectMenu'
import { DashSelectFastMenuList } from '../components/fast-menu-list/DashSelectFastMenuList'
import { DashSelectMenuEmptyState } from '../components/menu-empty-state/DashSelectMenuEmptyState'
import { DashSelectValueContainer } from '../components/DashSelectValueContainer'
import { DashSelectSingleValue } from '../components/DashSelectSingleValue'

export function selectValueContainerTestId(testId: string): string
export function selectValueContainerTestId(testId?: string): string | undefined
export function selectValueContainerTestId(testId?: string) {
  if (!testId) return undefined

  return `${testId}-select-value-container`
}

export function selectOptionTestId(testId: string, value?: string): string
export function selectOptionTestId(testId?: string, value?: string): string | undefined
export function selectOptionTestId(testId?: string, value?: string) {
  if (!testId) return undefined

  return `${testId}-select-option-container${value ? `-${value}` : ''}`
}

// https://github.com/JedWatson/react-select/blob/v2.4.4/src/Select.js#L1307
export const isGroupedOptionsList = <TOption>(
  options: GroupedOptionsType<TOption> | ReadonlyArray<TOption>
): options is GroupedOptionsType<TOption> => Object.keys(options[0] || {}).includes('options')

interface IsSingleValueProps<TOption>
  extends Pick<CommonProps<TOption>, 'getValue' | 'isMulti' | 'hasValue'> {}

export const useIsSingleValue = <TOption>({
  getValue,
  isMulti,
  hasValue,
}: IsSingleValueProps<TOption>): TOption | undefined =>
  useMemo(() => {
    const value = getValue()

    if (isMulti || !hasValue || !value) {
      return undefined
    }

    return Array.isArray(value) ? value[0] : value
  }, [getValue, hasValue, isMulti])

interface MakeDashSelectComponents {
  hideIndicator?: boolean
}

export const baseDashSelectComponents = {
  Option: DashSelectOption,
  Menu: DashSelectMenu,
  MenuList: DashSelectFastMenuList,
  NoOptionsMessage: DashSelectMenuEmptyState,
  ValueContainer: DashSelectValueContainer,
  SingleValue: DashSelectSingleValue,
}

export const makeDashSelectComponents = ({ hideIndicator }: MakeDashSelectComponents) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const components: Partial<SelectComponents<any>> = { ...baseDashSelectComponents }

  if (hideIndicator) {
    components.DropdownIndicator = () => null
    components.IndicatorSeparator = () => null
  }

  return components
}
