import React, { type FunctionComponent } from 'react'
import { Badge, type BadgeColorOptions } from '@retailer-platform/shared-components'
import { ApprovalRequestState, ReviewAction } from '../../api'
import { type DomainMessages, useDomainMessages } from '../../utils/domain/intl'
import { type ApiActivityHistory, type ApiApprovalRequest } from '../../api/common'
import { sortReviewsFunc } from '../activity-history/ActivityHistory'

export type ApprovalRequestStateBadgeProps = {
  approvalRequest?: ApiApprovalRequest
  state: ApprovalRequestState
}

export const ApprovalRequestStateBadge: FunctionComponent<
  React.PropsWithChildren<ApprovalRequestStateBadgeProps>
> = ({ approvalRequest: ar, state }) => {
  const i18n = useDomainMessages({
    state: `approvalManagementLibrary.approvalRequestState.${state}` as DomainMessages,
    changesRequested: 'approvalManagementLibrary.approvalRequestState.changesRequested',
  })

  if (ar && searchRecentChangeRequest(ar.reviews?.sort(sortReviewsFunc))) {
    return (
      <Badge css={CSS} color="yellow">
        {i18n.changesRequested}
      </Badge>
    )
  }

  const color: BadgeColorOptions =
    (state === ApprovalRequestState.Cancelled && 'red') ||
    (state === ApprovalRequestState.Pending && 'grey') ||
    'green'

  return (
    <Badge css={CSS} color={color}>
      {i18n.state}
    </Badge>
  )
}

const CSS = { fontVariationSettings: 'normal' }

const searchRecentChangeRequest = (reviews: ApiActivityHistory) => {
  if (!reviews) {
    return false
  }

  for (const review of reviews) {
    if (review.reviewContent.action === ReviewAction.Comment) {
      // "Comments" don't change the state of the approval request
      continue
    }

    if (review.reviewContent.action === ReviewAction.RequestChanges) {
      return true
    }
  }

  return false
}
