import { useState, useCallback } from 'react'
import { useFilestack } from '@retailer-platform/shared-components'
import { type ImageUploaderProps, type FileError } from './ImageUploader.types'
import { validateImage } from './ImageUploader.utils'

export const useImageUploader = ({
  value,
  onChange,
  filestackApiKey,
  requiredDimensions,
}: ImageUploaderProps) => {
  console.log('calling original..')

  const { url, fileName, uploadFile, clearFile, uploading, uploadError } = useFilestack({
    value,
    filestackApiKey,
    onFileUpload: onChange,
  })
  const [fileError, setFileError] = useState<FileError>(null)

  const uploadImage = useCallback(
    (file: File) => {
      validateImage(file, requiredDimensions)
        .then(() => {
          setFileError(null)
          uploadFile(file)
        })
        .catch(error => {
          setFileError(error)
        })
    },
    [uploadFile, requiredDimensions]
  )

  const clearImage = useCallback(() => {
    onChange?.(null, null)
    clearFile()
  }, [onChange, clearFile])

  return {
    url,
    fileName,
    uploadImage,
    clearImage,
    uploading,
    uploadError,
    fileError,
  }
}
