import { useQueryParams } from 'use-query-params'
import { useCallback } from 'react'
import { defaultItemsPerPageValues } from '@retailer-platform/shared-components'
import { makeEnumQueryParamEncoder } from './makeEnumQueryParamEncoder'
import { numberQueryParamEncoder } from './number.utils'

const queryParamsEncoders = {
  page: numberQueryParamEncoder,
  itemsPerPage: makeEnumQueryParamEncoder(defaultItemsPerPageValues, numberQueryParamEncoder),
}

export const usePaginationQueryParams = () => {
  const [queryParams, setQueryParams] = useQueryParams(queryParamsEncoders)

  const { page, itemsPerPage } = queryParams

  const setPage = useCallback(
    page => {
      setQueryParams({ page })
    },
    [setQueryParams]
  )
  const setItemsPerPage = useCallback(
    itemsPerPage => {
      setQueryParams({ itemsPerPage, page: 1 })
    },
    [setQueryParams]
  )

  return [
    {
      // default query params
      page: page ?? 1,
      itemsPerPage: itemsPerPage ?? defaultItemsPerPageValues[1],
    },
    setPage,
    setItemsPerPage,
  ] as const
}
