import React, { type ReactElement, useMemo } from 'react'
import { type DashTableDatum, type DashCell } from '../../../utils/dashTable.types'
import { useDashTableCellStyling } from '../../../utils/dashTableStyling.utils'

interface Props<TData extends DashTableDatum> {
  cell: DashCell<TData>
}

export function DashTableCell<TData extends DashTableDatum>({
  cell: { getCellProps, render, column },
}: Props<TData>): ReactElement {
  const renderedCell = useMemo(() => render('Cell'), [render])
  const cellProps = useMemo(() => getCellProps(), [getCellProps])
  const style = useDashTableCellStyling<TData>(column)

  return (
    <td {...cellProps} style={style}>
      {renderedCell}
    </td>
  )
}
