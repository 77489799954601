import { NumberParam } from 'use-query-params'
import { type QueryParamEncodedType } from './queryParams.types'

type BaseType = number | undefined

const makeEncoder =
  <TResult extends BaseType>() =>
  (inputValue: TResult | undefined): QueryParamEncodedType =>
    NumberParam.encode(inputValue)

const makeDecoder =
  <TResult extends BaseType>() =>
  (queryStr: QueryParamEncodedType): TResult | undefined => {
    if (!queryStr) return undefined

    return NumberParam.decode(queryStr) as TResult
  }

export const makeGenericNumberQueryParamEncoder = <TResult extends BaseType>() => ({
  encode: makeEncoder<TResult>(),
  decode: makeDecoder<TResult>(),
})

export const numberQueryParamEncoder = makeGenericNumberQueryParamEncoder<BaseType>()
