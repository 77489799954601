import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'

const URL_ADMIN_FULFILLMENT_EVENTS = `${URL_APP_ADMIN}/fulfillment-events`
const URL_ADMIN_FULFILLMENT_EVENTS_LIST = `${URL_ADMIN_FULFILLMENT_EVENTS}/list`
const URL_ADMIN_FULFILLMENT_EVENTS_PARENT_EVENT = `${URL_ADMIN_FULFILLMENT_EVENTS}/:parentEventId/children`
const URL_ADMIN_FULFILLMENT_EVENTS_BULK_EDIT_CHILDREN_EVENTS = `${URL_ADMIN_FULFILLMENT_EVENTS}/:parentEventId/children/edit-events`
const URL_ADMIN_FULFILLMENT_EVENTS_CREATE_EVENT = `${URL_ADMIN_FULFILLMENT_EVENTS}/create-event`
const URL_ADMIN_FULFILLMENT_EVENTS_DUPLICATE_EVENT = `${URL_ADMIN_FULFILLMENT_EVENTS}/duplicate-event/:eventId`
const URL_ADMIN_FULFILLMENT_EVENTS_EDIT_EVENT = `${URL_ADMIN_FULFILLMENT_EVENTS}/:eventId/edit-event`
const URL_ADMIN_FULFILLMENT_EVENTS_ADD_LOCATIONS = `${URL_ADMIN_FULFILLMENT_EVENTS}/add-locations/:eventId`
const URL_ADMIN_FULFILLMENT_EVENTS_EDIT_EVENT_DESCRIPTION = `${URL_ADMIN_FULFILLMENT_EVENTS}/:eventId/edit-event-description`
const URL_ADMIN_FULFILLMENT_EVENTS_CANCEL_EVENT = `${URL_ADMIN_FULFILLMENT_EVENTS}/:eventId/cancel-event`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-fulfillment-events'>
  | DashRouteType<'app-admin-fulfillment-events-list'>
  | DashRouteType<'app-admin-fulfillment-events-parent-event'>
  | DashRouteType<'app-admin-fulfillment-events-bulk-edit-children-events'>
  | DashRouteType<'app-admin-fulfillment-events-create-event'>
  | DashRouteType<'app-admin-fulfillment-events-duplicate-event'>
  | DashRouteType<'app-admin-fulfillment-events-edit-event'>
  | DashRouteType<'app-admin-fulfillment-events-add-locations'>
  | DashRouteType<'app-admin-fulfillment-events-edit-event-description'>
  | DashRouteType<'app-admin-fulfillment-events-cancel-event'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-fulfillment-events': URL_ADMIN_FULFILLMENT_EVENTS,
  'app-admin-fulfillment-events-list': URL_ADMIN_FULFILLMENT_EVENTS_LIST,
  'app-admin-fulfillment-events-parent-event': URL_ADMIN_FULFILLMENT_EVENTS_PARENT_EVENT,
  'app-admin-fulfillment-events-bulk-edit-children-events':
    URL_ADMIN_FULFILLMENT_EVENTS_BULK_EDIT_CHILDREN_EVENTS,
  'app-admin-fulfillment-events-create-event': URL_ADMIN_FULFILLMENT_EVENTS_CREATE_EVENT,
  'app-admin-fulfillment-events-duplicate-event': URL_ADMIN_FULFILLMENT_EVENTS_DUPLICATE_EVENT,
  'app-admin-fulfillment-events-edit-event': URL_ADMIN_FULFILLMENT_EVENTS_EDIT_EVENT,
  'app-admin-fulfillment-events-add-locations': URL_ADMIN_FULFILLMENT_EVENTS_ADD_LOCATIONS,
  'app-admin-fulfillment-events-edit-event-description':
    URL_ADMIN_FULFILLMENT_EVENTS_EDIT_EVENT_DESCRIPTION,
  'app-admin-fulfillment-events-cancel-event': URL_ADMIN_FULFILLMENT_EVENTS_CANCEL_EVENT,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
