import { useSyncExternalStore, type ReactElement } from 'react'
import { ConfirmIcon, IssueIcon, spacing } from '@instacart/ids-core'
import { Toast, ToastAction, ToastIcon, ToastsContainer, ToastText } from '@instacart/ids-customers'
import { useDomainMessages } from '../utils/domain/intl'

interface Toast {
  id: number
  type: 'success' | 'failure'
  title: string
}

let id = 0
let toasts: Toast[] = []
const callbacks: Set<() => void> = new Set()

function notify() {
  callbacks.forEach(callback => callback())
}

export function addToast(params: Omit<Toast, 'id'>) {
  const toast = { ...params, id: id++ }
  toasts = [...toasts, toast]
  setTimeout(() => removeToast(toast.id), 5000)
  notify()
}

function removeToast(id: number) {
  toasts = toasts.filter(toast => toast.id !== id)
  notify()
}

const icons: Record<Toast['type'], ReactElement> = {
  success: <ConfirmIcon />,
  failure: <IssueIcon />,
}

export default function Toasts() {
  const state = useSyncExternalStore(
    callback => {
      callbacks.add(callback)
      return () => {
        callbacks.delete(callback)
      }
    },
    () => toasts
  )
  const messages = useDomainMessages({
    toastAction: 'connectedStoresAdminDomain.toastAction',
  })
  return (
    <ToastsContainer css={{ position: 'absolute', bottom: spacing.s16, zIndex: 1 }}>
      {state.map(toast => (
        <Toast id={String(toast.id)} key={toast.id}>
          <ToastIcon>{icons[toast.type]}</ToastIcon>
          <ToastText>{toast.title}</ToastText>
          <ToastAction onClick={() => removeToast(toast.id)}>{messages.toastAction}</ToastAction>
        </Toast>
      ))}
    </ToastsContainer>
  )
}
