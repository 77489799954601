import React, { type FunctionComponent, useMemo } from 'react'
import { Text } from '@retailer-platform/shared-components'
import { GoogleTypeDayOfWeek } from '../../../../api'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { type FormRegularHours } from '../../../edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'
import { StoreHoursBulkRegularStoreHourItem } from './StoreHoursBulkRegularStoreHourItem'

interface StoreHoursBulkRegularStoreHoursProps {
  regularStoreHours: FormRegularHours
}

export const StoreHoursBulkRegularStoreHours: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkRegularStoreHoursProps>
> = ({ regularStoreHours }) => {
  const i18n = useDomainMessages({
    regularHeader: 'storeHoursBulkDomain.list.modal.header.regularHours',
    sunday: 'storeHoursBulkDomain.dayOfWeek.SUNDAY',
    monday: 'storeHoursBulkDomain.dayOfWeek.MONDAY',
    tuesday: 'storeHoursBulkDomain.dayOfWeek.TUESDAY',
    wednesday: 'storeHoursBulkDomain.dayOfWeek.WEDNESDAY',
    thursday: 'storeHoursBulkDomain.dayOfWeek.THURSDAY',
    friday: 'storeHoursBulkDomain.dayOfWeek.FRIDAY',
    saturday: 'storeHoursBulkDomain.dayOfWeek.SATURDAY',
  })

  const regularStoreHoursList = useMemo(() => {
    const googleDaysOfTheWeek: GoogleTypeDayOfWeek[] = [
      GoogleTypeDayOfWeek.Monday,
      GoogleTypeDayOfWeek.Tuesday,
      GoogleTypeDayOfWeek.Wednesday,
      GoogleTypeDayOfWeek.Thursday,
      GoogleTypeDayOfWeek.Friday,
      GoogleTypeDayOfWeek.Saturday,
      GoogleTypeDayOfWeek.Sunday,
    ]

    const i18nDaysOfTheWeek = [
      i18n.monday.toUpperCase(),
      i18n.tuesday.toUpperCase(),
      i18n.wednesday.toUpperCase(),
      i18n.thursday.toUpperCase(),
      i18n.friday.toUpperCase(),
      i18n.saturday.toUpperCase(),
      i18n.sunday.toUpperCase(),
    ]

    return googleDaysOfTheWeek.map((day, index) => {
      const i18nDay = i18nDaysOfTheWeek[index]
      const storeHour = regularStoreHours[day]
      return <StoreHoursBulkRegularStoreHourItem key={index} day={i18nDay} storeHour={storeHour} />
    })
  }, [i18n, regularStoreHours])

  return (
    <React.Fragment>
      <Text css={{ padding: '5px 0' }} size="medium" weight="bold">
        {i18n.regularHeader}
      </Text>
      {regularStoreHoursList}
    </React.Fragment>
  )
}
