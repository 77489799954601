import { useState, useCallback, useMemo } from 'react'
import { useDebouncedState } from '@retailer-platform/shared-components'

const MIN_COLLECTION_SEARCH_TERM_LENGTH = 3

export const useSearchTerm = (initialSearchTerm: string = '') => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState(initialSearchTerm, 300)
  const [slugs, setSlugs] = useState<string[] | undefined>()

  const searchTermIsSlug = useMemo(
    () => debouncedSearchTerm.startsWith('rc-') || debouncedSearchTerm.startsWith('n-'),
    [debouncedSearchTerm]
  )

  const shouldPerformSearch = debouncedSearchTerm.length >= MIN_COLLECTION_SEARCH_TERM_LENGTH

  const getDebouncedSlugs = useCallback(() => {
    if (searchTermIsSlug) return [debouncedSearchTerm?.replace(/^(rc-|n-)/, '')]
    if (debouncedSearchTerm.length || !slugs) return
    return slugs
      .filter((s): s is string => typeof s === 'string')
      .map(s => s.replace(/^(rc-|n-)/, ''))
  }, [searchTermIsSlug, debouncedSearchTerm, slugs])

  const getDebouncedSearchTerm = useCallback(() => {
    if (searchTermIsSlug) return
    return debouncedSearchTerm
  }, [searchTermIsSlug, debouncedSearchTerm])

  const setSearch = useCallback(
    (value: string) => {
      setSearchTerm(value)
    },
    [setSearchTerm]
  )

  return {
    slugs,
    searchTerm,
    setSlugs,
    setSearch,
    getDebouncedSlugs,
    getDebouncedSearchTerm,
    shouldPerformSearch,
  }
}
