import React, { type FunctionComponent, useMemo, useCallback } from 'react'
import { NewSelect, type NewSelectProps } from '@retailer-platform/shared-components'
import { type ApprovalRequestState } from '../../api'
import { useDomainMessages } from '../../utils/domain/intl'

export type SelectRequestStatusProps = {
  value?: ApprovalRequestState
  onChange?: (str?: ApprovalRequestState) => void
} & Omit<NewSelectProps<{ label: string; value: string }>, 'value' | 'onChange'>

export const SelectRequestStatus: FunctionComponent<
  React.PropsWithChildren<SelectRequestStatusProps>
> = ({ value, onChange, ...rest }) => {
  const i18n = useDomainMessages({
    allStatuses: 'approvalManagementLibrary.searchList.allStatuses',
    PENDING: 'approvalManagementLibrary.approvalRequestState.PENDING',
    APPROVED: 'approvalManagementLibrary.approvalRequestState.APPROVED',
    CANCELLED: 'approvalManagementLibrary.approvalRequestState.CANCELLED',
    PUBLISHED: 'approvalManagementLibrary.approvalRequestState.PUBLISHED',
    PUBLISHING: 'approvalManagementLibrary.approvalRequestState.PUBLISHING',
  })

  const options = useMemo(
    () => [
      { label: i18n.PENDING, value: 'PENDING' },
      { label: i18n.APPROVED, value: 'APPROVED' },
      { label: i18n.CANCELLED, value: 'CANCELLED' },
      { label: i18n.PUBLISHED, value: 'PUBLISHED' },
      { label: i18n.PUBLISHING, value: 'PUBLISHING' },
    ],
    [i18n]
  )

  const onChangeLocal = useCallback(
    (val?: string) => {
      onChange?.(val as ApprovalRequestState)
    },
    [onChange]
  )

  return (
    <NewSelect
      data-testid="select-request-status"
      options={options}
      isClearable={true}
      value={value}
      onChange={onChangeLocal}
      placeholder={i18n.allStatuses}
      {...rest}
    />
  )
}
