import React, { type FunctionComponent, type CSSProperties } from 'react'
import styled from '@emotion/styled'
import { Img } from 'react-image'
import { Flex } from '../../dash-blocks/flex/Flex'
import Link from '../../components/Link'
import { dashSpaceMapping } from '../../dash-blocks/foundation/spacing'
import type LinkBase from '../../components/LinkBase'
import { type RouteName } from '../../../utils/routing/routes'
import { type Filter } from '../../common/utils'
import missingImageSrc from '../../../assets/missing-image.svg'
import { DashText } from '../../dash-blocks/dash-text/DashText'
// eslint-disable-next-line workspaces/no-relative-imports
import { useProductViewRoute } from '../../../../../../domains/products/src/components/view/utils/useProductViewRoute.hooks'

type Sizes = 'large' | 'regular'

// Intentionally limit the views we can route to from a Product cell
type ProductRoutes = Filter<
  RouteName,
  'productEditDetails' | 'productEditAisles' | 'productEditLocations'
>

interface Props extends Pick<React.ComponentPropsWithoutRef<typeof LinkBase>, 'onClick'> {
  productId: string | number
  productName: string
  imageUrl?: string
  size?: Sizes
  productView?: ProductRoutes
}

function resolveStyle(size: Sizes): CSSProperties {
  switch (size) {
    case 'large':
      return {
        width: '100px',
        height: '100px',
      }
    case 'regular':
      return {
        width: '64px',
        height: '64px',
      }
  }
}

const ProductImageWrapper = styled.div`
  flex-shrink: 0;
  margin-right: ${dashSpaceMapping.small};
`

const ProductImage = styled(Img)`
  width: 100%;
  height: 100%;
`

const ProductLink = styled(Link)`
  transition: 0.2s color;
  line-height: 1.4;
  white-space: normal;
`

export const TableProductCell: FunctionComponent<React.PropsWithChildren<Props>> = ({
  productId,
  productName,
  imageUrl = '',
  size = 'regular',
  onClick,
  productView = 'productEditDetails',
}) => {
  const { route, query } = useProductViewRoute(productView, productId?.toString())

  return (
    <Flex align="center" wrap="nowrap">
      <ProductImageWrapper style={resolveStyle(size)}>
        <ProductImage src={[imageUrl, missingImageSrc]} alt={productName} />
      </ProductImageWrapper>

      {productId ? (
        <ProductLink route={route} query={query} underline onClick={onClick}>
          <DashText weight="semibold">{productName}</DashText>
        </ProductLink>
      ) : (
        <DashText weight="semibold">{productName}</DashText>
      )}
    </Flex>
  )
}
