import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint, NavItem } from '@retailer-platform/dashboard/routing'
import { ordersOperationalDashboardRouter } from './routing/ordersOperationalDashboardRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes, totemRouteOverrides } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { ordersOperationalDashboardAccessControl } from './access-control/ordersOperationalDashboardAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'orders-operational-dashboard',
  totem: {
    entity: 'orders-operational-dashboard-dashboard-tool',
    routeOverrides: totemRouteOverrides,
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [ordersOperationalDashboardRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Orders,
      attachToV2: NavEntryPoint.OperationsCustomerSupport,
      subNavItems: [
        {
          type: 'item',
          labelId: 'ordersOperationalDashboardDomain.menuItemTitle',
          route: 'orders-operational-dashboard-search-page',
          accessControl: ordersOperationalDashboardAccessControl,
          positionNavV2: 4,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
