import { type ReactHTML } from 'react'
import { INSTACART_SANS_FONT_FAMILY } from '@instacart/ids-core'

export const dashFontSizeMapping = {
  xsmall: '11px',
  small: '12px',
  medium: '14px',
  large: '16px',
  xlarge: '20px',
  xxlarge: '36px',
} as const

export type DashFontSize = keyof typeof dashFontSizeMapping

export const dashFontSizes = {
  XSMALL: dashFontSizeMapping.xsmall,
  SMALL: dashFontSizeMapping.small,
  MEDIUM: dashFontSizeMapping.medium,
  LARGE: dashFontSizeMapping.large,
  XLARGE: dashFontSizeMapping.xlarge,
  XXLARGE: dashFontSizeMapping.xxlarge,
} as const

export const dashFontSizeElementMapping: { [K in DashFontSize]: keyof ReactHTML } = {
  xsmall: 'p',
  small: 'p',
  medium: 'p',
  large: 'h3',
  xlarge: 'h2',
  xxlarge: 'h1',
} as const

export const dashFontWeightMapping = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
} as const

export type DashFontWeight = keyof typeof dashFontWeightMapping

export const DASH_BASE_FONT_FAMILY = `'${INSTACART_SANS_FONT_FAMILY}', Helvetica Neue, Arial, sans-serif`
export const DASH_MODERN_FONT_FAMILY = DASH_BASE_FONT_FAMILY

export const DASH_FONT_STYLE_OBJECT = {
  letterSpacing: '0.2px',
  lineHeight: '1.3',
}

export const DASH_FONT_STYLES = `
  letter-spacing: ${DASH_FONT_STYLE_OBJECT.letterSpacing};
  line-height: ${DASH_FONT_STYLE_OBJECT.lineHeight};
`
