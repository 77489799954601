/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { ViewType, HideMarketplace } from '@retailer-platform/dashboard/gin-and-tonic'
import {
  pageBuilderModifyAccessControl,
  pageBuilderViewAccessControl,
  storeConfigurationDraftViewAccessControl,
  storefrontBannerLogoAccessControl,
  storefrontCustomImagesAccessControl,
  slotManagerViewAccessControl,
} from '../access-control/storefrontAccess.configuration'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  autosuggestModifyAccessControl,
  autosuggestViewAccessControl,
} from '../access-control/autosuggestAccess.configuration'
import {
  departmentNavigationAccessControl,
  navigationViewAccessControl,
} from '../access-control/navigationAccess.configuration'
import { creativeManagerAccessControl } from '../access-control/creativeManagerAccess.configuration'

const StorefrontNavigationHeaderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontNavigationHeaderPage" */
      '../pages/navigation/StorefrontNavigationHeaderPage'
    )
)
const StorefrontBrandSettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontBrandSettingsPage" */
      '../pages/brand-settings/StorefrontBrandSettingsPage'
    )
)
const StorefrontBrandSettingsPageV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontBrandSettingsPage" */
      '../pages/brand-settings/StorefrontBrandSettingsPageV2'
    )
)
const StorefrontBrandEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontBrandSettingsPage" */
      '../pages/brand-settings/StorefrontBrandEditPage'
    )
)
const StorefrontCustomImagesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontCustomImagesPage" */
      '../pages/brand-settings/StorefrontCustomImagesPage'
    )
)
const StorefrontCustomImagesPageV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontCustomImagesPage" */
      '../pages/brand-settings/StorefrontCustomImagesPageV2'
    )
)
const StorefrontNavigationFooterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontNavigationFooterPage" */
      '../pages/navigation/StorefrontNavigationFooterPage'
    )
)

const StorefrontPlacementCreateBuilderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontPlacementBuilder" */
      '../pages/placement-builder/PlacementBuilderCreatePage'
    )
)

const StorefrontPlacementEditBuilderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontPlacementBuilder" */
      '../pages/placement-builder/PlacementBuilderEditPage'
    )
)

const PlacementSelectViewerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontPlacementBuilder" */
      '../pages/placement-select-viewer/PlacementSelectViewerPage'
    )
)

const StorefrontSiteScopedCreativePage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontSiteScopedCreativePage" */
      '../pages/creative-manager/StorefrontSiteScopedCreativePage'
    )
)

const StorefrontContentPagesDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontContentPagesDashboardPage" */
      '../pages/content-pages-dashboard/StorefrontContentPagesDashboardPage'
    )
)
const StorefrontContentPagesBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontContentPagesBuilder" */
      '../pages/content-pages-builder/StorefrontContentPagesBuilderPage'
    )
)
const StorefrontHomepageDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontHomepageDashboardPage" */
      '../pages/homepage-dashboard/StorefrontHomepageDashboardPage'
    )
)
const StorefrontHomepageBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontHomepageBuilderPage" */
      '../pages/homepage-builder/StorefrontHomepageBuilderPage'
    )
)
const StorefrontFlyoutBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontFlyoutBuilderPage" */
      '../pages/flyout-builder/StorefrontFlyoutBuilderPage'
    )
)
const StorefrontNavigationCollectionsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontNavigationCollectionsPage" */
      '../pages/navigation/StorefrontNavigationCollectionsPage'
    )
)
const StorefrontAutosuggestLinksListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontAutosuggestLinksListPage" */
      '../pages/autosuggest-links/StorefrontAutosuggestLinksListPage'
    )
)
const StorefrontAutosuggestLinksUpsertPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontAutosuggestLinksUpsertPage" */
      '../pages/autosuggest-links/StorefrontAutosuggestLinksUpsertPage'
    )
)
const StorefrontAutosuggestLinksViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontAutosuggestLinksViewPage" */
      '../pages/autosuggest-links/StorefrontAutosuggestLinksViewPage'
    )
)
const StorefrontItemAttributeIconsPage = lazy(
  () => import('../pages/item-attribute-icons/StorefrontItemAttributeIconsPage')
)
const SlotsManagerPage = lazy(() => import('../pages/slots-manager/SlotsManagerPage'))
const StorefrontBannerLogoPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontBannerLogoPage" */
      '../pages/brand-settings/StorefrontBannerLogoPage'
    )
)
// @hygen:inject page-import

export const storefrontRouter = (
  <DomainRoute route="storefront">
    <DashSwitch>
      <DomainRoute
        exact
        route="storefront-brand-settings"
        component={StorefrontBrandSettingsPage}
        accessControl={storeConfigurationDraftViewAccessControl}
        scopePicker={{
          titles: ['storefrontDomain.brand-settings.header'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-brand-settings-v2"
        component={StorefrontBrandSettingsPageV2}
        accessControl={storeConfigurationDraftViewAccessControl}
        scopePicker={{
          titles: ['storefrontDomain.brand-settings.header'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-brand-settings-edit"
        component={StorefrontBrandEditPage}
        accessControl={storeConfigurationDraftViewAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-custom-images"
        component={StorefrontCustomImagesPage}
        accessControl={storefrontCustomImagesAccessControl}
        scopePicker={{
          titles: ['storefrontDomain.brand-settings.header'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-custom-images-v2"
        component={StorefrontCustomImagesPageV2}
        accessControl={storefrontCustomImagesAccessControl}
        scopePicker={{
          titles: ['storefrontDomain.brand-settings.header'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-navigation-header"
        component={StorefrontNavigationHeaderPage}
        accessControl={navigationViewAccessControl}
        scopePicker={{
          titles: ['storefrontDomain.navigation.navigation.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-navigation-footer"
        component={StorefrontNavigationFooterPage}
        accessControl={navigationViewAccessControl}
        scopePicker={{
          titles: [], // intentionally left empty. No header
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-navigation-collections"
        component={StorefrontNavigationCollectionsPage}
        accessControl={departmentNavigationAccessControl}
        scopePicker={{
          titles: [], // intentionally left empty. No header
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
        }}
      />
      <DomainRoute
        exact
        route="storefront-placement-builder-create-with-type"
        component={StorefrontPlacementCreateBuilderPage}
        accessControl={pageBuilderModifyAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-placement-builder-edit"
        component={StorefrontPlacementEditBuilderPage}
        accessControl={pageBuilderModifyAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-placement-viewer"
        component={PlacementSelectViewerPage}
        accessControl={pageBuilderModifyAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-creative-manager"
        component={StorefrontSiteScopedCreativePage}
        accessControl={creativeManagerAccessControl}
        scopePicker={{
          titles: ['campaignManagement.creativeManager.creativeTitle'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-content-pages-dashboard"
        component={StorefrontContentPagesDashboardPage}
      />
      <DomainRoute
        exact
        route="storefront-content-pages-create"
        component={StorefrontContentPagesBuilder}
      />
      <DomainRoute
        exact
        route="storefront-content-pages-edit"
        component={StorefrontContentPagesBuilder}
      />
      <DomainRoute
        exact
        route="storefront-content-pages-edit-version"
        component={StorefrontContentPagesBuilder}
      />
      <DomainRoute
        exact
        route="storefront-flyout-pages-create"
        component={StorefrontFlyoutBuilder}
        accessControl={pageBuilderModifyAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-flyout-pages-edit"
        component={StorefrontFlyoutBuilder}
        accessControl={pageBuilderModifyAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-homepage-dashboard"
        component={StorefrontHomepageDashboard}
        accessControl={pageBuilderViewAccessControl}
        scopePicker={{
          titles: [],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-homepage-edit"
        component={StorefrontHomepageBuilder}
        accessControl={pageBuilderViewAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-autosuggest-links-list"
        component={StorefrontAutosuggestLinksListPage}
        accessControl={autosuggestViewAccessControl}
        scopePicker={{
          // titles: [],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-autosuggest-links-upsert"
        component={StorefrontAutosuggestLinksUpsertPage}
        accessControl={autosuggestModifyAccessControl}
        scopePicker={{
          titles: [], // left empty on purpose. We cannot accurately decide between "edit" or "new" title
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
        }}
      />
      <DomainRoute
        exact
        route="storefront-autosuggest-links-view"
        component={StorefrontAutosuggestLinksViewPage}
        accessControl={autosuggestViewAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-item-attribute-icons"
        component={StorefrontItemAttributeIconsPage}
        scopePicker={{
          titles: [],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="storefront-slots-manager"
        component={SlotsManagerPage}
        accessControl={slotManagerViewAccessControl}
        scopePicker={{
          titles: [],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
        }}
      />
      <DomainRoute
        exact
        route="storefront-banner-logo"
        component={StorefrontBannerLogoPage}
        accessControl={storefrontBannerLogoAccessControl}
        scopePicker={{
          titles: ['storefrontDomain.brand-settings.header'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
          storeConfigFilter: HideMarketplace,
        }}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
