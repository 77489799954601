import { useState, useCallback, useMemo } from 'react'
import { useDebouncedState } from '@retailer-platform/shared-components'

const MIN_MOMENT_SEARCH_TERM_LENGTH = 3

export const useSearchTerm = (initialSearchTerm: string = '') => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState(initialSearchTerm, 200)
  const [slugs, setSlugs] = useState<string[] | undefined>()

  const shouldPerformSearch = debouncedSearchTerm.length >= MIN_MOMENT_SEARCH_TERM_LENGTH

  const getDebouncedSearchTerm = useCallback(() => debouncedSearchTerm, [debouncedSearchTerm])

  const setSearch = useCallback(
    (value: string) => {
      setSearchTerm(value)
    },
    [setSearchTerm]
  )

  return {
    searchTerm,
    setSearch,
    getDebouncedSearchTerm,
    shouldPerformSearch,
  }
}
