import React from 'react'
import { components, type OptionProps } from 'react-select-5'
import { CheckboxBase } from '@instacart/ids-tooling'
import { colors, fontSize, fontWeight } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import useAccessControl from '../../../legacy/components/AccessControl/useAccessControl'
import { accessControlsHasAccess } from '../../../utils/contexts/access-controls/AccessControlsContext'
import { useOptionalAccessControlsContext } from '../../../utils/contexts/access-controls/AccessControls.hooks'
import { type WarehouseContextValue } from '../../../utils/contexts/warehouse/WarehouseContext'

const IconContainer = styled.div({
  alignSelf: 'baseline',
})

const LabelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  color: colors.GRAYSCALE.X70,
})

const Label = styled.div({
  fontSize: fontSize.X15,
  fontWeight: fontWeight.SEMIBOLD,
})

const SubLabel = styled.div({
  fontSize: fontSize.X12,
  fontWeight: fontWeight.REGULAR,
})

const OptionContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: colors.GRAYSCALE.X50,
  fontWeight: fontWeight.REGULAR,
  fontSize: fontSize.X15,
  lineHeight: '22px',
})

interface FiltersDropdownOption {
  label: string
  value: string
  context?: WarehouseContextValue
}

interface CustomOptionProps extends OptionProps<FiltersDropdownOption, false> {}

const CustomOption = (props: CustomOptionProps) => {
  const checkboxId = `group-checkbox-${props.data.value}`
  const selectedValues = props.getValue() as FiltersDropdownOption[]

  const isSelected = selectedValues
    ? selectedValues.some((option: FiltersDropdownOption) => option.value === props.data.value)
    : false

  const hasAccessControl = useAccessControl({
    ...props.data.context,
  })

  const accessControlsContext = useOptionalAccessControlsContext()

  const accessControls = accessControlsContext?.accessControls || []
  const hasAccess = accessControlsHasAccess(accessControls, hasAccessControl)

  if (!hasAccess) {
    props.innerProps.onClick = e => {
      e.stopPropagation()
    }
  }

  return (
    <components.Option {...props} isDisabled={!hasAccess}>
      <OptionContainer>
        <IconContainer>
          <CheckboxBase
            checked={isSelected}
            id={checkboxId}
            data-testid={checkboxId}
            disabled={!hasAccess}
          />
        </IconContainer>
        <LabelContainer>
          <Label>{props.data.label}</Label>
        </LabelContainer>
      </OptionContainer>
    </components.Option>
  )
}

export default CustomOption
