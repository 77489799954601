import React, { useMemo } from 'react'
import ReactSelect, { createFilter } from 'react-select'
import { type Props as ReactDashSelectProps } from 'react-select/src/Select'
import { Box } from '../box/Box'
import { DashSelectContext } from './utils/DashSelectContext'
import { type DashSelectProps, type BaseOption, type SimpleOption } from './utils/select.types'
import { useDashSelectBehaviour } from './utils/select.hooks'
import { makeSelectBaseStyle, makeSelectBaseTheme } from './utils/styles'
import { makeDashSelectComponents } from './utils/select.utils'

export const DashSelect = <TOption extends BaseOption = SimpleOption<string>>(
  props: DashSelectProps<TOption>
) => {
  const { reactSelectOverrides, dashSelectContextValue, filteredOptions } =
    useDashSelectBehaviour<TOption>(props)

  // Don't pass along messages - causes a TS conflict with React Select
  const { hideIndicator, loadingMessage, errorMessage, onChange, ...rest } = props

  const selectStyles = makeSelectBaseStyle(rest)

  // By default, react-select strips accents from inputs
  // There is a significant performance boost gained by disabling this
  const customFilterOption = createFilter({ ignoreAccents: false })

  const customComponents = useMemo(
    () => makeDashSelectComponents({ hideIndicator }),
    [hideIndicator]
  )

  return (
    <Box {...rest}>
      <DashSelectContext.Provider value={dashSelectContextValue}>
        <ReactSelect<TOption>
          {...rest}
          styles={selectStyles}
          theme={makeSelectBaseTheme}
          options={filteredOptions}
          components={customComponents}
          // Type assertion to support our more accurate onChange
          onChange={onChange as ReactDashSelectProps<TOption>['onChange']}
          filterOption={customFilterOption}
          {...reactSelectOverrides}
        />
      </DashSelectContext.Provider>
    </Box>
  )
}
