import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const insightsPortalDataExplorerAccessControl: DomainAccessControlConfig = [
  {
    permissions: Permission.DataExplorer,
    notFeatureToggles: [FeatureToggle.InsightsPortalDataExplorerV3],
    notWarehouseFeatureToggles: [FeatureToggle.InsightsPortalDataExplorerV3],
  },
  {
    permissions: Permission.DataExplorer,
    notFeatureToggles: [FeatureToggle.InsightsPortalDataExplorerV3],
    notWarehouseFeatureToggles: [FeatureToggle.InsightsPortalDataExplorerV3],
  },
]

export const insightsPortalDataExplorerV3AccessControl: DomainAccessControlConfig = [
  {
    permissions: Permission.DataExplorer,
    featureToggles: FeatureToggle.InsightsPortalDataExplorerV3,
  },
  {
    permissions: Permission.DataExplorer,
    warehouseFeatureToggles: FeatureToggle.InsightsPortalDataExplorerV3,
  },
]
