import { type Styles } from 'react-select/src/styles'
import { type Theme } from 'react-select/src/types'
import { type CSSProperties } from 'react'
import { dashFontSizes } from '../../foundation/typography'
import { dashColors } from '../../foundation/colors'
import { type MakeSelectStyleProps, type DashSelectProps } from './select.types'

export const DASH_SELECT_HEIGHT = 36
// Needs to be >10, which is the current `TableLoadingContainer` z-index
export const DASH_SELECT_Z_INDEX = 50
export const DASH_SELECT_OPTION_HEIGHT = DASH_SELECT_HEIGHT - 4

export const makeSelectBaseStyle = ({
  width,
  menuWidth,
  maxMenuWidth,
  overrideControlStyles = {},
}: MakeSelectStyleProps): Styles => ({
  container: (base: CSSProperties) => ({
    ...base,
    width,
    flexGrow: width ? 0 : 'inherit',
  }),
  control: (base: CSSProperties, state: { selectProps: DashSelectProps<{}> }) => ({
    ...base,
    ...overrideControlStyles,
    color: dashColors.DANGER,
    borderColor: state.selectProps.hasError ? dashColors.DANGER : dashColors.NEUTRAL,
    height: DASH_SELECT_HEIGHT,
    minHeight: DASH_SELECT_HEIGHT,
    fontSize: dashFontSizes.SMALL,
    cursor: 'pointer',
    flex: 1,
    width,
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    height: DASH_SELECT_HEIGHT,
    minHeight: DASH_SELECT_HEIGHT,
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    color: dashColors.NEUTRAL,
  }),
  indicatorsContainer: (base: CSSProperties) => ({
    ...base,
    // Allows the indicator container to slightly overlap the value container
    // This makes it so overflowed text ellipsis make better use of space
    marginLeft: -8,
    height: DASH_SELECT_HEIGHT,
    minHeight: DASH_SELECT_HEIGHT,
    pointerEvents: 'none',
  }),
  indicatorSeparator: (base: CSSProperties) => ({
    ...base,
    display: 'none',
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    left: 0,
    width: 'auto',
    zIndex: DASH_SELECT_Z_INDEX,
    minWidth: menuWidth || width || base.width,
    maxWidth: maxMenuWidth || menuWidth || width || base.width,
  }),
  option: (base: CSSProperties, state: { isSelected?: boolean; isFocused?: boolean }) => ({
    ...base,
    padding: 0,
    height: DASH_SELECT_OPTION_HEIGHT,
    fontSize: dashFontSizes.SMALL,
    cursor: 'pointer',
    backgroundColor:
      (state.isSelected && dashColors.NEUTRAL) ||
      (state.isFocused && dashColors.LIGHTER_NEUTRAL) ||
      dashColors.WHITE,
  }),
  singleValue: (base: object) => ({
    ...base,
    height: DASH_SELECT_OPTION_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: 0,
  }),
  group: (base: object) => ({
    ...base,
    paddingTop: 16,
    paddingBottom: 4,
  }),
  groupHeading: (base: object) => ({
    ...base,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
})

export const makeSelectBaseTheme = (base: Theme): Theme => ({
  ...base,
  colors: {
    ...base.colors,
    primary: dashColors.PRIMARY,
    primary75: dashColors.LIGHTER_NEUTRAL,
    primary50: dashColors.LIGHTER_NEUTRAL,
    primary25: dashColors.LIGHTER_NEUTRAL,
    danger: dashColors.DANGER,
    dangerLight: dashColors.LIGHTER_NEUTRAL,
    neutral30: dashColors.DARK_NEUTRAL,
    neutral60: dashColors.NEUTRAL,
    neutral80: dashColors.DARK_NEUTRAL,
  },
})
