import React, { type ReactElement, useMemo } from 'react'
import { type UseTableInstanceProps } from 'react-table-next'
import { type DashTableDatum, type DashRow } from '../../utils/dashTable.types'
import { DashTableRow } from './components/DashTableRow'

interface Props<TData extends DashTableDatum> {
  getTableBodyProps: UseTableInstanceProps<TData>['getTableBodyProps']
  prepareRow: UseTableInstanceProps<TData>['prepareRow']
  page: DashRow<TData>[]
}

export function DashTableBody<TData extends DashTableDatum>({
  getTableBodyProps,
  prepareRow,
  page,
}: Props<TData>): ReactElement {
  const renderedRows = useMemo(
    () =>
      page.map(row => {
        prepareRow(row)

        return <DashTableRow<TData> key={row.index} row={row} />
      }),
    [page, prepareRow]
  )

  const tableBodyProps = useMemo(() => getTableBodyProps, [getTableBodyProps])

  return <tbody {...tableBodyProps}>{renderedRows}</tbody>
}
