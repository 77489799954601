import React, { type FunctionComponent, useCallback } from 'react'
import styled from '@emotion/styled'
import {
  Text,
  colors,
  fontSize,
  fontWeight,
  spacing,
  LoadingDots,
  borderRadius,
} from '@retailer-platform/shared-components'
import { useDashMessages } from '../../../intl/intl.hooks'
import { useCreateHref } from '../../../utils/routing/navigation.hooks'

const Wrapper = styled.div({
  position: 'relative',
  display: 'flex',
  width: '100%',
  padding: `${spacing.X16} ${spacing.X20}`,
})

const Container = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `thin solid ${colors.GRAYSCALE.X20}`,
  borderRadius: borderRadius.X12,
})

const HeaderContainer = styled.div({
  height: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: `${spacing.X24} ${spacing.X16} 0 ${spacing.X16}`,
})

const HeaderTitle = styled.div({
  fontSize: fontSize.X23,
  fontWeight: fontWeight.BOLD,
  color: colors.GRAYSCALE.X70,
  lineHeight: '28px',
})

const HeaderSubTitle = styled.div({
  fontSize: fontSize.X15,
  fontWeight: fontWeight.REGULAR,
  color: colors.GRAYSCALE.X50,
  lineHeight: '22px',
})

const AnalyticsCta = styled.a({
  marginTop: '5px',
  color: colors.PRIMARY.REGULAR,
  textDecoration: 'none',
  ':hover': {
    color: colors.SECONDARY.DARK,
  },
})

const MetricsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const LoadingContainer = styled.div({
  margin: `${spacing.X24} auto`,
})

interface PickingPerformanceOverviewProps {
  title?: string
  subtitle?: string
  isLoading?: boolean
  showAnalyticsCta?: boolean
  onAnalyticsCtaClicked?: () => void
  warehouseLocationId?: number
  retailerLocationId?: string
}

export const PickingPerformanceOverview: FunctionComponent<
  React.PropsWithChildren<PickingPerformanceOverviewProps>
> = ({
  title = '',
  subtitle = '',
  isLoading = true,
  showAnalyticsCta,
  onAnalyticsCtaClicked,
  warehouseLocationId,
  retailerLocationId,
  children,
}) => {
  const messages = useDashMessages({
    analyticsCta: 'shiftsDomain.performance.cta.toPickupAnalytics',
  })

  const retailerLocationSelectValue = JSON.stringify({ retailerLocationId })
  const pickupAnalyticsUrl = useCreateHref('pickupAnalytics', {
    warehouseLocationId,
    retailerLocationSelectValue,
  })

  const handleClick = useCallback(() => {
    onAnalyticsCtaClicked?.()
  }, [onAnalyticsCtaClicked])

  const header = (
    <HeaderContainer>
      <div>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubTitle>{subtitle}</HeaderSubTitle>
      </div>
      {showAnalyticsCta && (
        <AnalyticsCta href={pickupAnalyticsUrl} onClick={handleClick}>
          <Text size="medium" weight="semibold">
            {messages.analyticsCta}
          </Text>
        </AnalyticsCta>
      )}
    </HeaderContainer>
  )

  if (isLoading) {
    return (
      <Wrapper>
        <Container>
          {header}
          <MetricsContainer>
            <LoadingContainer>
              <LoadingDots height={20} centered testId="pickingPerformanceOverviewLoading" />
            </LoadingContainer>
          </MetricsContainer>
        </Container>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Container>
        {header}
        <MetricsContainer>{children}</MetricsContainer>
      </Container>
    </Wrapper>
  )
}
