import { useMemo } from 'react'
import { useListStoreGroups } from '../../../common/graphql/store-groups/list.hooks'
import { useWarehouseLocations } from '../../../common/utils/warehouse-locations/locations.hooks'
import { type RetailerLocation } from '../../../common/graphql-types/retailerLocation.types'

export const useRetailerLocationSelectData = () => {
  const {
    data: warehouseLocations,
    loading: warehouseLocationsLoading,
    error: warehouseLocationError,
  } = useWarehouseLocations()

  const { storeGroups, loading: storeGroupsLoading, error: storeGroupsError } = useListStoreGroups()

  const error = useMemo(() => {
    if (warehouseLocationError) return warehouseLocationError
    if (storeGroupsError) return storeGroupsError

    return undefined
  }, [storeGroupsError, warehouseLocationError])

  const loading = useMemo(() => {
    if (error) return false

    return storeGroupsLoading || warehouseLocationsLoading
  }, [error, storeGroupsLoading, warehouseLocationsLoading])

  const locations = warehouseLocations || []
  const retailerLocations: RetailerLocation[] = locations.map(({ id, name }) => ({
    id,
    name: name || `Unnamed Store ${id}`,
  }))

  return {
    retailerLocations,
    storeGroups,
    loading,
    error,
  }
}
