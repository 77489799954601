import React, { type FunctionComponent, useState, useCallback } from 'react'
import { DateRangePicker, type FocusedInputShape } from 'react-dates'
import { type Moment } from 'moment'
import { type StartEndDate } from '../../../common/utils/time/dateRanges.types'
import { Box } from '../../../dash-blocks/box/Box'

interface Props {
  value: StartEndDate
  onChange: (nextValue: StartEndDate) => void
  isOutsideRange: ((day: Moment) => boolean) | undefined
}

export const DateSelectRangeCustomDates: FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  isOutsideRange,
}) => {
  const [internalValue, setInternalValue] = useState<StartEndDate>(value)
  const [focusedInput, setFocusedState] = useState<FocusedInputShape | null>(null)
  const handleFocusChange = useCallback((nextFocusedInput: FocusedInputShape | null) => {
    setFocusedState(nextFocusedInput)
  }, [])

  const handleInternalValueChange = useCallback(
    (nextDates: StartEndDate) => {
      setInternalValue(nextDates)

      // If the focused input was endDate, then we know the modal is going to close
      // Now, we execute the search
      if (focusedInput === 'endDate' && nextDates.endDate) {
        onChange(nextDates)
      }
    },
    [focusedInput, onChange]
  )

  return (
    <Box paddingLeft="xsmall">
      <DateRangePicker
        minimumNights={0}
        isOutsideRange={isOutsideRange}
        small
        withPortal
        startDate={internalValue.startDate}
        startDateId="startDate"
        endDate={internalValue.endDate}
        endDateId="endDate"
        onDatesChange={handleInternalValueChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
      />
    </Box>
  )
}
