import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  fulfillmentEventsModifyAccessControl,
  fulfillmentEventsViewAccessControl,
} from '../access-control/fulfillmentEventsAdminAccess.configuration'

const FulfillmentEventsEditEventDescriptionPage = lazy(
  () => import('../pages/edit-event-description/FulfillmentEventsEditEventDescriptionPage')
)

const FulfillmentEventsEditEventPage = lazy(
  () => import('../pages/edit-event/FulfillmentEventsEditEventPage')
)

const FulfillmentEventsCancelEventPage = lazy(
  () => import('../pages/cancel-event/FulfillmentEventsCancelEventPage')
)

const FulfillmentEventsParentEventPage = lazy(
  () => import('../pages/parent-event/FulfillmentEventsParentEventPage')
)

const FulfillmentEventsBulkEditChildrenEventsPage = lazy(
  () => import('../pages/bulk-edit-children-events/FulfillmentEventsBulkEditChildrenEventsPage')
)

const FulfillmentEventsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FulfillmentEventsListPage" */
      '../pages/list/FulfillmentEventsListPage'
    )
)
const FulfillmentEventsCreateEventPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FulfillmentEventsCreateEventPage" */
      '../pages/create-event/FulfillmentEventsCreateEventPage'
    )
)

const FulfillmentEventsAddLocationsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FulfillmentEventsCreateEventPage" */
      '../pages/add-locations/FulfillmentEventsAddLocationsPage'
    )
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const fulfillmentEventsAdminRouter = (
  <DomainRoute
    route="app-admin-fulfillment-events"
    accessControl={fulfillmentEventsViewAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-list"
        component={FulfillmentEventsListPage}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-create-event"
        component={FulfillmentEventsCreateEventPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-duplicate-event"
        component={FulfillmentEventsCreateEventPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-edit-event"
        component={FulfillmentEventsEditEventPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-add-locations"
        component={FulfillmentEventsAddLocationsPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-edit-event-description"
        component={FulfillmentEventsEditEventDescriptionPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-cancel-event"
        component={FulfillmentEventsCancelEventPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-parent-event"
        component={FulfillmentEventsParentEventPage}
      />
      <DomainRoute
        exact
        route="app-admin-fulfillment-events-bulk-edit-children-events"
        component={FulfillmentEventsBulkEditChildrenEventsPage}
        accessControl={fulfillmentEventsModifyAccessControl}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
