import styled from '@emotion/styled'
import { INSTACART_SANS_THEME_TYPOGRAPHY } from '@instacart/ids-core'
import { Button } from '../button/Button'
import { colors, spacing } from '../../foundation'

export const PaginationPageInput = styled.input({
  border: `1px solid ${colors.GRAYSCALE.X30}`,
  borderRadius: 6,
  appearance: 'textfield',
  textAlign: 'left',
  display: 'block',
  margin: '0px 8px',
  paddingLeft: 12,
  paddingRight: 8,
  width: 38,
  ...INSTACART_SANS_THEME_TYPOGRAPHY['bodySmall1'],

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
})

export const PaginationContainer = styled.div({
  display: 'flex',

  '> .item': {
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.total': {
    width: 'auto',
    paddingRight: spacing.X12,
  },
})

export const PaginationButton = styled(Button)(
  {
    margin: 0,
    padding: 0,
    borderRadius: 6,
    width: 28,
  },

  ({ disabled }) => {
    const fill = disabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X70

    return {
      svg: { fill },
    }
  }
)
