import React from 'react'
import { Text } from '@retailer-platform/shared-components'
import moment from 'moment'
import { type Holiday } from '../../../../api'
import { type DomainMessages, useDomainMessages } from '../../../../utils/domain/intl'
import { useDomainGoToPath } from '../../../../utils/domain/routing'

interface StoreHoursBulkHolidaysHolidayNameCellProps {
  holiday: Holiday
}

export const StoreHoursBulkHolidaysHolidayNameCell = ({
  holiday,
}: StoreHoursBulkHolidaysHolidayNameCellProps) => {
  const i18n = useDomainMessages({
    unknownHoliday: 'storeHoursBulkDomain.holidays.names.unknownHoliday',
    holidayName: `storeHoursBulkDomain.holidays.names.${holiday.holidaySlug}` as DomainMessages,
  })

  const date = holiday.date
  if (!date.day || !date.month || !date.year) {
    throw Error(`Date missing attributes: ${JSON.stringify(holiday.date)}`)
  }

  const gotoStoresConfirmedListPage = useDomainGoToPath(
    'store-hours-bulk-holidays-stores-confirmed-list',
    {
      date: `${date.year}-${date.month}-${date.day}`,
    }
  )

  let holidayName = i18n.unknownHoliday
  if (holiday.holidaySlug) {
    holidayName = i18n.holidayName

    if (holiday.date.year > moment().year()) {
      //if the holiday is next year, append the year to the holiday
      holidayName += ` ${holiday.date.year}`
    }
  }

  return (
    <Text variant="link" data-testid="holiday-name-cell" weight="semibold">
      <div onClick={gotoStoresConfirmedListPage}>{holidayName}</div>
    </Text>
  )
}
