import { type FormatXMLElementFn, type PrimitiveType } from 'intl-messageformat'
import { useIntl, type IntlShape } from 'react-intl'
import { useDeepMemo } from '../useDeepReactHooks.hooks'

const useMessage = (messageId?: string, values?: Record<string, PrimitiveType>) => {
  const intl = useIntl()

  if (!messageId) {
    return undefined
  }

  return intl.formatMessage({ id: messageId }, values)
}

const useMessages = <T extends Record<string, string>>(
  messageIdMap: T = {} as T,
  values?: Record<string, PrimitiveType | React.ReactElement>
) => {
  const intl = useIntl()
  type ResultType = Record<keyof T, React.ReactNode>

  return useDeepMemo(
    () =>
      Object.entries(messageIdMap).reduce<Partial<ResultType>>((acc, [key, messageId]) => {
        acc[key as keyof T] = messageId ? intl.formatMessage({ id: messageId }, values) : undefined
        return acc
      }, {}) as ResultType,
    [messageIdMap, intl, values]
  )
}

/**
 * The following types/functions are just convenience  over the same instance of the
 * original useMessage/useMessages/useIntl hooks.
 *
 * The only difference is that each factory will return a hook that is aware of the
 * message map of wherever it's used
 */
type UseMessage<TMessages extends string> = (
  id: TMessages,
  values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>
) => string

export function makeUseMessage<TMessageIds extends string>(): UseMessage<TMessageIds> {
  return useMessage as UseMessage<TMessageIds>
}

type UseMessages<TMessageIds extends string> = <TMessageIdMap extends Record<string, TMessageIds>>(
  messageIdMap: TMessageIdMap,
  values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>
) => Record<keyof TMessageIdMap, string>

export function makeUseMessages<TMessageIds extends string>(): UseMessages<TMessageIds> {
  return useMessages as UseMessages<TMessageIds>
}

export function makeUseIntl<TMessageIds extends IntlShape>() {
  return () => useIntl() as TMessageIds
}
