import React from 'react'
import { type Permission, type PartnerType } from '../../common/types'
import { type WarehouseLocationFeature } from '../../common/warehouseLocations'
import { type FeatureToggle } from '../../contexts/featureToggles/FeatureToggleContext'
import { AccessControlOneOf, type AccessControlConfig } from './accessControl.utils'
import useAccessControl from './useAccessControl'

interface Props extends AccessControlConfig {
  children: React.ReactNode
}

const AccessControl: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  ...rest
}) => {
  const hasAccess = useAccessControl()

  if (hasAccess(rest)) {
    return <>{children}</>
  }

  return null
}

function oneOf(values: Permission[]): AccessControlOneOf<Permission>
function oneOf(values: FeatureToggle[]): AccessControlOneOf<FeatureToggle>
function oneOf(values: WarehouseLocationFeature[]): AccessControlOneOf<WarehouseLocationFeature>
function oneOf(values: PartnerType[]): AccessControlOneOf<PartnerType>
function oneOf<T>(values: T[]) {
  return new AccessControlOneOf<T>(values)
}

export default Object.assign(AccessControl, { oneOf })
