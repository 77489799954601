import React from 'react'
import { Img } from 'react-image'
import {
  Button,
  Modal,
  type ModalProps,
  ModalHeader,
  ModalFooter,
  ModalBody,
  spacing,
  LoadingDots,
} from '@retailer-platform/shared-components'
import { Text } from '@instacart/ids-customers'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export type GenericModalProps = ModalProps & {
  image?: string
  imageComponent?: React.ReactNode
  i18n: {
    title: string
    bodyHeadline: string
    body: string
    ok: string
  }
  additionalContent?: React.ReactNode
}

export const GenericModal = (props: GenericModalProps) => {
  const { i18n, image, imageComponent } = props

  return (
    <Modal {...props} data-testid="review-modal">
      <ModalHeader>{i18n.title}</ModalHeader>
      <CenteredBody>
        {image && (
          <ImageContainer>
            <Img css={imageStyles} src={image} />
          </ImageContainer>
        )}
        {imageComponent}
        <div>
          <CenteredText typography="bodyLarge1">{i18n.bodyHeadline}</CenteredText>
          <CenteredText typography="bodyMedium2">{i18n.body}</CenteredText>
        </div>
        {Boolean(props.additionalContent) && <div>{props.additionalContent}</div>}
      </CenteredBody>
      <ModalFooter>
        <Button variant="primary" data-testid="ok" onClick={props.onRequestClose}>
          {i18n.ok}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const LoadingModal = (props: ModalProps) => (
  <Modal {...props} styleOverrides={{ content: { margin: spacing.X64 } }}>
    <CenteredBody css={{ height: 300 }}>
      <LoadingDots />
    </CenteredBody>
  </Modal>
)

export const CenteredBody = styled(ModalBody)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing.X16,
})

export const CenteredText = styled(Text)({
  textAlign: 'center',
})

export const imageStyles = css({
  height: '64px',
  width: '64px',
  alignItems: 'center',
})

export const ImageContainer = styled.div({
  padding: spacing.X16,
})
