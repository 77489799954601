import React, { Fragment } from 'react'
import { components } from 'react-select'
import { type MenuProps } from 'react-select/src/components/Menu'
import { useRequiredContext } from '../../../contexts/utils'
import { type BaseOption } from '../utils/select.types'
import { DashSelectContext } from '../utils/DashSelectContext'
import { Box } from '../../box/Box'
import { DashSelectToggleableHeader } from './DashSelectToggleableHeader'

const { Menu } = components

export const DashSelectMenu = <TOption extends BaseOption>(props: MenuProps<TOption>) => {
  const { children, selectProps, ...rest } = props
  const { toggle } = useRequiredContext(DashSelectContext)

  return (
    <Menu selectProps={selectProps} {...rest}>
      <Fragment>
        {toggle && (
          <DashSelectToggleableHeader
            onToggle={toggle.handleToggle}
            toggleActive={toggle.toggleActive}
            toggleHeading={toggle.toggleHeading}
          />
        )}
        <Box paddingBottom="xxsmall">{children}</Box>
      </Fragment>
    </Menu>
  )
}
