import { useAccessControl } from '@retailer-platform/dashboard/access-control'
import {
  type AccessControlConfig,
  type WarehouseLocationFeature,
} from '@retailer-platform/dashboard/access-control'
import { type Permission } from '../../access-control/permissions'
import { type FeatureToggle } from '../../access-control/featureToggles'
import { type FeatureEntitlements } from '../../access-control/featureEntitlements'

export type SingleDomainAccessControlConfig = AccessControlConfig<
  FeatureToggle,
  Permission,
  WarehouseLocationFeature,
  FeatureEntitlements
>

export type DomainAccessControlConfig =
  | SingleDomainAccessControlConfig
  | SingleDomainAccessControlConfig[]

export const useDomainAccessControl = useAccessControl as any as () => (
  accessControlConfig: DomainAccessControlConfig
) => boolean
