import React, { type ReactElement, useMemo } from 'react'
import { type CSSObject } from '@emotion/react'
import {
  type DashTableDatum,
  type DashColumnInstance,
  DashTableSortingDirections,
} from '../../../utils/dashTable.types'
import { useDashTableHeaderCellStyling } from '../../../utils/dashTableStyling.utils'
import { DashTableHeaderIcon } from './DashTableHeaderIcon'

interface Props<TData extends DashTableDatum> {
  column: DashColumnInstance<TData>
  id: string
}

export function DashTableHeaderCell<TData extends DashTableDatum>({
  column,
  id,
}: Props<TData>): ReactElement {
  const { getHeaderProps, render, getSortByToggleProps, isSorted, isSortedDesc } = column
  const renderedHeader = useMemo(() => render('Header'), [render])

  // Set a default style if none is provided
  const headerProps = useMemo(
    () => ({ style: {}, ...getHeaderProps(getSortByToggleProps()) }),
    [getHeaderProps, getSortByToggleProps]
  )

  const style = useDashTableHeaderCellStyling<TData>(column)

  return (
    <th {...headerProps} css={style as CSSObject} id={`dashTable-head-${id}`}>
      {renderedHeader}
      {isSorted && isSortedDesc !== undefined && (
        <DashTableHeaderIcon
          direction={
            isSortedDesc ? DashTableSortingDirections.DESC : DashTableSortingDirections.ASC
          }
        />
      )}
    </th>
  )
}
