import React, { useState, useCallback, useEffect } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@instacart/ids-core'
import { Text } from '@instacart/ids-customers'
import { useMessages } from '../../utils/intl/intl.hooks'
import { PaginationContainer, PaginationButton, PaginationPageInput } from './Pagination.styles'

export type PaginationProps = {
  onChange: (pageNumber: number) => void
  page: number
  total?: number
  renderTotalLabel?: (total: number) => string | React.ReactElement
  disabled?: boolean
}

export const Pagination: React.FunctionComponent<React.PropsWithChildren<PaginationProps>> = ({
  total = Infinity,
  page,
  onChange: incomingOnChange,
  renderTotalLabel,
  disabled,
}) => {
  const [internalTotal, setInternalTotal] = useState(total)
  const [internalPage, setInternalPage] = useState(page.toString())
  const messages = useMessages(
    {
      totalLabel: 'sharedComponents.pagination.totalLabel',
      totalLabelIndeterminate: 'sharedComponents.pagination.totalLabelIndeterminate',
    },
    { total }
  )

  const constrain = useCallback((value: number) => Math.max(1, Math.min(total, value)), [total])

  // Handle selecting all the content when focusing
  const selectAllOnFocus = useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
    evt.target.select()
  }, [])

  const onChange = useCallback(
    (newValue: number) => {
      incomingOnChange(constrain(newValue))
    },
    [constrain, incomingOnChange]
  )

  // Commits internal state
  const commitChange = useCallback(() => {
    if (!internalPage) {
      return setInternalPage(page.toString())
    }

    const nextValue = constrain(Number(internalPage))

    // This update is necessary  because a constrained value may actually generate no changes
    // For instance, being on page 10 and trying to go to page 15 will be constrained to 10
    // This will not trigger the useEffect below.
    setInternalPage(nextValue.toString())
    onChange(nextValue)
  }, [onChange, setInternalPage, constrain, internalPage, page])

  // Sync incoming state to internal
  useEffect(() => setInternalPage(page.toString()), [page])

  // Sync total
  useEffect(() => setInternalTotal(total), [total])

  // If total and internal total are different, trigger an onChange in case page is out of bounds
  useEffect(() => {
    if (total === internalTotal) return

    const constrainedPage = constrain(page)

    if (constrainedPage !== page) onChange(constrainedPage)
  }, [total, internalTotal, page, constrain, onChange])

  const totalLabel = total === Infinity ? messages.totalLabelIndeterminate : messages.totalLabel

  return (
    <PaginationContainer>
      <PaginationButton
        data-testid="pagination-prev-button"
        className="item"
        variant="secondary"
        disabled={page === 1 || disabled}
        onClick={() => onChange(page - 1)}
      >
        <ChevronLeftIcon />
      </PaginationButton>

      <PaginationPageInput
        data-testid="pagination-page-input"
        className="item"
        onFocus={selectAllOnFocus}
        type="number"
        max={total}
        min={1}
        step={1}
        pattern="^\d*$"
        onChange={evt => {
          setInternalPage(evt.target.value)
        }}
        onBlur={commitChange}
        onKeyPress={evt => {
          if (evt.key !== 'Enter') return

          commitChange()
        }}
        value={internalPage}
        disabled={disabled}
      />

      <div data-testid="pagination-total-label" className="item total">
        <Text typography="bodySmall2">
          {renderTotalLabel ? renderTotalLabel(total) : totalLabel}
        </Text>
      </div>

      <PaginationButton
        data-testid="pagination-next-button"
        className="item"
        variant="secondary"
        disabled={page === total || disabled}
        onClick={() => onChange(page + 1)}
      >
        <ChevronRightIcon />
      </PaginationButton>
    </PaginationContainer>
  )
}
