const EN_US = {
  'fulfillmentEventsDomain.title': 'Fulfillment Events',
  'fulfillmentEventsDomain.createNewEvent.title': 'Create Fulfillment Events',
  'fulfillmentEventsDomain.updateEvent.title': 'Update Fulfillment Events',
  'fulfillmentEventsDomain.breadCrumbs.L1': 'Event Management',
  'fulfillmentEventsDomain.breadCrumbs.createNewEvent': 'Create New Event',
  'fulfillmentEventsDomain.breadCrumbs.createNewEvent.fulfillmentEventType':
    'New Fulfillment Event',
  'fulfillmentEventsDomain.breadCrumbs.createNewEvent.closureType': 'New Closure Event',
  'fulfillmentEventsDomain.breadCrumbs.createNewEvent.restrictedAvailabilityType':
    'New Restricted Availability Event',
  'fulfillmentEventsDomain.createNewEvent.zoneClosureEvent.warning':
    'This will create a Closure event with Zone as the location type.',
  'fulfillmentEventsDomain.breadCrumbs.updateEvent.fulfillmentEventType':
    'Update Fulfillment Event',
  'fulfillmentEventsDomain.breadCrumbs.updateEvent.closureType': 'Update Closure Event',
  'fulfillmentEventsDomain.breadCrumbs.updateEvent.restrictedAvailabilityType':
    'Update Restricted Availability Event',
  'fulfillmentEventsDomain.createNewEvent.eventWillBeCreatedAsync':
    'Since this event impacts over 50 locations, it will be created asynchronously. We will create the parent event immediately and then the child events in the background. You will receive an email once it completes or if any child events fail to create.',
  'fulfillmentEventsDomain.createNewEvent.eventIsValid': 'Form is valid. Please see summary below.',
  'fulfillmentEventsDomain.createNewEvent.eventIsNotValid':
    'Form is invalid. Please see errors below.',
  'fulfillmentEventsDomain.breadCrumbs.helpButton': 'Help',
  'fulfillmentEventsDomain.manageEvents': 'Manage fulfillment events',
  'fulfillmentEventsDomain.clickToOpen': 'Click to open',
  'fulfillmentEventsDomain.header.stagingEnvText':
    'You are on the staging environment. Changes here will have no impact to live systems.',
  'fulfillmentEventsDomain.updateEvent.overwriteChildEvents.warning':
    'Updating this parent event will also overwrite all of its child events.',

  // Severity Levels
  'fulfillmentEventsDomain.severityLevel.zeroCapacityImpact': 'No Fulfillment Impact',
  'fulfillmentEventsDomain.severityLevel.lowCapacityImpact': 'Low Capacity Impact',
  'fulfillmentEventsDomain.severityLevel.mediumCapacityImpact': 'Medium Capacity Impact',
  'fulfillmentEventsDomain.severityLevel.highCapacityImpact': 'High Capacity Impact',
  'fulfillmentEventsDomain.severityLevel.storeClosure': 'Closure',
  'fulfillmentEventsDomain.severityLevel.restrictedServiceOptions': 'Restricted Availability',
  'fulfillmentEventsDomain.severityLevel.zeroCapacityImpact.abbreviation': 'L0',
  'fulfillmentEventsDomain.severityLevel.lowCapacityImpact.abbreviation': 'L1',
  'fulfillmentEventsDomain.severityLevel.mediumCapacityImpact.abbreviation': 'L2',
  'fulfillmentEventsDomain.severityLevel.highCapacityImpact.abbreviation': 'L3',
  'fulfillmentEventsDomain.severityLevel.storeClosure.abbreviation': 'L4',
  'fulfillmentEventsDomain.severityLevel.restrictedServiceOptions.abbreviation': 'L5',

  // Fulfillment Event Statuses
  'fulfillmentEventsDomain.fulfillmentEventStatus.upcoming': 'Upcoming',
  'fulfillmentEventsDomain.fulfillmentEventStatus.inProgress': 'In Progress',
  'fulfillmentEventsDomain.fulfillmentEventStatus.completed': 'Completed',
  'fulfillmentEventsDomain.fulfillmentEventStatus.deleted': 'Deleted',

  // Event Location Types
  'fulfillmentEventsDomain.eventLocationType.warehouseLocation': 'Warehouse Location',
  'fulfillmentEventsDomain.eventLocationType.postalCode': 'Postal/Zip Code',
  'fulfillmentEventsDomain.eventLocationType.zone': 'Zone',
  'fulfillmentEventsDomain.eventLocationType.region': 'Region',
  'fulfillmentEventsDomain.eventLocationType.warehouseZone': 'Warehouse & Zone',
  'fulfillmentEventsDomain.eventLocationType.warehouse': 'Warehouse',

  // Filters
  'fulfillmentEventsDomain.filters.applyPasteInstruction': 'Apply',
  'fulfillmentEventsDomain.filters.pasteIdsPlaceholder':
    'Optional: Enter comma separated record IDs (IDs are database IDs, not codes or names)',
  'fulfillmentEventsDomain.filters.asyncSelectTypeToStartSearching': 'Type to start searching...',
  'fulfillmentEventsDomain.filters.searchPlaceholder': 'Search event name or issue link',
  'fulfillmentEventsDomain.filters.searchIssuePlaceholder': 'Search issue link',
  'fulfillmentEventsDomain.filters.statusPlaceholder': 'Status',
  'fulfillmentEventsDomain.filters.impactTypePlaceholder': 'Impact type',
  'fulfillmentEventsDomain.filters.dateRangePlaceholder': 'Event date range',
  'fulfillmentEventsDomain.filters.warehousesPlaceholder': 'Warehouses...',
  'fulfillmentEventsDomain.filters.warehousesNoOptionsMessage':
    'Type to start searching warehouses',
  'fulfillmentEventsDomain.filters.warehousesValuesSectionHeader': 'warehouse(s) added',
  'fulfillmentEventsDomain.filters.warehouseLocationsPlaceholder': 'Warehouse locations...',
  'fulfillmentEventsDomain.filters.warehouseLocationsNoOptionsMessage':
    'Type to start searching warehouse locations',
  'fulfillmentEventsDomain.filters.warehouseLocationsValuesSectionHeader':
    'warehouse locations(s) added',
  'fulfillmentEventsDomain.filters.warehouseLocationsPickupLabel': 'Pickup',
  'fulfillmentEventsDomain.filters.warehouseLocationsPartnerPickLabel': 'Partner Pick',
  'fulfillmentEventsDomain.filters.tagsPlaceholder': 'Tags...',
  'fulfillmentEventsDomain.filters.creatorsPlaceholder': 'Created by...',
  'fulfillmentEventsDomain.filters.regionPlaceholder': 'Region...',
  'fulfillmentEventsDomain.filters.regionsPlaceholder': 'Regions...',
  'fulfillmentEventsDomain.filters.regionsNoOptionsMessage': 'Type to start searching regions',
  'fulfillmentEventsDomain.filters.regionsValuesSectionHeader': 'region(s) added',
  'fulfillmentEventsDomain.filters.postalCodesPlaceholder': 'Postal/Zip Code...',
  'fulfillmentEventsDomain.filters.postalCodesNoOptionsMessage':
    'Type to start searching Postal/Zip Codes',
  'fulfillmentEventsDomain.filters.postalCodesValuesSectionHeader': 'Postal/Zip Code(s) added',
  'fulfillmentEventsDomain.filters.postalCodesBulkPastePlaceholder':
    'Optional: Enter multiple comma separated postal/zip codes',
  'fulfillmentEventsDomain.filters.zonesPlaceholder': 'Zone...',
  'fulfillmentEventsDomain.filters.zonesNoOptionsMessage': 'Type to start searching zones',
  'fulfillmentEventsDomain.filters.zonesValuesSectionHeader': 'zone(s) added',
  'fulfillmentEventsDomain.filters.countryPlaceholder': 'Country...',
  'fulfillmentEventsDomain.filters.countriesSelectedText': 'countries added',
  'fulfillmentEventsDomain.filters.numberOfFiltersSelected':
    '{numberOfFiltersSelected} filter(s) selected',
  'fulfillmentEventsDomain.filters.clearAllFilters': 'Clear all filters',

  // Grid
  'fulfillmentEventsDomain.list.grid.allRecordsOnPageSelected':
    'All {numberOfRecords} events on this page have been selected',
  'fulfillmentEventsDomain.list.grid.numberOfRecordsSelected':
    '{numberOfRecords} event(s) selected',
  'fulfillmentEventsDomain.list.grid.unselectAll': 'Unselect all',
  'fulfillmentEventsDomain.list.grid.edit': 'Edit',
  'fulfillmentEventsDomain.list.grid.selectAddPage.toast':
    'All {numberOfEvents} events in the page have been selected',
  'fulfillmentEventsDomain.list.grid.selectRemovePage.toast':
    'All {numberOfEvents} events in the page have been removed from the selection',
  'fulfillmentEventsDomain.list.grid.eventCount': 'Events ({eventCount})',
  'fulfillmentEventsDomain.list.grid.noResults':
    'No events found. Try changing or removing some of the filters.',
  'fulfillmentEventsDomain.list.grid.loading': 'Loading...',
  'fulfillmentEventsDomain.list.grid.headers.id': 'ID',
  'fulfillmentEventsDomain.list.grid.headers.description': 'Event description',
  'fulfillmentEventsDomain.list.grid.headers.locations': 'Locations',
  'fulfillmentEventsDomain.list.grid.headers.status': 'Status',
  'fulfillmentEventsDomain.list.grid.headers.impactType': 'Impact type',
  'fulfillmentEventsDomain.list.grid.headers.startDateTime': 'Start',
  'fulfillmentEventsDomain.list.grid.headers.endDateTime': 'End',
  'fulfillmentEventsDomain.list.grid.headers.actionMenu.editEventDescription':
    'Edit event description',
  'fulfillmentEventsDomain.list.grid.headers.actionMenu.editAllLocations': 'Edit all locations',
  'fulfillmentEventsDomain.list.grid.headers.actionMenu.duplicateEvent': 'Duplicate event',
  'fulfillmentEventsDomain.list.grid.headers.actionMenu.stop': 'Stop',
  'fulfillmentEventsDomain.list.grid.createdAt': 'Created at',
  'fulfillmentEventsDomain.list.grid.createdBy': 'Created by',
  'fulfillmentEventsDomain.list.grid.updatedAt': 'Last modified at',
  'fulfillmentEventsDomain.list.grid.updatedBy': 'Updated by',
  'fulfillmentEventsDomain.list.grid.childLocationsTooltip.loading': 'Loading...',
  'fulfillmentEventsDomain.list.grid.childLocationsTooltip.cutOffMessage':
    '...{remainingLocationCount} more location(s)',

  // Parent event view page
  'fulfillmentEventsDomain.parentEvent.message.noFulfillmentEventsFound':
    'No fulfillment events found.',
  'fulfillmentEventsDomain.parentEvent.message.notAParent':
    'The provided event ID is not a parent event.',

  // Parent event view page header
  'fulfillmentEventsDomain.parentEvent.pageHeader.eventStatus.upcoming.label': 'Upcoming',
  'fulfillmentEventsDomain.parentEvent.pageHeader.eventStatus.inProgress.label': 'In Progress',
  'fulfillmentEventsDomain.parentEvent.pageHeader.eventStatus.completed.label': 'Completed',
  'fulfillmentEventsDomain.parentEvent.pageHeader.eventStatus.deleted.label': 'Deleted',
  'fulfillmentEventsDomain.parentEvent.pageHeader.actionMenu.duplicate': 'Duplicate Event',
  'fulfillmentEventsDomain.parentEvent.pageHeader.actionMenu.addLocations': 'Add Locations',
  'fulfillmentEventsDomain.parentEvent.pageHeader.editAllLocationsButton.label':
    'Edit All Locations',

  // Parent event cards
  'fulfillmentEventsDomain.parentEvent.summaryCard.header': 'Event Summary',
  'fulfillmentEventsDomain.parentEvent.summaryCard.impactType.label': 'Impact Type',
  'fulfillmentEventsDomain.parentEvent.summaryCard.dateRange.label': 'Date Range',
  'fulfillmentEventsDomain.parentEvent.summaryCard.created.label': 'Created',
  'fulfillmentEventsDomain.parentEvent.summaryCard.createdAtBy': '{createdAt} by {createdBy}',
  'fulfillmentEventsDomain.parentEvent.summaryCard.lastModified.label': 'Last Modified',
  'fulfillmentEventsDomain.parentEvent.descriptionCard.header': 'Event Description',
  'fulfillmentEventsDomain.parentEvent.descriptionCard.editButton.label': 'Edit',
  'fulfillmentEventsDomain.parentEvent.descriptionCard.eventName.label': 'Event Name',
  'fulfillmentEventsDomain.parentEvent.descriptionCard.tags.label': 'Tags',
  'fulfillmentEventsDomain.parentEvent.descriptionCard.issueLink.label': 'Issue Link',

  //Child events grid
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.actionMenu.edit': 'Edit',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.actionMenu.duplicate': 'Duplicate',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.actionMenu.stop': 'Stop',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.delivery': 'Delivery Multiple',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.capacity': 'Negative Capacity',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.cause': 'Cause',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.yes': 'Yes',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.no': 'No',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.NA': 'N/A',

  //Child events grid closure data columns
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.regionCount': 'Regions ({eventCount})',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.zoneCount': 'Zones ({eventCount})',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.postalCodeCount':
    'Postal/Zip Codes ({eventCount})',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.warehouseLocationCount':
    'Warehouse Locations ({eventCount})',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.shiftsCanceled': 'Shifts Canceled',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.ordersRescheduled':
    'Delivery Orders Rescheduled',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.inactiveDeliveryOptions':
    'Inactive Delivery Options',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.batchStaged': 'Batch Staged',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.zeroOutStaffing':
    'Zero Out Staffing',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.warehouseLocationsDisabled':
    'Warehouse Locations Disabled',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.setWarehouseLocationsToPickupOnly':
    'Warehouse Locations set to Pickup Only',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.cancelAllPartnerShifts': 'Partner',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.disableDeliveryOptions': 'Delivery',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.disableIssOptions': 'ISS',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.disablePickupOptions': 'Pickup',

  //Child events grid restricted availability data columns
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.pickupRestrictions':
    'Pickup Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.scheduledWindowRestrictions':
    'Scheduled Window Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.standardEtaRestrictions':
    'Standard ETA Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.priorityEtaRestrictions':
    'Priority ETA Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.immediateRestrictions':
    'Immediate Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.asap': 'ASAP?',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.storeCloseBuffer':
    'Store close buffer',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.batchingRestrictions':
    'Batching Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.headers.scheduledDeliveryRestrictions':
    'Scheduled Delivery Restrictions',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.asap': 'ASAP',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.pickup': 'Pickup',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.pickupEta': 'Pickup ETA',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.limitedAvailability':
    'Disable all scheduled windows',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.scheduledAvailability1hr':
    'Disable 1hr scheduled window',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.limitedAvailability2hr':
    'Disable 2hr scheduled window',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.limitedAvailability3hr':
    'Disable 3hr scheduled window',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.limitedAvailability4hr':
    'Disable 4hr scheduled window',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.limitedAvailability5hr':
    'Disable 5hr scheduled window',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.twoHoursImmediate':
    'Two hours immediate',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.fiveHoursImmediate':
    'Five hours immediate',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.standardEta': 'Standard ETA',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.priorityEta': 'Priority ETA',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.standardEtaByEndAt':
    'Standard ETA (Block by ETA end time)',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.priorityEtaByEndAt':
    'Priority ETA (Block by ETA end time)',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.unspecifiedRestrictedServiceOptionType':
    'Unspecified restricted service option type',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.batchingForDelivery': 'Delivery',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.batchingForInflightOrders':
    'Inflight Orders',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.batchingForPickup': 'Pickup',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.retailerLocationAvailabilityForDeliveryAndPickup':
    'Restrict Delivery and Pickup Availability',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.priorityEtaPickup':
    'Priority ETA Pickup',
  'fulfillmentEventsDomain.parentEvent.childEventsGrid.labels.threeHourScheduledDelivery':
    'Three hour scheduled delivery',

  // Form action buttons
  'fulfillmentEventsDomain.form.actionButtons.cancelButton.label': 'Cancel',
  'fulfillmentEventsDomain.form.actionButtons.submitButton.label': 'Save',

  // Create form
  'fulfillmentEventsDomain.createForm.impactType0': 'Fulfillment',
  'fulfillmentEventsDomain.createForm.impactType0SecondaryText':
    'Impact to the speed at which we can fulfill orders',
  'fulfillmentEventsDomain.createForm.impactType1': 'Closure',
  'fulfillmentEventsDomain.createForm.impactType1SecondaryText':
    'Disable fulfillment operations at selected stores',
  'fulfillmentEventsDomain.createForm.impactType2': 'Restricted Availability',
  'fulfillmentEventsDomain.createForm.impactType2SecondaryText': 'Turn on and off service options',
  'fulfillmentEventsDomain.createForm.storeInterventionMessage':
    'The form has been pre-filled based on a Store Intervention request.',
  'fulfillmentEventsDomain.createForm.storeInterventionMessage.opsEscalationRequestId': `[ID: {opsEscalationRequestId}]`,
  'fulfillmentEventsDomain.createForm.storeInterventionMessage.openInNewTab': 'Open in new tab',

  // Create form description section
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.title': 'Description',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.nameField.label': 'Name',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.nameField.placeholder':
    'Enter name',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.nameField.errors.notPresent':
    'Name is required',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.tagsField.label':
    'Tags - Optional',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.tagsField.newTagLabel':
    'Create new tag',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.tagsField.addTagsToName.label':
    'Add tags to event name',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.tagsField.newTagSubmit':
    'Add tag',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.tagsField.newTagCancel': 'Cancel',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.issueLinkField.label':
    'Issue link - Optional',
  'fulfillmentEventsDomain.createNewEvent.form.descriptionSection.issueLinkField.placeholder':
    'https://',

  // Create form location type section
  'fulfillmentEventsDomain.createNewEvent.form.locationTypeSection.title': 'Location',
  'fulfillmentEventsDomain.createNewEvent.form.locationTypeSection.locationTypeField.label':
    'Location type',
  'fulfillmentEventsDomain.createNewEvent.form.locationTypeSection.locationTypeField.placeholder':
    'Select...',
  'fulfillmentEventsDomain.createNewEvent.form.locationTypeSection.locationTypeField.errors.notPresent':
    'Location type is required',
  'fulfillmentEventsDomain.createNewEvent.form.locationTypeSection.locationIdsField.errors.notPresent':
    'You must select at least one location',
  'fulfillmentEventsDomain.createNewEvent.form.locationTypeSection.warehouseIdsField.errors.notPresent':
    'You must select at least one warehouse',

  // Warehouse Zone Manual Selector
  'fulfillmentEventsDomain.form.locationTypeSection.warehouseZoneManualSelector.typeZoneIds':
    'Enter comma separated zone IDs',
  'fulfillmentEventsDomain.form.locationTypeSection.warehouseZoneManualSelector.typeWarehouseIds':
    'Enter comma separated warehouse IDs',
  'fulfillmentEventsDomain.form.locationTypeSection.warehouseZoneManualSelector.badInput':
    'Only numbers, commas and whitespace are allowed',

  // Create form schedule section
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.title': 'Schedule',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.startDate.label': 'Start date',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.startDate.errors.notPresent':
    'Start date is required',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.startTime.label': 'Start time',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.endDate.label': 'End date',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.endDate.errors.notPresent':
    'End date is required',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.endDate.errors.afterStartDate':
    'End date must be after or on start date',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.endTime.label': 'End time',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.duration.label': 'Duration',
  'fulfillmentEventsDomain.createNewEvent.form.scheduleSection.duration.longDurationWarning':
    'You are creating a Fulfillment Event longer than 30 days. Please double check the dates.',

  // Create form impact section
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.title': 'Impact',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.severityLevel.label':
    'Impact severity',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.severityLevel.placeholder':
    'Choose impact severity',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.severityLevel.errors.notPresent':
    'Impact severity is required',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.impactCause.label': 'Event type',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.impactCause.placeholder':
    'Choose event type',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.impactCause.errors.notPresent':
    'Event type is required',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.negativeCapacity.label':
    'Negative capacity',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.negativeCapacity.placeholder':
    'Choose negative capacity',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.negativeCapacity.errors.notPresent':
    'Negative capacity is required',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryMultiple.label':
    'Delivery multiple',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryMultiple.placeholder':
    'Choose delivery multiple',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryMultiple.errors.notPresent':
    'Delivery multiple is required',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.negativeCapacityAndDeliveryMultiple.errors.notPresent':
    'Must provide a Negative Capacity, a Delivery Multiple, or both',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.disabledFields.placeholder':
    'Not applicable for selected impact severity',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.cancelShiftsByType':
    'Cancel shifts by type',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.cancelAllPartnerShifts.label':
    'Cancel all partner shifts',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.rescheduleOrders': 'Reschedule orders',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.skipDeliveriesReschedulingLabel.label':
    'Do not reschedule delivery orders',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.batchStagedBatches.label':
    'Batch staged batches',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.keepWarehouseLocationsEnabled.label':
    'Keep warehouse locations enabled',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.setWarehouseLocationsToPickupOnly.label':
    'Set warehouse locations to pickup only',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryOptions': 'Delivery options',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.disableDeliveryOptions.label':
    'Turn off delivery options',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.disablePickupOptions.label':
    'Turn off all pickup options',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.disableIssOptions.label':
    'Turn off FS pickup options',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.addDeliveryBlock.label':
    'Add delivery block',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.staffing': 'Staffing',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.zeroOutStaffing.label':
    'Zero out staffing',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions':
    'Delivery type restrictions',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.ASAP.label':
    'ASAP',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.pickup.label':
    'Pickup',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.pickupEta.label':
    'Pickup ETA',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.limitedAvailability.label':
    'Disable all scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.scheduledAvailability1hr.label':
    'Disable 1hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.limitedAvailability2hr.label':
    'Disable 2hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.limitedAvailability3hr.label':
    'Disable 3hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.limitedAvailability4hr.label':
    'Disable 4hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.limitedAvailability5hr.label':
    'Disable 5hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.twoHoursImmediate.label':
    'Two hours immediate',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.fiveHoursImmediate.label':
    'Five hours immediate',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.standardEta.label':
    'Standard ETA',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.priorityEta.label':
    'Priority ETA',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.standardEtaByEndAt.label':
    "Standard ETA - block by ETA's end time",
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.priorityEtaByEndAt.label':
    "Priority ETA - block by ETA's end time",
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.priorityEtaPickup.label':
    'Priority ETA Pickup',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryTypeRestrictions.threeHourScheduledDelivery.label':
    'Three hour scheduled delivery',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.storeCloseBuffer.label':
    'Store close buffer',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.batchingRestrictions':
    'Batching Restrictions',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.pickupRestrictions':
    'Pickup Restrictions',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictBatchingForDelivery.label':
    'Disable batching for delivery orders',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictBatchingForInflightOrders.label':
    'Disable batching for inflight orders',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictBatchingForPickup.label':
    'Disable batching for pickup orders',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.retailerLocationAvailabilityForDeliveryAndPickup.label':
    'Mark warehouse location unavailable for delivery and pickup',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.retailerLocationAvailabilityForDeliveryAndPickup.warning':
    'All Delivery and Pickup options will be disabled.',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictedAvailability.noLocationSelectedLabel':
    'Please select a location type in order to see possible options.',

  // Create form footer section
  'fulfillmentEventsDomain.createNewEvent.form.footerSection.backButton.label': 'Back',
  'fulfillmentEventsDomain.createNewEvent.form.footerSection.cancelButton.label': 'Cancel',
  'fulfillmentEventsDomain.createNewEvent.form.footerSection.submitButton.label': 'Create event',
  'fulfillmentEventsDomain.createNewEvent.form.footerSection.updateButton.label': 'Update event',

  // Create form preview modal
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.header': 'Preview event',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.impactType.label': 'Impact Type',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.impactType.fulfillment': 'Fulfillment',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.impactType.restrictedAvailability':
    'Restricted Availability',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.impactType.closure': 'Closure',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.name.label': 'Name',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.tags.label': 'Tags',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.issueLink.label': 'Issue link',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.locationType.label': 'Location type',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.locations.label': 'Locations',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.locationsNumberSelected.label':
    'locations selected',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.start.label': 'Start',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.end.label': 'End',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.duration.label': 'Duration',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.impactLevel.label': 'Impact level',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.impactCause.label': 'Event type',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.capacityReduction.label':
    'Capacity reduction',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.deliveryMultiple.label':
    'Delivery multiple',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.closureOptions.label':
    'Closure options',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.closureOptions.empty.label':
    'No closure options selected - default behavior will apply',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.create.fulfillment.event.button':
    'Create Fulfillment Event',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.create.closure.event.button':
    'Create Closure Event',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.create.restrictedAvailability.event.button':
    'Create Restricted Availability Event',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.create.event.error':
    'Something went wrong when creating the event. Please try and refresh the main table in a few minutes to confirm if the event was created. If this error persists, contact the eng team.',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.update.fulfillment.event.button':
    'Update Fulfillment Event(s)',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.update.closure.event.button':
    'Update Closure Event(s)',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.update.restrictedAvailability.event.button':
    'Update Restricted Availability Event(s)',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.update.event.error':
    'An error occurred while update the event(s). Please try again later.',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.cancel.button': 'Cancel',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.pickupRestrictions':
    'Pickup Restrictions',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.batchingRestrictions':
    'Batching Restrictions',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.batchingRestrictions.empty':
    'No batching restrictions selected',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.restrictBatchingForDelivery.label':
    'Disable batching for delivery orders',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.restrictBatchingForInflightOrders.label':
    'Disable batching for inflight orders',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.restrictBatchingForPickup.label':
    'Disable batching for pickup orders',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.confirmScopeAndDetailsMessage.fulfillmentEvent':
    'This event will impact {locationCount} location(s) of type {locationType} and reduce their capacity by {negativeCapacity} and increase the delivery multiple by {deliveryMultiple}.',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.confirmScopeAndDetailsMessage.closureEvent':
    'This event will impact {locationCount} location(s) of type {locationType}. All retailers in these locations will be affected immediately.',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.confirmScopeAndDetailsMessage.restrictedAvailabilityEvent':
    'This event will impact {locationCount} location(s) of type {locationType}. All retailers in these locations will be affected immediately.',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.confirmScopeAndDetailsPrompt':
    'Please confirm that the above scope and details are correct before creating this event.',
  'fulfillmentEventsDomain.createNewEvent.form.previewModal.confirmScopeAndDetailsCheckbox.label':
    'The scope and details above are correct',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryRestrictions.label':
    'Delivery restrictions',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.deliveryRestrictions.empty.label':
    'No delivery restrictions selected',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.storeCloseBuffer.empty.label':
    'No store close buffer',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeAsap.label': 'ASAP',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypePickup.label': 'Pickup',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypePickupEta.label':
    'Pickup ETA',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeLimitedAvailability.label':
    'Disable all scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeLimitedAvailabilityOneHour.label':
    'Disable 1hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeLimitedAvailabilityTwoHour.label':
    'Disable 2hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeLimitedAvailabilityThreeHour.label':
    'Disable 3hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeLimitedAvailabilityFourHour.label':
    'Disable 4hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeLimitedAvailabilityFiveHour.label':
    'Disable 5hr scheduled window',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeTwoHour.label':
    'Two hours immediate',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeFiveHour.label':
    'Five hours immediate',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeStandardEta.label':
    'Standard ETA',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypePriorityEta.label':
    'Priority ETA',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeStandardEtaByEndAt.label':
    "Standard ETA - block by ETA's end time",
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypePriorityEtaByEndAt.label':
    "Priority ETA - block by ETA's end time",
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypePriorityEtaPickup.label':
    'Priority ETA Pickup',
  'fulfillmentEventsDomain.createNewEvent.form.impactSection.restrictionTypeThreeHourScheduledDelivery.label':
    'Three hour scheduled delivery',

  // Create event success notification
  'fulfillmentEventsDomain.createEvent.successNotification': 'created',
  'fulfillmentEventsDomain.createEvent.successNotificationAsync':
    'is being created async. You will receive an email update about its status',

  // Quick create store intervention event modal
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModal.title':
    'Quick Create Store Intervention',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModal.cancel.label': 'Cancel',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModal.editDetails.label':
    'Edit details',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModal.submit.label': 'Submit',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModal.validationErrorMessage':
    'At least one of the selected requests cannot be used to quick create an intervention. Upon submit, only the valid requests will be created.',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModalItem.successMessage':
    'Store intervention created successfully.',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModalItem.errorMessage':
    'An error occurred while creating the intervention. Click "Submit" to try again or contact the eng team if the error persists.',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModalItem.wrongStatusMessage':
    'Intervention cannot be created as the request does not have "New" status',
  'fulfillmentEventsDomain.quickCreateStoreInterventionEventModalItem.validationErrorMessage':
    'Intervention cannot be quick created.',

  // Edit fulfillment event description
  'fulfillmentEventsDomain.editEventDescription.title': 'Edit Event Description',
  'fulfillmentEventsDomain.editEventDescription.form.title': 'Edit Event Description',
  'fulfillmentEventsDomain.editEventDescription.notification.eventUpdated':
    '"{newEventName}" updated.',
  'fulfillmentEventsDomain.editEventDescription.errors.noFulfillmentEventFound':
    'No fulfillment event found.',
  'fulfillmentEventsDomain.editEventDescription.errors.errorDuringRequest':
    'An error occurred during the request.',

  // Cancel and Stop event modal
  'fulfillmentEventsDomain.cancelEvent.errorMessage.noEventFound': 'No event found.',
  'fulfillmentEventsDomain.cancelEvent.errorMessage.invalidEventStatus':
    'Cannot cancel or stop "{eventName}" as it is neither in progress nor upcoming.',

  // Cancel event modal labels/messages
  'fulfillmentEventsDomain.cancelEvent.title': 'Cancel Event',
  'fulfillmentEventsDomain.cancelEvent.cancelEventButton.label': 'Cancel Event',
  'fulfillmentEventsDomain.cancelEvent.warningMessage': 'This action cannot be undone.',

  'fulfillmentEventsDomain.cancelEvent.successMessage': '"{eventName}" was successfully canceled.',
  'fulfillmentEventsDomain.cancelEvent.errorMessage':
    'An error occurred while canceling "{eventName}".',
  'fulfillmentEventsDomain.cancelEvent.previewMessage':
    'This will cancel the upcoming event "{eventName}" for {childEventCount} child event(s).',

  'fulfillmentEventsDomain.cancelEvent.child.zone.successMessage':
    '"{eventName}" for the zone "{locationName}" was successfully canceled.',
  'fulfillmentEventsDomain.cancelEvent.child.zone.errorMessage':
    'An error occurred while canceling "{eventName}" for the zone "{locationName}".',
  'fulfillmentEventsDomain.cancelEvent.child.zone.previewMessage':
    'This will cancel the event "{eventName}" for the zone "{locationName}".',

  'fulfillmentEventsDomain.cancelEvent.child.postalCode.successMessage':
    '"{eventName}" for the Postal/Zip Code "{locationName}" was successfully canceled.',
  'fulfillmentEventsDomain.cancelEvent.child.postalCode.errorMessage':
    'An error occurred while canceling "{eventName}" for the Postal/Zip Code "{locationName}".',
  'fulfillmentEventsDomain.cancelEvent.child.postalCode.previewMessage':
    'This will cancel the event "{eventName}" for the Postal/Zip Code "{locationName}".',

  'fulfillmentEventsDomain.cancelEvent.parent.warningMessage':
    'This is a parent event. Stopping it will also stop {childEventCount} associated location(s).',

  //Stop event modal labels/messages
  'fulfillmentEventsDomain.stopEvent.title': 'Stop Event',
  'fulfillmentEventsDomain.stopEvent.stopEventButton.label': 'Stop Event',
  'fulfillmentEventsDomain.stopEvent.warningMessage': 'This action cannot be undone.',

  'fulfillmentEventsDomain.stopEvent.successMessage': '"{eventName}" was successfully stopped.',
  'fulfillmentEventsDomain.stopEvent.errorMessage':
    'An error occurred while stopping "{eventName}".',
  'fulfillmentEventsDomain.stopEvent.previewMessage':
    'This will stop the event "{eventName}" for {childEventCount} child event(s).',

  'fulfillmentEventsDomain.stopEvent.child.zone.successMessage':
    '"{eventName}" for the zone "{locationName}" was successfully stopped.',
  'fulfillmentEventsDomain.stopEvent.child.zone.errorMessage':
    'An error occurred while stopping "{eventName}" for the zone "{locationName}".',
  'fulfillmentEventsDomain.stopEvent.child.zone.previewMessage':
    'This will stop the event "{eventName}" for the zone "{locationName}".',

  'fulfillmentEventsDomain.stopEvent.child.postalCode.successMessage':
    '"{eventName}" for the Postal/Zip Code "{locationName}" was successfully stopped.',
  'fulfillmentEventsDomain.stopEvent.child.postalCode.errorMessage':
    'An error occurred while stopping "{eventName}" for the Postal/Zip Code "{locationName}".',
  'fulfillmentEventsDomain.stopEvent.child.postalCode.previewMessage':
    'This will stop the event "{eventName}" for the Postal/Zip Code "{locationName}".',

  'fulfillmentEventsDomain.stopEvent.parent.warningMessage':
    'This is a parent event. Stopping it will also stop {childEventCount} associated location(s).',

  // Bulk cancel events modal labels/messages
  'fulfillmentEventsDomain.bulkCancelEvents.openModalButtonLabel': 'Stop / Cancel',
  'fulfillmentEventsDomain.bulkCancelEventsModal.title': 'Stop or Cancel Events',
  'fulfillmentEventsDomain.bulkCancelEventsModal.confirmButton': 'Stop Events',
  'fulfillmentEventsDomain.bulkCancelEventsModal.cancelButton': 'Cancel',
  'fulfillmentEventsDomain.bulkCancelEventsModal.cancelableEventsTitle':
    'This will stop or cancel the following events:',
  'fulfillmentEventsDomain.bulkCancelEventsModal.nonCancelableEventsTitle':
    'The following completed or deleted events cannot be stopped or canceled:',
  'fulfillmentEventsDomain.bulkCancelEventsModal.child.cancelableEventsTitle':
    'This will stop or cancel the event for the following locations:',
  'fulfillmentEventsDomain.bulkCancelEventsModal.child.nonCancelableEventsTitle':
    'The event had already been canceled or deleted for the following locations:',
  'fulfillmentEventsDomain.bulkCancelEventsModal.successMessage':
    '{numEvents} event(s) was successfully stopped or canceled.',
  'fulfillmentEventsDomain.bulkCancelEventsModal.errorMessage':
    'An error occurred while stopping or canceling events.',
  'fulfillmentEventsDomain.bulkCancelEventsModal.parentEventWarning':
    'The selection includes one or more parent events. Stopping or canceling them will also stop/cancel their associated child location(s).',
  'fulfillmentEventsDomain.bulkCancelEventsSummaries.nameColumnLabel': 'Name',
  'fulfillmentEventsDomain.bulkCancelEventsSummaries.numChildrenColumnLabel': 'Number of children',
  'fulfillmentEventsDomain.bulkCancelEventsSummaries.summaryItem':
    '"{eventName}" with {childEventCount} child event(s).',
  'fulfillmentEventsDomain.bulkCancelEventsSummaries.child.summaryItem': '"{eventName}"',

  // Warehouse Zone loader
  'fulfillmentEventsDomain.warehouseZoneLoader.loadingWarehouses': 'Loading affected warehouses...',
  'fulfillmentEventsDomain.warehouseZoneLoader.loadingZones': 'Loading affected zones...',
  'fulfillmentEventsDomain.warehouseZoneLoader.affectedWarehouses':
    'warehouse(s) will be affected as part of these zone(s)',
  'fulfillmentEventsDomain.warehouseZoneLoader.affectedZones':
    'zone(s) will be affected as part of these warehouse(s)',
  'fulfillmentEventsDomain.warehouseZoneLoader.errorLoadingAffectedWarehouses':
    'Something went wrong when loading the affected warehouses',
  'fulfillmentEventsDomain.warehouseZoneLoader.errorLoadingAffectedZones':
    'Something went wrong when loading the affected zones',
  'fulfillmentEventsDomain.warehouseZoneLoader.clickToSeeAffectedWarehouses':
    'Click to see affected warehouse(s)',
  'fulfillmentEventsDomain.warehouseZoneLoader.clickToSeeAffectedZones':
    'Click to see affected zone(s)',
  'fulfillmentEventsDomain.warehouseZoneLoader.warehousesDetails': 'Warehouses details',
  'fulfillmentEventsDomain.warehouseZoneLoader.zonesDetails': 'Zones details',
  'fulfillmentEventsDomain.warehouseZoneLoader.closeButtonLabel': 'Close',
  'fulfillmentEventsDomain.warehouseZoneLoader.warehousesAffected': 'Affected warehouses',
  'fulfillmentEventsDomain.warehouseZoneLoader.zonesAffected': 'Affected zones',

  // Add locations
  'fulfillmentEventsDomain.addLocationsToParentEvent.title': 'Add locations',
  'fulfillmentEventsDomain.addLocationsToParentEvent.errorLoadingLocations':
    'Something went wrong when loading locations',
  'fulfillmentEventsDomain.addLocationsToParentEvent.confirmAddLocations':
    'Confirm adding locations',
  'fulfillmentEventsDomain.addLocationsToParentEvent.confirmAddLocationsMessage':
    'Are you sure you want to add the following additional {locationType}s to the event?',
  'fulfillmentEventsDomain.addLocationsToParentEvent.successMessage':
    'Successfully added locations to event',
  'fulfillmentEventsDomain.addLocationsToParentEvent.errorMessage':
    'An error occurred while adding locations to the event',

  // Copy to clipboard button
  'fulfillmentEventsDomain.copyToClipboardButton.tooltip.copy': 'Copy',
  'fulfillmentEventsDomain.copyToClipboardButton.tooltip.copyLink': 'Copy Link',
  'fulfillmentEventsDomain.copyToClipboardButton.tooltip.copied': 'Copied!',
  'fulfillmentEventsDomain.copyToClipboardButton.tooltip.error': 'Failed to copy',

  // Locations Grid
  'fulfillmentEventsDomain.locationsGrid.title': 'Locations',
  'fulfillmentEventsDomain.locationsGrid.loading': 'Loading...',
  'fulfillmentEventsDomain.locationsGrid.noResults': 'No locations found',
  'fulfillmentEventsDomain.locationsGrid.headers.id': 'ID',
  'fulfillmentEventsDomain.locationsGrid.headers.name': 'Name',
} as const

export default EN_US
