import React, { type FunctionComponent } from 'react'
import { Icon } from 'ic-snacks'
import { dashSpacing } from '../../../../foundation/spacing'
import {
  type DashTableSortingDirection,
  DashTableSortingDirections,
} from '../../../utils/dashTable.types'

type Props = {
  direction?: DashTableSortingDirection
}

const styles = {
  position: 'absolute',
  right: dashSpacing.XSMALL,
} as const

export const DashTableHeaderIcon: FunctionComponent<React.PropsWithChildren<Props>> = ({
  direction,
}) => {
  if (!direction) return null

  return (
    <span style={styles}>
      {direction === DashTableSortingDirections.DESC ? (
        <Icon name="arrowDownSmall" />
      ) : (
        <Icon name="arrowUpSmall" />
      )}
    </span>
  )
}
