import React, { type FunctionComponent } from 'react'
import { type TestableProps } from '../../common/utils/testing/testing.types'
import { Flex } from '../flex/Flex'
import { DashText } from '../dash-text/DashText'

export interface DashErrorBoxProps extends TestableProps {
  errorMessage: string
}

export const DashErrorBox: FunctionComponent<React.PropsWithChildren<DashErrorBoxProps>> = ({
  errorMessage,
  testId,
}) => (
  <Flex fillWidth fillHeight testId={testId} align="center" justify="center">
    <DashText testId={testId} color="danger">
      {errorMessage}
    </DashText>
  </Flex>
)
