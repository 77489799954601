import React, { type ReactElement, useMemo } from 'react'
import {
  type DashTableDatum,
  type DashTableFooterProps,
  type DashTablePageSize,
} from '../../../utils/dashTable.types'
import { DashSimpleSelect } from '../../../../dash-simple-select/DashSimpleSelect'
import { DASH_TABLE_PAGE_SIZE_OPTIONS } from '../../../utils/dashTable.constants'
import { DashText } from '../../../../dash-text/DashText'
import { Flex, type FlexProps } from '../../../../flex/Flex'
import { Box } from '../../../../box/Box'
import { dashPixels } from '../../../../foundation/spacing-units/spacingUnits.types'

const flexProps: FlexProps = {
  wrap: 'nowrap',
  align: 'center',
  justify: 'flex-start',
  fillWidth: true,
  fillHeight: true,
  padding: ['none', 'small'],
}

interface Props<TData extends DashTableDatum> extends DashTableFooterProps<TData> {}

export function DashTableFooterPageSizePicker<TData extends DashTableDatum>({
  loading,
  error,
  pageSizeText,
  pageSize,
  setPageSize,
  hidePageSizeSelector,
}: Props<TData>): ReactElement {
  const textContent = useMemo(() => {
    if (typeof pageSizeText === 'function') {
      return pageSizeText({ pageSize })
    }

    return pageSizeText
  }, [pageSize, pageSizeText])

  const styledTextContent = textContent && (
    <Box paddingRight={'xsmall'}>
      <DashText size="small" elementType="div" overrideStyle={{ whiteSpace: 'nowrap' }}>
        {textContent}
      </DashText>
    </Box>
  )

  if (hidePageSizeSelector || error) {
    return <Flex {...flexProps} />
  }

  return (
    <Flex {...flexProps} disabled={loading}>
      {styledTextContent}
      <DashSimpleSelect<DashTablePageSize>
        width={dashPixels.PX60}
        isSearchable={false}
        options={DASH_TABLE_PAGE_SIZE_OPTIONS}
        value={pageSize}
        onChange={setPageSize}
        menuPlacement="auto"
        inputId="dash-table-footer-size-picker-select"
      />
    </Flex>
  )
}
