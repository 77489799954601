import styled from '@emotion/styled'
import { fontSize } from '@retailer-platform/shared-components'

export const TextFieldStep = styled.div({
  fontSize: fontSize.X12,
  fontWeight: 600,
  marginTop: '4px',
  lineHeight: '18px',
  color: '#72767E',
})

export const TextFieldQuestion = styled.div({
  fontSize: fontSize.X15,
  fontWeight: 600,
  lineHeight: '22px',
  marginTop: '8px',
  marginBottom: '4px',
})
