import {
  useCurrentRetailerScope,
  useCurrentStoreConfigScope,
} from '../utils/context/CurrentScopeProvider'
import { useGetRetailerCollectionsQuery } from './index'

export interface RetailerCollectionSearchArgs {
  searchTerm?: string
  skip?: boolean
  collectionTypes: (
    | 'retailer_collection'
    | 'department'
    | 'hub'
    | 'retailer_department'
    | 'holiday'
  )[]
  slugs?: string[]
  excludeDynamicRetailerCollections?: boolean
  excludedSourceTypes?: string[]
  retailerId?: string
  useFullNamePath?: boolean
}

export const useGetRetailerCollections = ({
  searchTerm,
  skip = false,
  collectionTypes = ['retailer_collection'],
  slugs,
  excludeDynamicRetailerCollections = false,
  excludedSourceTypes = ['flyer', 'flyer_activity'],
  retailerId: retailerIdInput,
  useFullNamePath = false,
}: RetailerCollectionSearchArgs) => {
  let { retailerId } = useCurrentRetailerScope()
  const { retailerIds } = useCurrentRetailerScope()
  retailerId = retailerIdInput || retailerId
  const { storeConfigId } = useCurrentStoreConfigScope()

  // Skip searching for retailer collections if retailerId is not set,
  // MRP builder in admin use this hook to search IC departments so we should not skip that
  const shouldSkip = skip || (!retailerId && collectionTypes.includes('retailer_collection'))

  if (retailerIds && retailerIds.length > 1) {
    // We do not support searching for cross retailers department yet
    // https://instacart.atlassian.net/browse/CXP-151526
    collectionTypes = ['retailer_collection']
  }

  const { data, error, loading, refetch } = useGetRetailerCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: shouldSkip,
    variables: {
      searchTerm,
      retailerId,
      collectionTypes,
      slugs,
      excludeDynamicRetailerCollections,
      excludedSourceTypes,
      storeConfigurationId: storeConfigId, // used for department
      useFullNamePath,
      includedRetailerIds: retailerIds, // cross retailer search
    },
  })

  return {
    searchRetailerCollections: refetch,
    apiResult: data?.collectionsServiceGetCollections?.collections,
    apiSuccess: Boolean(data),
    apiLoading: loading,
    apiError: error,
  }
}
