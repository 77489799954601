import { useMemo } from 'react'
import { useQueryParams } from 'use-query-params'
import {
  stringQueryParamEncoder,
  usePaginationQueryParams,
  makeEnumQueryParamEncoder,
} from '@retailer-platform/dashboard/utils'
import {
  type ListApprovalRequestsQueryVariables,
  useListApprovalRequestsQuery,
  type ContentSearchInput,
  type ApprovalRequestState,
} from '../../api'
import { AllApprovalRequestStates } from '../../api/common'

export const approvalRequestsQueryParamsEncoders = {
  name: stringQueryParamEncoder,
  status: makeEnumQueryParamEncoder(AllApprovalRequestStates, stringQueryParamEncoder),
  tool: stringQueryParamEncoder,
}

type SearchProps = {
  variables?: Partial<ListApprovalRequestsQueryVariables>
}

export const useApprovalRequestsSearch = (props: SearchProps = {}) => {
  const { variables } = props
  const [queryParams] = useQueryParams(approvalRequestsQueryParamsEncoders)
  const { name, status, tool } = queryParams || {}

  const newVars = useMemo(() => {
    const newVars: ListApprovalRequestsQueryVariables = {
      ...variables,
    }

    if (name) newVars.ilikeName = name
    if (tool) {
      newVars.contentSearches = [{ equalsContentType: tool } as ContentSearchInput]
    }
    if (status) {
      newVars.includesStates = [status as ApprovalRequestState]
    }

    return newVars
  }, [name, status, tool, variables])

  const { data, ...rest } = useListApprovalRequestsQuery({
    variables: newVars,
  })

  return { approvalRequests: data?.approvalsManagementListRequests, ...rest }
}

export const useApprovalRequestsSearchPagination = (props: SearchProps = {}) => {
  const { approvalRequests, ...rest } = useApprovalRequestsSearch(props)

  const [pageQueryParams, setPage, setItemsPerPage] = usePaginationQueryParams()
  const { page, itemsPerPage } = pageQueryParams
  const totalPages = Math.ceil((approvalRequests?.length || 0) / itemsPerPage)

  const slicedApprovalRequests = useMemo(
    () => approvalRequests?.slice((page - 1) * itemsPerPage, page * itemsPerPage),
    [itemsPerPage, page, approvalRequests]
  )

  return {
    approvalRequests: slicedApprovalRequests,
    ...rest,
    page,
    itemsPerPage,
    totalPages,
    setPage,
    setItemsPerPage,
  }
}
