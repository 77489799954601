import React, { useCallback, useEffect, useMemo } from 'react'
import { createContext, type Dispatch, type SetStateAction, useContext, useState } from 'react'
import { Button, Tooltip } from '@retailer-platform/shared-components'
import { SearchIcon } from '@instacart/ids-core'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { useOs } from '@mantine/hooks'
import { Spotlight, spotlight } from '@mantine/spotlight'
import { type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import _ from 'lodash'
import {
  type NavMenuHierarchyL2,
  type NavMenuHierarchy,
  type NavMenuHierarchyL1,
} from '../NavMenuHierarchy'
import useAccessControl from '../../../../legacy/components/AccessControl/useAccessControl'
import { type RouteName } from '../../../../utils/routing/routes'
import { useCreateHref, useGoToPath } from '../../../../utils/routing/navigation.hooks'
import { useNavContext } from '../../nav/utils/NavContext'
import { type AccessControlConfig as ComponentActionControlConfig } from '../../../../legacy/components/AccessControl/accessControl.utils'
import { type AccessControlConfig } from '../../../../legacy/common/accessControl/accessControl.types'
import { useAdminControls } from '../../../../utils/contexts/admin-controls/AdminControlsContext'
import { AppAdminSectionAccessControl } from '../../../../sections/admin/AdminSection.configuration'
import { trackEvent } from '../../../../utils/events/trackEvent'
import { type FeatureToggle } from '../../../../legacy/contexts/featureToggles/FeatureToggleContext'
import { useMenuBarTopContext } from './MenuBarTop'

type SpotlightAction = {
  group: string
  label: string
  onClick: () => void
  route?: string
  href?: string
}

const SearchActionsContext = createContext<{
  searchActions: SpotlightAction[]
  setSearchActions: Dispatch<SetStateAction<SpotlightAction[]>>
}>({ searchActions: [], setSearchActions: () => {} })

const useNavSearchContext = () => {
  const context = useContext(SearchActionsContext)
  if (!context) {
    throw new Error('useNavContext must be used within a NavProvider')
  }
  return context
}

export const NavSearchButton: FunctionComponent<{ navMenuHierarchy: NavMenuHierarchy }> = ({
  navMenuHierarchy,
}) => {
  const [searchActions, setSearchActions] = useState<SpotlightAction[]>([])
  const hasAccess = useAccessControl()
  const intl = useIntl()
  const os = useOs()
  const tooltipText =
    os === 'macos' || os === 'ios'
      ? intl.formatMessage({ id: 'navV2.search.button.tooltipMac' })
      : intl.formatMessage({ id: 'navV2.search.button.tooltipWindows' })

  const nothingFoundBox = (
    <div css={{ padding: 24 }}>{intl.formatMessage({ id: 'navV2.search.nothingFound' })}</div>
  )

  const openSpotlight = useCallback(() => {
    trackEvent({
      id: 'navV2.search.open',
      description: 'Open the IPP navigation search',
    })
    spotlight.open()
  }, [])

  const actions = useMemo(
    () =>
      Object.entries(_.groupBy(searchActions, 'group')).map(([group, actions]) => ({
        group,
        actions: actions
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(action => ({
            ...action,
            id: action.label,
          })),
      })),
    [searchActions]
  )

  if (!hasAccess(NavSearchAccessControlConfig)) {
    return null
  }

  const searchButton = (
    <Button compact variant="tertiary" onClick={openSpotlight}>
      <SearchIcon size={16} />
    </Button>
  )

  return (
    <>
      <SearchActionsContext.Provider value={{ searchActions, setSearchActions }}>
        <Tooltip target={searchButton} placement="bottom">
          {tooltipText}
        </Tooltip>
        <StyledSpotlight
          actions={actions}
          nothingFound={nothingFoundBox}
          highlightQuery
          searchProps={{
            placeholder: intl.formatMessage({ id: 'navV2.search.placeholder' }),
          }}
        />
        {/** Because IPP's navigation is a mess and we can define nav entry points in
         *  multiple different ways, we use the two methods below to aggregate all the nav
         *  items into the search context. We use components since we need to use hooks to
         *  resolve some of the search entry data like its i18n'd label and goto action.
         * */}
        <NavMenuHierarchyL1SearchActions navMenuHierarchy={navMenuHierarchy} />
        <NavContextSearchActions />
        <NonStandardSearchEntries />
      </SearchActionsContext.Provider>
    </>
  )
}

const NavSearchAccessControlConfig: ComponentActionControlConfig = {
  featureToggles: ['rt_ipp_nav_search' as FeatureToggle],
}

/**
 * Iterates over useNavContext navigationEntries and adds them to the search context.
 */
const NavContextSearchActions = () => {
  const { navigationEntries } = useNavContext()

  return Object.keys(navigationEntries).flatMap(navEntryPoint =>
    navigationEntries[navEntryPoint]
      .filter(navItem => navItem.name)
      .map(navItem => <NavContextSearchAction key={navEntryPoint + navItem.name} {...navItem} />)
  )
}

const NavContextSearchAction = ({
  labelId,
  route,
  href,
  accessControl,
}: {
  name: string
  labelId: string
  route?: string
  href?: string
  accessControl?: AccessControlConfig
}) => {
  useAddNavEntryToSearchActionsContext({
    group: 'navV2.search.misc',
    label: labelId,
    href,
    route,
    accessControl,
  })

  return null
}

/**
 * Iterates over the provided NavMenuHierarchyL1's children and adds them to the search context.
 */
const NavMenuHierarchyL1SearchActions = ({
  navMenuHierarchy,
}: {
  navMenuHierarchy: NavMenuHierarchy
}) =>
  navMenuHierarchy.hierarchy.map(navL1 => <SearchL1 key={navL1.name} navMenuHierarchyL1={navL1} />)

const SearchL1 = ({ navMenuHierarchyL1 }: { navMenuHierarchyL1: NavMenuHierarchyL1 }) =>
  navMenuHierarchyL1.children.map(navL2 => (
    <SearchL2 key={navL2.name} navMenuHierarchyL2={navL2} navL1={navMenuHierarchyL1} />
  ))

const SearchL2 = ({
  navMenuHierarchyL2,
  navL1,
}: {
  navMenuHierarchyL2: NavMenuHierarchyL2
  navL1: NavMenuHierarchyL1
}) => {
  if (navMenuHierarchyL2.children) {
    // This is a Level 2 nav group.
    // Iterate over the children and set the nav context.
    return (navMenuHierarchyL2.children ?? []).map(navL2Child => (
      <SearchL2 key={navL2Child.name} navMenuHierarchyL2={navL2Child} navL1={navL1} />
    ))
  } else {
    const accessControl = navMenuHierarchyL2.accessControl
    // This is a Level 2 nav item.
    // Add it to the nav context so it shows up in the search.
    // This hook call should be stable in a conditional.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAddNavEntryToSearchActionsContext({
      group: navL1.name,
      label: navMenuHierarchyL2.name,
      href: navMenuHierarchyL2.href,
      route: navMenuHierarchyL2.route,
      accessControl,
    })
    return null
  }
}

const NonStandardSearchEntries = () => {
  const searchContext = useNavSearchContext()
  const adminControlsContext = useAdminControls()
  const menuBarTopContext = useMenuBarTopContext()
  const hasAccess = useAccessControl()
  const gotoInstacartAdmin = useCreateGotoForNavEntry({ route: 'app-admin' })
  const intl = useIntl()

  useEffect(() => {
    searchContext.setSearchActions(prev => {
      const i18nGroup = intl.formatMessage({ id: 'navV2.search.misc' })
      const i18nSwitchPartner = intl.formatMessage({ id: 'navV2.userSettings.switchPartner' })

      if (prev.find(a => a.group === i18nGroup && a.label === i18nSwitchPartner)) {
        return prev
      }

      return [
        ...prev,
        {
          group: i18nGroup,
          label: i18nSwitchPartner,
          onClick: () => {
            menuBarTopContext.setShowPartnerSelector(true)
          },
        },
      ]
    })

    searchContext.setSearchActions(prev => {
      const i18nGroup = intl.formatMessage({ id: 'navV2.search.misc' })
      const i18nMyAccount = intl.formatMessage({ id: 'navV2.userSettings.manageAccount' })

      if (prev.find(a => a.group === i18nGroup && a.label === i18nMyAccount)) {
        return prev
      }

      return [
        ...prev,
        {
          group: i18nGroup,
          label: i18nMyAccount,
          onClick: () => {
            menuBarTopContext.setShowManageAccount(true)
          },
        },
      ]
    })

    searchContext.setSearchActions(prev => {
      const i18nGroup = intl.formatMessage({ id: 'navV2.search.misc' })
      const i18nAdminControls = intl.formatMessage({ id: 'app.admin.controlPanel.title' })

      if (prev.find(a => a.group === i18nGroup && a.label === i18nAdminControls)) {
        return prev
      }

      return [
        ...prev,
        {
          group: i18nGroup,
          label: i18nAdminControls,
          onClick: () => {
            adminControlsContext.openAdminControls()
          },
        },
      ]
    })

    if (hasAccess(AppAdminSectionAccessControl)) {
      searchContext.setSearchActions(prev => {
        const i18nGroup = intl.formatMessage({ id: 'navV2.search.misc' })
        const i18nAdmin = intl.formatMessage({ id: 'app.admin.nav.links.instacartAdmin' })

        if (prev.find(a => a.group === i18nGroup && a.label === i18nAdmin)) {
          return prev
        }

        return [
          ...prev,
          {
            group: i18nGroup,
            label: i18nAdmin,
            onClick: gotoInstacartAdmin,
          },
        ]
      })
    }
  }, [intl, menuBarTopContext, searchContext, adminControlsContext, hasAccess, gotoInstacartAdmin])

  return null
}

/**
 * The common hook for adding a nav entry to the search context.
 */
function useAddNavEntryToSearchActionsContext({
  group,
  label,
  href,
  route,
  accessControl,
}: {
  group: string
  label: string
  href?: string
  route?: string
  accessControl?: AccessControlConfig | ComponentActionControlConfig
}) {
  const hasAccess = useAccessControl()
  const navContext = useNavSearchContext()
  const goto = useCreateGotoForNavEntry({ href, route })
  const intl = useIntl()

  useEffect(() => {
    // If the user does not have access to the navL2, return null.
    // They can't access it, so don't show it in the search.
    if (accessControl && !hasAccess(accessControl)) {
      return null
    }

    if (!group || !label || !goto) {
      return null
    }

    const i18nGroup = intl.formatMessage({ id: group })
    const i18nLabel = intl.formatMessage({ id: label })

    navContext.setSearchActions(prev => {
      const hasEntry = prev.find(a => (href && a.href === href) || (route && a.route === route))
      if (hasEntry) {
        // Check if the nav is already in the navContext.
        // If it is, return null -- we already have it in the context.
        // Use the href and route to de-dupe search entries,
        // since that's the closest thing to a unique identifier
        return prev
      }
      return [...prev, { group: i18nGroup, label: i18nLabel, onClick: goto, href, route }]
    })
  }, [group, label, goto, href, route, accessControl, hasAccess, navContext, intl])
}

/**
 * Creates a function that navigates to the given navL2.
 * This is kind of hacky since nav entries can have a href or a route.
 * We need to try both to see if it's a path or a href.
 */
function useCreateGotoForNavEntry(navEntry: { href?: string; route?: string }) {
  let createHref: ReturnType<typeof useCreateHref> = undefined
  let gotoPath: ReturnType<typeof useGoToPath> = undefined

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    createHref = useCreateHref(navEntry.href as RouteName)
  } catch (error) {
    //do nothing
  }

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    gotoPath = useGoToPath(navEntry.route as RouteName)
  } catch (error) {
    //do nothing
  }

  const goto = useCallback(() => {
    trackEvent({
      id: 'navV2.search.action',
      description: 'Navigate to the search action',
      data: {
        href: navEntry.href,
        route: navEntry.route,
      },
    })
    if (gotoPath) {
      gotoPath()
    } else if (createHref) {
      //navigate to the href
      window.location.href = createHref
    }
  }, [createHref, gotoPath, navEntry.href, navEntry.route])

  if (!createHref && !gotoPath) {
    return undefined
  }

  return goto
}

const StyledSpotlight = styled(Spotlight)({
  '.mantine-Spotlight-actionsList': {},
  '.mantine-Spotlight-actionSection': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  '.mantine-Spotlight-actionsGroup': {
    padding: '12px 12px',
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    gap: '8px',
  },
  '.mantine-Spotlight-actionsGroup::before': {
    content: 'var(--spotlight-label)',
  },
  '.mantine-Spotlight-action': {
    padding: '8px 12px',
    display: 'block',

    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    '&[data-selected="true"]': {
      backgroundColor: '#8ac2ff',
    },
  },
})
