import { useMemo, useState } from 'react'
import sortBy from 'lodash/sortBy'
import { type DashSelectProps, type BaseOption, type DashSelectOptionsType } from './select.types'
import { isGroupedOptionsList } from './select.utils'
import { type DashSelectContextValue } from './DashSelectContext'

export const useDashSelectBehaviour = <TOption extends BaseOption>({
  error,
  errorMessage,
  loading,
  loadingMessage,
  noOptions,
  options,
  toggle,
  testId,
  sortBy: sortByValue,
}: DashSelectProps<TOption>) => {
  const [toggleActive, setToggleState] = useState(false)

  const normalizedSortByValue = useMemo(() => {
    if (!sortByValue) return undefined

    return Array.isArray(sortByValue) ? sortByValue : [sortByValue]
  }, [sortByValue])

  // Sorts and filters options
  const filteredOptions: DashSelectOptionsType<TOption> = useMemo(() => {
    // Grouped List
    if (isGroupedOptionsList(options)) {
      let nextOptions = [...options]

      // Filter groups options by toggle if necessary
      if (toggle && toggleActive) {
        nextOptions = nextOptions.filter(group =>
          toggle.filterGroup ? toggle.filterGroup(group) : true
        )
      }

      // Sort options within groups if necessary
      return nextOptions.map(group => {
        let nextGroupedOptions = [...group.options]

        if (normalizedSortByValue) {
          nextGroupedOptions = sortBy<unknown>(
            nextGroupedOptions,
            normalizedSortByValue
          ) as TOption[]
        }

        return {
          ...group,
          options: nextGroupedOptions,
        }
      })
    }

    // Single Lists
    let nextOptions = [...options]

    // Sort options if necessary
    if (normalizedSortByValue) {
      nextOptions = sortBy<unknown>(nextOptions, normalizedSortByValue) as TOption[]
    }

    return nextOptions
  }, [normalizedSortByValue, options, toggle, toggleActive])

  const dashSelectContextValue: DashSelectContextValue = useMemo(
    () => ({
      toggle: toggle
        ? {
            toggleActive,
            handleToggle: () => setToggleState(!toggleActive),
            toggleHeading: toggle.heading,
          }
        : undefined,
      noOptions,
      testId,
    }),
    [noOptions, testId, toggle, toggleActive]
  )

  const reactSelectOverrides = useMemo(() => {
    if (error) {
      return {
        placeholder: errorMessage || '',
        value: null,
        isDisabled: true,
      }
    }

    if (loading) {
      return {
        placeholder: loadingMessage || '',
        value: null,
        isLoading: true,
      }
    }

    return {}
  }, [error, errorMessage, loading, loadingMessage])

  return {
    dashSelectContextValue,
    filteredOptions,
    reactSelectOverrides,
  }
}
