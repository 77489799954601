import React, { type ReactElement, useMemo, type CSSProperties } from 'react'
import { type DashTableDatum, type DashHeaderGroup } from '../../../utils/dashTable.types'
import { dashTableRowStyles } from '../../../utils/dashTable.styles'
import { DashTableHeaderCell } from './DashTableHeaderCell'

interface Props<TData extends DashTableDatum> {
  row: DashHeaderGroup<TData>
}

export function DashTableHeaderRow<TData extends DashTableDatum>({
  row: { headers, getHeaderGroupProps },
}: Props<TData>): ReactElement {
  const renderedCells = useMemo(
    () =>
      headers.map(column => <DashTableHeaderCell key={column.id} column={column} id={column.id} />),
    [headers]
  )

  // Set a default style if none is provided
  const headerGroupProps = useMemo(
    () => ({ style: {}, ...getHeaderGroupProps() }),
    [getHeaderGroupProps]
  )

  const style: CSSProperties = {
    ...dashTableRowStyles,
    ...headerGroupProps.style,
  }

  return (
    <tr {...headerGroupProps} style={style}>
      {renderedCells}
    </tr>
  )
}
