import React, { type FunctionComponent } from 'react'
import { Text } from '@instacart/ids-customers'
import styled from '@emotion/styled'
import { spacing } from '@retailer-platform/shared-components'

export interface TitleContentPair {
  title: string
  content: string
}

export type ExtraTextContentGridProps = {
  items?: TitleContentPair[]
}

export const ExtraTextContentGrid: FunctionComponent<
  React.PropsWithChildren<ExtraTextContentGridProps>
> = ({ items }) => {
  if (!items) {
    return null
  }

  return (
    <StyledGrid>
      {items?.map((item, index) => (
        <StyledItem key={index}>
          <Text typography="bodySmall2">{item.title}</Text>
          <Text typography="bodyMedium1">{item.content}</Text>
        </StyledItem>
      ))}
    </StyledGrid>
  )
}

const StyledGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: spacing.X20,
})

const StyledItem = styled.div({})
