import { useQueryParam, StringParam } from 'use-query-params'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { type AccessControlConfig, type Route } from '@retailer-platform/dashboard/legacy'
import { useAccessControl } from '@retailer-platform/dashboard/access-control'
import { type RouteName } from '@retailer-platform/dashboard/routing'
import { useWarehouseContext } from '@retailer-platform/dashboard/utils'
import { type DomainRouteName } from '../../../routing/routes'
import {
  productsViewInternalAccessControl,
  productsViewWarehouseAccessControl,
} from '../../../access-control/viewAccess.configuration'

/**
 * Extract a subset of the route union. Only reason why this exists instead of just the strings,
 * is to have typescript yell at us if any of those stop existing in the original definition.
 */
type MappedRouteNames = keyof Pick<
  Record<RouteName, any>,
  'product' | 'productEditDetails' | 'productEditAisles' | 'productEditLocations'
>

/**
 * Routes an old route into the modern page, only when the current warehouse meets
 * the feature toggle criteria.
 *
 * This utility only knows a specific set of routes to map, any others will throw
 * an error!
 *
 * TODO: remove me please!
 *
 * @param intendedRoute
 * @param productId
 * @returns
 */
export const useProductViewRoute = (intendedRoute: MappedRouteNames, productId: string) => {
  const { warehouse } = useWarehouseContext()

  const hasAccess = useAccessControl()
  const isInternal = hasAccess(productsViewInternalAccessControl as AccessControlConfig)
  const hasPageAccessToggle = hasAccess(productsViewWarehouseAccessControl as AccessControlConfig)
  const canAccessNewProductViewPage = isInternal || hasPageAccessToggle

  const [locale] = useQueryParam('locale', StringParam)

  const routeMapper: Partial<Record<RouteName, DomainRouteName>> = {
    product: 'products-view-details',
    productEditDetails: 'products-view-details',
    productEditAisles: 'products-view-aisles',
    productEditLocations: 'products-view-store-locations',
  }

  if (!(intendedRoute in routeMapper)) {
    throw new Error(`Product route not recognized: ${intendedRoute}`)
  }

  const result = canAccessNewProductViewPage
    ? {
        name: routeMapper[intendedRoute],
        params: { productId },
      }
    : {
        name: intendedRoute,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        params: { product_id: productId },
      }

  return {
    route: result as Route,
    query: canAccessNewProductViewPage
      ? { locale: locale || warehouse.defaultLocale.localeCode }
      : undefined,
  }
}
