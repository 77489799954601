import React, { type FunctionComponent, useMemo } from 'react'
import styled from '@emotion/styled'
import { fontWeight } from '@retailer-platform/shared-components'
import { FilterPill } from './FilterPill'

export const FilterPillContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

const ClearAllButton = styled.button({
  background: 'none',
  color: '#000000',
  fontWeight: fontWeight.SEMIBOLD,
  marginTop: 5,
  textDecoration: 'underline',
})

export interface Filter {
  name: string
  onRemove: () => void
  id: string
  removeMessage: string
}

export interface ClearAllButtonInfo {
  onClearAllButtonClicked: () => void
  clearAllMessage: string
}

interface FilterPillsProps {
  filters: Filter[]
  clearAllButtonInfo?: ClearAllButtonInfo
  customPillStyles?: React.CSSProperties
}

export const RenderedFilterPill: FunctionComponent<
  React.PropsWithChildren<{
    filter: Filter
    customPillStyles?: React.CSSProperties
    customTextContainerStyles?: React.CSSProperties
  }>
> = ({ filter, customPillStyles, customTextContainerStyles }) => (
  <FilterPill
    name={filter.name}
    key={filter.id}
    id={filter.id}
    onRemove={filter.onRemove}
    removeMessage={filter.removeMessage}
    customPillStyles={customPillStyles}
    customTextContainerStyles={customTextContainerStyles}
  />
)

export const FilterPills: FunctionComponent<React.PropsWithChildren<FilterPillsProps>> = ({
  filters,
  clearAllButtonInfo,
  customPillStyles,
}) => {
  const renderedFilterPills = useMemo(
    () =>
      filters.map(filter => (
        <RenderedFilterPill key={filter.id} filter={filter} customPillStyles={customPillStyles} />
      )),
    [filters, customPillStyles]
  )
  return (
    <FilterPillContainer>
      {renderedFilterPills}
      {filters.length > 0 && (
        <ClearAllButton
          data-testid="filter-pills-clear-all-button"
          onClick={clearAllButtonInfo.onClearAllButtonClicked}
        >
          {clearAllButtonInfo.clearAllMessage}
        </ClearAllButton>
      )}
    </FilterPillContainer>
  )
}
