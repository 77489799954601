export enum FeatureToggle {
  campaignManagement = 'campaignManagement',
  campaignManagementContentPageSlots = 'rt_campaign_management_content_page_slots',
  campaignManagementPrimarySplitBanner = 'rt_campaign_management_primary_split_banner',
  campaignManagementRedemptionUrls = 'rt_redemption_urls',
  campaignManagementMultiRetailerPages = 'rt_multi_retailer_pages',
  navigateToContentPage = 'rt_navigate_to_content_page',
  navigateToAuthentication = 'rt_navigate_to_authentication',
  navigateToFlyers = 'rt_navigate_to_flyers',
  flyoutsOnCreativeManager = 'rt_flyout_on_creative_manager',
  adminCalendarPage = 'rt_campaigns_admin_calendar_enabled',
  adminCreativesPage = 'rt_campaigns_admin_creatives_enabled',
  universalValueCarousel = 'rt_universal_value_carousel',
  collectionSlots = 'rt_collection_slots',
  destinationRowV2 = 'rt_destination_row_v2',
  retailerForwardList = 'rt_retailer_forward_list',
  retailerForwardListImageRow = 'rt_retailer_forward_list_image_row',
  nonIncentiveCampaignsEnabled = 'rt_campaigns_mp_non_incentives_enabled',
  universalValueCarouselSlot = 'rt_uvc_slot',
  multiRetailerPageSlots = 'rt_multi_retailer_page_slots',
  multiRetailerRichText = 'rt_multi_retailer_rich_text',
  multiRetailerFaqs = 'rt_multi_retailer_faqs',
  announcementBannerCampaignsEnabled = 'rt_announcement_banner_campaigns_enabled',
  multiRetailerPageItemListImageRow = 'rt_multi_retailer_page_item_list_image_row',
  multiRetailerHeaderBannerSettings = 'rt_multi_retailer_header_banner_settings',
}
