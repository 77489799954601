export enum Metrics {
  IMPRESSIONS = 'SUM_VISIT_IMPRESSIONS',
  ADD_TO_CARTS = 'SUM_VISIT_ADD_TO_CARTS',
  CLICK_THROUGH_RATE = 'VISIT_CLICK_THROUGH_RATE',
  MAX_UPDATED_AT_DATE_TIME_UTC = 'MAX_UPDATED_AT_DATE_TIME_UTC',
  SUM_ATTRIBUTED_SALES = 'SUM_ATTRIBUTED_SALES',
  SUM_ATTRIBUTED_ERV = 'SUM_ATTRIBUTED_ERV',
  SUM_ATTRIBUTED_UNITS_SOLD = 'SUM_ATTRIBUTED_UNITS_SOLD',
  NUM_DISTINCT_CAMPAIGNS = 'NUM_DISTINCT_CAMPAIGNS',
  CLICKS = 'SUM_VISIT_CLICKS',
  USERS_ADDED_TO_CART = 'SUM_USER_ADD_TO_CARTS',
  AVG_BASKET_SPEND = 'AVG_BASKET_SPEND',
  AVG_UNIT_SPEND = 'AVG_UNIT_SPEND',
  NUM_ORDERS_WITH_ATTRIBUTED_SALES = 'NUM_ORDERS_WITH_ATTRIBUTED_SALES',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_CODE = 'WAREHOUSE_LOCATION_CODE',
  WAREHOUSE_LOCATION_REGION = 'WAREHOUSE_LOCATION_REGION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  DIM_CAMPAIGN_NAME = 'DIM_CAMPAIGN_NAME',
  CAMPAIGN = 'CAMPAIGN',
  DIM_CAMPAIGN_START_DATE = 'DIM_CAMPAIGN_START_DATE',
  DIM_CAMPAIGN_END_DATE = 'DIM_CAMPAIGN_END_DATE',
  DIM_CAMPAIGN_STATUS = 'DIM_CAMPAIGN_STATUS',
  DIM_USER_SEGMENT = 'DIM_USER_SEGMENT',
  CAMPAIGN_ENDING_SOON = 'CAMPAIGN_ENDING_SOON',
  DIM_STORE_CONFIGURATION = 'DIM_STORE_CONFIGURATION',
}
