import React, { type ReactElement, useMemo } from 'react'
import { type DashTableDatum, type DashHeaderGroup } from '../../utils/dashTable.types'
import { DashTableHeaderRow } from './components/DashTableHeaderRow'

interface Props<TData extends DashTableDatum> {
  headerGroups: DashHeaderGroup<TData>[]
}

export function DashTableHeader<TData extends DashTableDatum>({
  headerGroups,
}: Props<TData>): ReactElement {
  const renderedRows = useMemo(
    () =>
      headerGroups.map((headerGroup, index) => (
        <DashTableHeaderRow<TData> key={headerGroup.id || index} row={headerGroup} />
      )),
    [headerGroups]
  )

  return <thead>{renderedRows}</thead>
}
