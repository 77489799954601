import styled from '@emotion/styled'
import { Grid } from '../../common/grid/Grid'
import { fontSize, spacing } from '../../foundation'
import { withContainerSize } from '../../hocs/withContainerSize'

export const ExpandableGrid = withContainerSize(Grid)

export const PaginatedGridFooter = styled.div({
  marginTop: spacing.X16,
  marginBottom: spacing.X16,
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: spacing.X16,
  paddingLeft: spacing.X16,
})

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
})

export const Spacer = styled.div({
  flex: 1,
})

export const ItemsPerPageSelectContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: fontSize.X12,

  label: {
    whiteSpace: 'nowrap',
    marginRight: spacing.X12,
  },
})
