import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'

const URL_ADMIN_CASHFLOW = `${URL_APP_ADMIN}/cashflow`
const URL_ADMIN_CASHFLOW_PRICE_AUDIT_LANDING = `${URL_ADMIN_CASHFLOW}/price-audit`
const URL_ADMIN_CASHFLOW_PRICE_AUDIT_DETAILS = `${URL_ADMIN_CASHFLOW}/price-audit/:auditReviewUuid`
const URL_ADMIN_CASHFLOW_SPREAD_LANDING = `${URL_ADMIN_CASHFLOW}/spread`
const URL_ADMIN_CASHFLOW_SPREAD_DETAILS = `${URL_ADMIN_CASHFLOW}/spread/:riskIssueId`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-cashflow'>
  | DashRouteType<'app-admin-cashflow-price-audit-landing'>
  | DashRouteType<'app-admin-cashflow-price-audit-details'>
  | DashRouteType<'app-admin-cashflow-spread-landing'>
  | DashRouteType<'app-admin-cashflow-spread-details'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-cashflow': URL_ADMIN_CASHFLOW,
  'app-admin-cashflow-price-audit-landing': URL_ADMIN_CASHFLOW_PRICE_AUDIT_LANDING,
  'app-admin-cashflow-price-audit-details': URL_ADMIN_CASHFLOW_PRICE_AUDIT_DETAILS,
  'app-admin-cashflow-spread-landing': URL_ADMIN_CASHFLOW_SPREAD_LANDING,
  'app-admin-cashflow-spread-details': URL_ADMIN_CASHFLOW_SPREAD_DETAILS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
