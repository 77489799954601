import React, { useCallback } from 'react'
import { components, type OptionProps } from 'react-select'
import { Badge, Button, spacing } from '@retailer-platform/shared-components'
import { OpenInIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import { useDomainMessages } from '../../../utils/domain/intl'
import { type OptionData } from './types'

const OptionContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const LabelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: `${spacing.X8} 0`,
})

const StyledBadge = styled(Badge)({
  textAlign: 'left',
  padding: `0px ${spacing.X8}`,
  marginRight: spacing.X4,
})

interface SelectorOptionProps extends OptionProps<OptionData> {
  openCollectionPreview: (collectionSlug: string) => void
  disablePreview: boolean
}

const SelectorOption = ({
  data,
  openCollectionPreview,
  disablePreview,
  ...props
}: SelectorOptionProps) => {
  const i18n = useDomainMessages({
    icOwned: 'storefrontDomain.collections-selector.icOwned',
    retailerOwned: 'storefrontDomain.collections-selector.retailerOwned',
  })

  const optionData: OptionData = data

  const handlePreviewClick = useCallback(() => {
    openCollectionPreview(optionData.slug || '')
  }, [optionData.slug, openCollectionPreview])

  return (
    <OptionContainer>
      <components.Option {...props} data={optionData} css={{ flexGrow: 1 }}>
        <LabelContainer>
          {props.children}
          <div>
            {/* Hardcoded collection may not have slug */}
            <StyledBadge>
              {optionData?.slug?.replace('^rc-', '').replace('^n-', '') ?? ''}
            </StyledBadge>
            <StyledBadge>
              {optionData?.collectionType === 'department' ? i18n.icOwned : i18n.retailerOwned}
            </StyledBadge>
          </div>
        </LabelContainer>
      </components.Option>
      {!disablePreview && (
        <Button compact variant="transparent" onClick={handlePreviewClick}>
          <OpenInIcon size={16} />
        </Button>
      )}
    </OptionContainer>
  )
}

export default SelectorOption
