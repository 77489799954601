import { useDomainGoToPath } from './utils/domain/routing'

export const useGoToShifts = (queryParams?: Record<string, any>) =>
  useDomainGoToPath('shifts', null, 'push', queryParams)

export { ShiftTypeEnum, RecommendationTypeEnum } from './api'
export { ShiftType } from './components/types'
export {
  type RecommendationShiftType,
  RecommendedShiftsContext,
} from './components/contexts/RecommendationShiftsContext'
