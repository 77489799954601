import React, { type FunctionComponent } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { fontSize } from '../../foundation/fontSize'
import { fontWeight } from '../../foundation/fontWeight'
import { type TestID } from '../../utils/testing/types'
import { type EmotionStyles } from '../../utils/styling/types'
import { spacing } from '../../foundation/spacing'
import { type TypographySizes } from '../../foundation/types'

interface Props extends EmotionStyles, TestID {
  size: TypographySizes
  className?: string
}

const subtitleStyles = css({
  fontWeight: fontWeight.BOLD,
  marginTop: spacing.X0,
  marginBottom: spacing.X0,
  marginLeft: spacing.X8,
  marginRight: spacing.X8,
})

const sizeStyles: { [key in TypographySizes]: SerializedStyles } = {
  small: css({
    fontSize: fontSize.X12,
    lineHeight: '18px',
  }),
  medium: css({
    fontSize: fontSize.X15,
    lineHeight: '22px',
  }),
  large: css({
    fontSize: fontSize.X18,
    lineHeight: '28px',
  }),
}

export const Subtitle: FunctionComponent<React.PropsWithChildren<Props>> = ({
  size,
  children,
  styles,
  ...rest
}) => (
  <div {...rest} css={css(subtitleStyles, sizeStyles[size], styles)}>
    {children}
  </div>
)
