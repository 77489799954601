import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { spacing } from '@retailer-platform/shared-components'

export const metricStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
})

export const splitStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
})

export const bodyStyles = css({
  marginLeft: spacing.X24,
  marginRight: spacing.X24,
})

export const ChartWrapper = styled.div({
  paddingTop: spacing.X32,
})

export const ChartLoadingWrapper = styled.div({
  minHeight: 200,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
})

export const TabContainer = styled.div({
  option: {
    marginRight: '6px',
  },
})
