import styled from '@emotion/styled'
import { Badge, colors, spacing, Text, Tooltip } from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useCallback, useMemo } from 'react'
import { InformationIcon } from '@instacart/ids-core'
import { useDashMessage, useDashMessages } from '../../../intl/intl.hooks'
import { useWarehouseContext } from '../../../utils/contexts/warehouse/WarehouseContext.hooks'
import { type InstacartStoreConfiguration } from '../../../__codegen__/api'
import { useAdminControls } from '../../../utils/contexts/admin-controls/AdminControlsContext'

/**
 * Describes the intent of operations to be done specifically on any "protected" Site related data.
 * The term "protected" data means it should only be modified by the retailer that owns the Site.
 * For example, the "Marketplace" site is accessible to many retailers, but only Instacart
 * employees should be able to modify site related data. The same could be true for other Sites that
 * are shared.
 *
 * On the other hand, if your tool is NOT modifying protected Site related data and just using Site level
 * data for viewing purposes, (such as Retailer Funded Marketing tooling, Customer Service tooling, etc),
 * then your operation intent is just to "view" the site data -- not modify it.
 *
 * VIEW: Read-only operations for the site
 * MODIFY: Mutation operations for the site
 */
export type SiteOperationIntent = 'view' | 'modify'

export enum SiteSelectOption {
  ALLOW,
  SELECT_NOT_APPLICABLE,
  HIDE,
}

export interface Site extends InstacartStoreConfiguration {}

export interface SiteOption {
  site: Site
  selectOption: SiteSelectOption
}

export type SelectCallback = (site: SiteOption) => void

export interface SiteSelectProps {
  sites: SiteOption[]
  onSelect?: SelectCallback
  buildGoToSite?: (site: Site) => () => void
}

export const SiteSelect: FunctionComponent<React.PropsWithChildren<SiteSelectProps>> = (
  props: SiteSelectProps
) => {
  const { warehouse } = useWarehouseContext()
  const i18n = useDashMessages(
    {
      selectToManage: 'sites.selectToManage',
      noSites: 'sites.noSites',
      noSitesForX: 'sites.noSitesAvailableForX',
    },
    { warehouseName: warehouse.name }
  )

  const visibleSites = useMemo(
    () => props.sites?.filter(siteOption => siteOption.selectOption !== SiteSelectOption.HIDE),
    [props.sites]
  )

  return (
    <SiteSelectContainer data-testid={props['data-testid'] || 'siteSelectContainer'}>
      {!visibleSites?.length && (
        <Text size="large" data-TestId="noSitesWarning">
          {i18n.noSitesForX}
        </Text>
      )}

      {Boolean(visibleSites?.length) && (
        <>
          <Text size="large">{i18n.selectToManage}</Text>
          <SitesScrollContainer>
            {props.sites
              .sort((a, b) => parseInt(a.site.id) - parseInt(b.site.id))
              .map(siteOption => (
                <SiteItem key={siteOption.site.id} siteOption={siteOption} {...props} />
              ))}
          </SitesScrollContainer>
        </>
      )}
    </SiteSelectContainer>
  )
}

export const getDisplaySiteUrl = (site: Pick<InstacartStoreConfiguration, 'domainName'>) => {
  if (!site || !site.domainName) {
    return null
  }

  try {
    const url = new URL(site.domainName)
    return url.host + (url.pathname.length > 1 ? url.pathname : '')
  } catch (err) {
    return site.domainName
  }
}

const SiteItem = (props: {
  siteOption: SiteOption
  onSelect?: SelectCallback
  buildGoToSite?: (site: Site) => () => void
}) => {
  const site = props.siteOption.site
  const disabled = props.siteOption.selectOption === SiteSelectOption.SELECT_NOT_APPLICABLE
  const hidden = props.siteOption.selectOption === SiteSelectOption.HIDE

  const children = (
    <>
      <StyledLogoImage src={site.logoUrl || defaultLogo} />
      <div css={{ flex: 1 }}>
        <Text size="large" weight="semibold">
          {site.brandedName}
        </Text>
        <Text size="medium">{getDisplaySiteUrl(site)}</Text>
      </div>
      <ToolTipForAccessControl site={site} />
      {disabled && <NotApplicableBadge />}
    </>
  )

  const gotoSite = props.buildGoToSite?.(site)
  const onClick = useCallback(() => {
    gotoSite?.()
    props.onSelect?.(props.siteOption)
  }, [gotoSite, props])

  if (hidden) {
    return null
  } else if (disabled) {
    return (
      <SiteItemContainerDisabled data-testid={`site-item-${site.brandedName}`}>
        {children}
      </SiteItemContainerDisabled>
    )
  }

  return (
    <SiteItemContainer data-testid={`site-item-${site.brandedName}`} onClick={onClick}>
      {children}
    </SiteItemContainer>
  )
}

const NotApplicableBadge = () => {
  const i18n = useDashMessage('sites.notApplicable')
  return (
    <Badge color="grey">
      <Text size="small" weight="bold" css={{ textTransform: 'uppercase' }}>
        {i18n}
      </Text>
    </Badge>
  )
}

const defaultLogo =
  'https://www.instacart.com/assets/beetstrap/brand/2022/carrotlogo-1286c257354036d178c09e815906198eb7f012b8cdc4f6f8ec86d3e64d799a5b.png'
const grey = colors.GRAYSCALE.X20

const SiteSelectContainer = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X12,
})

const SitesScrollContainer = styled.div({
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X12,
})

const SiteItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  padding: spacing.X16,
  gap: spacing.X12,
  alignItems: 'center',
  border: `1px solid ${grey}`,
  borderRadius: spacing.X12,
  ':hover': {
    backgroundColor: grey,
    cursor: 'pointer',
  },
})

const SiteItemContainerDisabled = styled(SiteItemContainer)({
  backgroundColor: grey,
  ':hover': {
    cursor: 'unset',
  },
})

const StyledLogoImage = styled.img({
  width: 64,
  height: 64,
  background: 'white',
  borderRadius: '50%',
  objectFit: 'contain',
})

const ToolTipForAccessControl = (props: { site: Site }) => {
  const hasAccess = useHasShowAccessControls()
  const i18n = useDashMessages({
    none: 'common.none',
    storeConfig: 'sites.tooltip.storeConfig',
    visibleToPartnersQuestion: 'sites.tooltip.visibleToPartnersQuestion',
    allowedToModifyPartners: 'sites.tooltip.allowedToModifyPartners',
    internalOnlyInfo: 'sites.tooltip.internalOnlyInfo',
  })

  if (!hasAccess) {
    return null
  }

  const visibleToPartners = props.site.partnerAccessSettings?.visibleToPartnersOnIpp ?? true
  const allowedToModifyPartnerIds =
    props.site.partnerAccessSettings?.modificationRules?.allowlistPartnerIds ?? []

  const tipBody = (
    <ToolTipBodyContainer>
      <Text size="small">{i18n.internalOnlyInfo}</Text>
      <ToolTipBodyTip label={i18n.storeConfig} value={`${props.site.id} ${props.site.slug}`} />
      <ToolTipBodyTip label={i18n.visibleToPartnersQuestion} value={String(visibleToPartners)} />
      <ToolTipBodyTip
        label={i18n.allowedToModifyPartners}
        value={String(allowedToModifyPartnerIds) || i18n.none}
      />
    </ToolTipBodyContainer>
  )

  return (
    <Tooltip
      target={<InformationIcon color="systemGrayscale50" size={22} style={{ paddingLeft: 8 }} />}
      containerStyles={{ maxWidth: null }}
    >
      {tipBody}
    </Tooltip>
  )
}

const ToolTipBodyContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X8,
})

const ToolTipBodyTip = (props: { label: string; value: string }) => (
  <div>
    <Text size="small">
      {props.label}: {<Text weight="bold">{props.value}</Text>}
    </Text>
  </div>
)

const useHasShowAccessControls = () => {
  const { showNavAccessControlSettings } = useAdminControls()
  return showNavAccessControlSettings
}
