import React, { type ReactElement, useMemo } from 'react'
import { type DashTableDatum, type DashTableFooterProps } from '../../../../utils/dashTable.types'
import { DashSimpleSelect } from '../../../../../dash-simple-select/DashSimpleSelect'
import { Flex, type FlexProps } from '../../../../../flex/Flex'
import { DashText } from '../../../../../dash-text/DashText'
import { dashPixels } from '../../../../../foundation/spacing-units/spacingUnits.types'
import { DASH_SELECT_HEIGHT } from '../../../../../dash-select/utils/styles'
import { dashColors } from '../../../../../foundation/colors'
import { EN_SPACE } from '../../../../../../common/utils/ascii/characters'
import { DashTableFooterPaginatorButton } from './components/DashTableFooterPaginatorButton'

const flexProps: FlexProps = {
  wrap: 'nowrap',
  align: 'center',
  justify: 'flex-end',
  fillWidth: true,
  fillHeight: true,
  padding: ['none', 'xsmall'],
}

const paginatorProps: FlexProps = {
  wrap: 'nowrap',
  align: 'center',
  overrideStyle: {
    height: DASH_SELECT_HEIGHT,
  },
}

const paginanationTextProps: FlexProps = {
  align: 'center',
  fillHeight: true,
  overrideStyle: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: dashColors.NEUTRAL,
  },
}

interface Props<TData extends DashTableDatum> extends DashTableFooterProps<TData> {}

export function DashTableFooterPaginator<TData extends DashTableDatum>({
  loading,
  error,
  paginatorText,
  totalRecordCount,
  pageCount,
  pageIndex,
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
}: Props<TData>): ReactElement {
  const unknownPageCount = totalRecordCount === undefined
  const generatedOptionsCount = unknownPageCount ? pageIndex + 1 : pageCount

  // Calculate the total page count which we pass to the paginatorText function
  const totalPageCount = useMemo(() => {
    if (totalRecordCount === 0) return 0
    if (unknownPageCount) return undefined

    return pageCount
  }, [pageCount, totalRecordCount, unknownPageCount])

  const textContent = useMemo(() => {
    if (typeof paginatorText === 'function') {
      return paginatorText({ pageIndex, totalPageCount })
    }

    return paginatorText
  }, [pageIndex, paginatorText, totalPageCount])

  const generatedOptions = Array.from({ length: generatedOptionsCount }).map((_, index) => ({
    // Pad the start so the single-digit options is closer to the center of the dropdown
    label: (index + 1).toString().padStart(2, EN_SPACE),
    value: index,
  }))

  // If there is an unknownPageCount, we prevent the user from using the quick-select picker
  // They will need to use the pagination buttons instead
  const isDisabled = generatedOptionsCount <= 1 || unknownPageCount

  const styledTextContent = textContent && (
    <Flex padding={['none', 'xsmall', 'none', 'xsmall']} {...paginanationTextProps}>
      <DashText
        size="small"
        elementType="div"
        overrideStyle={{ whiteSpace: 'nowrap', paddingTop: '3px' }}
      >
        {textContent}
      </DashText>
    </Flex>
  )

  if (error) {
    return <Flex {...flexProps} />
  }

  return (
    <Flex {...flexProps} disabled={loading}>
      <Flex {...paginatorProps}>
        <DashTableFooterPaginatorButton
          isDisabled={!canPreviousPage}
          onClick={previousPage}
          direction="left"
        />

        <DashSimpleSelect<number>
          width={dashPixels.PX40}
          menuWidth={dashPixels.PX48}
          hideIndicator
          options={generatedOptions}
          value={pageIndex}
          onChange={gotoPage}
          isDisabled={isDisabled}
          placeholder="0"
          menuPlacement="auto"
          overrideControlStyles={{
            borderRadius: 0,
          }}
          overrideStyle={{
            border: 0,
          }}
          inputId="dash-table-footer-size-paginator-select"
        />

        {styledTextContent}

        <DashTableFooterPaginatorButton
          isDisabled={!canNextPage}
          onClick={nextPage}
          direction="right"
        />
      </Flex>
    </Flex>
  )
}
