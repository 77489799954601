import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'
import {
  type DashRouteSet as SharedDataIngestionRouteSet,
  routes as SharedDataIngestionRoutes,
} from './shared/dataIngestion'
import { type YodaRulesDashRouteSet, yodaRulesRoutes } from './shared/yodaRules'

const URL_ADMIN_APPROVALS_MANAGEMENT_LIBRARY = `${URL_APP_ADMIN}/approvals-management`
const URL_ADMIN_APPROVALS_MANAGEMENT_LIBRARY_APPROVAL_REQUESTS_SEARCH = `${URL_ADMIN_APPROVALS_MANAGEMENT_LIBRARY}/approval-requests-search`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-approvals-management-library'>
  | DashRouteType<'app-admin-approvals-management-library-approval-requests-search'>
  | SharedDataIngestionRouteSet
  | YodaRulesDashRouteSet
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-approvals-management-library': URL_ADMIN_APPROVALS_MANAGEMENT_LIBRARY,
  'app-admin-approvals-management-library-approval-requests-search':
    URL_ADMIN_APPROVALS_MANAGEMENT_LIBRARY_APPROVAL_REQUESTS_SEARCH,
  ...SharedDataIngestionRoutes,
  ...yodaRulesRoutes,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
