import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { NewSelect, spacing } from '@retailer-platform/shared-components'
import { components, type MenuListProps } from 'react-select-5'
import styled from '@emotion/styled'
import { useDomainMessages } from '../../../utils/domain/intl'
import {
  type RetailerCollectionSearchArgs,
  useGetRetailerCollections,
} from '../../../api/getRetailerCollections.hooks'
import { collectionSelectorAdvancedAccessControl } from '../../../access-control/storefrontAccess.configuration'
import { useDomainAccessControl } from '../../../utils/domain/accessControl'
import SelectorOption from './SelectorOption'
import SelectorMenuList from './SelectorMenuList'
import { useCollectionPills } from './pillFilter/useCollectionPills'
import AdvancedSearchModal from './advancedSearch/AdvancedSearchModal'
import { type CollectionType, type OptionData } from './types'
import { useSearchTerm } from './useSearchTerm'
import { getUniqueCollections } from './utils'
import { useCollectionPreviewLink } from './useCollectionPreviewLink'

export enum CollectionSelectionValue {
  CollectionId = 'collectionId',
  CollectionSlug = 'collectionSlug',
}

export interface CollectionSelectorProps extends React.ComponentProps<typeof NewSelect> {
  collectionTypes?: RetailerCollectionSearchArgs['collectionTypes']
  initialSlug?: string
  isDisabled?: boolean
  initialCollections?: OptionData[]
  additionalOptions?: { label: string; value: string }[]
  showTags?: boolean
  excludeDynamicRetailerCollections?: boolean
  selectionValueType?: CollectionSelectionValue
  setCollectionId?: (value: string) => void
  retailerId?: string
  advancedSearch?: boolean
  disablePreview?: boolean
}

const Container = styled.div({
  '> *': {
    lineHeight: spacing.X24,
  },
})

const CollectionSelector = ({
  initialSlug = '',
  initialCollections = [],
  isDisabled = false,
  additionalOptions,
  showTags = true,
  excludeDynamicRetailerCollections,
  collectionTypes = ['retailer_collection'],
  selectionValueType = CollectionSelectionValue.CollectionSlug,
  retailerId,
  advancedSearch = true,
  disablePreview = false,
  ...props
}: CollectionSelectorProps) => {
  const i18n = useDomainMessages({
    placeholder: 'storefrontDomain.collections-selector.placeholder',
    noOptionsMessage: 'storefrontDomain.collections-selector.advancedSearch.empty',
    typeToSearchMessage: 'storefrontDomain.collections-selector.advancedSearch.typeToSearch',
  })
  const hasAccess = useDomainAccessControl()
  const advancedView = !!advancedSearch && hasAccess(collectionSelectorAdvancedAccessControl)

  const [selectedOptionsCache, setSelectedOptionsCache] = useState<OptionData[]>(initialCollections)
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false)

  const { pillOptions, selectedPills, togglePill, getCollectionTypes } = useCollectionPills({
    collectionTypes: collectionTypes as CollectionType[],
    enablePills: advancedView,
  })

  const {
    slugs,
    searchTerm,
    setSearch,
    getDebouncedSlugs,
    getDebouncedSearchTerm,
    shouldPerformSearch,
    setSlugs,
  } = useSearchTerm()

  const { openCollectionPreview } = useCollectionPreviewLink()

  useEffect(() => {
    setSlugs([initialSlug ?? ''])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { apiResult, apiLoading } = useGetRetailerCollections({
    ...(retailerId && { retailerId }),
    searchTerm: getDebouncedSearchTerm(),
    skip: !shouldPerformSearch && !slugs?.length,
    collectionTypes: getCollectionTypes(),
    slugs: getDebouncedSlugs(),
    excludeDynamicRetailerCollections,
  })

  const toggleAdvancedSearch = useCallback(() => setIsAdvancedSearchOpen(prev => !prev), [])

  const handleAdvancedSearchChange = useCallback(
    (selectedOptions?: OptionData[]) => {
      // Cache for display
      let optionsToCache: OptionData[] = []
      if (Array.isArray(selectedOptions)) {
        optionsToCache = selectedOptions.filter(o => o != null)
      } else if (selectedOptions != null) {
        optionsToCache = [selectedOptions]
      }

      setSelectedOptionsCache(optionsToCache)

      if (selectedOptions?.length) {
        if (props.isMulti) {
          props.onChange?.(
            (selectedOptions as OptionData[]).map(o => o.value),
            selectedOptions
          )
        } else {
          const selectedOption = selectedOptions[0]
          props.setCollectionId?.(selectedOption.id ?? '')
          // first
          props.onChange?.(selectedOption.value, selectedOption)
        }
      } else {
        props.setCollectionId?.('')
        // uncheck the selected options
        props.onChange?.(null, null)
      }
      setSearch('')
    },
    [props, setSearch]
  )

  const options: OptionData[] = useMemo(
    () =>
      (apiResult ?? []).map(x => ({
        ...x,
        label: x.name ?? '',
        value:
          selectionValueType === CollectionSelectionValue.CollectionId ? x.id ?? '' : x.slug ?? '',
      })),
    [apiResult, selectionValueType]
  )

  // Update the options prop in the NewSelect component
  const menuListComponent = useMemo(
    () => (menuListProps: MenuListProps<OptionData>) =>
      advancedView ? (
        <SelectorMenuList
          {...menuListProps}
          pillOptions={pillOptions}
          selectedPills={selectedPills}
          onPillSelect={togglePill}
          onToggleAdvancedSearch={toggleAdvancedSearch}
        />
      ) : (
        <components.MenuList {...menuListProps} />
      ),
    [advancedView, pillOptions, selectedPills, togglePill, toggleAdvancedSearch]
  )

  const optionComponent = useMemo(
    () =>
      showTags
        ? (props: any) => (
            <SelectorOption
              {...props}
              openCollectionPreview={openCollectionPreview}
              disablePreview={disablePreview}
            />
          )
        : components.Option,
    [showTags, openCollectionPreview, disablePreview]
  )

  // Look at removing this block and use the search in new select
  const filteredAdditionalOptions: OptionData[] = useMemo(() => {
    if (!additionalOptions || !shouldPerformSearch) return []

    const searchTerm = getDebouncedSearchTerm()?.toLowerCase() ?? ''
    return additionalOptions.filter(o => o.label.toLowerCase().includes(searchTerm))
  }, [additionalOptions, getDebouncedSearchTerm, shouldPerformSearch])

  return (
    <Container>
      <NewSelect
        css={{ zIndex: 100 }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        components={
          {
            Option: optionComponent,
            MenuList: menuListComponent,
          } as any
        }
        // Add selected options here so they are always shown in the selected bar
        options={getUniqueCollections([
          ...selectedOptionsCache,
          ...filteredAdditionalOptions,
          ...options,
        ])}
        onInputChange={(value, meta) => {
          // set search term when input is not blurred or menu is not closed
          if (meta?.action !== 'input-blur' && meta?.action !== 'menu-close') {
            setSearch(value)
          }
        }}
        placeholder={i18n.placeholder}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? i18n.noOptionsMessage : i18n.typeToSearchMessage
        }
        isClearable
        inputValue={searchTerm}
        isLoading={apiLoading}
        isDisabled={isDisabled}
        {...props}
        onChange={(value, options) => {
          // reset
          if (!value) {
            props.onChange?.(null, null)
            props.setCollectionId?.('')
            setSelectedOptionsCache([])
            setSearch('')
            return
          }

          const collectionResult = options as OptionData
          props.setCollectionId?.(collectionResult?.id ?? '')
          props.onChange?.(value, options)

          setSelectedOptionsCache(Array.isArray(options) ? options : [options])
        }}
      />
      {advancedView && isAdvancedSearchOpen && (
        <AdvancedSearchModal
          onClose={toggleAdvancedSearch}
          onChange={handleAdvancedSearchChange}
          initialSearchTerm={getDebouncedSearchTerm()}
          // original props from parent
          value={props.value}
          isClearable={props.isClearable}
          isMulti={props.isMulti}
          selectedOptionsCache={selectedOptionsCache}
          collectionTypes={collectionTypes as CollectionType[]}
          initialSlug={initialSlug}
          retailerId={retailerId}
          excludeDynamicRetailerCollections={excludeDynamicRetailerCollections}
          selectionValueType={selectionValueType}
          additionalOptions={additionalOptions}
        />
      )}
    </Container>
  )
}

export default CollectionSelector
