import { useState, useMemo } from 'react'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { Author, CollectionType } from '../types'

type TypeConfig = {
  [key: string]: {
    label: string
    types: CollectionType[]
    authors: Author[]
  }
}

type AuthorConfig = {
  [key in Author]: {
    label: string
    types: CollectionType[]
  }
}

export const useTypeAuthorFilters = (collectionTypes: CollectionType[] | undefined) => {
  const [selectedType, setSelectedType] = useState<keyof TypeConfig | null>(null)
  const [selectedAuthor, setSelectedAuthor] = useState<Author | null>(null)

  const i18n = useDomainMessages({
    collection: 'storefrontDomain.collections-selector.advancedSearch.type.collection',
    department: 'storefrontDomain.collections-selector.advancedSearch.type.department',
    hub: 'storefrontDomain.collections-selector.advancedSearch.type.hub',
    holiday: 'storefrontDomain.collections-selector.advancedSearch.type.holiday',
    retailer: 'storefrontDomain.collections-selector.advancedSearch.author.retailer',
    instacart: 'storefrontDomain.collections-selector.advancedSearch.author.instacart',
  })

  // Configuration mapping for types, their associated collection types, and allowed authors
  const TYPE_CONFIG: TypeConfig = useMemo(
    () => ({
      COLLECTION: {
        label: i18n.collection,
        types: [CollectionType.RETAILER_COLLECTION, CollectionType.HOLIDAY, CollectionType.HUB],
        authors: [Author.RETAILER, Author.INSTACART],
      },
      DEPARTMENT: {
        label: i18n.department,
        types: [CollectionType.RETAILER_DEPARTMENT, CollectionType.DEPARTMENT],
        authors: [Author.RETAILER, Author.INSTACART],
      },
    }),
    [i18n]
  )

  // Configuration mapping for authors and their associated collection types
  const AUTHOR_CONFIG: AuthorConfig = useMemo(
    () => ({
      [Author.RETAILER]: {
        label: i18n.retailer,
        types: [CollectionType.RETAILER_COLLECTION, CollectionType.RETAILER_DEPARTMENT],
      },
      [Author.INSTACART]: {
        label: i18n.instacart,
        types: [CollectionType.HUB, CollectionType.HOLIDAY, CollectionType.DEPARTMENT],
      },
    }),
    [i18n]
  )

  // Update typeOptions to consider selectedAuthor
  const typeOptions = useMemo(
    () =>
      Object.entries(TYPE_CONFIG)
        .filter(([_, config]) => config.types.some(type => collectionTypes?.includes(type)))
        .map(([value, { label }]) => ({ value, label })),
    [TYPE_CONFIG, collectionTypes]
  )

  // Generate author options based on selected type and available collection types
  const authorOptions = useMemo(() => {
    const availableAuthors =
      selectedType && selectedType in TYPE_CONFIG
        ? TYPE_CONFIG[selectedType as keyof typeof TYPE_CONFIG].authors
        : Object.keys(AUTHOR_CONFIG)

    return (availableAuthors as Author[])
      .filter(author => AUTHOR_CONFIG[author].types.some(type => collectionTypes?.includes(type)))
      .map(author => ({ value: author, label: AUTHOR_CONFIG[author].label }))
  }, [selectedType, TYPE_CONFIG, AUTHOR_CONFIG, collectionTypes])

  const handleTypeChange = (newType: keyof typeof TYPE_CONFIG | null) => {
    setSelectedType(newType)
    // Reset author when type changes to ensure consistency
    setSelectedAuthor(null)
  }

  const handleAuthorChange = (newAuthor: Author | null) => {
    setSelectedAuthor(newAuthor)
    // Only clear type if the new author doesn't support the current type
    if (newAuthor && selectedType && !TYPE_CONFIG[selectedType].authors.includes(newAuthor)) {
      setSelectedType(null)
    }
  }

  // Filter collection types based on selected type and author
  const getFilteredCollectionTypes = () => {
    if (!collectionTypes) return []
    return collectionTypes.filter(type => {
      const matchesType = !selectedType || TYPE_CONFIG[selectedType].types.includes(type)
      const matchesAuthor = !selectedAuthor || AUTHOR_CONFIG[selectedAuthor].types.includes(type)
      return matchesType && matchesAuthor
    })
  }

  return {
    typeOptions,
    authorOptions,
    selectedType,
    selectedAuthor,
    handleTypeChange,
    handleAuthorChange,
    getFilteredCollectionTypes,
  }
}
