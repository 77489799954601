import React from 'react'
import styled from '@emotion/styled'
import { spacing, fontSize, fontWeight } from '@retailer-platform/shared-components'

export const StyledNotification = styled.div({
  '.header': {
    fontSize: fontSize.X15,
    fontWeight: fontWeight.SEMIBOLD,
    marginBottom: spacing.X8,
  },
  '.content': {
    fontSize: fontSize.X12,
  },
})

export const DefaultNotificationContent: React.FunctionComponent<
  React.PropsWithChildren<{
    header: React.ReactNode
    content: React.ReactNode
  }>
> = ({ header, content }) => (
  <StyledNotification>
    <div className="header">{header}</div>
    <div className="content">{content}</div>
  </StyledNotification>
)
