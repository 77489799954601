import React from 'react'
import styled from '@emotion/styled'
import { ButtonPill } from '@instacart/ids-customers'
import { spacing } from '@retailer-platform/shared-components'
import { type PillValue } from './usePillConfig'

interface PillFilterProps {
  pillOptions: Array<{ label: string; value: PillValue }>
  selectedPills: PillValue[]
  onPillSelect: (pill: PillValue) => void
}

const PillSection = styled.div({
  position: 'sticky',
  top: 0,
  paddingBottom: spacing.X8,
  borderBottom: '1px solid #e0e0e0',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
})

const PillFilter: React.FC<PillFilterProps> = ({ pillOptions, selectedPills, onPillSelect }) => (
  <PillSection>
    {pillOptions.map(({ value, label }) => (
      <ButtonPill
        key={value}
        active={selectedPills.includes(value)}
        onClick={() => onPillSelect(value)}
      >
        {label}
      </ButtonPill>
    ))}
  </PillSection>
)

export default PillFilter
