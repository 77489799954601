import { useMemo, useCallback } from 'react'
import { type StoreGroup } from '../../../common/graphql-types/retailerLocationGroup.types'
import { type RetailerLocation } from '../../../common/graphql-types/retailerLocation.types'
import { useDashIntl } from '../../../../intl/intl.hooks'
import { trackEvent } from '../../../../utils/events/trackEvent'
import { toInteger } from '../../../../utils/parsing/numbers'
import {
  type RetailerLocationSelectOption,
  type RetailerLocationSelectOnChange,
  type RetailerLocationSelectValueType,
} from './options.types'

const retailerLocationIcon: RetailerLocationSelectOption['icon'] = 'locationMarkerFilled'
const storeGroupIcon: RetailerLocationSelectOption['icon'] = 'folderFilled'

interface GroupedOptions {
  label?: string
  options: RetailerLocationSelectOption[]
}

export interface UseRetailerLocationSelectOptionBehaviourProps {
  onChange: RetailerLocationSelectOnChange
  retailerLocations: RetailerLocation[]
  storeGroups: StoreGroup[]
  value: Partial<RetailerLocationSelectValueType> | undefined
}

interface UseNormalizedValueProps
  extends Pick<UseRetailerLocationSelectOptionBehaviourProps, 'retailerLocations' | 'storeGroups'> {
  value: Partial<RetailerLocationSelectValueType>
}

const ALL_LOCATIONS_VALUE = {
  retailerLocationId: null,
  storeGroupId: null,
  name: null,
}

const useNormalizedValue = ({
  retailerLocations,
  storeGroups,
  value: { retailerLocationId: initialRetailerLocationId, storeGroupId: initialStoreGroupId },
}: UseNormalizedValueProps): RetailerLocationSelectValueType => {
  const retailerLocationIds = retailerLocations.map(({ id }) => id)
  const storeGroupIds = storeGroups.map(({ id }) => id)

  if (initialRetailerLocationId && retailerLocationIds.includes(initialRetailerLocationId)) {
    return {
      name: null,
      retailerLocationId: initialRetailerLocationId,
      storeGroupId: null,
    }
  }

  if (initialStoreGroupId && storeGroupIds.includes(initialStoreGroupId)) {
    return {
      name: null,
      retailerLocationId: null,
      storeGroupId: initialStoreGroupId,
    }
  }

  return ALL_LOCATIONS_VALUE
}

export const useRetailerLocationSelectOptionsBehaviour = ({
  onChange,
  retailerLocations,
  storeGroups,
  value: inputValue = {},
}: UseRetailerLocationSelectOptionBehaviourProps) => {
  const intl = useDashIntl()

  const value = useNormalizedValue({ value: inputValue, retailerLocations, storeGroups })

  const selectAllOption = useMemo(
    () => ({
      label: intl.formatMessage({ id: 'components.retailerLocationSelect.allLocationsOption' }),
      value: ALL_LOCATIONS_VALUE,
    }),
    [intl]
  )

  const retailerLocationsOptions: RetailerLocationSelectOption[] = useMemo(
    () =>
      retailerLocations.map(({ id, name }) => ({
        label: name,
        value: {
          name: name,
          storeGroupId: null,
          retailerLocationId: id,
        },
        icon: retailerLocationIcon,
        iconColor: 'neutral',
      })),
    [retailerLocations]
  )

  const storeGroupsOptions: RetailerLocationSelectOption[] = useMemo(
    () =>
      storeGroups.map(({ id, name, retailerLocationIds }) => ({
        // Note that we add the number of retailer locations in a group to the label
        label: `${name} (${retailerLocationIds.length})`,
        value: {
          name: name,
          storeGroupId: id,
          retailerLocationId: null,
        },
        icon: storeGroupIcon,
        iconColor: 'primary',
      })),
    [storeGroups]
  )

  const options = [
    {
      options: [selectAllOption],
    },
    {
      label: 'Locations',
      options: retailerLocationsOptions,
    },
    {
      label: 'Store Groups',
      options: storeGroupsOptions,
    },
  ]

  // Selects only the store group options
  const filterGroup = useCallback(
    (group: GroupedOptions) => group.options === storeGroupsOptions,
    [storeGroupsOptions]
  )

  const setOptionValue = ({ value: nextValue }: RetailerLocationSelectOption) => {
    if (nextValue.retailerLocationId) {
      trackEvent({
        id: 'components.retailer_location_select.location_selected',
        description: 'Selected a location from the retailer location select dropdown',
        data: {
          retailerLocationId: toInteger(nextValue.retailerLocationId),
        },
      })
    }

    if (nextValue.storeGroupId) {
      trackEvent({
        id: 'components.retailer_location_select.group_selected',
        description: 'Selected a group from the retailer location select dropdown',
        data: {
          storeGroupId: toInteger(nextValue.storeGroupId),
        },
      })
    }

    onChange(nextValue)
  }

  const optionValue = useMemo(() => {
    if (value.retailerLocationId) {
      const match = retailerLocationsOptions.find(
        ({ value: { retailerLocationId } }) => retailerLocationId === value.retailerLocationId
      )
      if (match) {
        return match
      }
    }

    if (value.storeGroupId) {
      const match = storeGroupsOptions.find(
        ({ value: { storeGroupId } }) => storeGroupId === value.storeGroupId
      )
      if (match) {
        return match
      }
    }

    return selectAllOption
  }, [retailerLocationsOptions, selectAllOption, storeGroupsOptions, value])

  return {
    optionValue,
    setOptionValue,
    options,
    filterGroup,
    defaultValue: selectAllOption,
  }
}
