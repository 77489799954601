import { dateRange, type DateRangeOption } from './dateRanges.types'

export const DEFAULT_DATE_RANGE = dateRange.LAST_WEEK

export const dateRangeOption: {
  [k in keyof typeof dateRange]: DateRangeOption<(typeof dateRange)[k]>
} = {
  TODAY: {
    value: dateRange.TODAY,
    label: 'Today',
  },
  YESTERDAY: {
    value: dateRange.YESTERDAY,
    label: 'Yesterday',
  },
  LAST_WEEK: {
    value: dateRange.LAST_WEEK,
    label: 'Last Week',
  },
  LAST_MONTH: {
    value: dateRange.LAST_MONTH,
    label: 'Last Month',
  },
  LAST_7_DAYS: {
    value: dateRange.LAST_7_DAYS,
    label: 'Last 7 Days',
  },
  LAST_30_DAYS: {
    value: dateRange.LAST_30_DAYS,
    label: 'Last 30 Days',
  },
  LAST_90_DAYS: {
    value: dateRange.LAST_90_DAYS,
    label: 'Last 90 Days',
  },
  CUSTOM: {
    value: dateRange.CUSTOM,
    label: 'Custom',
  },
}

export const DEFAULT_DATE_RANGE_OPTIONS = [
  dateRangeOption.TODAY,
  dateRangeOption.YESTERDAY,
  dateRangeOption.LAST_WEEK,
  dateRangeOption.LAST_MONTH,
  dateRangeOption.LAST_90_DAYS,
  dateRangeOption.CUSTOM,
]

// PAST_DATE_RANGE_OPTIONS are all date ranges that do *not* include today
export const PAST_DATE_RANGE_OPTIONS = [
  dateRangeOption.YESTERDAY,
  dateRangeOption.LAST_WEEK,
  dateRangeOption.LAST_MONTH,
  dateRangeOption.CUSTOM,
]
