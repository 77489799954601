const EN_US = {
  'oauthApplicationsDomain.title': 'OAuth Applications',
  // OauthApplicationsListPage
  'oauthApplicationsDomain.oauthApplicationsListPage.title': 'OAuth applications',
  // OauthApplicationsGrid
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.name': 'Name',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.status': 'Status',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.environment': 'Environment',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.clientId': 'Client ID',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.activeTokens': 'Active tokens',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.created': 'Created',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.renewBy': 'Renew by',
} as const

export default EN_US
