export interface DimensionRestraints {
  minWidth: number
  maxWidth: number
  minHeight: number
  maxHeight: number
}

export interface ImageDimensions {
  width: number
  height: number
}

export interface RequiredImageDimensions
  extends Partial<DimensionRestraints>,
    Partial<ImageDimensions> {}

export type RequiredImageDimensionsFn = (ImageDimensions) => boolean

export interface ImageUploaderProps {
  filestackApiKey: string
  value?: string
  onChange?: (uploadUrl: string, file: File) => void
  requiredDimensions?: RequiredImageDimensions | RequiredImageDimensionsFn
  compact?: boolean
}

export enum FileError {
  InvalidDimensions = 'INVALID_DIMENSIONS',
  InvalidFile = 'INVALID_FILE',
}
