/* eslint-disable react/no-multi-comp */
import React from 'react'
import VirtualList from 'react-tiny-virtual-list'
import { type MenuListComponentProps } from 'react-select/src/components/Menu'
import { type BaseOption } from '../../utils/select.types'
import { useRequiredContext } from '../../../../contexts/utils'
import { DashSelectContext } from '../../utils/DashSelectContext'
import { type baseDashSelectComponents, isGroupedOptionsList } from '../../utils/select.utils'
import { useDashSelectFastMenuListBehaviour } from './utils/dashSelectFastMenuList.hooks'
import {
  FAST_MENU_LIST_MAX_HEIGHT,
  FAST_MENU_LIST_ITEM_SIZE,
  FAST_MENU_LIST_ITEMS_PER_PAGE,
} from './utils/dashSelectFastMenuList.constants'

export const DashSelectFastMenuList = <TOption extends BaseOption>(
  props: MenuListComponentProps<TOption>
) => {
  const {
    options,
    children,
    selectProps: { inputValue, components },
  } = props

  const { NoOptionsMessage } = components as typeof baseDashSelectComponents
  const { toggle } = useRequiredContext(DashSelectContext)
  const isGroupedList = isGroupedOptionsList<TOption>(options)

  const { scrollToIndexConfig, renderItem, itemCount } = useDashSelectFastMenuListBehaviour({
    children,
    inputValue,
    isGroupedList,
    isToggled: toggle ? toggle.toggleActive : false,
  })

  // If there are no items, we return the NoOptionsMessage
  if (itemCount === 0) {
    return <NoOptionsMessage {...props} />
  }

  // Add some buffer to the bottom row
  const height = Math.min(itemCount * FAST_MENU_LIST_ITEM_SIZE, FAST_MENU_LIST_MAX_HEIGHT) + 2

  return (
    <VirtualList
      {...scrollToIndexConfig}
      width="100%"
      height={height}
      itemCount={itemCount}
      itemSize={FAST_MENU_LIST_ITEM_SIZE}
      overscanCount={FAST_MENU_LIST_ITEMS_PER_PAGE}
      // Work-around for some issues importing the enum required by scrollToAlignment
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scrollToAlignment={'auto' as any}
      renderItem={renderItem}
    />
  )
}
