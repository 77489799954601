import React, { useEffect } from 'react'
import {
  StringParam,
  useQueryParams,
  encodeObject,
  decodeObject,
  type QueryParamConfig,
} from 'use-query-params'
import { SimpleOptionsParam, compactObject } from '@retailer-platform/dashboard/utils'
import { useFilterParamsContext } from '../../../../utils/filter-params/filterParamsContext'
import { WeekStartDayToValue } from '../../../../utils/insights-portal.types'
import { useDomainMessages } from '../../../../utils/domain/intl'
import {
  insightsSchemaConfigs,
  type InsightsSchemaConfigs,
} from '../../../../schemas/configuration/schema.configuration'
import { InsightsPortalSchema } from '../../../../api'
import { type DataExplorerParamsNames } from './dataExplorerParams.types'
import { DataExplorerParamsContext } from './dataExplorerParamsContext'

// serialize-query-params by default uses "_" as a separation delimiter
const CustomOrderBysObjectParam = {
  encode: (val: { [key: string]: string | undefined }) => encodeObject(val, '--', '----'),
  decode: (str: string | undefined) => decodeObject(str, '--', '----'),
}
const validSchema = (schema: string): schema is keyof InsightsSchemaConfigs => {
  const keys = Object.keys(insightsSchemaConfigs)
  return keys.includes(schema)
}

const SchemaParam: QueryParamConfig<keyof InsightsSchemaConfigs | undefined> = {
  encode: schema => schema,
  decode: (schema: string | undefined) => (validSchema(schema) ? schema : undefined),
}

export const useDataExplorerParams = () => {
  const { dates } = useFilterParamsContext()

  const labels = useDomainMessages({
    weeks: 'insightsPortalDomain.pillTabs.weeks.label',
  })

  const [selectedDataExplorerValues, setSelectedDataExplorerValues] = useQueryParams({
    groupBy: SimpleOptionsParam,
    metric: SimpleOptionsParam,
    reportName: StringParam,
    orderBys: CustomOrderBysObjectParam,
    schema: SchemaParam,
  })
  const selectedValuesWithDefaults: typeof selectedDataExplorerValues = {
    metric: [],
    groupBy: [],
    reportName: '',
    schema: InsightsPortalSchema.OrderDelivery,
    orderBys: undefined,
    ...compactObject(selectedDataExplorerValues),
  }

  useEffect(() => {
    const combinedGroupByMetricList = selectedDataExplorerValues?.groupBy
      ?.concat(selectedDataExplorerValues?.metric)
      .map(option => option?.value)
    if (!combinedGroupByMetricList?.includes(selectedDataExplorerValues.orderBys?.columnName)) {
      setDataExplorerParamsSelection('orderBys', undefined)
    }
    // setDataExplorerParamsSelection will never be stale
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDataExplorerValues.groupBy,
    selectedDataExplorerValues.metric,
    selectedDataExplorerValues.orderBys,
  ])
  useEffect(() => {
    const weekGroupByIndex = selectedDataExplorerValues.groupBy?.findIndex(groupByOption =>
      Object.values(WeekStartDayToValue).includes(groupByOption.value)
    )
    if (
      weekGroupByIndex >= 0 &&
      selectedDataExplorerValues.groupBy[weekGroupByIndex].value !==
        WeekStartDayToValue[dates.weekStartDay]
    ) {
      setDataExplorerParamsSelection('groupBy', [
        ...selectedDataExplorerValues.groupBy.slice(0, weekGroupByIndex),
        {
          label: labels.weeks,
          value: WeekStartDayToValue[dates.weekStartDay],
        },
        ...selectedDataExplorerValues.groupBy.slice(
          weekGroupByIndex + 1,
          selectedDataExplorerValues.groupBy.length
        ),
      ])
    }
    // setDataExplorerParamsSelection will never be stale
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates.weekStartDay, selectedDataExplorerValues.groupBy])

  type DataExplorerParamsSelections = Parameters<typeof setSelectedDataExplorerValues>[0]

  function setDataExplorerParamsSelection<TName extends DataExplorerParamsNames>(
    name: TName,
    selection: DataExplorerParamsSelections[TName]
  ) {
    setSelectedDataExplorerValues({ [name]: selection })
  }
  return {
    setSelectedDataExplorerValues,
    setDataExplorerParamsSelection,
    selectedDataExplorerValues: selectedValuesWithDefaults,
  }
}

export const DataExplorerParamsProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const dataExplorerParamsState = useDataExplorerParams()

  return (
    <DataExplorerParamsContext.Provider value={dataExplorerParamsState}>
      {children}
    </DataExplorerParamsContext.Provider>
  )
}
