import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  Card,
  colors,
  spacing,
  ContentStylizer,
  Text,
} from '@retailer-platform/shared-components'
import CollectionsEvolution from './CollectionsEvolution.pdf'

const StyledCardBody = styled.div({
  display: 'flex',
})

const ImageWrapper = styled.div({
  width: 300,
  height: 148,
  background: colors.GRAYSCALE.X0,
  borderRadius: spacing.X16,
})

const Image = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const StyledMarkdown = styled(ContentStylizer)({
  flex: 1,
})

const Details = styled('div')({
  padding: spacing.X8,
  marginLeft: spacing.X16,
  display: 'flex',
  flexDirection: 'column',
})

const DownloadLink = styled.a({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  padding: '8px 16px',
  textDecoration: 'none',
  borderRadius: '8px',
  margin: '8px',
  fontWeight: 'bold',
  color: colors.GRAYSCALE.X70,
  backgroundColor: colors.GRAYSCALE.X10,
})

type EducationBannerBuilderProps = {
  heading: string
  information: string
  buttonText: string
  downloadLinkText?: string
  onClick: () => void
  imageSrc: string
}

export const EducationBannerBuilder: FunctionComponent<
  React.PropsWithChildren<EducationBannerBuilderProps>
> = ({ heading, information, buttonText, downloadLinkText, onClick, imageSrc }) => (
  <Card css={{ margin: '21px' }}>
    <StyledCardBody>
      <ImageWrapper>
        <Image src={imageSrc} />
      </ImageWrapper>
      <Details>
        <StyledMarkdown>
          <Text
            size="large"
            weight="bold"
            css={{ color: colors.GRAYSCALE.X70, paddingBottom: spacing.X12 }}
          >
            {heading}
          </Text>
          <Text css={{ color: colors.GRAYSCALE.X70, marginBottom: spacing.X12 }}>
            {information}
          </Text>
        </StyledMarkdown>
        <div>
          <Button onClick={onClick} variant="primary">
            {buttonText}
          </Button>
          {/* Leaving the default implementation of the download link for now */}
          {downloadLinkText && (
            <DownloadLink href={CollectionsEvolution} download>
              {downloadLinkText}
            </DownloadLink>
          )}
        </div>
      </Details>
    </StyledCardBody>
  </Card>
)
