import React from 'react'
import { DashSelect } from '../../dash-blocks/dash-select/DashSelect'
import {
  type DashSelectProps,
  type BaseOption,
  type SimpleOption,
} from '../../dash-blocks/dash-select/utils/select.types'
import { useSelectBehaviour } from './utils/select.hooks'

export const Select = <TOption extends BaseOption = SimpleOption<string>>(
  props: DashSelectProps<TOption>
) => {
  const behaviour = useSelectBehaviour()

  return <DashSelect<TOption> {...behaviour} {...props} />
}
