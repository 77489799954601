import { useMemo } from 'react'
import { dashSpacing } from '../../foundation/spacing'
import { type DashTableDatum, type DashColumnInstance } from './dashTable.types'
import { dashTableCellStyles, dashTableHeaderCellStyles } from './dashTable.styles'

interface CalculateCellStyling<TData extends DashTableDatum> extends DashColumnInstance<TData> {}

const DEFAULT_MIN_WIDTH = 30
const DEFAULT_MAX_WIDTH = 200

export const useDashTableSharedCellStyling = <TData extends DashTableDatum>({
  style = {},
  width,
  minWidth,
  maxWidth,
  align = 'left',
  paddingLeft,
  paddingRight,
  canSort,
}: CalculateCellStyling<TData>) => {
  // If there is a "width" set but no min/max width, then we force the column to
  // stay at that size. If a column should shrink/grow, the user should set a min
  // and max width instead of just a target width
  const isFixedWidth = Boolean(width) && !minWidth && !maxWidth && width !== 'auto'

  const targetMinWidth = isFixedWidth ? width : minWidth || DEFAULT_MIN_WIDTH
  const targetMaxWidth = isFixedWidth ? width : maxWidth || DEFAULT_MAX_WIDTH

  const paddingRightForSortIcon = canSort ? dashSpacing.MEDIUM : paddingRight

  return useMemo(
    () => ({
      ...style,
      width,
      minWidth: targetMinWidth,
      maxWidth: targetMaxWidth,
      textAlign: align,
      paddingLeft,
      paddingRight: paddingRightForSortIcon,
    }),
    [style, width, targetMinWidth, targetMaxWidth, align, paddingLeft, paddingRightForSortIcon]
  )
}

export const useDashTableCellStyling = <TData extends DashTableDatum>({
  style = {},
  cellStyle = {},
  ...rest
}: CalculateCellStyling<TData>) =>
  useDashTableSharedCellStyling({
    ...rest,
    style: {
      ...dashTableCellStyles,
      ...style,
      ...cellStyle,
    },
  })

export const useDashTableHeaderCellStyling = <TData extends DashTableDatum>({
  style = {},
  headerStyle = {},
  ...rest
}: CalculateCellStyling<TData>) =>
  useDashTableSharedCellStyling({
    ...rest,
    style: {
      ...dashTableHeaderCellStyles,
      ...style,
      ...headerStyle,
    },
  })
