import { type QueryParamEncodedType } from './queryParams.types'

/**
 * Makes an encoder that will only allow certain values to be passed to/from query.
 * This is useful to avoid users tampering with the query param manually, e.g. changing
 * items per page to be 100000000000 instead of 10.
 *
 * @param allowedValues
 * @param encoder
 * @returns
 */
export const makeEnumQueryParamEncoder = <T>(
  allowedValues: T[],
  encoder: { encode: (v: T) => QueryParamEncodedType; decode: (v: QueryParamEncodedType) => T }
) => ({
  encode: (inputValue: T) => {
    if (allowedValues.includes(inputValue)) {
      return encoder.encode(inputValue)
    }

    if (inputValue === undefined) return undefined

    throw new Error(
      `EnumQueryParamEncoder: value '${inputValue}' does not exist within the allowed values ${allowedValues.join(
        ','
      )}`
    )
  },
  decode: (queryStr: QueryParamEncodedType): T => {
    const parsed = encoder.decode(queryStr)

    if (parsed === undefined) return

    return allowedValues.includes(parsed) ? parsed : undefined
  },
})
