import React from 'react'
import { ModalBody, ModalHeader, Text } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import SurveyCompleted from '../../../../assets/retailer-satisfaction-survey-completed.svg'
import { useDashMessages } from '../../../../intl/intl.hooks'
import clientEnv from '../../../../utils/global/clientEnv'

const Image = styled.img({
  width: '18%',
  height: '18%',
  objectFit: 'contain',
  marginBottom: '20px',
})
const TextContainer = styled.div({
  textAlign: 'center',
  margin: '8px',
  lineHeight: '1.5',
})
const ModalBodyDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Link = styled.a({
  color: '#0AAD0A',
  target: '_blank',
  rel: 'noreferrer',
})

export const Page3 = () => {
  const i18n = useDashMessages({
    thankYou: 'retailerSatisfactionSurvey.thankYou',
    thankYou1: 'retailerSatisfactionSurvey.thankYou1',
    thankYou2: 'retailerSatisfactionSurvey.thankYou2',
    thankYou3Link: 'retailerSatisfactionSurvey.thankYou3Link',
    thankYou4: 'retailerSatisfactionSurvey.thankYou4',
  })

  return (
    <>
      <ModalHeader data-testid="page3">{i18n.thankYou}</ModalHeader>

      <ModalBody>
        <ModalBodyDiv>
          <Image src={SurveyCompleted} />
          <TextContainer>
            <p>{i18n.thankYou1}</p>
            {i18n.thankYou2}{' '}
            <Link href={clientEnv.PUBLIC_CLIENT_PARTNER_DOCS_URL} target="_blank" rel="noreferrer">
              {i18n.thankYou3Link}
            </Link>{' '}
            {i18n.thankYou4}
          </TextContainer>
        </ModalBodyDiv>
      </ModalBody>
    </>
  )
}
