import React, { type FunctionComponent, useMemo } from 'react'
import styled from '@emotion/styled'
import { Text, type TimeOfDay } from '@retailer-platform/shared-components'
import { type Moment } from 'moment'
import { type OpenMode } from '../../../edit/components/store-open-mode-select/StoreHoursBulkStoreOpenModeSelect'
import { StoreHoursBulkStoreHourItem } from './StoreHoursBulkStoreHourItem'

interface StoreHoursBulkSpecialStoreHourItemProps {
  description: string
  date: Moment
  openMode: OpenMode
  openTime: TimeOfDay
  closeTime: TimeOfDay
}

const SpecialStoreHoursItemContainer = styled.div({
  display: 'flex',
  padding: '5px 0',
})

export const StoreHoursBulkSpecialStoreHourItem: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkSpecialStoreHourItemProps>
> = ({ description, date, openMode, openTime, closeTime }) => {
  const specialStoreHoursItem = useMemo(
    () => (
      <SpecialStoreHoursItemContainer>
        <Text css={{ flex: '35' }} weight="bold">
          {description}
        </Text>
        <Text css={{ flex: '25' }}>{date.format('MM/DD/YY')}</Text>
        <div css={{ flex: '40' }}>
          <StoreHoursBulkStoreHourItem
            openTime={openTime}
            openMode={openMode}
            closeTime={closeTime}
          />
        </div>
      </SpecialStoreHoursItemContainer>
    ),
    [date, description, openTime, closeTime, openMode]
  )
  return specialStoreHoursItem
}
