import React, { type FunctionComponent, useMemo } from 'react'
import { Select } from '../select/Select'
import { useDashIntl } from '../../../intl/intl.hooks'
import { dashPixels } from '../../dash-blocks/foundation/spacing-units/spacingUnits.types'
import {
  useRetailerLocationSelectOptionsBehaviour,
  type UseRetailerLocationSelectOptionBehaviourProps,
} from './utils/options.hooks'
import {
  type RetailerLocationSelectOptionStylingProps,
  type RetailerLocationSelectOption,
} from './utils/options.types'

export interface RetailerLocationSelectContentProps
  extends RetailerLocationSelectOptionStylingProps,
    UseRetailerLocationSelectOptionBehaviourProps {
  addGroupRedirectLink: string
  loading: boolean
  error?: Error
}

export const RetailerLocationSelectContent: FunctionComponent<
  React.PropsWithChildren<RetailerLocationSelectContentProps>
> = ({ addGroupRedirectLink, loading, error, width, menuWidth, ...rest }) => {
  const intl = useDashIntl()

  const noGroupsMessage = intl.formatMessage({ id: 'components.retailerLocationSelect.noGroups' })

  const createGroupMessage = intl.formatMessage({
    id: 'components.retailerLocationSelect.createGroup',
  })
  const toggleGroupsMessage = intl.formatMessage({
    id: 'components.retailerLocationSelect.toggleGroups',
  })

  const { optionValue, setOptionValue, defaultValue, options, filterGroup } =
    useRetailerLocationSelectOptionsBehaviour(rest)

  const selectProps = useMemo(() => {
    return {
      noOptions: {
        toggledMessage: noGroupsMessage,
        toggledLink: {
          text: createGroupMessage,
          to: addGroupRedirectLink,
        },
      },
      toggle: {
        heading: toggleGroupsMessage,
        filterGroup,
      },
    }
  }, [addGroupRedirectLink, createGroupMessage, filterGroup, noGroupsMessage, toggleGroupsMessage])

  return (
    <Select<RetailerLocationSelectOption>
      {...rest}
      sortBy="label"
      testId={rest.testId || 'retailer-location-select'}
      width={width || dashPixels.PX256}
      menuWidth={menuWidth || dashPixels.PX384}
      loading={loading}
      error={error}
      defaultValue={defaultValue}
      value={optionValue}
      options={options}
      isMulti={false}
      onChange={setOptionValue}
      {...selectProps}
    />
  )
}
