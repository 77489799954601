export type InsightsPortalFilterTypes = 'inFilter' | 'betweenFilter' | 'equalFilter'

export type DashboardTypes =
  | 'outOfStock'
  | 'performance'
  | 'basket'
  | 'search'
  | 'summaryReport'
  | 'data-explorer-performance'
  | 'data-explorer'
  | 'ads'
  | 'inventoryIntelligenceOutOfStock'
  | 'campaigns-user-growth'
  | 'campaigns-basket'
  | 'campaigns-redemptions'
  | 'campaigns-all'
  | 'placements-performance-comparison'
  | 'collections-overview'
  | 'collections-all-collections'
  | 'collections-performance-comparison'
  | 'collections-product-sales'

export enum WeekStartDayToValue {
  sunday = 'WEEK_SUN_PT',
  monday = 'WEEK_MON_PT',
  tuesday = 'WEEK_TUE_PT',
  wednesday = 'WEEK_WED_PT',
  thursday = 'WEEK_THU_PT',
  friday = 'WEEK_FRI_PT',
  saturday = 'WEEK_SAT_PT',
}

export type AnalyticTrackingData = {
  // name of the dashboard e.g. 'outOfStock'
  dashboard?: DashboardTypes
  // name of the widget e.g. 'insightsTable
  widget?: string
  // specific instance of widget e.g. 'category-insights-table' or 'product-insights-table'
  widgetId?: string
}
