import React, { type FC } from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import {
  Timeline as MantineTimeline,
  type TimelineProps as MantineTimelineProps,
  TimelineItem,
} from '@mantine/core'
import { colors } from '../../foundation'

export interface TimelineProps
  extends Omit<MantineTimelineProps, 'color' | 'radius' | 'bulletSize'> {
  color?: string
  radius?: number
  bulletSize?: number
}

interface TimelineComponent extends FC<TimelineProps> {
  Item: typeof TimelineItem
}

const Timeline: TimelineComponent = ({ children, color = colors.GRAYSCALE.X30, ...props }) => (
  <MantineTimeline
    radius={4}
    lineWidth={5}
    bulletSize={16}
    active={React.Children.count(children)} // This is to have the color show. Otherwise its gray.
    styles={{
      root: {
        '--tl-color': color,
      },
    }}
    {...props}
  >
    {children}
  </MantineTimeline>
)

Timeline.displayName = 'Timeline'
Timeline.Item = TimelineItem

export { Timeline }
