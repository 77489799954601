import { useMemo } from 'react'
import moment, { type Moment } from 'moment'
import { type DateRangePickerShape } from 'react-dates'
import {
  type StartEndDate,
  dateRange,
  type DateRangeOption,
  type DateRange,
} from '../../../common/utils/time/dateRanges.types'
import { DEFAULT_DATE_RANGE_OPTIONS } from '../../../common/utils/time/dateRanges'
import {
  type DateSelectRangeValueType,
  type DateSelectRangeOnChange,
} from './dateSelectRange.types'

export type DateSelectRangeValue = {
  dateRange?: DateSelectRangeValueType['dateRange']
  customDates?: Partial<DateSelectRangeValueType['customDates']>
}

export type UseDateSelectRangeBehaviourProps = {
  onChange: DateSelectRangeOnChange
  value: DateSelectRangeValue | undefined
  defaultDateRange?: DateRange
  dateRangeOptions?: ReadonlyArray<DateRangeOption>
} & (
  | {
      isOutsideCustomDateRange?: DateRangePickerShape['isOutsideRange']
      minDate?: undefined
      maxDate?: undefined
    }
  | {
      isOutsideCustomDateRange?: undefined
      minDate?: Moment
      maxDate?: Moment
    }
)

interface UseNormalizedValueProps {
  value: DateSelectRangeValue
  defaultDateRange: DateRange
}

const NULL_CUSTOM_DATES_VALUE = { startDate: null, endDate: null }

const makeDefaultIsOutsideCustomDateRange =
  (minDate?: Moment, maxDate?: Moment) => (date: Moment) => {
    // By default, we allow up to 2 years ago
    const earliestDate = minDate || moment().startOf('day').subtract(2, 'years')

    const latestDate = maxDate || moment().endOf('day')

    return date > latestDate || date < earliestDate
  }

const startEndWithDefaults = (customDates: Partial<StartEndDate>) => {
  // Default custom start date to one week ago
  const startDate = customDates.startDate || moment().startOf('day').subtract(7, 'days')

  // Default custom end date to today
  const endDate = customDates.endDate || moment().endOf('day')

  return {
    startDate,
    endDate,
  }
}

const useNormalizedValue = ({
  value: { dateRange: initialDateRange, customDates: initialCustomDates = {} },
  defaultDateRange,
}: UseNormalizedValueProps): DateSelectRangeValueType => {
  // Default to today as the date range
  const dateRangeValue = useMemo(
    () => initialDateRange || defaultDateRange,
    [defaultDateRange, initialDateRange]
  )

  const customDatesValue = useMemo(() => {
    if (dateRangeValue !== dateRange.CUSTOM) {
      return NULL_CUSTOM_DATES_VALUE
    }

    return startEndWithDefaults(initialCustomDates)
  }, [dateRangeValue, initialCustomDates])

  return {
    dateRange: dateRangeValue,
    customDates: customDatesValue,
  }
}

export const useDateSelectRangeBehaviour = ({
  onChange,
  value: inputValue = {},
  defaultDateRange = dateRange.TODAY,
  dateRangeOptions = DEFAULT_DATE_RANGE_OPTIONS,
  isOutsideCustomDateRange,
  minDate,
  maxDate,
}: UseDateSelectRangeBehaviourProps) => {
  const value = useNormalizedValue({ value: inputValue, defaultDateRange })

  const setDateRange = (nextDateRange: DateRange) => {
    if (nextDateRange !== dateRange.CUSTOM) {
      onChange({
        dateRange: nextDateRange,
        customDates: NULL_CUSTOM_DATES_VALUE,
      })
    } else {
      onChange({
        dateRange: nextDateRange,
        customDates: startEndWithDefaults(value.customDates),
      })
    }
  }

  const setCustomDates = (nextCustomDates: StartEndDate) => {
    onChange({
      dateRange: dateRange.CUSTOM,
      customDates: nextCustomDates,
    })
  }

  const showCustom = useMemo(() => value.dateRange === dateRange.CUSTOM, [value.dateRange])

  const customDateRangeHandler =
    isOutsideCustomDateRange || makeDefaultIsOutsideCustomDateRange(minDate, maxDate)

  return {
    dateRangeOptions,
    showCustom,
    setDateRange,
    setCustomDates,
    dateSelectRangeState: value,
    isOutsideCustomDateRange: customDateRangeHandler,
  }
}
