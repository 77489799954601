import React, { type FunctionComponent, Fragment } from 'react'
import { type DashRouteComponentProps } from '@retailer-platform/dashboard/routing'
import {
  DocumentTitle,
  HeaderPortal,
  SelectionManagerProvider,
} from '@retailer-platform/dashboard/gin-and-tonic'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { AppHeader } from '@retailer-platform/dashboard/legacy'
import { ReportsBreadcrumbs } from '../../components/breadcrumbs/ReportsBreadcrumbs'
import { InstacartManagedReportsList } from '../../components/normalized-reports-list/InstacartManagedReportsList'

interface Props
  extends DashRouteComponentProps<{
    /*
    Add here any properties that you expect to come from the route. For instance,

    id: string
  */
    partner_id: string
    warehouse_id: string
  }> {}

const InstacartManagedReportsListPage: FunctionComponent<React.PropsWithChildren<Props>> = ({
  match,
}) => {
  const partnerId = match?.params?.partner_id
  const retailerId = match?.params?.warehouse_id

  return (
    <>
      <DocumentTitle titleId="reportsDomain.normalizedReports.list.pageTitle" />
      <HeaderPortal headerComponent={<AppHeader title={<ReportsBreadcrumbs />} />} />
      <SelectionManagerProvider>
        <InstacartManagedReportsList partnerId={partnerId} retailerId={retailerId} />
      </SelectionManagerProvider>
    </>
  )
}

export default InstacartManagedReportsListPage
