import React, { type FunctionComponent } from 'react'
import { usePartnerId, useRetailerId } from '../../../utils/routing/params.hooks'
import { url } from '../../../utils/parsing/url'
import { STORE_GROUPS_ADD_URL } from '../../common/routes'
import { RetailerLocationSelectContent } from './RetailerLocationSelectContent'
import { useRetailerLocationSelectData } from './utils/data.hooks'
import { type RetailerLocationSelectOptionStylingProps } from './utils/options.types'
import { type UseRetailerLocationSelectOptionBehaviourProps } from './utils/options.hooks'

interface Props
  extends RetailerLocationSelectOptionStylingProps,
    Pick<UseRetailerLocationSelectOptionBehaviourProps, 'value' | 'onChange'> {}

export const RetailerLocationSelect: FunctionComponent<React.PropsWithChildren<Props>> = props => {
  const { retailerLocations, storeGroups, loading, error } = useRetailerLocationSelectData()

  const partnerId = usePartnerId()
  const retailerId = useRetailerId()

  const addGroupRedirectLink = url(STORE_GROUPS_ADD_URL, {
    partner_id: partnerId,
    warehouse_id: retailerId,
  })

  return (
    <RetailerLocationSelectContent
      {...props}
      addGroupRedirectLink={addGroupRedirectLink}
      loading={loading}
      error={error}
      retailerLocations={retailerLocations}
      storeGroups={storeGroups}
    />
  )
}
