import React, { type FunctionComponent, useMemo, Fragment } from 'react'
import { SVGIcon } from 'ic-snacks'
import styled from '@emotion/styled'
import { colors, borderRadius, Text, spacing } from '@retailer-platform/shared-components'
import LoadingDots from '../../../legacy/components/LoadingDots'
import { ErrorMessage } from '../../common/error-message/ErrorMessage'
import { useDashMessages } from '../../../intl/intl.hooks'
import { useImageUploader } from '../../../exports/gin-and-tonic'
import { type ImageUploaderProps, FileError } from './utils/ImageUploader.types'

const UploadIcon = styled(SVGIcon)({
  marginBottom: 20,
  transform: 'rotate(-90deg)',
  width: 70,
  height: 70,
})

const UploadIconSmall = styled(SVGIcon)({
  marginBottom: 20,
  transform: 'rotate(-90deg)',
})

const FileInput = styled.input({
  position: 'absolute',
  opacity: 0,
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  top: 0,
  left: 0,
})

const Wrapper = styled.div({
  border: `1px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: borderRadius.X4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: spacing.X16,
  width: '100%',
  position: 'relative',
})

const StyledImage = styled.img({
  width: '100%',
})

const RemoveLink = styled.a({
  color: colors.DETRIMENTAL.REGULAR,
  width: '100%',
})

export const ImageUploaderTestIds = {
  image: 'image-uploader-image',
  removeButton: 'image-uploader-remove-button',
  loading: 'image-uploader-loading',
  fileInput: 'image-uploader-file-input',
}

export const ImageUploader: FunctionComponent<
  React.PropsWithChildren<ImageUploaderProps>
> = props => {
  const { url, uploading, uploadError, fileError, uploadImage, clearImage } =
    useImageUploader(props)

  const messages = useDashMessages({
    remove: 'components.imageUploader.remove',
    add: 'components.imageUploader.add',
    uploadError: 'components.imageUploader.uploadError',
    [FileError.InvalidFile]: 'components.imageUploader.fileError.invalidFile',
    [FileError.InvalidDimensions]: 'components.imageUploader.fileError.invalidDimensions',
    fallback: 'components.imageUploader.fileError.invalidFile',
  })

  const testId = props['data-testid']
  const Icon = props.compact ? UploadIconSmall : UploadIcon
  const textSize = props.compact ? 'small' : 'medium'

  const content = useMemo(() => {
    if (uploading) {
      return <LoadingDots testId={ImageUploaderTestIds.loading} />
    }

    if (url) {
      return (
        <Fragment>
          <StyledImage data-testid={ImageUploaderTestIds.image} src={url} />
          <Text size="small" display="inline">
            <RemoveLink data-testid={ImageUploaderTestIds.removeButton} onClick={clearImage}>
              {messages.remove}
            </RemoveLink>
          </Text>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Icon color={colors.GRAYSCALE.X50} name="logout" size="large" />
        <Text weight="semibold" size={textSize}>
          {messages.add}
        </Text>
        {fileError && <ErrorMessage>{messages[fileError] || messages.fallback}</ErrorMessage>}
        {uploadError && <ErrorMessage>{messages.uploadError}</ErrorMessage>}
        <FileInput
          accept="image/png, image/jpeg"
          type="file"
          onChange={e => uploadImage(e.target.files[0])}
          data-testid={testId ?? ImageUploaderTestIds.fileInput}
        />
      </Fragment>
    )
  }, [
    url,
    uploading,
    uploadError,
    fileError,
    uploadImage,
    clearImage,
    messages,
    testId,
    textSize,
    Icon,
  ])

  return <Wrapper>{content}</Wrapper>
}
