import React from 'react'
import { components } from 'react-select'
import { type NoticeProps } from 'react-select/src/components/Menu'
import { useRequiredContext } from '../../../../contexts/utils'
import { DashSelectContext } from '../../utils/DashSelectContext'
import { type BaseOption } from '../../utils/select.types'
import { DashSelectMenuEmptyStateNoOptions } from './components/DashSelectMenuEmptyStateNoOptions'

const { NoOptionsMessage } = components

export const DashSelectMenuEmptyState = <TOption extends BaseOption>(
  props: Omit<NoticeProps<TOption>, 'innerProps'>
) => {
  const { noOptions, toggle } = useRequiredContext(DashSelectContext)

  if (!noOptions) return null

  return (
    <NoOptionsMessage {...props} innerProps={{}}>
      <DashSelectMenuEmptyStateNoOptions
        noOptions={noOptions}
        toggleActive={toggle && toggle.toggleActive}
      />
    </NoOptionsMessage>
  )
}
