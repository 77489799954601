import React, { type ReactElement, useMemo } from 'react'
import { type DashTableDatum, type DashTableFooterProps } from '../../../utils/dashTable.types'
import { Flex, type FlexProps } from '../../../../flex/Flex'
import { DashText } from '../../../../dash-text/DashText'

const flexProps: FlexProps = {
  direction: 'column',
  wrap: 'nowrap',
  align: 'center',
  justify: 'space-around',
  fillWidth: true,
  fillHeight: true,
}

interface Props<TData extends DashTableDatum> extends DashTableFooterProps<TData> {}

export function DashTableFooterPageDetails<TData extends DashTableDatum>({
  loading,
  error,
  totalRecordCount,
  firstVisibleRecordIndex,
  lastVisibleRecordIndex,
  recordCountText,
  tableFooterPageDetails,
}: Props<TData>): ReactElement {
  const textContent = useMemo(() => {
    if (typeof recordCountText === 'function') {
      return recordCountText({ firstVisibleRecordIndex, lastVisibleRecordIndex, totalRecordCount })
    }

    return recordCountText
  }, [firstVisibleRecordIndex, lastVisibleRecordIndex, recordCountText, totalRecordCount])

  const styledTextContent = textContent && (
    <DashText size="small" elementType="div" overrideStyle={{ whiteSpace: 'nowrap' }}>
      {textContent}
    </DashText>
  )

  if (error) {
    return <Flex {...flexProps} />
  }

  return (
    <Flex disabled={loading} {...flexProps}>
      {tableFooterPageDetails ? (
        <DashText size="small" elementType="div" overrideStyle={{ whiteSpace: 'nowrap' }}>
          {tableFooterPageDetails}
        </DashText>
      ) : null}
      {styledTextContent}
    </Flex>
  )
}
