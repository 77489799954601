import React, { type FunctionComponent, useMemo } from 'react'
import styled from '@emotion/styled'
import { DashText } from '../../../../dash-text/DashText'
import Link from '../../../../../components/Link'
import { Flex } from '../../../../flex/Flex'
import { type NoOptionsProps } from '../../../utils/select.types'

const FlexContainer = styled(Flex)`
  min-height: 14px;
`

interface Props {
  noOptions: NoOptionsProps
  toggleActive?: boolean
}

export const DashSelectMenuEmptyStateNoOptions: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ noOptions, toggleActive }) => {
  // We allow the user to pass `toggledMessage` and `toggledLink` if they
  // want to override `message` and `link` when the state is toggled
  const message = useMemo(() => {
    if (toggleActive && noOptions.toggledMessage) {
      return noOptions.toggledMessage
    }

    return noOptions.message
  }, [noOptions, toggleActive])

  const link = useMemo(() => {
    if (toggleActive && noOptions.toggledLink) {
      return noOptions.toggledLink
    }

    return noOptions.link
  }, [noOptions, toggleActive])

  if (!message && !link) return null

  return (
    <FlexContainer direction="column" justify="center" align="center">
      <DashText
        size="small"
        testId="select-menu-empty-message"
        overrideStyle={{ paddingTop: '4px' }}
      >
        {message}
      </DashText>
      {link && (
        <DashText
          size="small"
          weight="semibold"
          overrideStyle={{ marginTop: '10px' }}
          elementType="div"
        >
          <Link
            {...link}
            data-testid="select-menu-empty-cta"
            style={{ paddingTop: '14px', paddingBottom: '14px' }}
          >
            {link.text && link.text}
          </Link>
        </DashText>
      )}
    </FlexContainer>
  )
}
