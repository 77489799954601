import styled from '@emotion/styled'
import {
  FullWidthContent,
  Card,
  Text,
  type GridColumns,
  PaginatedGrid,
  defaultItemsPerPageValues,
  ActionSheetDropdownItem,
  colors,
  Portal,
  useBooleanState,
} from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useCallback, useMemo } from 'react'
import { useQueryParams } from 'use-query-params'
import { ExpandMenuIcon, OptionsIcon, ReceiptIcon } from '@instacart/ids-core'
import {
  ActionSheetDropdown,
  ActionSheetItemIcon,
  SheetDropdown,
  SheetDropdownDisclosureBase,
  IconButton,
} from '@instacart/ids-tooling'
import { type Column } from 'react-table-next'
import { type DomainMessages, useDomainMessages } from '../../utils/domain/intl'
import { SearchTextBar } from '../search-text-bar/SearchTextBar'
import {
  approvalRequestsQueryParamsEncoders,
  useApprovalRequestsSearchPagination,
} from '../../utils/hooks/useApprovalRequestsSearch.hook'
import { ErrorPageAlert } from '../generic-error-page-alert/ErrorPageAlert'
import { type ApiApprovalRequest } from '../../api/common'
import { ApprovalRequestStateBadge } from '../approval-request-state-badge/ApprovalRequestStateBadge'
import { SelectRequestStatus } from '../select-request-status/SelectRequestStatus'
import {
  type ListApprovalRequestsQueryVariables,
  type ApprovalRequestState,
  ReviewAction,
  type AffectingScopeSearchInput,
} from '../../api'
import { type ContentTypes } from '../../integrationConfigurations'
import { SelectIntegration } from '../select-integration/SelectIntegration'
import { useSubmitFeedbackFlow } from '../use-submit-feedback-flow/useSubmitFeedbackFlow.hook'
import { ActivityHistoryModal } from '../activity-history/ActivityHistory'
import { IntegrationConfigProvider } from '../integration-config-provider/IntegrationConfigProvider'
import { getLastUpdatedString } from '../date/date'
import { isApprovalRequestOpen } from '../../utils/domain/approvalRequestUtils'
import { useGoToApprovalRequestDetails } from '../../utils/domain/routing'
import { RefetchListProvider } from './components/refetch-list-provider/RefetchListProvider'

export type SearchApprovalRequestsListProps = {
  onlyForIntegration?: ContentTypes
  customColumns?: GridColumns<ApiApprovalRequest>
  affectingScope?: AffectingScopeSearchInput
}

export const SearchApprovalRequestsList: FunctionComponent<
  React.PropsWithChildren<SearchApprovalRequestsListProps>
> = props => (
  <PageContainer>
    <FiltersContainer {...props} />
    <NoMarginCard>
      <FilteredApprovalRequestList {...props} />
    </NoMarginCard>
  </PageContainer>
)

const FiltersContainer: FunctionComponent<
  React.PropsWithChildren<SearchApprovalRequestsListProps>
> = ({ onlyForIntegration }) => {
  const i18n = useDomainMessages({
    searchPlaceholder: 'approvalManagementLibrary.searchList.searchForName',
  })
  const [queryParams, setQueryParams] = useQueryParams(approvalRequestsQueryParamsEncoders)

  const onSearchUpdate = useCallback(
    (name: string) => {
      setQueryParams({ name })
    },
    [setQueryParams]
  )

  const onStatusUpdate = useCallback(
    (status?: ApprovalRequestState) => {
      setQueryParams({ status })
    },
    [setQueryParams]
  )

  const onToolChange = useCallback(
    (tool?: ContentTypes) => {
      setQueryParams({ tool })
    },
    [setQueryParams]
  )

  return (
    <Row>
      <SearchTextBar
        css={{
          maxWidth: 300,
          height: 40,
          fontSize: 15,
          fontVariationSettings: '"wght" 600,"opsz" 8',
        }}
        placeholder={i18n.searchPlaceholder}
        onChange={onSearchUpdate}
        value={queryParams.name}
      />

      <SelectRequestStatus
        css={{ maxWidth: 200 }}
        value={queryParams.status as ApprovalRequestState}
        onChange={onStatusUpdate}
      />

      {/* Only show the selector if the list is not restricted to a single integration (aka tool) */}
      {!onlyForIntegration && (
        <SelectIntegration
          data-testid="tool-selector"
          css={{ maxWidth: 300 }}
          value={queryParams.tool as ContentTypes}
          onChange={onToolChange}
        />
      )}
    </Row>
  )
}

const FilteredApprovalRequestList: FunctionComponent<
  React.PropsWithChildren<SearchApprovalRequestsListProps>
> = ({ onlyForIntegration, customColumns, affectingScope }) => {
  const variables = useMemo(() => {
    const variables: Partial<ListApprovalRequestsQueryVariables> = {}

    if (onlyForIntegration) {
      variables.contentSearches = [{ equalsContentType: onlyForIntegration }]
    }

    if (affectingScope) {
      variables.affectingScopeSearch = affectingScope
    }

    return variables
  }, [affectingScope, onlyForIntegration])

  const {
    approvalRequests,
    loading,
    error,
    refetch,
    page,
    totalPages,
    setPage,
    itemsPerPage,
    setItemsPerPage,
  } = useApprovalRequestsSearchPagination({ variables })

  const columns = useDefaultGridColumns()
  const i18n = useDomainMessages({
    noRequestsFound: 'approvalManagementLibrary.searchList.noRequestsFound',
  })

  if (error) {
    return <ErrorPageAlert error={error} refetch={refetch} />
  }

  return (
    <RefetchListProvider refetch={refetch}>
      <StyledGridContainer>
        <PaginatedGrid
          // @ts-ignore: columns is not assignable to type GridColumns<GridDatum>
          columns={customColumns ?? columns}
          data={approvalRequests || []}
          compact={true}
          emptyStateMessage={i18n.noRequestsFound}
          emptyStateShowHeader={true}
          isLoading={loading}
          loadingRowsCount={15}
          page={page}
          totalPages={totalPages || Number.POSITIVE_INFINITY}
          onPageChange={setPage}
          itemsPerPage={itemsPerPage}
          itemsPerPageValues={defaultItemsPerPageValues}
          onItemsPerPageChange={setItemsPerPage}
        />
      </StyledGridContainer>
    </RefetchListProvider>
  )
}

export const useBaseGridColumns = (): Record<string, Column<ApiApprovalRequest>> => {
  const i18n = useDomainMessages({
    name: 'approvalManagementLibrary.searchList.name',
    tool: 'approvalManagementLibrary.searchList.tool',
    createdBy: 'approvalManagementLibrary.searchList.createdBy',
    lastUpdated: 'approvalManagementLibrary.searchList.lastUpdated',
    status: 'approvalManagementLibrary.searchList.status',
  })

  return useMemo(
    () => ({
      Name: {
        id: 'name',
        Header: i18n.name,
        width: 120,
        Cell: ({ row }) => {
          const goToPath = useGoToApprovalRequestDetails(
            row.original.id,
            row.original.contentType as ContentTypes
          )

          return (
            <Text variant={goToPath ? 'link' : undefined} size="small" onClick={goToPath}>
              {row.original.name}
            </Text>
          )
        },
      },
      Tool: {
        id: 'tool',
        Header: i18n.tool,
        width: 120,
        Cell: ({ row }) => {
          const key = `approvalManagementLibrary.contentTypes.${row.original.contentType}`
          const i18n = useDomainMessages({
            name: key as DomainMessages,
          })

          // If the i18n key is not found, use the contentType from the API
          const contentType = i18n.name === key ? row.original.contentType : i18n.name
          return <Text size="small">{contentType}</Text>
        },
      },
      CreatedBy: {
        id: 'createdBy',
        Header: i18n.createdBy,
        width: 100,
        Cell: ({ row }) => <Text size="small">{row.original.createdByUser.givenName}</Text>,
      },
      LastUpdated: {
        id: 'lastUpdated',
        Header: i18n.lastUpdated,
        width: 150,
        Cell: ({ row }) => {
          const lastUpdatedString = getLastUpdatedString(row.original)
          return <Text size="small">{lastUpdatedString}</Text>
        },
      },
      Status: {
        id: 'status',
        Header: i18n.status,
        width: 80,
        Cell: ({ row }) => (
          <ApprovalRequestStateBadge
            state={row.original.requestState}
            approvalRequest={row.original}
          />
        ),
      },
      Actions: {
        id: 'actions',
        Header: '',
        width: 40,
        Cell: ({ row }) => <ApprovalRequestActionSheet ar={row.original} />,
      },
    }),
    [i18n]
  )
}

const useDefaultGridColumns = (): GridColumns<ApiApprovalRequest> => {
  const baseColumns = useBaseGridColumns()

  return useMemo(
    () => [
      baseColumns.Name,
      baseColumns.Tool,
      baseColumns.CreatedBy,
      baseColumns.LastUpdated,
      baseColumns.Status,
      baseColumns.Actions,
    ],
    [baseColumns]
  ) as GridColumns<ApiApprovalRequest>
}

export const StyledGridContainer = styled.div({
  height: 'calc(100vh - 200px)',
})

const PageContainer = styled(FullWidthContent)({
  minHeight: '100%',
})

const NoMarginCard = styled(Card)({
  margin: 0,
})

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
})

const ApprovalRequestActionSheet = (props: { ar: ApiApprovalRequest }) => {
  const i18n = useDomainMessages({
    submitFeedback: 'approvalManagementLibrary.actions.submitFeedback',
    viewHistory: 'approvalManagementLibrary.actions.viewHistory',
  })

  const { modal: addReviewModal, openModal: openSubmitFeedbackModal } = useSubmitFeedbackFlow({
    arId: props.ar.id,
  })

  const { modal: closeRequestModal } = useSubmitFeedbackFlow({
    arId: props.ar.id,
    modalProps: {
      defaultAction: ReviewAction.Cancel,
    },
  })

  const [isHistoryModalOpen, openHistoryModal, closeHistoryModal] = useBooleanState()
  const historyModal = (
    <ActivityHistoryModal
      isOpen={isHistoryModalOpen}
      onRequestClose={closeHistoryModal}
      ar={props.ar}
    />
  )

  const isArOpen = isApprovalRequestOpen(props.ar)

  return (
    <IntegrationConfigProvider contentType={props.ar.contentType as ContentTypes}>
      <SheetDropdown>
        <SheetDropdownDisclosureBase css={{ background: 'transparent' }}>
          <IconButton
            css={{
              backgroundColor: colors.GRAYSCALE.X0,
              border: `1px solid ${colors.GRAYSCALE.X30}`,
              height: 22,
            }}
            accessibleLabel="account-options"
            icon={OptionsIcon}
          />
        </SheetDropdownDisclosureBase>
        {addReviewModal}
        {closeRequestModal}
        {historyModal}
        <Portal>
          <ActionSheetDropdown description="Account options" css={{ width: 300 }}>
            {isArOpen && (
              <ActionSheetDropdownItem onClick={openSubmitFeedbackModal}>
                <ActionSheetItemIcon color="systemGrayscale70" icon={ExpandMenuIcon} />
                {i18n.submitFeedback}
              </ActionSheetDropdownItem>
            )}

            <ActionSheetDropdownItem onClick={openHistoryModal}>
              <ActionSheetItemIcon color="systemGrayscale70" icon={ReceiptIcon} />
              {i18n.viewHistory}
            </ActionSheetDropdownItem>
          </ActionSheetDropdown>
        </Portal>
      </SheetDropdown>
    </IntegrationConfigProvider>
  )
}
