import { type CSSProperties } from 'react'
import styled from '@emotion/styled'
import { colors, fontSize, fontWeight, spacing } from '@retailer-platform/shared-components'

export const Metric = styled.div({
  flex: '1',
  marginRight: spacing.X8,
  ':last-of-type': {
    marginRight: 0,
  },
})

export const LabelContainer = styled.div({ display: 'flex', flexDirection: 'row' })

export const Label = styled.div({
  fontSize: fontSize.X31,
  fontWeight: fontWeight.BOLD,
  marginBottom: spacing.X4,
  lineHeight: '40px',
})

interface TrendProps {
  trendColor: CSSProperties['color']
}

export const Trend = styled.div<TrendProps>(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: props.trendColor,
  marginLeft: spacing.X8,
  fontSize: fontSize.X12,
  svg: {
    fill: props.trendColor,
  },
}))

export const TrendDirectionContainer = styled.div({
  marginRight: spacing.X4,
})

export const TrendValue = styled.div({
  fontWeight: fontWeight.SEMIBOLD,
  lineHeight: '18px',
})

export const SubLabelContainer = styled.div({
  display: 'flex',
  flex: '0 1 auto',
  alignItems: 'center',
})

export const SubLabel = styled.span({
  fontSize: fontSize.X12,
  lineHeight: '18px',
  marginRight: spacing.X8,
  color: colors.GRAYSCALE.X70,
})

export const IconWrapper = styled.span({
  ':hover': {
    svg: {
      fill: colors.GRAYSCALE.X30,
    },
  },
})

export const LabelSecondary = styled.span({
  fontSize: fontSize.X23,
  fontWeight: fontWeight.BOLD,
  color: colors.GRAYSCALE.X30,
  lineHeight: fontSize.X23,
})
