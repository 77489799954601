import React, { useMemo } from 'react'
import {
  Button,
  Modal,
  type ModalProps,
  useBooleanState,
  ModalHeader,
  ModalBody,
  FormFieldLabel,
  TextArea,
  spacing,
  ModalFooter,
} from '@retailer-platform/shared-components'
import { type FunctionComponent } from 'react'
import { DividerBase, Text } from '@instacart/ids-customers'
import styled from '@emotion/styled'
import { useDomainMessages } from '../../utils/domain/intl'
import { GenericModal } from '../generic-modal/GenericModal'
import TIME from '../../assets/Time.svg'
import {
  ExtraTextContentGrid,
  type ExtraTextContentGridProps,
} from '../extra-text-content-grid/ExtraTextContentGrid'

export type SubmitResult = 'success' | 'failure'

export interface SubmitForReviewButtonProps {
  onSubmit: (form: { firstComment: string }) => Promise<SubmitResult>
  extraContent?: React.ReactNode
  extraTextContent?: ExtraTextContentGridProps
}

export const SubmitForReviewButton: FunctionComponent<
  React.PropsWithChildren<SubmitForReviewButtonProps>
> = props => {
  const [isModalOpen, setModalOpen, setModalClosed] = useBooleanState()

  const i18n = useDomainMessages({
    submitForReview: 'approvalManagementLibrary.submitForReview',
  })

  return (
    <>
      <StepModal
        isOpen={isModalOpen}
        onClose={setModalClosed}
        onRequestClose={setModalClosed}
        {...props}
      />
      <Button name="submitForReview" onClick={setModalOpen}>
        {i18n.submitForReview}
      </Button>
    </>
  )
}

type ReviewModalProps = { onClose: () => void } & ModalProps

const StepModal: FunctionComponent<
  React.PropsWithChildren<ReviewModalProps & SubmitForReviewButtonProps>
> = props => {
  const [state, setState] = React.useState<'input' | 'submitted'>('input')

  const onSubmitPrivate = useMemo(
    () => async (comment: string) => {
      const result = await props.onSubmit({ firstComment: comment })
      if (result === 'success') {
        setState('submitted')
      } else {
        // user should display their own error modal
        props.onClose()
      }
    },
    [props]
  )

  return state === 'input' ? (
    <SubmitForReviewModal {...props} onSubmit={onSubmitPrivate} />
  ) : (
    <RequestSubmittedSuccessModal {...props} />
  )
}

type SubmitForReviewProps = Omit<SubmitForReviewButtonProps, 'onSubmit'> &
  ReviewModalProps & {
    onSubmit: (comment: string) => void
  }

const SubmitForReviewModal = (props: SubmitForReviewProps) => {
  const i18n = useDomainMessages({
    submitForReview: 'approvalManagementLibrary.submitForReview',
    modalBody: 'approvalManagementLibrary.submitForReviewModal.body',
    leaveAComment: 'approvalManagementLibrary.actions.leaveAComment',
    optional: 'approvalManagementLibrary.general.optional',
    comment: 'approvalManagementLibrary.actions.comment',
    cancel: 'approvalManagementLibrary.actions.cancel',
    submit: 'approvalManagementLibrary.actions.submit',
  })

  const [comment, setComment] = React.useState('')

  const extraContent =
    props.extraContent ||
    (props.extraTextContent && <ExtraTextContentGrid {...props.extraTextContent} />)

  return (
    <Modal {...props} data-testid="review-modal">
      <ModalHeader>{i18n.submitForReview}</ModalHeader>

      {extraContent && (
        <>
          <StyledModalBody>{extraContent}</StyledModalBody>
          <DividerBase />
        </>
      )}

      <StyledModalBody>
        <Text typography="bodyMedium2">{i18n.modalBody}</Text>

        <div>
          <Row>
            <FormFieldLabel>{i18n.leaveAComment}</FormFieldLabel>
            <Text typography="bodyMedium2">{i18n.optional}</Text>
          </Row>
          <TextArea placeholder={i18n.comment} onChange={e => setComment(e.target.value)} />
        </div>
      </StyledModalBody>

      <ModalFooter>
        <Button
          variant="secondary"
          name="cancel"
          data-testid="cancel"
          onClick={props.onRequestClose}
        >
          {i18n.cancel}
        </Button>
        <Button
          variant="primary"
          name="submit"
          data-testid="submit"
          onClick={() => props.onSubmit(comment)}
        >
          {i18n.submit}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const RequestSubmittedSuccessModal = (props: ModalProps) => {
  const i18n = useDomainMessages({
    title: 'approvalManagementLibrary.submittedModal.title',
    bodyHeadline: 'approvalManagementLibrary.submittedModal.bodyHeadline',
    body: 'approvalManagementLibrary.submittedModal.body',
    ok: 'approvalManagementLibrary.submittedModal.ok',
  })

  return <GenericModal {...props} i18n={i18n} image={TIME} />
}

const StyledModalBody = styled(ModalBody)({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X24,
})

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing.X4,
})
