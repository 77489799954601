import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { dashPixels } from '../../dash-blocks/foundation/spacing-units/spacingUnits.types'
import { dashFontSizes } from '../../dash-blocks/foundation/typography'

interface Props {}

const alertStyles = css({
  color: '#181818',
  fontSize: dashFontSizes.MEDIUM,
  lineHeight: 1.5,
  padding: dashPixels.PX12,
  paddingRight: dashPixels.PX16,
  paddingLeft: dashPixels.PX48,
  borderRadius: 2,
  position: 'relative',
  backgroundColor: '#e4f8ff',
  svg: {
    fill: '#007bb6',
  },
})

const iconStyles = css({
  position: 'absolute',
  top: 14,
  left: 16,
  width: 18,
  height: 18,
  svg: {
    width: '100%',
    height: '100%',
  },
})

export const Alert: FunctionComponent<React.PropsWithChildren<Props>> = ({ children, ...rest }) => (
  <div css={alertStyles} role="alert" {...rest}>
    <div css={iconStyles}>
      <svg width="16px" height="16px" viewBox="0 0 24 24">
        <g id="info">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
        </g>
      </svg>
    </div>
    {children}
  </div>
)
