import { useState } from 'react'
import { type CollectionType } from '../types'
import { usePillConfig, type PillValue } from './usePillConfig'

interface UseCollectionPillsOptions {
  collectionTypes: CollectionType[]
  enablePills: boolean
}

export const useCollectionPills = ({ collectionTypes, enablePills }: UseCollectionPillsOptions) => {
  const { PILL_CONFIG, pillOptions } = usePillConfig(collectionTypes, enablePills)

  // State to track selected pills, initially set to 'ALL'
  const [selectedPills, setSelectedPills] = useState<PillValue[]>(['ALL'])

  // Get filtered collection types based on selected pills
  const getCollectionTypes = () => {
    if (!enablePills || selectedPills.includes('ALL')) return collectionTypes
    return [
      ...new Set(
        selectedPills
          .flatMap(pill => PILL_CONFIG[pill].types)
          .filter(type => collectionTypes?.includes(type))
      ),
    ]
  }

  // Toggle pill selection
  const togglePill = (pill: PillValue) => {
    setSelectedPills(prev => {
      if (pill === 'ALL') return ['ALL']
      const newPills = prev.includes(pill)
        ? prev.filter(p => p !== pill)
        : [...prev.filter(p => p !== 'ALL'), pill]
      return newPills.length === 0 ? ['ALL'] : newPills
    })
  }

  // Return hook values and functions
  return { pillOptions, selectedPills, togglePill, getCollectionTypes }
}
