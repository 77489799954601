import React, { type FunctionComponent, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import ReactSelect, { components, type OptionProps, type Styles } from 'react-select'
import { ConfirmIcon } from '@instacart/ids-core'
import { useDashMessage } from '../../../intl/intl.hooks'
import { borderRadius } from '../../foundation/borderRadius'
import { colors } from '../../foundation/colors'
import { fontWeight } from '../../foundation/fontWeight'
import { fontSize } from '../../foundation/fontSize'

interface DayWeekToggleProps {
  isWeek: boolean
  handleToggle: (isWeek: boolean) => void
}

interface ToggleOption {
  label: string
  value: string
}

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${colors.GRAYSCALE.X50};
  font-weight: ${fontWeight.REGULAR};
  font-size: ${fontSize.X15};
`

const SelectedText = styled.div`
  color: ${colors.GRAYSCALE.X70};
  font-weight: ${fontWeight.SEMIBOLD};
  font-size: ${fontSize.X15};
`

const OptionIcon = styled.div`
  margin-left: auto;
`

const selectStyles: Styles = {
  container: styles => ({
    ...styles,
    width: '100px',
    height: '38px',
  }),
  control: (styles, state) => ({
    ...styles,
    height: '38px',
    borderWidth: '2px',
    borderColor: state.isSelected || state.isFocused ? colors.SECONDARY.DARK : colors.GRAYSCALE.X10,
    borderRadius: borderRadius.X8,
    backgroundColor: colors.GRAYSCALE.X10,
    alignContent: 'center',
    lineHeight: '24px',
    fontSize: fontSize.X15,
    fontWeight: fontWeight.SEMIBOLD,
    padding: '5px 0',
    boxShadow: 'none',
    ':hover': {
      borderColor: colors.SECONDARY.DARK,
      boxShadow: 'none',
    },
  }),
  option: styles => ({
    ...styles,
    backgroundColor: colors.GRAYSCALE.X0,
    ':hover': {
      backgroundColor: colors.GRAYSCALE.X20,
    },
  }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  dropdownIndicator: styles => ({ ...styles, color: colors.SECONDARY.DARK }),
}

const Option = (props: OptionProps<ToggleOption>) => {
  const isSelected = props.getValue()[0]?.value === props.data.value

  return (
    <>
      <components.Option {...props}>
        <OptionContainer>
          {isSelected ? (
            <>
              <SelectedText>{props.data.label}</SelectedText>
              <OptionIcon>
                <ConfirmIcon color={'brandPrimaryRegular'} size={fontSize.X15} />
              </OptionIcon>
            </>
          ) : (
            props.data.label
          )}
        </OptionContainer>
      </components.Option>
    </>
  )
}

export const DayWeekToggle: FunctionComponent<React.PropsWithChildren<DayWeekToggleProps>> = ({
  isWeek = false,
  handleToggle,
}) => {
  const dayViewTypeMessage = useDashMessage('components.dayWeekToggle.day')
  const weekViewTypeMessage = useDashMessage('components.dayWeekToggle.week')

  const options: ToggleOption[] = useMemo(
    () => [
      {
        label: dayViewTypeMessage,
        value: 'day',
      },
      {
        label: weekViewTypeMessage,
        value: 'week',
      },
    ],
    [dayViewTypeMessage, weekViewTypeMessage]
  )

  const handleChange = useCallback(
    (option: ToggleOption) => handleToggle(option.value === options[1].value),
    [handleToggle, options]
  )

  return (
    <ReactSelect
      components={{ Option }}
      value={options[isWeek ? 1 : 0]}
      options={options}
      styles={selectStyles}
      onChange={handleChange}
      name="dayWeekToggle"
      isSearchable={false}
      ariaLabel={useDashMessage('components.dayWeekToggle.label')}
    />
  )
}
