import React, { type FunctionComponent } from 'react'
import { spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { useDashMessages } from '../../../../intl/intl.hooks'
import { PerformanceMetric } from './PerformanceMetric'

const PerformanceMetricContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: `${spacing.X24} ${spacing.X16}`,
  flex: 1,
})
export interface AggregateStorePerformanceMetricsProps {
  hoursScheduled?: number
  hoursRecommended?: number
  hoursUnderstaffed?: number
  hoursOverstaffed?: number
  scheduleGaps?: number
  scheduledDevices?: number
  matchRate?: number
}

export const AggregateStorePerformanceMetrics: FunctionComponent<
  React.PropsWithChildren<AggregateStorePerformanceMetricsProps>
> = ({
  hoursScheduled,
  hoursRecommended,
  hoursUnderstaffed,
  hoursOverstaffed,
  scheduleGaps,
  scheduledDevices,
  matchRate,
}) => {
  const messages = useDashMessages({
    totalHours: 'pickingPerformanceOverview.totalHours',
    totalHoursTooltip: 'pickingPerformanceOverview.totalHours.tooltip',

    understaffedHours: 'pickingPerformanceOverview.understaffedHours',
    understaffedHoursTooltip: 'pickingPerformanceOverview.understaffedHours.tooltip',

    scheduleGaps: 'pickingPerformanceOverview.scheduleGaps',
    scheduleGapsTooltip: 'pickingPerformanceOverview.scheduleGaps.tooltip',

    overstaffedHours: 'pickingPerformanceOverview.overstaffedHours',
    overstaffedHoursTooltip: 'pickingPerformanceOverview.overstaffedHours.tooltip',

    scheduledDevices: 'pickingPerformanceOverview.scheduledDevices',
    scheduledDevicesTooltip: 'pickingPerformanceOverview.scheduledDevices.tooltip',

    matchRate: 'pickingPerformanceOverview.matchRate',
    matchRateTooltip: 'pickingPerformanceOverview.matchRate.tooltip',
  })

  return (
    <PerformanceMetricContainer>
      <PerformanceMetric
        metric={hoursScheduled}
        metricDenominator={hoursRecommended}
        title={messages.totalHours}
        tooltip={messages.totalHoursTooltip}
      />
      <PerformanceMetric
        metric={hoursUnderstaffed}
        title={messages.understaffedHours}
        tooltip={messages.understaffedHoursTooltip}
      />
      <PerformanceMetric
        metric={scheduleGaps}
        title={messages.scheduleGaps}
        tooltip={messages.scheduleGapsTooltip}
      />
      <PerformanceMetric
        metric={hoursOverstaffed}
        title={messages.overstaffedHours}
        tooltip={messages.overstaffedHoursTooltip}
      />
      {matchRate !== undefined && (
        <PerformanceMetric
          metric={matchRate}
          metricSymbol={'%'}
          title={messages.matchRate}
          tooltip={messages.matchRateTooltip}
        />
      )}
      <PerformanceMetric
        metric={scheduledDevices}
        title={messages.scheduledDevices}
        tooltip={messages.scheduledDevicesTooltip}
      />
    </PerformanceMetricContainer>
  )
}
