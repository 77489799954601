import React, {
  useMemo,
  isValidElement,
  useCallback,
  type ReactNode,
  type ReactNodeArray,
} from 'react'
import { type ItemInfo } from 'react-tiny-virtual-list'
import { DashSelectFastMenuListOption } from '../components/DashSelectFastMenuListOption'
import { flattenGroupedChildren } from './dashSelectFastMenuList.utils'

interface UseDashSelectFastMenuListBehaviourProps {
  children: ReactNode
  inputValue?: string
  isGroupedList: boolean
  isToggled: boolean
}

interface UseFlattenedRowsProps {
  children: ReactNode
  isGroupedList: boolean
}

interface ScrollToIndexConfig {
  scrollToIndex?: number
}

const useFlattenedRows = ({ children, isGroupedList }: UseFlattenedRowsProps): ReactNodeArray =>
  useMemo(() => {
    if (!Array.isArray(children)) return []

    if (isGroupedList) {
      return flattenGroupedChildren(children)
    }

    return children
  }, [children, isGroupedList])

const useScrollToIndexConfig = (rows: ReactNodeArray): ScrollToIndexConfig =>
  useMemo(() => {
    const result = rows.findIndex(row => {
      if (!isValidElement(row)) return false

      return row.props.isFocused
    })

    if (result === -1) return {}

    return { scrollToIndex: result }
  }, [rows])

export const useDashSelectFastMenuListBehaviour = ({
  children,
  inputValue,
  isGroupedList,
  isToggled,
}: UseDashSelectFastMenuListBehaviourProps) => {
  const rows = useFlattenedRows({ children, isGroupedList })
  const scrollToIndexConfig = useScrollToIndexConfig(rows)

  const renderItem = useCallback(
    ({ index, style }: ItemInfo) => (
      <div key={index} style={style}>
        <DashSelectFastMenuListOption
          row={rows[index]}
          inputValue={inputValue}
          isToggled={isToggled}
        />
      </div>
    ),
    [inputValue, isToggled, rows]
  )

  return {
    itemCount: rows.length,
    scrollToIndexConfig,
    renderItem,
  }
}
