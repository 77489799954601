import React, { useCallback, useEffect, useState, type FunctionComponent } from 'react'
import { NewSelect } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { isEqual } from 'lodash'
import difference from 'lodash/difference'
import { type WarehouseContextValue } from '../../../utils/contexts/warehouse/WarehouseContext'
import { MultiSelectMenu } from './MultiSelectMenu'
import CustomOption from './Option'

export interface Props {
  options: { label: string; value: string; context?: WarehouseContextValue }[]
  selectedOptions: { value: string }[]
  urlRedirectFn: (ids: number[], clearSites: boolean) => void
  defaultText: string
}

const MultiRetailerPickerContainer = styled.div({
  flexDirection: 'column',
  margin: '10px 0px',
  minWidth: '300px',
})

const MultiScopePicker: FunctionComponent<React.PropsWithChildren<Props>> = ({
  options,
  selectedOptions,
  urlRedirectFn,
  defaultText,
}) => {
  const [displayedSelectValues, setDisplayedSelectValues] =
    useState<{ value: string }[]>(selectedOptions)

  const [savedSelectValues, setSavedSelectValues] = useState<{ value: string }[]>(selectedOptions)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    const currentlySelectedIdsSorted = selectedOptions
      ?.map(e => Number(e.value))
      .sort((a, b) => a - b)
    const newlySelectedIdsSorted = savedSelectValues
      ?.map(e => Number(e.value))
      .sort((a, b) => a - b)

    if (
      currentlySelectedIdsSorted &&
      newlySelectedIdsSorted &&
      !isEqual(currentlySelectedIdsSorted, newlySelectedIdsSorted)
    ) {
      urlRedirectFn(
        newlySelectedIdsSorted,
        difference(currentlySelectedIdsSorted, newlySelectedIdsSorted).length > 0
      )
    }
  }, [savedSelectValues, selectedOptions, urlRedirectFn])

  const onMenuClose = () => {
    setMenuIsOpen(false)
  }

  const handleOnSave = useCallback(() => {
    setMenuIsOpen(false)
    setSavedSelectValues(displayedSelectValues)
  }, [displayedSelectValues])

  const handleOnCancel = useCallback(() => {
    setDisplayedSelectValues(selectedOptions)

    setMenuIsOpen(false)
  }, [selectedOptions])

  return (
    <MultiRetailerPickerContainer>
      <NewSelect
        aria-label="multi-scope-picker-label"
        components={{
          Menu: props =>
            MultiSelectMenu({ props, onApply: handleOnSave, onCancel: handleOnCancel }),
          Option: CustomOption,
        }}
        closeMenuOnSelect={false}
        placeholder={defaultText}
        options={options}
        onMenuClose={onMenuClose}
        onMenuOpen={() => setMenuIsOpen(true)}
        menuIsOpen={menuIsOpen}
        value={displayedSelectValues?.map(option => option.value)}
        onChange={(selectedOptions, _selectedOption, meta) => {
          const selectedSimpleOptions = options.filter(option =>
            selectedOptions.includes(option.value)
          )

          setDisplayedSelectValues(selectedSimpleOptions)
        }}
        isMulti
        compactMultiLabel
        styles={{
          menuList: base => ({
            ...base,
            maxHeight: '600px',
          }),
        }}
      />
    </MultiRetailerPickerContainer>
  )
}

export default MultiScopePicker
