import React, { type FunctionComponent } from 'react'
import { Flex, type FlexProps } from '../../dash-blocks/flex/Flex'
import { type FormFieldStateProps } from '../forms/form-field/utils/FormField.types'
import { DateSelectRangeSingleDate } from './components/DashSelectRangeSingleDate'
import { DateSelectRangeCustomDates } from './components/DateSelectRangeCustomDates'
import {
  useDateSelectRangeBehaviour,
  type UseDateSelectRangeBehaviourProps,
} from './utils/dateSelectRange.hooks'
import { type DateSelectRangeStylingProps } from './utils/dateSelectRange.types'

type Props = DateSelectRangeStylingProps &
  UseDateSelectRangeBehaviourProps &
  FlexProps &
  FormFieldStateProps & {
    onBlur?: () => void
  }

export const DateSelectRange: FunctionComponent<React.PropsWithChildren<Props>> = ({
  width,
  hasError,
  onBlur,
  ...rest
}) => {
  const {
    dateRangeOptions,
    showCustom,
    setDateRange,
    setCustomDates,
    dateSelectRangeState,
    isOutsideCustomDateRange,
  } = useDateSelectRangeBehaviour(rest)

  return (
    <Flex fillWidth={false} wrap="nowrap" {...rest} testId="date-select-range">
      <DateSelectRangeSingleDate
        width={width}
        value={dateSelectRangeState.dateRange}
        options={dateRangeOptions}
        onChange={setDateRange}
        hasError={hasError}
        onBlur={onBlur}
      />
      {showCustom && (
        <DateSelectRangeCustomDates
          value={dateSelectRangeState.customDates}
          isOutsideRange={isOutsideCustomDateRange}
          onChange={setCustomDates}
        />
      )}
    </Flex>
  )
}
