const EN_US = {
  'approvalsManagementLibraryDomain.title': 'Approvals Management',
  'approvalsManagementLibraryDomain.listPage.title': 'Search approval requests',

  'approvalManagementLibrary.contentTypes.test_content_type': 'Testing content type',
  'approvalManagementLibrary.contentTypes.data_ingestion_column_maps': 'Data Ingestion Column Maps',
  'approvalManagementLibrary.contentTypes.connect_api_key': 'Connect API key',
  'approvalManagementLibrary.contentTypes.yoda_rules': 'Yoda Rules',
  /**
   * INTEGRATION DEVS: Add your translations here.
   * eg:
   * 'approvalManagementLibrary.contentTypes.<content_type>': '<translation name>',
   */

  'approvalManagementLibrary.actions.comment': 'Comment',
  'approvalManagementLibrary.actions.cancel': 'Cancel',
  'approvalManagementLibrary.actions.submit': 'Submit',
  'approvalManagementLibrary.actions.submitFeedback': 'Submit feedback',
  'approvalManagementLibrary.actions.review': 'Review',
  'approvalManagementLibrary.actions.approve': 'Approve',
  'approvalManagementLibrary.actions.reject': 'Reject',
  'approvalManagementLibrary.actions.leaveAComment': 'Leave a comment',
  'approvalManagementLibrary.actions.viewHistory': 'View history',
  'approvalManagementLibrary.actions.ok': 'Okay',

  'approvalManagementLibrary.general.unexpectedFailure': 'Unexpected failure',
  'approvalManagementLibrary.general.unexpectedFetchError':
    'There was an unexpected error fetching the data here.',
  'approvalManagementLibrary.general.retryOrContactSupport':
    'Please try again or contact support if the issue persists.',
  'approvalManagementLibrary.general.optional': '(optional)',
  'approvalManagementLibrary.general.system': 'System',
  'approvalManagementLibrary.general.readOnlyWarning':
    'Your account is read-only so you cannot perform any actions here.',

  'approvalManagementLibrary.approvalRequestState.APPROVED': 'Approved',
  'approvalManagementLibrary.approvalRequestState.CANCELLED': 'Rejected',
  'approvalManagementLibrary.approvalRequestState.PENDING': 'Review requested',
  'approvalManagementLibrary.approvalRequestState.PUBLISHED': 'Published',
  'approvalManagementLibrary.approvalRequestState.PUBLISHING': 'Publishing',
  'approvalManagementLibrary.approvalRequestState.changesRequested': 'Changes requested', //not a backend state

  'approvalManagementLibrary.reviewAction.REVIEW_REQUESTED': 'Review requested',
  'approvalManagementLibrary.reviewAction.APPROVAL': 'Approved',
  'approvalManagementLibrary.reviewAction.APPROVAL_AND_PUBLISH': 'Approved and published',
  'approvalManagementLibrary.reviewAction.BREAK_GLASS_PUBLISH': 'Break-glass published',
  'approvalManagementLibrary.reviewAction.CANCEL': 'Rejected',
  'approvalManagementLibrary.reviewAction.COMMENT': 'Commented',
  'approvalManagementLibrary.reviewAction.REQUEST_CHANGES': 'Changes requested',
  'approvalManagementLibrary.reviewAction.PUBLISH': 'Published',
  'approvalManagementLibrary.reviewAction.REVISE_CONTENT': 'Revised content',

  'approvalManagementLibrary.submitForReview': 'Submit for review',
  'approvalManagementLibrary.submitForReviewModal.body':
    "Your changes are reviewed before going live. Submit your request, and we'll notify you of the outcome.",
  'approvalManagementLibrary.submittedModal.title': 'Submitted',
  'approvalManagementLibrary.submittedModal.bodyHeadline': 'Your request has been submitted',
  'approvalManagementLibrary.submittedModal.body':
    'We will email the reviewers about your request. You will receive an email once the review process is complete.',
  'approvalManagementLibrary.submittedModal.ok': 'Got it',

  'approvalManagementLibrary.addReviewModal.title': 'Submit feedback',
  'approvalManagementLibrary.addReviewModal.commentDescription': 'Submit general feedback.',
  'approvalManagementLibrary.addReviewModal.approveDescription':
    'Approve this request and unlock publishing option.',
  'approvalManagementLibrary.addReviewModal.approveBreakGlass': 'Publish immediately',
  'approvalManagementLibrary.addReviewModal.approveBreakGlassDescription':
    'Approve this request and publish changes immediately. This bypasses any approval requirements.',
  'approvalManagementLibrary.addReviewModal.approveAndPublish': 'Approve',
  'approvalManagementLibrary.addReviewModal.approveAndPublishDescription': 'Approve this request.',
  'approvalManagementLibrary.addReviewModal.requestChanges': 'Request changes',
  'approvalManagementLibrary.addReviewModal.requestChangesDescription':
    'Request changes before continuing. This will invalidate existing approvals.',
  'approvalManagementLibrary.addReviewModal.rejectDescription':
    'Reject the request. No changes will be applied to the system.',
  'approvalManagementLibrary.addReviewModal.noActionsPermitted':
    'You do not have permission to submit feedback.',
  'approvalManagementLibrary.addReviewModal.userPermittedActions.failedToLoad.bodyHeadline':
    'Failed to load feedback options',
  'approvalManagementLibrary.addReviewModal.userPermittedActions.failedToLoad.body':
    'Please try again or contact support if the issue persists.',

  'approvalManagementLibrary.addReviewModal.success.title': 'Review submitted',
  'approvalManagementLibrary.addReviewModal.success.bodyHeadline':
    'Your review has been submitted successfully.',
  'approvalManagementLibrary.addReviewModal.success.body':
    'We will email the active members about your review.',
  'approvalManagementLibrary.addReviewModal.success.ok': 'Close',

  'approvalManagementLibrary.addReviewModal.failure.title': 'Review submission failed',
  'approvalManagementLibrary.addReviewModal.failure.bodyHeadline':
    'There was an issue submitting your review.',
  'approvalManagementLibrary.addReviewModal.failure.body':
    'Please try again or contact support if the issue persists.',

  'approvalManagementLibrary.searchList.searchForName': 'Search for a name',
  'approvalManagementLibrary.searchList.allTools': 'All tools',
  'approvalManagementLibrary.searchList.allStatuses': 'All statuses',
  'approvalManagementLibrary.searchList.allAuthors': 'All authors',
  'approvalManagementLibrary.searchList.name': 'Name',
  'approvalManagementLibrary.searchList.tool': 'Tool',
  'approvalManagementLibrary.searchList.createdBy': 'Created by',
  'approvalManagementLibrary.searchList.lastUpdated': 'Last updated',
  'approvalManagementLibrary.searchList.status': 'Status',
  'approvalManagementLibrary.searchList.noRequestsFound': 'No requests found',

  'approvalManagementLibrary.viewHistory': 'View history',
  'approvalManagementLibrary.activityHistory.title': 'Activity history',
  'approvalManagementLibrary.activityHistory.actionByUser': '<b>{action}</b> by {user}',

  'approvalManagementLibrary.arDetails.details': 'Details',
  'approvalManagementLibrary.arDetails.name': 'Name',
  'approvalManagementLibrary.arDetails.tool': 'Tool',
  'approvalManagementLibrary.arDetails.createdBy': 'Created by',
  'approvalManagementLibrary.arDetails.lastUpdated': 'Last updated',
  'approvalManagementLibrary.arDetails.comment': 'Comment',
  'approvalManagementLibrary.arDetails.changes': 'Changes',
} as const

export default EN_US
