import { type ReportTypeEnum } from '../../../api'

// config to determine if a report type can be merged
export const REPORT_TYPE_MERGE_CONFIG: Partial<Record<ReportTypeEnum, boolean>> = {
  RETAILER_TAX_LOGS: true,
  TAXES_PAID_AT_POS: true,
  SHOPPER_REIMBURSEMENTS_MONTHLY: true,
}

export const reportTypeCanMerge = (reportType: ReportTypeEnum): boolean =>
  Boolean(REPORT_TYPE_MERGE_CONFIG[reportType])
