import React, {
  type FunctionComponent,
  type ComponentProps,
  type ForwardRefExoticComponent,
  type RefAttributes,
} from 'react'
import styled from '@emotion/styled'
import { RadioSelectorBase } from '@instacart/ids-customers'
import { Text, type TextProps } from '../text/Text'
import { type TestID } from '../../utils/testing/types'
import { colors } from '../../foundation'

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

export interface RadioWithLabelProps
  extends Omit<ComponentProps<typeof RadioSelectorBase>, 'label'>,
    TestID {
  id: string
  label: React.ReactChild
  textSize?: TextProps['size']
}

export const RadioWithLabel: ForwardRefExoticComponent<
  Omit<RadioWithLabelProps, 'ref'> & RefAttributes<HTMLInputElement>
> = React.forwardRef<HTMLInputElement, RadioWithLabelProps>(
  ({ label, id, 'data-testid': testId, textSize = 'medium', disabled, ...props }, ref) => (
    <Wrapper>
      <RadioSelectorBase
        {...props}
        ref={ref}
        id={id}
        data-testid={`${testId}-input`}
        disabled={disabled}
      />
      <label
        css={{ lineHeight: 25, color: disabled ? colors.GRAYSCALE.X50 : 'initial' }}
        data-testid={testId}
        htmlFor={id}
      >
        <Text size={textSize}>{label}</Text>
      </label>
    </Wrapper>
  )
)
