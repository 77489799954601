import React, { type FunctionComponent, type PropsWithChildren } from 'react'
import { Alert } from '@retailer-platform/shared-components'
import { ReviewAction } from '../../api'

export type EventTypes = ReviewAction | 'REVIEW_REQUESTED'

export type ReviewAlertProps = {
  eventType: EventTypes
}

export const ReviewAlert: FunctionComponent<PropsWithChildren<ReviewAlertProps>> = ({
  eventType,
  children,
}) => {
  const variant =
    (eventType === ReviewAction.Approval && 'success') ||
    (eventType === ReviewAction.RequestChanges && 'warning') ||
    (eventType === ReviewAction.Cancel && 'warning') ||
    'info'

  return (
    <Alert variant={variant} showIcon={false}>
      {children}
    </Alert>
  )
}
