import React from 'react'
import styled from '@emotion/styled'
import { useTheme, type Theme } from '@instacart/ids-core'
import { spacing, colors } from '../../foundation'

export interface ContentStylizerProps {
  children: JSX.Element | JSX.Element[]
}

const Root = styled('div')(({ idsTheme }: { idsTheme: Theme }) => ({
  '& h1, h2, h3, h4, h5, h6': {
    marginTop: spacing.X24,
    marginBottom: spacing.X16,
    fontWeight: 600,
    lineHeight: 1.25,
    borderBottom: 'none',

    '&:first-child': {
      marginTop: 0,
    },
  },

  '& p, ul, ol, li': {
    lineHeight: 1.5,
    fontSize: idsTheme.typography.bodyMedium1.fontSize,
  },

  '& h1': {
    fontSize: idsTheme.typography.titleLarge.fontSize,
  },

  '& h2': {
    fontSize: idsTheme.typography.titleMedium.fontSize,
  },

  '& h3': {
    fontSize: idsTheme.typography.bodyLarge1.fontSize,
  },

  '& h4': {
    fontSize: idsTheme.typography.bodyMedium1.fontSize,
  },

  '& h5': {
    fontSize: idsTheme.typography.bodySmall1.fontSize,
  },

  '& h6': {
    fontSize: idsTheme.typography.bodySmall1.fontSize,
  },

  '& b': {
    fontWeight: 600,
  },

  '& ul': {
    paddingLeft: spacing.X20,
  },

  '& li': {
    listStyleType: 'disc',
  },

  '& a': {
    color: colors.PRIMARY.REGULAR,
  },

  '& a:hover': {
    color: colors.PRIMARY.REGULAR,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))

const ContentStylizer = ({ children, ...props }: ContentStylizerProps) => {
  const theme = useTheme()
  return (
    <Root {...props} idsTheme={theme}>
      {children}
    </Root>
  )
}

export default ContentStylizer
