import moment from 'moment'
import { type TimeOfDay } from '@retailer-platform/shared-components/src/common/time-of-day-select'
import {
  type GoogleTypeDate,
  GoogleTypeDayOfWeek,
  type GoogleTypeTimeOfDay,
  type RegularHours,
  type StoreHours,
} from '../api'
import {
  daysOfWeekKeys,
  type FormData,
} from '../components/edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'
import { type OpenMode } from '../components/edit/components/store-open-mode-select/StoreHoursBulkStoreOpenModeSelect'

export const FORM_DEFAULT = {
  delivery: {
    clearOverrideHoursCriteria: [],
    regular: {
      DAY_OF_WEEK_UNSPECIFIED: null,
      MONDAY: { openMode: null, openTime: null, closeTime: null },
      TUESDAY: { openMode: null, openTime: null, closeTime: null },
      WEDNESDAY: { openMode: null, openTime: null, closeTime: null },
      THURSDAY: { openMode: null, openTime: null, closeTime: null },
      FRIDAY: { openMode: null, openTime: null, closeTime: null },
      SATURDAY: { openMode: null, openTime: null, closeTime: null },
      SUNDAY: { openMode: null, openTime: null, closeTime: null },
    },
    special: [],
    regularReplaceHours: false,
    specialReplaceHours: false,
    specialHoursUpdateMode: null,
  },
  // FUTURE DEV: add defaults for other new ServiceTypes in the future here
} as FormData

export const apiDataToFormData = (storeHours: StoreHours): FormData => {
  //create a copy of the form default
  const formData = JSON.parse(JSON.stringify(FORM_DEFAULT))
  // FUTURE DEV: add support for new service types here
  if (storeHours?.regularHours) {
    const storeHoursMap: Map<string, RegularHours> = new Map()
    storeHours.regularHours.forEach(storeHour => {
      storeHoursMap.set(storeHour.dayOfWeek.toString().toUpperCase(), storeHour)
    })

    daysOfWeekKeys.map(day => {
      const mappedDay = GoogleTypeDayOfWeek[day]
      const storeHour = storeHoursMap.get(mappedDay)
      if (storeHour)
        formData.delivery.regular[mappedDay] = apiHoursToFormHoursData(
          storeHour.startTime,
          storeHour.endTime
        )
    })
  }

  if (storeHours?.overrideHours) {
    storeHours.overrideHours.map(storeHour => {
      const mappedStoreHour = apiHoursToFormHoursData(storeHour.startTime, storeHour.endTime)
      formData.delivery.special.push({
        ...mappedStoreHour,
        date: apiDateToMoment(storeHour.date),
        description: storeHour.description,
      })
    })
  }

  return formData
}

// Maps the API data to Form data schema
export const apiHoursToFormHoursData = (
  startTime: Pick<GoogleTypeTimeOfDay, 'hours' | 'minutes'>,
  endTime: Pick<GoogleTypeTimeOfDay, 'hours' | 'minutes'>
) => {
  let openMode: OpenMode
  let openTime: TimeOfDay = null
  let closeTime: TimeOfDay = null
  if (startTime === null || endTime === null) openMode = 'closed'
  else if (
    startTime.hours == null &&
    startTime.minutes == null &&
    endTime.hours == null &&
    endTime.minutes == null
  ) {
    openMode = 'open-24-hours'
    openTime = { hours: null, minutes: null }
    closeTime = { hours: null, minutes: null }
  } else {
    openMode = 'open'
    const openHours = startTime.hours || 0
    let closeHours = endTime.hours || 0
    if (closeHours < openHours) closeHours += 24

    openTime = { hours: openHours, minutes: startTime.minutes || 0 }
    closeTime = { hours: closeHours, minutes: endTime.minutes || 0 }
  }

  return { openMode, openTime, closeTime }
}

export const apiDateToMoment = (date: GoogleTypeDate) => {
  if (!date) return null
  // month-1 because: moment is 0-based. API is 1-based.
  return moment({ year: date.year, month: date.month - 1, day: date.day })
}
