import React from 'react'
import { components } from 'react-select'
import { type ValueContainerProps } from 'react-select/src/components/containers'
import styled from '@emotion/styled'
import { type BaseOption } from '../utils/select.types'
import { useRequiredContext } from '../../../contexts/utils'
import { selectValueContainerTestId } from '../utils/select.utils'
import { DashSelectContext } from '../utils/DashSelectContext'
import { Flex } from '../../flex/Flex'

const { ValueContainer } = components

const ValueWrapper = styled(Flex)`
  cursor: pointer;
`

export const DashSelectValueContainer = <TOption extends BaseOption>(
  props: ValueContainerProps<TOption>
) => {
  const { children } = props
  const { testId } = useRequiredContext(DashSelectContext)

  return (
    <ValueContainer {...props}>
      <ValueWrapper
        testId={selectValueContainerTestId(testId)}
        align="center"
        wrap="nowrap"
        fillWidth
      >
        {children}
      </ValueWrapper>
    </ValueContainer>
  )
}
