/**
 * Note that ordinality here matters, as the enum value will be used
 * to calculate whether a given version is greater than other.
 *
 * Add new versions to the end of the list.
 */
export enum ICPlatformVersion {
  r2023_08_0,
  r2023_09_0,
  r2023_11_0,
  r2023_12_0,
  r2024_01_0,
  r2024_02_0,
  r2024_03_0,
  r2024_04_0,
  r2024_05_0,
  r2024_06_0,
  r2024_07_0,
  r2024_08_0,
  r2024_09_0,
  r2024_10_0,
  r2024_11_0,
  r2024_12_0,
}

/**
 * This is the version that will be used if the version cannot be determined,
 * either by problems with the remote, or any other problem occuring during
 * attempting to resolve the platform version for the user.
 *
 * Keep it up to date from time to time.
 * Note that this is also the default on tests.
 */
export const fallbackICPlatformVersion = ICPlatformVersion.r2024_10_0
