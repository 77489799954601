import React, { useMemo, useCallback } from 'react'
import { type OnBlur } from '../../../utils/react/event.types'
import { DashSelect } from '../dash-select/DashSelect'
import { type DashSelectProps, type SimpleOption } from '../dash-select/utils/select.types'

export interface DashSimpleSelectProps<TValue>
  extends Omit<
    DashSelectProps<SimpleOption<TValue>>,
    'value' | 'defaultValue' | 'onChange' | 'isMulti' | 'options'
  > {
  options: ReadonlyArray<SimpleOption<TValue>>
  value: TValue
  defaultValue?: TValue
  onChange(value: TValue): void
  openMenuOnFocus?: boolean
  onBlur?: OnBlur
}

export const DashSimpleSelect = <TValue extends string | number | null | undefined = string>(
  props: DashSimpleSelectProps<TValue>
) => {
  const { value, onChange, defaultValue, options, ...rest } = props

  const resolvedOption = useMemo(
    () => options.find(option => option.value === value),
    [value, options]
  )

  const resolvedDefaultOption = useMemo(
    () => options.find(option => option.value === defaultValue),
    [defaultValue, options]
  )

  const handleChange = useCallback(
    (option: SimpleOption<TValue>) => {
      onChange((option ? option.value : option) as TValue)
    },
    [onChange]
  )

  return (
    <DashSelect
      {...rest}
      onChange={handleChange}
      options={options}
      value={resolvedOption}
      defaultValue={resolvedDefaultOption}
    />
  )
}
