import React, { type FunctionComponent, useMemo } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { Text } from '@retailer-platform/shared-components'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { type FormDayHours } from '../../../edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'
import { StoreHoursBulkStoreHourItem } from './StoreHoursBulkStoreHourItem'

interface StoreHoursBulkRegularStoreHourItemProps {
  day: string
  storeHour?: FormDayHours
}

const RegularStoreHourItemContainer = styled.div({
  display: 'flex',
  padding: '5px 0',
})

export const StoreHoursBulkRegularStoreHourItem: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkRegularStoreHourItemProps>
> = ({ day, storeHour }) => {
  const i18n = useDomainMessages({
    noHoursSet: 'storeHoursBulkDomain.list.modal.noHoursSet',
  })

  const regularStoreHoursItem = useMemo(
    () => (
      <RegularStoreHourItemContainer>
        <Text css={{ flex: '35' }} weight="bold">
          {moment().day(day).format('dddd')}
        </Text>
        <div css={{ flex: '65' }}>
          {storeHour && (
            <StoreHoursBulkStoreHourItem
              openTime={storeHour.openTime}
              closeTime={storeHour.closeTime}
              openMode={storeHour.openMode}
            />
          )}
          {!storeHour && (
            <Text css={{ opacity: '0.6', fontStyle: 'italic' }}>{i18n.noHoursSet}</Text>
          )}
        </div>
      </RegularStoreHourItemContainer>
    ),
    [i18n, day, storeHour]
  )
  return regularStoreHoursItem
}
