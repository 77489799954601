import React, { useMemo, type CSSProperties } from 'react'
import styled from '@emotion/styled'
import { SVGIcon } from 'ic-snacks'
import { dashColors } from '../../../../../../foundation/colors'
import { type OnClick } from '../../../../../../../../utils/react/event.types'

const PaginationButton = styled.button`
  height: 100%;
  width: 34px;
  color: ${dashColors.PRIMARY};
  cursor: pointer;
  padding: 0;
  border: 1px solid ${dashColors.NEUTRAL};

  &:hover {
    color: ${dashColors.DARK_PRIMARY};
  }

  &:disabled {
    color: ${dashColors.NEUTRAL};
    pointer-events: none;
  }
`

interface Props {
  direction: 'left' | 'right'
  isDisabled: boolean
  onClick: OnClick
}

export const DashTableFooterPaginatorButton = ({ direction, isDisabled, onClick }: Props) => {
  const buttonStyle: CSSProperties = useMemo(() => {
    if (direction === 'left') {
      return {
        paddingTop: 3,
        paddingLeft: 1,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
      }
    }

    return {
      paddingTop: 3,
      paddingRight: 1,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      borderLeftWidth: 0,
    }
  }, [direction])

  const svgIconName = direction === 'left' ? 'arrowLeftSmall' : 'arrowRightSmall'

  return (
    <PaginationButton style={buttonStyle} disabled={isDisabled} onClick={onClick}>
      <SVGIcon name={svgIconName} size="standard" />
    </PaginationButton>
  )
}
