import {
  createSchemaConfig,
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalDimensionsGrouping,
} from '../helpers/schema.helpers'
import { InsightsFormatterType, InsightsGroupByTypes } from '../types/schema.types'
import { Metrics, Dimensions } from './insightsPortalAggAwarenessCampaigns.types'

export { Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.IMPRESSIONS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.impressions',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.impressions',
    },
    [Metrics.ADD_TO_CARTS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.addToCarts',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.addToCarts',
    },
    [Metrics.CLICK_THROUGH_RATE]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.clickThroughRate',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.clickThroughRate',
    },
    [Metrics.MAX_UPDATED_AT_DATE_TIME_UTC]: {
      private: true,
      formatter: InsightsFormatterType.Date,
    },
    [Metrics.SUM_ATTRIBUTED_SALES]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.sumAttributedSales',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.attributedSales',
    },
    [Metrics.SUM_ATTRIBUTED_ERV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.sumAttributedErv',
    },
    [Metrics.SUM_ATTRIBUTED_UNITS_SOLD]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.sumAttributedUnitsSold',
    },
    [Metrics.NUM_DISTINCT_CAMPAIGNS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.numDistinctCampaigns',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.numDistinctCampaigns',
    },
    [Metrics.CLICKS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.clicks',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.clicks',
    },
    [Metrics.USERS_ADDED_TO_CART]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.usersAddedToCart',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.usersAddedToCart',
    },
    [Metrics.AVG_BASKET_SPEND]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.avgBasketSpend',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.avgBasketSpend',
    },
    [Metrics.AVG_UNIT_SPEND]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.avgUnitSpend',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.metrics.tooltip.avgUnitSpend',
    },
    [Metrics.NUM_ORDERS_WITH_ATTRIBUTED_SALES]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.awarenessCampaigns.metrics.numOrdersWithAttributedSales',
    },
  },
  dimensionsConfig: {
    [Dimensions.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.warehouse',
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.warehouseLocation',
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.warehouseLocationCode',
    },
    [Dimensions.WAREHOUSE_LOCATION_REGION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.warehouseLocationRegion',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.warehouseLocationState',
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.insightsPortalOrderSource',
    },
    [Dimensions.DIM_CAMPAIGN_NAME]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.campaignName',
      formatter: InsightsFormatterType.YesOrNo,
      tooltip: 'insightsPortalDomain.awarenessCampaigns.dimensions.tooltip.campaignName',
    },
    [Dimensions.CAMPAIGN]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.awarenessCampaigns.dimensions.campaignName',
    },
    [Dimensions.DIM_CAMPAIGN_START_DATE]: {
      type: InsightsGroupByTypes.Info,
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_START_DATE',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.dimensions.tooltip.campaignStartDate',
    },
    [Dimensions.DIM_CAMPAIGN_END_DATE]: {
      type: InsightsGroupByTypes.Info,
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_END_DATE',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.dimensions.tooltip.campaignEndDate',
    },
    [Dimensions.DIM_CAMPAIGN_STATUS]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignStatus',
      formatter: InsightsFormatterType.YesOrNo,
      tooltip: 'insightsPortalDomain.awarenessCampaigns.dimensions.tooltip.campaignStatus',
    },
    [Dimensions.DIM_USER_SEGMENT]: {
      type: InsightsGroupByTypes.Info,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
      tooltip: 'insightsPortalDomain.awarenessCampaigns.dimensions.tooltip.userSegment',
    },
    [Dimensions.CAMPAIGN_ENDING_SOON]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.campaignEndingSoon.label',
    },
    [Dimensions.DIM_STORE_CONFIGURATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.DIM_STORE_CONFIGURATION_ID',
    },
    ...buildTimeIntervalDimensionsConfig,
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.campaignName',
      dimensions: [Dimensions.DIM_CAMPAIGN_NAME],
    },
  ],
})
