import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { SVGIcon } from 'ic-snacks'
import { type OptionIconName } from '../utils/select.types'
import { type DashColor, dashColorMapping } from '../../foundation/colors'
import { dashFontSizes } from '../../foundation/typography'

interface Props {
  name: OptionIconName
  color?: DashColor
}

const IconContainer = styled.div`
  font-size: ${dashFontSizes.XSMALL};
  min-width: 20px;
  max-width: 20px;
`

export const DashSelectOptionIcon: FunctionComponent<React.PropsWithChildren<Props>> = ({
  name,
  color = 'neutral',
}) => (
  <IconContainer data-testid="select-option-icon">
    <SVGIcon
      name={name}
      color={dashColorMapping[color]}
      style={{ width: '13px', height: '13px' }}
    />
  </IconContainer>
)
