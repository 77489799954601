import React from 'react'
import {
  SheetDropdown,
  ActionSheetDropdown,
  SheetDropdownDisclosureBase,
  IconButton,
} from '@instacart/ids-tooling'
import { ActionSheetDropdownItem } from '@retailer-platform/shared-components'
import { OptionsIcon, HiddenIcon, ShownIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import { useDomainMessages } from '../../../../utils/domain/intl'

interface OptionsDropdownProps {
  showProductWithItems: boolean
  onShowProductWithItemsChange: (value: boolean) => void
}

const OptionContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledIcon = styled.span(() => ({
  marginRight: 8,
}))

const OptionsDropdown: React.FC<OptionsDropdownProps> = ({
  showProductWithItems,
  onShowProductWithItemsChange,
}) => {
  const i18n = useDomainMessages({
    options: 'storefrontDomain.collections-selector.advancedSearch.optionsDropdown.options',
    hide: 'storefrontDomain.collections-selector.advancedSearch.optionsDropdown.hide',
    show: 'storefrontDomain.collections-selector.advancedSearch.optionsDropdown.show',
  })
  return (
    <SheetDropdown>
      <SheetDropdownDisclosureBase css={{ background: 'transparent' }}>
        <IconButton accessibleLabel="account-options" icon={OptionsIcon} />
      </SheetDropdownDisclosureBase>
      <ActionSheetDropdown description={i18n.options} css={{ width: 250, zIndex: 2 }}>
        <ActionSheetDropdownItem
          onClick={() => onShowProductWithItemsChange(!showProductWithItems)}
        >
          {!showProductWithItems ? (
            <OptionContainer>
              <StyledIcon>
                <HiddenIcon />
              </StyledIcon>
              {i18n.hide}
            </OptionContainer>
          ) : (
            <OptionContainer>
              <StyledIcon>
                <ShownIcon />
              </StyledIcon>
              {i18n.show}
            </OptionContainer>
          )}
        </ActionSheetDropdownItem>
      </ActionSheetDropdown>
    </SheetDropdown>
  )
}

export default OptionsDropdown
