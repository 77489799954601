import React, { type FunctionComponent } from 'react'
import {
  type DateSelectRangeStylingProps,
  type DateSelectRangeOption,
} from '../utils/dateSelectRange.types'
import { type DateRange } from '../../../common/utils/time/dateRanges.types'
import { dashPixels } from '../../../dash-blocks/foundation/spacing-units/spacingUnits.types'
import { DashSimpleSelect } from '../../../dash-blocks/dash-simple-select/DashSimpleSelect'
import { type FormFieldStateProps } from '../../forms/form-field/utils/FormField.types'

interface Props extends DateSelectRangeStylingProps, FormFieldStateProps {
  options: ReadonlyArray<DateSelectRangeOption>
  value: DateRange
  onChange: (nextDateRange: DateRange) => void
  onBlur?: () => void
}

export const DateSelectRangeSingleDate: FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  options,
  onChange,
  onBlur,
  width,
  hasError,
}) => (
  <DashSimpleSelect<DateRange>
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    options={options}
    width={width || dashPixels.PX128}
    hasError={hasError}
    testId="date-select-range-single-date"
  />
)
