import { type FunctionComponent, type CSSProperties } from 'react'
import {
  type Column,
  type UseTableInstanceProps,
  type UsePaginationInstanceProps,
  type StringKey,
  type ColumnInstance,
  type Row,
  type Cell,
  type UseSortByColumnProps,
  type HeaderGroup,
} from 'react-table-next'
import { type TestableProps } from '../../../common/utils/testing/testing.types'
import { type SpacingPixelUnits } from '../../foundation/spacing-units/spacingUnits.types'

export type DashTablePageSize = 10 | 25 | 50

export type DashTableIntlProps = {
  defaultErrorMessage: string
  pageSizeText?: string | ((args: { pageSize: number }) => string)
  paginatorText?: string | ((args: { pageIndex: number; totalPageCount?: number }) => string)
  recordCountText?:
    | string
    | ((args: {
        firstVisibleRecordIndex: number
        lastVisibleRecordIndex: number
        totalRecordCount?: number
      }) => string)
}

export type DashTableDatum<TData extends object = object> = TData

export type DashTableData<TData extends DashTableDatum> = DashTableDatum<TData>[]

type CustomDashCellProps = {
  style?: CSSProperties
  cellStyle?: CSSProperties
  headerStyle?: CSSProperties
  align?: 'left' | 'center' | 'right'
  width?: CSSProperties['width']
  paddingLeft?: SpacingPixelUnits
  paddingRight?: SpacingPixelUnits
}

export type DashColumnInstance<TData extends DashTableDatum> = Omit<
  ColumnInstance<TData>,
  'width'
> &
  UseSortByColumnProps<TData> &
  CustomDashCellProps

type DashColumnCellProps<TData extends DashTableDatum> = {
  column: DashColumnInstance<TData>
  row: Row<TData>
  cell: Omit<Cell<TData>, 'value'> & {
    value: TData
  }
}

export type DashCell<TData extends DashTableDatum> = Omit<Cell<TData>, 'column'> & {
  column: DashColumnInstance<TData>
}

export type DashRow<TData extends DashTableDatum> = Omit<Row<TData>, 'cells'> & {
  cells: DashCell<TData>[]
}

export type DashHeaderGroup<TData extends DashTableDatum> = Omit<HeaderGroup<TData>, 'headers'> & {
  headers: DashColumnInstance<TData>[]
}

export type DashTableColumn<TData extends DashTableDatum> = Omit<
  Column<DashTableDatum<TData>>,
  'accessor' | 'Cell' | 'width' | 'show'
> & {
  id: string
  isVisible?: boolean
  accessor?: StringKey<TData>
  Cell?: FunctionComponent<DashColumnCellProps<TData>>
  sortable?: boolean
} & CustomDashCellProps

export type DashTableColumns<TData extends DashTableDatum> = DashTableColumn<TData>[]

export interface DashTableStateSortingRule {
  id: string
  desc?: boolean
}

export type DashTableState = {
  pageSize: DashTablePageSize
  pageIndex: number
  hiddenColumns: string[]
  sortBy: DashTableStateSortingRule[]
}

export type DashTableLoadingErrorProps = {
  loading: boolean
  error?: Error
}

// Props that should be passed down as part of behaviour
export type DashTablePaginationConfigurationProps = {
  totalRecordCount?: number
  firstVisibleRecordIndex: number
  lastVisibleRecordIndex: number
  hidePageSizeSelector?: boolean
  initialPageSize?: DashTablePageSize
}

export type DashTableStyleOverrides = {
  tableWrapperOverrideStyle?: CSSProperties
}

type DashUseTableInstanceProps<TData extends DashTableDatum> = Omit<
  UseTableInstanceProps<TData>,
  'headerGroups'
> & {
  headerGroups: DashHeaderGroup<TData>[]
}
export type DashTableBaseContentProps<TData extends DashTableDatum> =
  DashUseTableInstanceProps<TData> &
    Omit<UsePaginationInstanceProps<TData>, 'page'> & {
      page: DashRow<TData>[]
      state: DashTableState
    } & DashTablePaginationConfigurationProps &
    DashTableLoadingErrorProps &
    DashTableStyleOverrides &
    TestableProps & {
      tableFooterPageDetails?: React.ReactNode
      emptyState?: React.ReactNode
    }

export type DashTableFooterProps<TData extends DashTableDatum> = Pick<
  DashTableBaseContentProps<TData>,
  | 'canPreviousPage'
  | 'canNextPage'
  | 'pageCount'
  | 'gotoPage'
  | 'nextPage'
  | 'previousPage'
  | 'setPageSize'
  | 'loading'
  | 'error'
  | 'totalRecordCount'
  | 'firstVisibleRecordIndex'
  | 'lastVisibleRecordIndex'
  | 'hidePageSizeSelector'
> &
  Pick<DashTableState, 'pageSize' | 'pageIndex'> &
  Omit<DashTableIntlProps, 'defaultErrorMessage'> & {
    tableFooterPageDetails?: React.ReactNode
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DashTableBaseFiltersType = Record<string, any> | undefined

export const DashTableSortingDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const

export type DashTableSortingDirection = ValueOf<typeof DashTableSortingDirections>

export interface FetchDataSortBy {
  id: string
  direction: DashTableSortingDirection
}

export type DashTableFetchDataProps<TFilters extends DashTableBaseFiltersType> = {
  limit: number
  offset: number
  filters: TFilters
  sortBy?: FetchDataSortBy
  signal?: AbortSignal
}

export type DashTableFetchData<TFilters extends DashTableBaseFiltersType = undefined> = (
  props: DashTableFetchDataProps<TFilters>
) => void

export type DashTableManualPaginationProps<TFilters extends DashTableBaseFiltersType> =
  | {
      manualPagination?: false
      allowUnknownRecordCount?: false
      totalRecordCount?: undefined
      fetchData?: undefined
      filters?: undefined
    }
  | ({
      manualPagination: true
      fetchData: DashTableFetchData<TFilters>
      filters: TFilters
    } & (
      | {
          allowUnknownRecordCount?: false
          totalRecordCount: number
        }
      | {
          allowUnknownRecordCount: true
          totalRecordCount?: number
        }
    ))
