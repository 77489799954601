import { useMemo } from 'react'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { CollectionType } from '../types'

export type PillValue = 'ALL' | 'RETAILER_CREATED' | 'DEPARTMENTS' | 'INSTACART_CREATED'

export const usePillConfig = (
  collectionTypes: CollectionType[] | undefined,
  enablePills: boolean
) => {
  const i18n = useDomainMessages({
    all: 'storefrontDomain.all',
    retailerCreated: 'storefrontDomain.collections-selector.retailerOwned',
    departments: 'storefrontDomain.collections-selector.departments',
    instacartCreated: 'storefrontDomain.collections-selector.icOwned',
  })

  const PILL_CONFIG = useMemo(
    () => ({
      ALL: { label: i18n.all, types: [] },
      RETAILER_CREATED: {
        label: i18n.retailerCreated,
        types: [CollectionType.RETAILER_COLLECTION, CollectionType.RETAILER_DEPARTMENT],
      },
      DEPARTMENTS: {
        label: i18n.departments,
        types: [CollectionType.DEPARTMENT, CollectionType.RETAILER_DEPARTMENT],
      },
      INSTACART_CREATED: {
        label: i18n.instacartCreated,
        types: [CollectionType.HUB, CollectionType.HOLIDAY, CollectionType.DEPARTMENT],
      },
    }),
    [i18n]
  )

  const pillOptions = useMemo(() => {
    if (!enablePills) return []

    return Object.entries(PILL_CONFIG)
      .filter(
        ([value, config]) =>
          value === 'ALL' || config.types.some(type => collectionTypes?.includes(type))
      )
      .map(([value, { label }]) => ({ label, value: value as PillValue }))
  }, [collectionTypes, enablePills, PILL_CONFIG])

  return { PILL_CONFIG, pillOptions }
}
