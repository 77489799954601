import React from 'react'
import { components } from 'react-select'
import { type SingleValueProps } from 'react-select/src/components/SingleValue'
import { type BaseOption } from '../utils/select.types'
import { DashText } from '../../dash-text/DashText'
import { useIsSingleValue } from '../utils/select.utils'
import { DASH_SELECT_OPTION_HEIGHT } from '../utils/styles'
import { DashSelectOptionIcon } from './DashSelectOptionIcon'

const { SingleValue } = components

const textOverrideStyling = {
  height: DASH_SELECT_OPTION_HEIGHT,
  display: 'flex',
  alignItems: 'center',
}

interface Props<TOption> extends SingleValueProps<TOption> {
  children: React.ReactNode
}

export const DashSelectSingleValue = <TOption extends BaseOption>(props: Props<TOption>) => {
  const { children } = props

  const singleValue = useIsSingleValue(props)

  return (
    <SingleValue {...props}>
      {singleValue && singleValue.icon && (
        <DashSelectOptionIcon name={singleValue.icon} color={singleValue.iconColor} />
      )}
      <DashText
        size="small"
        color="dark-neutral"
        elementType="span"
        overflowEllipsis
        overrideStyle={textOverrideStyling}
      >
        {children}
      </DashText>
    </SingleValue>
  )
}
