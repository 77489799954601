import React, { type FunctionComponent } from 'react'
import { Breadcrumb, Breadcrumbs } from '@retailer-platform/shared-components'
import { useScopedPathname } from '@retailer-platform/dashboard/routing'
import { useDomainMessage } from '../../utils/domain/intl'

export const ReportsBreadcrumbs: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const reportsPath = useScopedPathname('reports-normalized-reports-list')
  const rootText = useDomainMessage('reportsDomain.breadcrumbs.root')

  return (
    <Breadcrumbs>
      <Breadcrumb path={reportsPath}>{rootText}</Breadcrumb>
      {children}
    </Breadcrumbs>
  )
}
