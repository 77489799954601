/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ApiResponse<T, U = null> {
  data: T
  meta: U
}

export enum AsyncStatus {
  Busy = 'busy',
  Done = 'done',
  Fail = 'fail',
  Initial = 'initial',
}

export enum Role {
  Hacker = 'hacker',
  Admin = 'admin',
  PartnerStaff = 'partner_staff',
  PartnerAdmin = 'partner_admin',
  Partner = 'partner',
}

export enum Permission {
  AdminPanel = 'admin_panel.legacy_permission',
  GlobalAdmin = 'global_admin.view',
  Basic = 'basic.legacy_permission',
  Dashboard = 'dashboard.legacy_permission',
  Catalog = 'catalog.legacy_permission',
  OrdersDelivery = 'orders_delivery.legacy_permission',
  OrdersPickup = 'orders_pickup.legacy_permission',
  OrdersScanAndPay = 'orders_scan_and_pay.view',
  Reports = 'retailer_reports.view',
  Analytics = 'analytics.legacy_permission',
  PickupAnalytics = 'pickup_analytics.legacy_permission',
  Tlogs = 'tlogs.legacy_warehouse_level_permission',
  ManageUsers = 'manage_users.legacy_permission',
  UserActivities = 'user_activities.legacy_warehouse_level_permission',
  SetWarehouses = 'set_warehouses.legacy_permission',
  Stripe = 'stripe.legacy_permission',
  SetPartner = 'set_partner.legacy_permission',
  AlcoholTlogs = 'alcohol_tlogs.legacy_warehouse_level_permission',
  ManageShoppers = 'manage_shoppers.legacy_permission',
  ManageStoreHours = 'manage_store_hours.legacy_permission',
  ManageStoreGroups = 'manage_store_groups.legacy_permission',
  AdminEditBrand = 'admin_edit_brand.legacy_permission',
  AdminAccountManagement = 'admin_account_management.legacy_permission',
  DevPortal = 'dev_portal.view',
  DevPortalAdmin = 'dev_portal_admin.view',
  ReadOnly = 'revoke_writes.legacy_permission',
  StorefrontProPartnerDocs = 'partner_docs.storefront_pro',
  RecipesModify = 'recipes.modify',
  InsightsPortalHomepage = 'insights_portal.homepage',
  Totem = 'totem.view',
  ManageWarehouseSecurity = 'warehouse_security.modify',
}

export interface InventoryArea {
  id: number
  name: string
  region_id: number
  region_name: string
  warehouse_name?: string
  street?: string
}

export interface InventoryFile {
  original_file_name: string
  original_file_url: string
  state: string
  inventory_area_id?: number
  created_at: string
  inventory_area_name: string
}

export interface Partner {
  id: string
  name?: string
  partnerType?: PartnerType
}

export interface Warehouse {
  id: number
  name: string
  slug?: string
  logo?: {
    url: string
  }
  country?: Country
}

export type Country = {
  id: string
  name: string
}

export interface WarehouseLocation {
  id: string
  name?: string | null
  street?: string
  city?: string
  state?: string
  zipCode?: string
  locationCode?: string | null
  pickup: boolean
  pickingLabor: PickingLabor | null
  fulfillmentMethods: FulfillmentMethod[] | null
  partnerAlcoholDelivery: boolean
  configValues?: {
    hybridPickingEnabled?: boolean
  }
  active?: boolean
}

export enum PickingLabor {
  Retailer = 'RETAILER',
  FullService = 'FULL_SERVICE',
  ISS = 'ISS',
}

export enum FulfillmentMethod {
  Retailer = 'RETAILER',
  Locker = 'LOCKER',
  ISS = 'ISS',
}

export interface User {
  email: string
  first_name: string
  last_name: string
  partner: Partner | null
  partner_id: number | null
  permissions: Permission[]
  phone: string | null
  roles: Role[]
  title: string | null
  uuid: string
  warehouse_ids: number[] | null
  warehouse_location_ids: number[] | null
  warehouses: Warehouse[]
}

export interface DropdownOption<TValue = any> {
  label: string
  value: TValue
}

export type KeyValueString = { [key: string]: string }

export interface PartnerScopedRouteParams extends KeyValueString {
  partner_id: string
}

export interface RetailerScopedRouteParams extends PartnerScopedRouteParams {
  warehouse_id: string
}

export interface StoreConfigurationScopedRouteParams extends RetailerScopedRouteParams {
  storeConfigId: string
}

// Legacy interface - should use `RetailerScopedRouteParams`
export interface ScopedRouteParams extends RetailerScopedRouteParams {}

export type ApiDataState<TData extends {}> =
  | ({
      loaded: false
      loading: false
      error: false
    } & { [Key in keyof TData]: undefined })
  | ({
      loaded: false
      loading: true
      error: false
    } & { [Key in keyof TData]: TData[Key] | undefined })
  | ({
      loaded: true
      loading: false
      error: false
    } & TData)
  | ({
      loaded: false
      loading: false
      error: true
    } & { [Key in keyof TData]: undefined })

interface InitialState {
  status: AsyncStatus.Initial
  data: null
}

interface LoadingState {
  status: AsyncStatus.Busy
  data: null
}

type LoadedState<T extends {}> = {
  status: AsyncStatus.Done
  data: T
}

interface ErrorState {
  status: AsyncStatus.Fail
  data: null
  error?: Error
}

export type AsyncDataState<T extends {}> = InitialState | LoadingState | LoadedState<T> | ErrorState

export class DateString extends String {
  constructor(date: Date) {
    super(
      `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}-${
        date.getDate() < 10 ? '0' : ''
      }${date.getDate()}`
    )
  }
}

export enum FeatureEntitlements {
  ScanAndPayRetailers = 'scan_and_pay_retailers',
}

export enum PartnerType {
  Retailer = 'retailer',
  IDP = 'idp',
}
