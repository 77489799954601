import {
  useGetScopedPathnameFactory,
  useGoToPathFactory,
  useScopedPathnameFactory,
} from '@retailer-platform/dashboard/routing'
import { type DomainRouteName } from '../../routing/routes'

export const useDomainScopedPathname = useScopedPathnameFactory<DomainRouteName>()
export const useDomainGoToPath = useGoToPathFactory<DomainRouteName>()
export const useGetScopedPathname = useGetScopedPathnameFactory<DomainRouteName>()
