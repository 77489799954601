import React, { type ReactElement, useMemo, type CSSProperties } from 'react'
import { type DashTableDatum, type DashRow } from '../../../utils/dashTable.types'
import { dashTableRowStyles } from '../../../utils/dashTable.styles'
import { DashTableCell } from './DashTableCell'

interface Props<TData extends DashTableDatum> {
  row: DashRow<TData>
}

export function DashTableRow<TData extends DashTableDatum>({
  row: { cells, getRowProps },
}: Props<TData>): ReactElement {
  // Set a default style if none is provided
  const rowProps = useMemo(() => ({ style: {}, ...getRowProps() }), [getRowProps])

  const style: CSSProperties = {
    ...dashTableRowStyles,
    ...rowProps.style,
  }

  return (
    <tr {...rowProps} style={style}>
      {cells.map(cell => {
        return <DashTableCell key={`${cell.row.index}-${cell.column.id}`} cell={cell} />
      })}
    </tr>
  )
}
