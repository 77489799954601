import React from 'react'
import styled from '@emotion/styled'
import { useDashMessage } from '../../../intl/intl.hooks'

const Container = styled.div`
  position: relative;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
`

const ViewType = styled.span<{ isSelected: boolean }>`
  float: left;
  height: 32px;
  width: 68px;
  border: 2px solid ${({ isSelected }) => (isSelected ? '#343538' : '#FFFFFF')};
  border-radius: 16px;
  background-color: ${({ isSelected }) => (isSelected ? '#E8E9EB' : '#F6F7F8')};
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  text-align: center;
  padding: 5px 0;
`

interface Props {
  isWeeklyView: boolean
  handleDailyWeeklyToggle: (isWeeklyView: boolean) => void
}

export const DailyWeeklyToggle = (props: Props) => {
  const { isWeeklyView, handleDailyWeeklyToggle } = props
  const dayViewTypeMessage = useDashMessage('shiftsDomain.day')
  const weekViewTypeMessage = useDashMessage('shiftsDomain.week')
  return (
    <Container>
      <ViewType
        isSelected={!isWeeklyView}
        onClick={() => handleDailyWeeklyToggle(false)}
        style={{ marginRight: '5px' }}
      >
        {dayViewTypeMessage}
      </ViewType>
      <ViewType isSelected={isWeeklyView} onClick={() => handleDailyWeeklyToggle(true)}>
        {weekViewTypeMessage}
      </ViewType>
    </Container>
  )
}
