import React, { type FunctionComponent } from 'react'
import { Text } from '@retailer-platform/shared-components'
import { type GoogleTypeDate } from '../../../../api'
import { apiDateToMoment } from '../../../../utils/useApiToFormDataMapping.hooks'
import { useDomainMessages } from '../../../../utils/domain/intl'

interface StoreHoursBulkHolidaysDateCellProps {
  date: GoogleTypeDate
}

export const StoreHoursBulkHolidaysDateCell: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkHolidaysDateCellProps>
> = ({ date }) => {
  const i18n = useDomainMessages({
    unknownDate: 'storeHoursBulkDomain.holidays.date.unknown',
  })

  return (
    <Text data-testid="holiday-date-cell" css={{ flex: '25' }}>
      {apiDateToMoment(date)?.format('YYYY/MM/DD') || i18n.unknownDate}
    </Text>
  )
}
