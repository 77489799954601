/* eslint-disable max-lines */
export type SpacingUnits =
  | 0
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 72
  | 80
  | 88
  | 96
  | 104
  | 112
  | 120
  | 128
  | 144
  | 160
  | 176
  | 192
  | 224
  | 256
  | 320
  | 384
  | 448
  | 512
  | 640
  | 768
  | 896
  | 1024
  | 1280
  | 1536
  | 1792
  | 2048

export const dashPixels = {
  PX0: '0px',
  PX4: '4px',
  PX8: '8px',
  PX12: '12px',
  PX16: '16px',
  PX20: '20px',
  PX24: '24px',
  PX28: '28px',
  PX32: '32px',
  PX36: '36px',
  PX40: '40px',
  PX44: '44px',
  PX48: '48px',
  PX52: '52px',
  PX56: '56px',
  PX60: '60px',
  PX64: '64px',
  PX72: '72px',
  PX80: '80px',
  PX88: '88px',
  PX96: '96px',
  PX104: '104px',
  PX112: '112px',
  PX120: '120px',
  PX128: '128px',
  PX144: '144px',
  PX160: '160px',
  PX176: '176px',
  PX192: '192px',
  PX224: '224px',
  PX256: '256px',
  PX320: '320px',
  PX384: '384px',
  PX448: '448px',
  PX512: '512px',
  PX640: '640px',
  PX768: '768px',
  PX896: '896px',
  PX1024: '1024px',
  PX1280: '1280px',
  PX1536: '1536px',
  PX1792: '1792px',
  PX2048: '2048px',
} as const

export type SpacingPixelUnits = ValueOf<typeof dashPixels>
