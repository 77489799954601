import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { PLATFORM_TOTEM_ENTITY } from '../../utils/events/analytics'
import { usePartnerId, useRetailerId, useRetailerIds } from '../../utils/routing/params.hooks'
import { useTotemInfo } from './useTotemInfo'

export const AttachTotemAnalyticsMetadata = () => {
  const totemInfo = useTotemInfo()

  useEffect(() => {
    if (totemInfo?.routePattern) {
      datadogRum.setGlobalContextProperty('totem-entity', totemInfo?.routePattern)
    }

    return () => {
      datadogRum.setGlobalContextProperty('totem-entity', PLATFORM_TOTEM_ENTITY)
    }
  }, [totemInfo])

  return null
}

export const AttachPartnerAnalyticsMetadata = ({
  partnerId: partnerIdArg,
}: {
  partnerId?: string
}) => {
  const partnerIdDefault = usePartnerId({ throwOnMissing: false })
  const partnerId = partnerIdArg ?? partnerIdDefault

  useEffect(() => {
    datadogRum.setGlobalContextProperty('partner-id', partnerId)

    return () => {
      datadogRum.removeGlobalContextProperty('partner-id')
    }
  }, [partnerId])

  return null
}

export const AttachRetailerAnalyticsMetadata = ({
  retailerId: retailerIdArg,
  retailerIds: retailerIdsArg,
}: {
  retailerId?: string
  retailerIds?: string[]
}) => {
  const retailerIdDefault = useRetailerId({ throwOnMissing: false })
  const retailerIdsDefault = useRetailerIds({ throwOnMissing: false })
  const retailerId = retailerIdArg ?? retailerIdDefault
  const retailerIds = retailerIdsArg ?? retailerIdsDefault

  useEffect(() => {
    datadogRum.setGlobalContextProperty('retailer-id', retailerId)
    datadogRum.setGlobalContextProperty('retailer-ids', retailerIds)

    return () => {
      datadogRum.removeGlobalContextProperty('retailer-id')
      datadogRum.removeGlobalContextProperty('retailer-ids')
    }
  }, [retailerIds, retailerId])

  return null
}
