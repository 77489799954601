import React, { type ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'
import { Box } from '../box/Box'
import { Flex } from '../flex/Flex'
import { DashLoadingDots } from '../dash-loading-dots/DashLoadingDots'
import { DashErrorBox } from '../dash-error-box/DashErrorBox'
import {
  type DashTableDatum,
  type DashTableBaseContentProps,
  type DashTableIntlProps,
} from './utils/dashTable.types'
import {
  DASH_TABLE_BORDER_CSS,
  DASH_TABLE_Z_INDEX,
  DASH_TABLE_MINIMUM_WIDTH,
} from './utils/dashTable.styles'
import { DashTableHeader } from './components/header/DashTableHeader'
import { DashTableBody } from './components/body/DashTableBody'
import { DashTableFooter } from './components/footer/DashTableFooter'

export interface DashTableContentProps<TData extends DashTableDatum>
  extends DashTableBaseContentProps<TData>,
    DashTableIntlProps {}

const TableOuterWrapper = styled(Flex)`
  min-width: ${DASH_TABLE_MINIMUM_WIDTH};
`

const TableWrapper = styled(Flex)`
  border: ${DASH_TABLE_BORDER_CSS};
`

const TableInnerWrapper = styled(Box)`
  /* Modifying the total height to help hide an extra 1px of border on the last row */
  margin-bottom: -1px;
  z-index: ${DASH_TABLE_Z_INDEX};
  height: 100%;
  width: 100%;

  /* We need the table to be in display-block mode in order for scrolling/overflow to work properly */
  display: block;
  overflow-y: scroll;
  overflow-x: auto;
`

const StyledTable = styled.table`
  width: 100%;
`

export function DashTableContent<TData extends DashTableDatum>({
  // Required to show/hide the table body
  loading,
  error,
  // Required for DashTableHeader
  headerGroups,
  // Required for DashTableBody
  defaultErrorMessage,
  getTableProps,
  getTableBodyProps,
  prepareRow,
  // Required for DashTableFooter
  hidePageSizeSelector,
  page,
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  firstVisibleRecordIndex,
  lastVisibleRecordIndex,
  totalRecordCount,
  pageSizeText,
  paginatorText,
  recordCountText,
  // Required for styling overrides
  tableWrapperOverrideStyle,
  // Required for DOM testing
  testId,
  state: { pageIndex, pageSize },
  tableFooterPageDetails,
  emptyState,
}: DashTableContentProps<TData>): ReactElement {
  const tableProps = useMemo(() => getTableProps(), [getTableProps])

  const tableContent = useMemo(() => {
    if (error) {
      return <DashErrorBox errorMessage={error.message || defaultErrorMessage} />
    }

    if (loading) {
      return <DashLoadingDots />
    }

    if (page.length === 0 && emptyState) {
      return <div css={{ marginTop: '200px' }}>{emptyState}</div>
    }

    return (
      <StyledTable {...tableProps}>
        <DashTableHeader headerGroups={headerGroups} />
        <DashTableBody getTableBodyProps={getTableBodyProps} prepareRow={prepareRow} page={page} />
      </StyledTable>
    )
  }, [
    defaultErrorMessage,
    error,
    getTableBodyProps,
    headerGroups,
    loading,
    page,
    prepareRow,
    tableProps,
  ])

  return (
    <TableOuterWrapper fillHeight fillWidth wrap="nowrap" testId={testId}>
      <TableWrapper
        fillHeight
        fillWidth
        direction="column"
        wrap="nowrap"
        overrideStyle={tableWrapperOverrideStyle}
      >
        <TableInnerWrapper>{tableContent}</TableInnerWrapper>
        <DashTableFooter
          hidePageSizeSelector={hidePageSizeSelector}
          loading={loading}
          error={error}
          totalRecordCount={totalRecordCount}
          firstVisibleRecordIndex={firstVisibleRecordIndex}
          lastVisibleRecordIndex={lastVisibleRecordIndex}
          recordCountText={recordCountText}
          pageSizeText={pageSizeText}
          paginatorText={paginatorText}
          pageIndex={pageIndex}
          pageSize={pageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          tableFooterPageDetails={tableFooterPageDetails}
        />
      </TableWrapper>
    </TableOuterWrapper>
  )
}
