import React from 'react'
import { components, type MenuListProps } from 'react-select-5'
import styled from '@emotion/styled'
import { Button, spacing } from '@retailer-platform/shared-components'
import { useDomainMessages } from '../../../utils/domain/intl'
import { type PillValue } from './pillFilter/usePillConfig'
import PillFilter from './pillFilter/PillFilter'

interface PillOption {
  label: string
  value: PillValue
}

interface SelectorMenuListProps<Option> extends MenuListProps<Option> {
  pillOptions: PillOption[]
  selectedPills: PillValue[]
  onPillSelect: (pill: PillValue) => void
  onToggleAdvancedSearch: () => void
}

const MenuContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: '600px',
})

const PillFilterContainer = styled.div({
  paddingLeft: spacing.X8,
})

const OptionsSection = styled.div({
  flexGrow: 1,
  overflowY: 'auto',
  margin: `${spacing.X8} 0`,
})

const ModalButtonSection = styled.div({
  borderTop: '1px solid #e0e0e0',
  width: '100%',
  paddingRight: spacing.X16,
})

const FullWidthButton = styled(Button)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SelectorMenuList = <Option,>({
  pillOptions,
  selectedPills,
  onPillSelect,
  children,
  onToggleAdvancedSearch,
  ...menuListProps
}: SelectorMenuListProps<Option>) => {
  const i18n = useDomainMessages({
    openModal: 'storefrontDomain.collections-selector.openModal',
  })

  return (
    <MenuContainer>
      <PillFilterContainer>
        <PillFilter
          pillOptions={pillOptions}
          selectedPills={selectedPills}
          onPillSelect={onPillSelect}
        />
      </PillFilterContainer>
      <components.MenuList {...menuListProps}>
        <OptionsSection>{children}</OptionsSection>
      </components.MenuList>
      <ModalButtonSection>
        <FullWidthButton onClick={onToggleAdvancedSearch} compact variant="tertiary">
          {i18n.openModal}
        </FullWidthButton>
      </ModalButtonSection>
    </MenuContainer>
  )
}

export default SelectorMenuList
