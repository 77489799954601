import memoize from 'lodash/memoize'
import moment from 'moment-timezone'

/**
 * Returns the timezone for the current user
 * @date 9/28/2022 - 12:23:30 PM
 *
 * @returns {string}
 */
export const currentUserTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Returns the current datetime
 * @date 9/28/2022 - 12:23:30 PM
 *
 * @param {boolean} localize whether or not we should localize to current user timezone
 * @returns {moment.Moment} current date time
 */
export const currentTime = (localize: boolean): moment.Moment => {
  if (localize) return moment().tz(currentUserTimezone())
  return moment()
}

/**
 * Creates an ISO 8601 datetime string for a given date
 * @date 2/1/2023 - 7:55:31 AM
 *
 * @param {Date} date
 * @param {?string} [timezone]
 * @returns {(string | null)}
 */
export const dateToMidnightISOString = (date: string, timezone?: string): string | null => {
  if (!date) return null
  return moment
    .tz(date, timezone || currentUserTimezone())
    .startOf('day')
    .toISOString()
}
