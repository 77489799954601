import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { spacing, colors, fontSize, fontWeight } from '@retailer-platform/shared-components'
import { CloseIcon } from '@instacart/ids-core'

const Pill = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: colors.GRAYSCALE.X10,
  color: colors.GRAYSCALE.X70,
  fontSize: fontSize.X15,
  fontWeight: fontWeight.REGULAR,
  padding: `${spacing.X4} ${spacing.X12}`,
  margin: `${spacing.X8} ${spacing.X8} 0 0`,
  lineHeight: '22px',
  borderRadius: '50px',
})

const TextContainer = styled.span({
  maxWidth: '240px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

const IconButton = styled.button({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  padding: `0 0 0 ${spacing.X12}`,
})

interface FilterPillProps {
  name: string
  onRemove: () => void
  removeMessage: string
  id?: string
  customPillStyles?: React.CSSProperties
  customTextContainerStyles?: React.CSSProperties
}

export const FilterPill: FunctionComponent<React.PropsWithChildren<FilterPillProps>> = ({
  name,
  onRemove,
  removeMessage,
  id,
  customPillStyles,
  customTextContainerStyles,
}) => (
  <Pill data-testid={id} style={customPillStyles}>
    <TextContainer style={customTextContainerStyles}>{name}</TextContainer>
    <IconButton onClick={onRemove} aria-label={removeMessage}>
      <CloseIcon size={fontSize.X15} />
    </IconButton>
  </Pill>
)
