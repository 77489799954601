import { useCallback } from 'react'
import {
  useOptionalPartnerContext,
  type WarehouseWithFeatures,
} from '@retailer-platform/dashboard/utils'
import { clientEnv } from '@retailer-platform/dashboard/env'
import { useGetStoreConfigsQuery } from '../../../api'
import {
  useCurrentRetailerScope,
  useCurrentStoreConfigScope,
} from '../../../utils/context/CurrentScopeProvider'

const env = clientEnv.PUBLIC_CLIENT_ENVIRONMENT

const STG_URL = 'https://web-instacart-customers-stg.instacart.team'
const LOCALE_DOMAIN_MAP = {
  CA: 'https://instacart.ca',
  US: 'https://instacart.com',
}

export const useCollectionPreviewLink = () => {
  const { retailerId } = useCurrentRetailerScope()
  const { storeConfigId } = useCurrentStoreConfigScope()

  const partnerContext = useOptionalPartnerContext()
  const warehouse: WarehouseWithFeatures | undefined = partnerContext?.warehouses?.find(
    w => w.id === Number(retailerId)
  )
  const country = warehouse?.country?.id !== '840' ? 'CA' : 'US'

  const { data: storeConfigData } = useGetStoreConfigsQuery({
    variables: {
      retailerIds: [retailerId as string],
    },
    skip: !retailerId,
  })

  const getCollectionPreviewUrl = useCallback(
    (collectionSlug: string) => {
      const getDomainName = () => {
        if (storeConfigId) {
          const storeConfig = storeConfigData?.storeConfigurationsByRetailerIds?.find(
            sc => sc.id === storeConfigId
          )
          return storeConfig?.domainName || ''
        }

        if (['development', 'staging'].includes(env)) {
          return STG_URL
        }

        return LOCALE_DOMAIN_MAP[country as keyof typeof LOCALE_DOMAIN_MAP] ?? LOCALE_DOMAIN_MAP.US
      }
      if (!collectionSlug) {
        console.warn('Collection slug not found')
        return ''
      }

      const domainName = getDomainName()

      return `${domainName}/store/${warehouse?.slug}/collections/${collectionSlug}`
    },
    [country, storeConfigData, storeConfigId, warehouse?.slug]
  )

  const openCollectionPreview = (collectionSlug: string) => {
    const url = getCollectionPreviewUrl(collectionSlug)
    if (url) {
      window.open(url, '_blank')
    }
  }

  return { openCollectionPreview, getCollectionPreviewUrl }
}
