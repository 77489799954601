import { useDashIntl } from '../../../../intl/intl.hooks'

export const useSelectBehaviour = () => {
  const intl = useDashIntl()

  const errorMessage = intl.formatMessage({ id: 'common.error' })
  const loadingMessage = intl.formatMessage({ id: 'common.loadingDots' })

  return {
    errorMessage,
    loadingMessage,
  }
}
