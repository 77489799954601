import React, { useState, useMemo, useEffect } from 'react'
import { NewSelect, spacing } from '@retailer-platform/shared-components'
import { components, type MenuListProps } from 'react-select-5'
import styled from '@emotion/styled'
import { useListMoments } from '../../../utils/useListMoments.hooks'
import { useDomainMessages } from '../../../utils/domain/intl'
import { type OptionData } from './types'
import { useSearchTerm } from './useSearchTerm'
import { getUniqueMoments } from './utils'

export interface MomentSelectorProps extends React.ComponentProps<typeof NewSelect> {
  isDisabled?: boolean
  setMomentId?: (value: string) => void
}

const Container = styled.div({
  '> *': {
    lineHeight: spacing.X24,
  },
})

const MomentSelector = ({ isDisabled = false, ...props }: MomentSelectorProps) => {
  const i18n = useDomainMessages({
    placeholder: 'storefrontDomain.moments-selector.placeholder',
    noOptionsMessage: 'storefrontDomain.collections-selector.advancedSearch.empty',
    typeToSearchMessage: 'storefrontDomain.collections-selector.advancedSearch.typeToSearch',
  })

  // We need to keep track of the selected options so that we can make sure they are always available in the dropdown regardless of the search term
  const [selectedOptionsCache, setSelectedOptionsCache] = useState<OptionData[]>([])

  const { setSearch, getDebouncedSearchTerm, shouldPerformSearch } = useSearchTerm()

  const { response: availableMoments, loading: apiLoading } = useListMoments({
    filters: {
      uiStatus: ['active', 'scheduled'],
    },
    skip: false,
  })

  useEffect(() => {
    // set selected option cache if it's not already set and api result is not empty
    if (selectedOptionsCache.length === 0 && availableMoments && availableMoments.length > 0) {
      const matchingMoment = availableMoments.find(moment => moment.id === props.value)

      if (matchingMoment) {
        setSelectedOptionsCache([
          {
            ...matchingMoment,
            label: matchingMoment.name ?? '',
            value: matchingMoment.id ?? '',
          },
        ])
      }
    }
  }, [availableMoments, props.value, selectedOptionsCache])

  const options: OptionData[] = useMemo(
    () =>
      (availableMoments ?? []).map(moment => ({
        ...moment,
        label: moment.name ?? '',
        value: moment.id ?? '',
      })),
    [availableMoments]
  )

  // Update the options prop in the NewSelect component
  const menuListComponent = useMemo(
    () => (menuListProps: MenuListProps<OptionData>) => <components.MenuList {...menuListProps} />,
    []
  )

  return (
    <Container>
      <NewSelect
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        components={
          {
            Option: components.Option,
            MenuList: menuListComponent,
          } as any
        }
        // Add selected options here so they are always shown in the selected bar
        options={getUniqueMoments([...selectedOptionsCache, ...options])}
        css={{ zIndex: 100 }}
        isLoading={apiLoading}
        onInputChange={(value, meta) => {
          // Don't clear search if a value is being set as it causes the dropdown to close
          if (meta?.action === 'set-value' && !value) return
          return setSearch(value)
        }}
        placeholder={i18n.placeholder}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? i18n.noOptionsMessage : i18n.typeToSearchMessage
        }
        {...props}
        onChange={(value, options) => {
          setSelectedOptionsCache(Array.isArray(options) ? options : [options])
          props.setMomentId(value)
        }}
        isDisabled={isDisabled}
      />
    </Container>
  )
}

export default MomentSelector
