import {
  type RequiredImageDimensions,
  FileError,
  type RequiredImageDimensionsFn,
} from './ImageUploader.types'

const validateImageDimensions = (
  image: HTMLImageElement,
  requiredDimensions: RequiredImageDimensions | RequiredImageDimensionsFn
): boolean => {
  if (typeof requiredDimensions === 'function') {
    return requiredDimensions(image)
  }

  const {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    width: requiredWidth,
    height: requiredHeight,
  } = requiredDimensions
  const { width, height } = image

  return (
    (!requiredWidth || width === requiredWidth) &&
    (!requiredHeight || height === requiredHeight) &&
    (!minWidth || width >= minWidth) &&
    (!maxWidth || width <= maxWidth) &&
    (!minHeight || height >= minHeight) &&
    (!maxHeight || height <= maxHeight)
  )
}

export const validateImage = (
  file: File,
  requiredDimensions: RequiredImageDimensions | RequiredImageDimensionsFn = null
) =>
  new Promise<void>((resolve, reject) => {
    const image = new Image()

    // onload only gets called if image.src is a valid image
    image.onload = () => {
      if (requiredDimensions && !validateImageDimensions(image, requiredDimensions)) {
        reject(FileError.InvalidDimensions)
      }

      resolve()
    }

    image.onerror = () => {
      reject(FileError.InvalidFile)
    }

    image.src = URL.createObjectURL(file)
  })
