import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { type TestableProps } from '../../common/utils/testing/testing.types'
import { dashColors } from '../foundation/colors'
import { Flex } from '../flex/Flex'

const DashLoadingDotsContainer = styled(Flex)`
  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .loading-dots {
    animation: load 1s infinite;
    animation-fill-mode: both;
  }

  .loading-dots:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .loading-dots:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`

interface Props extends TestableProps {}

const NUM_LOADING_DOTS = 3

const svgProps = {
  height: 40,
  width: 40 * NUM_LOADING_DOTS,
  viewBox: '0 0 10 2',
  xmlns: 'http://www.w3.org/2000/svg',
}

const makeCircleProps = (index: number) => ({
  key: index,
  className: 'loading-dots',
  fill: dashColors.DARK_NEUTRAL,
  cx: index * 4 + 1,
  cy: 1,
  r: 1,
})

export const DashLoadingDots: FunctionComponent<React.PropsWithChildren<Props>> = ({ testId }) => (
  <DashLoadingDotsContainer fillWidth fillHeight testId={testId} align="center" justify="center">
    <svg {...svgProps}>
      {Array.from({ length: NUM_LOADING_DOTS }).map((_, idx) => (
        <circle {...makeCircleProps(idx)} />
      ))}
    </svg>
  </DashLoadingDotsContainer>
)
