import React, { type FunctionComponent, type ReactHTML, type CSSProperties } from 'react'
import { type DashColor, dashColorMapping } from '../foundation/colors'
import {
  type DashFontWeight,
  type DashFontSize,
  dashFontWeightMapping,
  dashFontSizeMapping,
} from '../foundation/typography'
import { type OnClick } from '../../../utils/react/event.types'
import { type TestableProps } from '../../common/utils/testing/testing.types'
import { type OverridableStyles } from '../../common/utils/styling/styling.types'
import { resolveDashTextElementType } from './utils/dashText.utils'

export type DashTextProps = TestableProps &
  OverridableStyles & {
    elementType?: keyof ReactHTML
    color?: DashColor
    size?: DashFontSize
    weight?: DashFontWeight
    overflowEllipsis?: boolean
    className?: string
    onClick?: OnClick<HTMLElement>
  }

export const DashText: FunctionComponent<React.PropsWithChildren<DashTextProps>> = ({
  children,
  color,
  elementType,
  size = 'medium',
  weight = 'regular',
  overrideStyle = {},
  overflowEllipsis,
  testId,
  className,
  onClick,
}) => {
  const fontWeight = dashFontWeightMapping[weight]
  const fontSize = dashFontSizeMapping[size]
  const resultingElementType = elementType || resolveDashTextElementType(size)

  const ellipsisStyle: CSSProperties = overflowEllipsis
    ? { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
    : {}

  const style: CSSProperties = {
    color: color ? dashColorMapping[color] : undefined,
    fontWeight,
    fontSize,
    ...ellipsisStyle,
    ...overrideStyle,
  }

  return React.createElement(
    resultingElementType,
    { style, 'data-testid': testId, className, onClick },
    children
  )
}
