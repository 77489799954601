import _ from 'lodash'
import moment from 'moment'
import { type ApiApprovalRequest, type ApiReview } from '../../api/common'

export const formatStringDate = (date: string): string => {
  const mom = moment(date)
  return mom.format('MMMM D YYYY, h:mm A')
}

export const getLastUpdatedString = (ar: ApiApprovalRequest) => {
  const reviews = ar.reviews
  const lastReview = _.last(reviews) as ApiReview | undefined
  const date: string = lastReview?.updatedAt || ar.createdAt
  const lastUpdatedString = moment(date).format('MMMM D YYYY, h:mm A')
  return lastUpdatedString
}
