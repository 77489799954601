import React, { type FunctionComponent } from 'react'
import { Text } from '@instacart/ids-customers'
import { FormattedMessage } from 'react-intl'
import {
  ContentStylizer,
  Modal,
  ModalHeader,
  ModalBody,
  type ModalProps,
  spacing,
  ModalFooter,
  Button,
  ModalContainer,
  Timeline,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import moment from 'moment'
import { type ApiReview, type ApiApprovalRequest } from '../../api/common'
import { type DomainMessages, useDomainMessages } from '../../utils/domain/intl'
import { formatStringDate } from '../date/date'
import { ReviewAlert, type EventTypes } from '../review-alert/ReviewAlert'

export type ActivityHistoryProps = {
  ar: ApiApprovalRequest
}

/**
 * Will sort reviews by date in descending order (new to old).
 */
export const sortReviewsFunc = (a: ApiReview, b: ApiReview) =>
  moment(b.updatedAt).diff(moment(a.updatedAt))

export const ActivityHistory: FunctionComponent<React.PropsWithChildren<ActivityHistoryProps>> = ({
  ar,
}) => (
  <Timeline>
    {ar.reviews.sort(sortReviewsFunc).map(event => (
      <Timeline.Item key={event.id}>
        <Event
          eventType={event.reviewContent.action}
          user={event.reviewedBy?.givenName}
          date={event.updatedAt}
          comment={event.reviewContent.comment}
        />
      </Timeline.Item>
    ))}

    <Timeline.Item>
      <Event eventType="REVIEW_REQUESTED" date={ar.createdAt} user={ar.createdByUser?.givenName} />
    </Timeline.Item>
  </Timeline>
)

export const ActivityHistoryModal: FunctionComponent<
  React.PropsWithChildren<ActivityHistoryProps & ModalProps>
> = ({ ar, ...rest }) => {
  const i18n = useDomainMessages({
    title: 'approvalManagementLibrary.activityHistory.title',
  })

  return (
    <Modal {...rest}>
      <ModalContainer>
        <ModalHeader>{i18n.title}</ModalHeader>
        <ModalBody>
          <ActivityHistory ar={ar} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={rest.onRequestClose}>Close</Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

interface EventProps {
  eventType: EventTypes
  user?: string
  date: string
  comment?: string
}

const Event = ({ eventType, user, date, comment }: EventProps) => {
  const i18n1 = useDomainMessages({
    action: `approvalManagementLibrary.reviewAction.${eventType}` as DomainMessages,
    system: 'approvalManagementLibrary.general.system',
  })

  return (
    <Column>
      <div>
        <ContentStylizer>
          <FormattedMessage
            id="approvalManagementLibrary.activityHistory.actionByUser"
            values={{
              action: i18n1.action,
              user: user || i18n1.system,
              b: (...chunks) => <b>{chunks}</b>,
            }}
          />
        </ContentStylizer>
      </div>
      <div>
        <Text typography="bodySmall2">{formatStringDate(date)}</Text>
      </div>
      {comment && <Comment text={comment} eventType={eventType} />}
    </Column>
  )
}

const Comment = ({ text, eventType }: { text: string; eventType: EventTypes }) => (
  <ReviewAlert eventType={eventType}>
    <Text typography="bodyMedium2">{text}</Text>
  </ReviewAlert>
)

const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X4,
})
