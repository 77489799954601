import React from 'react'
import { spacing, colors } from '../../foundation'

export const FullWidthContent: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, ...props }) => (
  <div css={{ backgroundColor: colors.GRAYSCALE.X10 }} {...props}>
    <div
      css={{
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: spacing.X24,
        gap: spacing.X24,
      }}
    >
      {children}
    </div>
  </div>
)
