const EN_US = {
  // TODO: move to shared component
  'actionBar.default.primaryBtnText': 'Confirm',
  'actionBar.default.cancelBtnText': 'Cancel',

  'onboardingDomain.title': 'Onboarding',
  'onboardingDomain.featureNotReady': 'Sorry, the feature is not fully rolled out yet.',
  'onboardingDomain.notApplicable': 'n/a',
  'onboardingDomain.navItem.storeManagement': 'Store Management',
  'onboardingDomain.navItem.retailerProfile': 'Retailer profile',

  'onboardingDomain.error.common':
    'An error has occurred. Please try again or contact Instacart support.',
  'onboardingDomain.error.featureNotAvailableForBanner':
    'This feature is not available for the selected banner. Please switch to a different banner and try again, or contact Instacart support',
  'onboardingDomain.error.failedToLoadData':
    'Failed to load data. Please try again or contact Instacart support.',
  'onboardingDomain.error.failedToLoadSomeTypeOfData':
    'Failed to load {dataType} data. Please try again or contact Instacart support.',
  'onboardingDomain.error.failedToLoadWarehouseLocation':
    'Failed to load warehouse location data. Please try again or contact Instacart support.',
  'onboardingDomain.error.invalidWarehouseLocationId':
    'Invalid warehouse location ID. Please try again or contact Instacart support.',
  'onboardingDomain.error.invalidAddress':
    'Address is invalid. Please correct your address and try again.',
  'onboardingDomain.error.invalidAddressWithReason':
    'Address is invalid{reason}. Please correct your address or try it with a different format. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.invalidLocationWithReason':
    'Store location is invalid{reason}. Please correct your address or try it with a different format. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.unserviceable':
    "We're sorry, this postal code is not currently within Instacart's serviceable area. Please confirm the postal code has been entered correctly. For further assistance, please contact retailer-onboarding@instacart.com.",
  'onboardingDomain.error.postalCodeNotFound':
    'This postal code is not found. Please confirm the postal code has been entered correctly. For further assistance, please contact retailer-onboarding@instacart.com.',
  'onboardingDomain.error.addressHasBeenTaken':
    'Address has already been taken. Please correct your address or try it with a different format. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.locationCodeHasBeenTaken':
    'Location code has already been taken. Please correct your location code. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.storeNameNotUnique':
    'Store name must be unique. Please update the store name and try again.',
  'onboardingDomain.validation.invalidPostalCode': 'Invalid postal code',
  'onboardingDomain.validation.invalidCertifiedDeliveryThreshold':
    'Invalid certified delivery threshold. Please enter a number.',
  'onboardingDomain.alert.loadingData': 'Loading data...',

  'onboardingDomain.supportMessage':
    'Please reach out to retailer-onboarding@instacart.com if you have questions.',

  'onboardingDomain.progressBar.progressMessage': '{current} of {total} steps completed',
  'onboardingDomain.accountSetup.header': 'Welcome to Instacart Platform Portal',
  'onboardingDomain.accountSetup.header.content': 'Create your Instacart Platform Portal account',
  'onboardingDomain.accountSetup.submit': 'Create account',
  'onboardingDomain.accountSetup.form.fields.partnerName': 'Partner name',
  'onboardingDomain.accountSetup.form.fields.email': 'Email Address',
  'onboardingDomain.accountSetup.form.fields.givenName': 'First name',
  'onboardingDomain.accountSetup.form.fields.surname': 'Last name',
  'onboardingDomain.accountSetup.form.fields.password': 'Password',
  'onboardingDomain.accountSetup.form.fields.password.hint': 'At least 8 characters.',
  'onboardingDomain.accountSetup.form.fields.password.placeholder': 'Set up password',
  'onboardingDomain.accountSetup.form.fields.confirmPassword': 'Confirm password',
  'onboardingDomain.form.error.required': '{fieldName} cannot be blank.',
  'onboardingDomain.form.error.email.pattern': 'Invalid Email',
  'onboardingDomain.form.error.password.minLength': 'Password must be at least 8 characters long',
  'onboardingDomain.form.error.confirmPassword.validate': 'Passwords must match',
  'onboardingDomain.form.error.insecurePassword': 'Please pick a more secure password',
  'onboardingDomain.accountSetup.error.expiredToken':
    'Sorry - your account setup token is expired. Please request a new one!',
  'onboardingDomain.accountSetup.error.notFound':
    'Sorry - your account setup token is invalid. Please contact Instacart support.',
  'onboardingDomain.accountSetup.error.insecurePassword':
    'Password is too easy to guess. Please try a more unique password (at least 8 characters long).',
  'onboardingDomain.accountSetup.error.common':
    'An error has occurred. Please try again or contact Instacart support.',
  'onboardingDomain.accountSetup.error.invalidPartnerIds':
    'Sorry - the partner Id associated with your account is invalid. Please contact Instacart support.',
  'onboardingDomain.accountSetup.errorAction': 'Send Me Another Account Setup Email',
  'onboardingDomain.accountSetup.form.error.insecurePassword': 'Please pick a more secure password',
  'onboardingDomain.accountSetup.form.error.existingEmail':
    'An account with that email already exists',
  'onboardingDomain.requestAccountSetup.form.error.emailNotFound': 'No account matches that email.',
  'onboardingDomain.requestAccountSetup.form.error.accountAlreadySetup':
    'Account is already setup with this email. Please log in instead.',
  'onboardingDomain.requestAccountSetup.form.error.common': 'Account Setup Email Request Failed.',

  'onboardingDomain.accountSetup.form.error.common': 'Sign Up Failed',
  'onboardingDomain.requestAccountSetup.header': 'Request a new link',
  'onboardingDomain.requestAccountSetup.submitBtnText': 'Send me a new link',
  'onboardingDomain.requestAccountSetup.goToLogin': 'Go to log in',
  'onboardingDomain.requestAccountSetup.success': 'New link sent, please check your inbox',
  'onboardingDomain.requestAccountSetup.tokenExpired.header':
    'The link is no longer available or expired',
  'onboardingDomain.requestAccountSetup.tokenExpired.message':
    'You can request a new link to set up your account or contact your Instacart account manager for further support.',

  'onboardingDomain.getStarted.header': 'Get started',
  'onboardingDomain.getStarted.contentHeader': 'Next steps',
  'onboardingDomain.getStarted.contentDescription': 'Estimated time: 10 minutes',
  'onboardingDomain.getStarted.subtitle1': '1. Set up your retailer profile',
  'onboardingDomain.getStarted.content1':
    'Your profile determines how your stores are shown on the Instacart home page.',
  'onboardingDomain.getStarted.content1.list1': 'Add your logo and choose your category',
  'onboardingDomain.getStarted.content1.list2': 'Logo image requirements:',
  'onboardingDomain.getStarted.content1.list2.sublist1': 'Minimum 468 px by 468 px',
  'onboardingDomain.getStarted.content1.list2.sublist2': 'Ratio: 1:1 (square)',
  'onboardingDomain.getStarted.content1.list2.sublist3': 'Filetype: AI, EPS, jpeg, png',
  'onboardingDomain.getStarted.content1.list2.sublist4':
    'Refer to Logo image best practices for more details',
  'onboardingDomain.getStarted.subtitle2': '2. Add your store locations',
  'onboardingDomain.getStarted.content2':
    'Your profile determines how your stores are shown on the Instacart home page.',
  'onboardingDomain.getStarted.content2.list1':
    'Add up to 5 stores to start for Instacart to review',
  'onboardingDomain.getStarted.content2.list2':
    'You can add more stores later once you’re in the Instacart Platform Portal',
  'onboardingDomain.getStarted.content2.list3':
    'Start adding products to your storefront when at least 1 location is approved by Instacart',

  'onboardingDomain.getStarted.submit': 'Get started',
  'onboardingDomain.retailerProfile.header': 'Set up your retailer profile',
  'onboardingDomain.retailerProfile.contentHeader': 'Retailer profile',
  'onboardingDomain.retailerProfile.contentDescription':
    'Your retailer profile is what your storefront is based on.',
  'onboardingDomain.retailerProfile.back': 'Back',
  'onboardingDomain.retailerProfile.continue': 'Continue',
  'onboardingDomain.retailerProfile.infoDemo': 'Where do my customers see this information?',
  'onboardingDomain.retailerProfile.infoDemo.pricingPolicy':
    'Your pricing policy can be viewed when customers click on ‘view pricing policy’ under your logo.',
  'onboardingDomain.retailerProfile.infoDemo.categories.desktop':
    'On desktop, the categories are under the store name when the customers browse all the retailers in their region. On mobile, customers can filter the retailers by categories.',
  'onboardingDomain.retailerProfile.infoDemo.categories.mobile':
    'On mobile app, your category, pricing policy and description shows under your logo when customers tab on your logo.',
  'onboardingDomain.retailerProfile.infoDemo.description':
    'On mobile app, your description shows under your logo when customers tab on your logo.',
  'onboardingDomain.retailerProfile.form.retailerName': 'Retailer name',
  'onboardingDomain.retailerProfile.form.retailerName.hint':
    'This is the name customers will see on Instacart',
  'onboardingDomain.retailerProfile.form.retailerName.placeholder': 'Enter your storefront name',
  'onboardingDomain.retailerProfile.form.businessType': 'Business type',
  'onboardingDomain.retailerProfile.form.businessType.placeholder': 'Select...',
  'onboardingDomain.retailerProfile.form.businessType.hint':
    'This helps categorize your storefront correctly',
  'onboardingDomain.retailerProfile.form.country': 'Country',
  'onboardingDomain.retailerProfile.form.country.placeholder': 'Select...',
  'onboardingDomain.retailerProfile.form.country.hint': 'Tell us where your store is located',
  'onboardingDomain.retailerProfile.form.allowAlcohol': 'Are you selling alcohol on Instacart?',
  'onboardingDomain.retailerProfile.form.allowAlcohol.text':
    'Yes, I’m going to sell alcohol on Instacart',
  'onboardingDomain.retailerProfile.form.allowAlcohol.hint':
    'Selling alcohol on Instacart will require extra steps.',
  'onboardingDomain.retailerProfile.form.logo': 'Logo',
  'onboardingDomain.retailerProfile.form.logo.required': 'Please upload a logo',
  'onboardingDomain.retailerProfile.form.logo.imageBestPractice': 'Logo image best practices.',
  'onboardingDomain.retailerProfile.form.logo.text':
    'A square logo image is required because it is used in many places, such as the Instacart store directory, the storefront, and the page that shows the status of customer orders.',
  'onboardingDomain.retailerProfile.form.logoImage': 'Logo image',
  'onboardingDomain.retailerProfile.form.logoImage.description':
    'Minimum size: 468 pixels by 468 pixels \n' +
    'Ratio: 1:1 (square) \n' +
    'File type: .pngs, .jpegs, .ai, .eps\n' +
    'Maximum file size: 5MB\n' +
    'Contents: Logo/mark only — the name of the store is displayed nearby in addition to the logo in most cases. Extend a solid color background to the edges of the square file.',
  'onboardingDomain.retailerProfile.form.logoBackgroundColor': 'Logo Background Color',
  'onboardingDomain.retailerProfile.form.logoBackgroundColor.hint':
    'In order to maintain consistency, please provide a HEX color code (e.g., #FFFFFF) with your logo to fill the background of the logo container. This color should match the background of your logo. Any asset without a provided HEX code will default to white (#FFFFFFF).',
  'onboardingDomain.addStoreLocations.header': 'Add your store locations',
  'onboardingDomain.addStoreLocations.contentHeader': 'Store locations',
  'onboardingDomain.addStoreLocations.contentDescription':
    'You can add up to 10 store locations for now for Instacart to review and approve. You’ll be able to add more stores on the Instacart Platform Portal.',
  'onboardingDomain.addStoreLocations.back': 'Back',
  'onboardingDomain.addStoreLocations.continue': 'Submit for review',

  'onboardingDomain.addStoreLocations.addStore': 'Add another store',
  'onboardingDomain.addStoreLocations.form.header.new': 'Add New Store',
  'onboardingDomain.addStoreLocations.form.header.edit': 'Edit Store',
  'onboardingDomain.addStoreLocations.form.storeId': 'Store ID',
  'onboardingDomain.addStoreLocations.form.storeId.hint':
    'This is the unique numbering system you use to differentiate your stores. This does not show up to customers or impact the Instacart storefront, but can help with organizing your locations. If you only have one store, feel free to leave this field blank and we will populate this for you. Store ID cannot be edited once the store is fully approved.',
  'onboardingDomain.addStoreLocations.form.storeName': 'Store name',
  'onboardingDomain.addStoreLocations.form.storeName.hint':
    'This is the unique name you use to differentiate your stores. This does not show up to customers or impact the Instacart storefront, but can help with organizing your locations. This store name cannot be the same as another store name that already exists.',
  'onboardingDomain.addStoreLocations.form.address': 'Address',
  'onboardingDomain.addStoreLocations.form.address.line1': 'Address',
  'onboardingDomain.addStoreLocations.form.address.line2': 'Address line 2',
  'onboardingDomain.addStoreLocations.form.streetAddress': 'Street address',
  'onboardingDomain.addStoreLocations.form.apartmentNumber': 'Apartment number',
  'onboardingDomain.addStoreLocations.form.city': 'City',
  'onboardingDomain.addStoreLocations.form.state': 'State',
  'onboardingDomain.addStoreLocations.form.postalCode': 'Postal code/ZIP',
  'onboardingDomain.addStoreLocations.form.submit': 'Save location',

  'onboardingDomain.country.usa': 'USA',
  'onboardingDomain.country.can': 'Canada',

  // TODO: refactor with partner onboarding domain
  'onboardingDomain.addStoreLocations.form.cancel': 'Cancel',
  'onboardingDomain.usa.states.alabama': 'Alabama',
  'onboardingDomain.usa.states.alaska': 'Alaska',
  'onboardingDomain.usa.states.arizona': 'Arizona',
  'onboardingDomain.usa.states.arkansas': 'Arkansas',
  'onboardingDomain.usa.states.california': 'California',
  'onboardingDomain.usa.states.colorado': 'Colorado',
  'onboardingDomain.usa.states.connecticut': 'Connecticut',
  'onboardingDomain.usa.states.delaware': 'Delaware',
  'onboardingDomain.usa.states.districtOfColumbia': 'District Of Columbia',
  'onboardingDomain.usa.states.florida': 'Florida',
  'onboardingDomain.usa.states.georgia': 'Georgia',
  'onboardingDomain.usa.states.guam': 'Guam',
  'onboardingDomain.usa.states.hawaii': 'Hawaii',
  'onboardingDomain.usa.states.idaho': 'Idaho',
  'onboardingDomain.usa.states.illinois': 'Illinois',
  'onboardingDomain.usa.states.indiana': 'Indiana',
  'onboardingDomain.usa.states.iowa': 'Iowa',
  'onboardingDomain.usa.states.kansas': 'Kansas',
  'onboardingDomain.usa.states.kentucky': 'Kentucky',
  'onboardingDomain.usa.states.louisiana': 'Louisiana',
  'onboardingDomain.usa.states.maine': 'Maine',
  'onboardingDomain.usa.states.maryland': 'Maryland',
  'onboardingDomain.usa.states.massachusetts': 'Massachusetts',
  'onboardingDomain.usa.states.michigan': 'Michigan',
  'onboardingDomain.usa.states.minnesota': 'Minnesota',
  'onboardingDomain.usa.states.mississippi': 'Mississippi',
  'onboardingDomain.usa.states.missouri': 'Missouri',
  'onboardingDomain.usa.states.montana': 'Montana',
  'onboardingDomain.usa.states.nebraska': 'Nebraska',
  'onboardingDomain.usa.states.nevada': 'Nevada',
  'onboardingDomain.usa.states.newHampshire': 'New Hampshire',
  'onboardingDomain.usa.states.newJersey': 'New Jersey',
  'onboardingDomain.usa.states.newMexico': 'New Mexico',
  'onboardingDomain.usa.states.newYork': 'New York',
  'onboardingDomain.usa.states.northCarolina': 'North Carolina',
  'onboardingDomain.usa.states.northDakota': 'North Dakota',
  'onboardingDomain.usa.states.ohio': 'Ohio',
  'onboardingDomain.usa.states.oklahoma': 'Oklahoma',
  'onboardingDomain.usa.states.oregon': 'Oregon',
  'onboardingDomain.usa.states.pennsylvania': 'Pennsylvania',
  'onboardingDomain.usa.states.rhodeIsland': 'Rhode Island',
  'onboardingDomain.usa.states.southCarolina': 'South Carolina',
  'onboardingDomain.usa.states.southDakota': 'South Dakota',
  'onboardingDomain.usa.states.tennessee': 'Tennessee',
  'onboardingDomain.usa.states.texas': 'Texas',
  'onboardingDomain.usa.states.utah': 'Utah',
  'onboardingDomain.usa.states.vermont': 'Vermont',
  'onboardingDomain.usa.states.virginia': 'Virginia',
  'onboardingDomain.usa.states.washington': 'Washington',
  'onboardingDomain.usa.states.westVirginia': 'West Virginia',
  'onboardingDomain.usa.states.wisconsin': 'Wisconsin',

  'onboardingDomain.usa.states.wyoming': 'Wyoming',
  'onboardingDomain.canada.states.alberta': 'Alberta',
  'onboardingDomain.canada.states.britishColumbia': 'British Columbia',
  'onboardingDomain.canada.states.manitoba': 'Manitoba',
  'onboardingDomain.canada.states.newBrunswick': 'New Brunswick',
  'onboardingDomain.canada.states.newfoundlandAndLabrador': 'Newfoundland and Labrador',
  'onboardingDomain.canada.states.northwestTerritories': 'Northwest Territories',
  'onboardingDomain.canada.states.novaScotia': 'Nova Scotia',
  'onboardingDomain.canada.states.nunavut': 'Nunavut',
  'onboardingDomain.canada.states.ontario': 'Ontario',
  'onboardingDomain.canada.states.princeEdwardIsland': 'Prince Edward Island',
  'onboardingDomain.canada.states.quebec': 'Quebec',
  'onboardingDomain.canada.states.saskatchewan': 'Saskatchewan',

  'onboardingDomain.canada.states.yukonTerritory': 'Yukon Territory',
  'onboardingDomain.initialStepsCompleted.header': 'Congrats! You’ve completed your inital setup',
  'onboardingDomain.initialStepsCompleted.subtitle': 'Next steps',
  'onboardingDomain.initialStepsCompleted.step1':
    'We’ll review your retailer profile and store locations. This usually takes around 2 business days.',
  'onboardingDomain.initialStepsCompleted.step2':
    'You can continue adding stores on your Instacart Platform Portal. ',

  'onboardingDomain.initialStepsCompleted.step3':
    'Check out our launch checklist to get your stores ready.',
  'onboardingDomain.modal.close': 'Close',

  'onboardingDomain.initialStepsCompleted.modal.confirm': 'View launch checklist',
  'onboardingDomain.launchChecklist': 'Launch Checklist',
  'onboardingDomain.launchChecklist.header': 'Welcome to Instacart',
  'onboardingDomain.launchChecklist.completeRetailerProfile.header': 'Complete retailer profile',
  'onboardingDomain.launchChecklist.completeRetailerProfile.content':
    'Complete your profile before launch date',
  'onboardingDomain.launchChecklist.completeRetailerProfile.incomplete': 'Not completed',
  'onboardingDomain.launchChecklist.addStoreLocations.header': 'Add store locations',
  'onboardingDomain.launchChecklist.addStoreLocations.content':
    'Add stores locations to start adding your products to the catalog',
  'onboardingDomain.launchChecklist.addStoreLocations.complete.single': '1 location added',
  'onboardingDomain.launchChecklist.addStoreLocations.complete.plural': '{count} locations added',
  'onboardingDomain.launchChecklist.setStoreHours.header': 'Complete store setup',
  'onboardingDomain.launchChecklist.setStoreHours.content':
    'Let us know your regular store hours to get ready for launch. You can also edit store detail or add a new store location.',
  'onboardingDomain.launchChecklist.setStoreHours.pendingApproval.single':
    '1 store is still pending approval. You can add store hours once a store is fully approved.',
  'onboardingDomain.launchChecklist.setStoreHours.pendingApproval.plural':
    '{count} stores are still pending approval. You can add store hours once a store is fully approved.',
  'onboardingDomain.launchChecklist.setStoreHours.refresh':
    'The store approval could take some time. Please refresh later to see the latest status.',
  'onboardingDomain.launchChecklist.setStoreHours.complete.single':
    '1 store has completed store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.complete.plural':
    '{count} stores have completed store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.incomplete.single':
    '1 store is fully approved and requires store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.incomplete.plural':
    '{count} stores are fully approved and require store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.additionalStores':
    'There may be additional stores that require action. Please visit the store management page for further details.',
  'onboardingDomain.launchChecklist.addProducts.header':
    'Add products to your catalog (file ingestion required)',
  'onboardingDomain.launchChecklist.addProducts.incomplete': '0 product in your catalog',
  'onboardingDomain.launchChecklist.addProducts.complete': '{totalCount} products in your catalog',
  'onboardingDomain.launchChecklist.addProducts.loading': 'Loading data for catalog',
  'onboardingDomain.launchChecklist.addProducts.content':
    'This is done outside of this portal. \n' +
    'Review our inventory file guidelines and POS integration partners. ',
  'onboardingDomain.launchChecklist.connectStripe.header': 'Connect Stripe',
  'onboardingDomain.launchChecklist.connectStripe.content':
    'Connect Stripe to get your payment system configured.',
  'onboardingDomain.launchChecklist.connectStripe.incomplete': 'Not connected',
  'onboardingDomain.launchChecklist.otherThings': 'Other Things to Help You Succeed',
  'onboardingDomain.launchChecklist.inviteOthers.header': 'Invite team members',
  'onboardingDomain.launchChecklist.inviteOthers.content':
    'Invite your team members to Instacart Portal',
  'onboardingDomain.launchChecklist.optInEmail.header': 'Opt in for email notifications',
  'onboardingDomain.launchChecklist.optInEmail.content':
    'Receive email notifications for system alerts and reportings',
  'onboardingDomain.launchChecklist.readyForLaunch.header1': 'Congrats!',
  'onboardingDomain.launchChecklist.readyForLaunch.header2': 'You are ready for launch.',
  'onboardingDomain.launchChecklist.readyForLaunch.subtitle': 'What’s next?',
  'onboardingDomain.launchChecklist.readyForLaunch.content':
    'Instacart will reach out and schedule your launch with you. In the meantime, you can still make changes.',

  'onboardingDomain.retailerProfileSetting.header': 'Retailer Profile Setting',
  'onboardingDomain.retailerProfileSetting.header.updateProfile': 'Update Profile',
  'onboardingDomain.retailerProfileSetting.basicInformation': 'Basic Information',
  'onboardingDomain.retailerProfileSetting.otherInformation': 'Additional Information',
  'onboardingDomain.retailerProfileSetting.actionBar.primaryBtnText': 'Save',

  'onboardingDomain.retailerProfileSetting.actionBar.info':
    'We will review your change after you save the information, it will take up to 2 business days to approve.',
  'onboardingDomain.retailerProfile.form.categories': 'Categories',
  'onboardingDomain.retailerProfile.form.categories.hint':
    'Are there any other categories that fit your storefront? These will be shown to customers under the store name to share the types of things you offer. Select up to 3 categories. ',
  'onboardingDomain.retailerProfile.form.categories.placeholder': 'Select up to 3 categories...',
  'onboardingDomain.retailerProfile.form.field.error': 'Error occurred for this field',
  'onboardingDomain.retailerProfile.form.description': 'Description',
  'onboardingDomain.retailerProfile.form.description.hint':
    "Please tell us a little about your store or your brand. This description will be shown to customers if they'd like to learn more information about your store. ",
  'onboardingDomain.retailerProfile.form.description.placeholder': 'Retailer description',
  'onboardingDomain.retailerProfile.form.pricingPolicy': 'Pricing Policy',
  'onboardingDomain.retailerProfile.form.pricingPolicyText': 'Pricing Policy Text',
  'onboardingDomain.retailerProfile.form.pricingPolicy.required': 'Please select a pricing policy',
  'onboardingDomain.retailerProfile.form.pricingPolicy.hint':
    'Please select how your prices will be displayed on the Instacart platform. This pricing policy and description help customers understand how prices are set at your store on Instacart. Customers will be able to view this information under the "view pricing policy" section on your storefront.',
  'onboardingDomain.retailerProfile.form.certifiedDeliveryThreshold':
    'Certified Delivery Threshold',
  'onboardingDomain.retailerProfile.form.certifiedDeliveryThreshold.hint':
    'Any order with an item over this amount will require a customer signature at time of delivery',
  'onboardingDomain.retailerProfile.form.certifiedDeliveryThreshold.placeholder': 'Enter an amount',
  'onboardingDomain.retailerProfile.form.alcohol': 'Alcohol',
  'onboardingDomain.retailerProfile.approval.pending':
    'There are changes pending approval. Instacart reviews all changes made to the retailer profile.',

  // category options
  'onboardingDomain.categories.alcohol': 'Alcohol',
  'onboardingDomain.categories.bakery': 'Bakery',
  'onboardingDomain.categories.butcherShop': 'Butcher Shop',
  'onboardingDomain.categories.coOp': 'Co-op',
  'onboardingDomain.categories.drugstore': 'Drugstore',
  'onboardingDomain.categories.ethnic': 'Ethnic',
  'onboardingDomain.categories.farmersMarket': "Farmer's Market",
  'onboardingDomain.categories.groceries': 'Groceries',
  'onboardingDomain.categories.meals': 'Meals',
  'onboardingDomain.categories.organic': 'Organic',
  'onboardingDomain.categories.petSupplies': 'Pet Supplies',
  'onboardingDomain.categories.preparedMeals': 'Prepared Meals',
  'onboardingDomain.categories.specialty': 'Specialty',
  'onboardingDomain.categories.vegetarian': 'Vegetarian',
  'onboardingDomain.categories.wholesale': 'Wholesale',
  'onboardingDomain.categories.backToSchool': 'Back to School',
  'onboardingDomain.categories.books': 'Books',
  'onboardingDomain.categories.stationery': 'Stationery',
  'onboardingDomain.categories.home': 'Home',
  'onboardingDomain.categories.convenience': 'Convenience',
  'onboardingDomain.categories.essentials': 'Essentials',
  'onboardingDomain.categories.snacks': 'Snacks',
  'onboardingDomain.categories.preparedFood': 'Prepared Food',
  'onboardingDomain.categories.supplements': 'Supplements',
  'onboardingDomain.categories.healthWellness': 'Health & Wellness',
  'onboardingDomain.categories.electronics': 'Electronics',
  'onboardingDomain.categories.generalMerchandise': 'General Merchandise',
  'onboardingDomain.categories.beauty': 'Beauty',
  'onboardingDomain.categories.bed': 'Bed',
  'onboardingDomain.categories.extremeValue': 'Extreme Value',
  'onboardingDomain.categories.gamesToys': 'Games & Toys',
  'onboardingDomain.categories.tech': 'Tech',
  'onboardingDomain.categories.homeOffice': 'Home & Office',
  'onboardingDomain.categories.retail': 'Retail',
  'onboardingDomain.categories.lifestyle': 'Lifestyle',
  'onboardingDomain.categories.fashion': 'Fashion',
  'onboardingDomain.categories.sportingGoods': 'Sporting Goods',
  'onboardingDomain.categories.outdoors': 'Outdoors',
  'onboardingDomain.categories.costumeStores': 'Costume Stores',

  // Pricing policy options
  'onboardingDomain.retailerProfile.form.pricingPolicy.higher': 'Higher than in-store',
  'onboardingDomain.retailerProfile.form.pricingPolicy.higher.hint':
    'Select this option if a blanket markup amount is being applied to all item prices.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.higher.defaultText':
    '{retailerName} sets the price of items on the Instacart marketplace. Item prices for this retailer are higher than in-store prices in your area to cover the cost of personal shopping. Items on sale in the store may not be on sale through the Instacart platform. Prices and availability are based on available data feeds, subject to delays or errors and may change without notice.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy': 'Other',
  'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy.hint':
    'Select this option if you are applying a markup, but only on specific items.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy.defaultText':
    '{retailerName} sets the price of items on the Instacart marketplace. Item prices may vary from in-store prices in your area. Prices may be higher than in-store prices to cover the cost of personal shopping.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices':
    'Everyday Store Prices',
  'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices.hint':
    'Select this option if your non-sale item prices are the same as in-store and no markup is being applied.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices.defaultText':
    'Item prices for {retailerName} reflect the average in-store prices in your area. Items on sale in the store may not be on sale through the Instacart platform. Prices and availability are based on available data feeds, subject to delays or errors and may change without notice. As a result, the in-store price of a particular item at the time a purchase is made, including for weighted items, may differ from what is available through the Instacart platform.',

  'onboardingDomain.component.imageUploader.add': 'add image',
  'onboardingDomain.component.imageUploader.remove': 'remove image',
  'onboardingDomain.component.imageUploader.uploadError': 'Image upload error, please try again',
  'onboardingDomain.component.imageUploader.validation.invalidFile':
    'Invalid file, please try again',
  'onboardingDomain.component.imageUploader.validation.invalidFileType': 'Invalid file type',
  'onboardingDomain.component.imageUploader.validation.sizeExceedsLimit':
    'File size exceeds maximum limit of 5MB',
  'onboardingDomain.component.imageUploader.validation.invalidDimensions':
    'Minimum image size: 468 pixels by 468 pixels',
  'onboardingDomain.component.imageUploader.validation.notASquare':
    'Image ratio must be 1:1 (square)',

  'onboardingDomain.storeManagement.header': 'Store Management',
  'onboardingDomain.storeManagement.header.addStore': 'Add New Store Location',
  'onboardingDomain.storeManagement.header.editStore': 'Edit Information for',
  'onboardingDomain.storeManagement.requestNewStore': 'Request New Store',
  'onboardingDomain.storeManagement.storeId': 'Store ID',
  'onboardingDomain.storeManagement.storeName': 'Store Name',
  'onboardingDomain.storeManagement.storeAddress': 'Store Address',
  'onboardingDomain.storeManagement.status': 'Status',
  'onboardingDomain.storeManagement.lastUpdated': 'Last Updated',
  'onboardingDomain.storeManagement.table.error.failToLoad': 'Fail to load data',
  'onboardingDomain.storeManagement.form.header': 'Store Information',
  'onboardingDomain.storeManagement.form.actionBar.primaryBtnText': 'Save',
  'onboardingDomain.storeManagement.form.actionBar.info':
    'We will review the detail and approve the information within 2 business days.',

  'onboardingDomain.storeDetail.header': 'Store Detail',
  'onboardingDomain.storeDetail.editStoreDetail': 'Edit Store Detail',
  'onboardingDomain.storeDetail.storeHours.header': 'Store Hours',
  'onboardingDomain.storeDetail.storeHours.edit': 'Edit Store Hours',
  'onboardingDomain.storeDetail.storeHours.add': 'Add Store Hours',
  'onboardingDomain.storeDetail.storeHours.pendingApproval':
    'You can add store hours once this store is fully approved.',
  'onboardingDomain.storeDetail.storeHours.refresh':
    'The store approval could take some time. Please refresh later to see the store hours.',
  'onboardingDomain.storeDetail.approval.pending':
    'There are changes pending approval. Instacart reviews all changes made to the store detail.',
  'onboardingDomain.storeDetail.status.pending': 'Pending approval',
  'onboardingDomain.storeDetail.status.active': 'Launched',
  'onboardingDomain.storeDetail.status.inactive': 'Ready for Launch',
  'onboardingDomain.storeDetail.status.actionRequired': 'Action required',

  'onboardingDomain.catalogLaunchProcess.header':
    'Add products to your catalog (file ingestion required)',
  'onboardingDomain.catalogLaunchProcess.subtitle': 'Launch process for your catalog',
  'onboardingDomain.catalogLaunchProcess.inventoryFile': 'Prepare your inventory file',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.link':
    'Instacart catalog inventory setup FAQ',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.details':
    'There are 2 options to add products to your catalog on Instacart.',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option1':
    'Option 1: Integration with our POS partners',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option1.posGuideline':
    'Current POS partners and integration guidelines',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option1.details':
    'We support multiple POS integration. Each POS has its own guidelines.',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2':
    'Option 2: Inventory file ingestion',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.groceryFileTemplate':
    'Grocery file template',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.groceryFileGuideline':
    'Grocery file guideline',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.alcoholFileTemplate':
    'Alcohol file template',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.alcoholFileGuideline':
    'Alcohol file guideline',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.details':
    'If your current POS system is not yet our partners, you can create the inventory file manually or pull an existing file from your POS following our templates and guidelines',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.steps':
    '1. Work with your Technical Account Manager to create a inventory file.\n' +
    '2. Drop your inventory file in the SFTP folder we will provide, manually or via a script.',
  'onboardingDomain.catalogLaunchProcess.step2': 'Storefront product population',
  'onboardingDomain.catalogLaunchProcess.step2.details':
    'This typically takes 2-3 days. We will inform you when the storefront is ready for QA. \n' +
    'Products are added to the catalog and the launch checklist for catalog are complete.',
  'onboardingDomain.catalogLaunchProcess.step3': 'Catalog QA',
  'onboardingDomain.catalogLaunchProcess.step3.details':
    'This typically takes 2-3 weeks. We work with you to ensure your storefront is populated correctly and your catalog is populated correctly.',
  'onboardingDomain.catalogLaunchProcess.step4': 'Retailer approval',
  'onboardingDomain.catalogLaunchProcess.step4.details':
    'Once you are happy with your storefront, you can sign off and we can get ready for launch.',
  'onboardingDomain.catalogLaunchProcess.step5': 'Schedule launch',
  'onboardingDomain.catalogLaunchProcess.errorFetchingFile':
    'Could not download file. Please try again. If the issue persists, please contact support.',

  'onboardingDomain.logoImageBestPractice.header': 'Logo Image Best Practice',
  'onboardingDomain.logoImageBestPractice.content':
    "We can now put your logo in the marketplace's top bar navigation, allowing your store a better branding experience as customers explore your store online. \n" +
    '\n' +
    'On iPhone or Android we can show the same vibrant logo image with confidence to your customers.\n' +
    '\n' +
    'A square logo is the most standard and consistent experience for customers — and provides the opportunity to use the logo in more places. \n' +
    '\n' +
    'In order to maintain consistency, please also provide a HEX color code (e.g., #FFFFFF) with your logo to fill the background of the logo container. This color should match the background of your logo. Any asset without a provided HEX code will default to white.\n',
  'onboardingDomain.logoImageBestPractice.subtitle1': 'Image Requirement',
  'onboardingDomain.logoImageBestPractice.subtitle2': 'Dos & Don’ts',
  'onboardingDomain.logoImageBestPractice.sample.standard':
    'Use the most iconic thing about your brand if opting to use a symbol for representation',
  'onboardingDomain.logoImageBestPractice.sample.text':
    'Text logos are perfect representations, so long as the text meets the edges of the asset container',
  'onboardingDomain.logoImageBestPractice.sample.crowded':
    'Do not include complex backgrounds as part of logo assets; they will not be accepted',
  'onboardingDomain.logoImageBestPractice.sample.notFilling':
    'Avoid not filling the entire space of the asset dimension size',
  'onboardingDomain.logoImageBestPractice.sample.tooMuch':
    'Avoid including too much in your logo asset which could detract from your brand.',
  'onboardingDomain.logoImageBestPractice.sample.differentBackground':
    'Do not supply a different background color fill than what is provided with your asset.',

  'onboardingDomain.approvalRequest.pending.info': 'The content update is pending',
  'onboardingDomain.approvalRequest.pending.modal.header': 'Pending Request',
  'onboardingDomain.approvalRequest.pending.modal.message':
    'There are changes that are pending approval.\nYou can still continue to update the content.',
  'onboardingDomain.approvalRequest.denied.info': 'The content update was declined',
  'onboardingDomain.approvalRequest.denied.modal.header': 'Declined Request',
  'onboardingDomain.approvalRequest.denied.modal.updateMessage':
    'Please update this content for us to review.',
  'onboardingDomain.approvalRequest.denied.modal.updateStoreDetail': 'Update store detail',
  'onboardingDomain.approvalRequest.viewDetail': 'View detail',
  'onboardingDomain.approvalRequest.viewComments': 'View comments',
  'onboardingDomain.approvalRequest.requestedAttribute': 'Requested attribute',
  'onboardingDomain.approvalRequest.requestedChange': 'Requested change',
  'onboardingDomain.approvalRequest.declineComments': 'Decline comments',
  'onboardingDomain.action.updateProfile': 'Update profile',

  // whitelabel site pages
  'onboardingDomain.siteSetup.pageTitle': 'Storefront Pro Site Setup',
  'onboardingDomain.siteSetup.pageHeader': 'Site Setup',
  'onboardingDomain.siteSetup.notSubmitted.title': 'Site setup form has not been submitted',
  'onboardingDomain.siteSetup.notSubmitted.message':
    'Complete the initial site setup form to kick off your Storefront Pro setup process.',
  'onboardingDomain.siteSetup.button.goToInitialSetup': 'Go to initial setup',

  'onboardingDomain.setupStepsTitle': 'Storefront Pro setup',
  'onboardingDomain.step.0.title': 'Domain setup',
  'onboardingDomain.step.1.title': 'Fulfillment',
  'onboardingDomain.step.2.title': 'Identity',
  'onboardingDomain.step.3.title': 'Privacy',
  'onboardingDomain.step.4.title': 'Customer experience',
  'onboardingDomain.step.5.title': 'CMS',
  'onboardingDomain.step.6.title': 'Customer Payment options',
  'onboardingDomain.step.7.title': 'Legal information',
  'onboardingDomain.step.8.title': 'Mobile app setup',

  'onboardingDomain.step.0.description': 'Set up your domain for your site',
  'onboardingDomain.step.1.description': 'Fulfillment options for your storefront',
  'onboardingDomain.step.2.description': 'Set up your business identity',
  'onboardingDomain.step.3.description': 'Manage your privacy settings',
  'onboardingDomain.step.4.description': 'Customize customer experience',
  'onboardingDomain.step.5.description': 'Content management settings',
  'onboardingDomain.step.6.description': 'Configure customer payment methods',
  'onboardingDomain.step.7.description': 'Add legal information',
  'onboardingDomain.step.8.description': 'Set up mobile app features',

  // Domain Setup Form
  'onboardingDomain.form.domainHostingQuestion':
    'Will you be hosting your site on Instacart or on a custom domain?',
  'onboardingDomain.form.hostOnInstacart': 'Host on Instacart',
  'onboardingDomain.form.hostOnCustomDomain': 'Host on custom domain',
  'onboardingDomain.form.domainNameLabel': 'URL',
  'onboardingDomain.form.cnameLabel': 'CNAME',

  // Fulfillment Form
  'onboardingDomain.form.fulfillmentQuestion':
    'Will you be using Instacart shoppers or your own in-store labor to pick and pack orders?',
  'onboardingDomain.form.useInstacartShoppers': 'Use Instacart shoppers',
  'onboardingDomain.form.useInStoreLabor': 'Use in-store labor',

  // Identity Form
  'onboardingDomain.form.identityQuestion':
    'Will your site authenticate against an existing identity management solution?',
  'onboardingDomain.form.useInstacartIdentity': 'Use Instacart’s identity solution',
  'onboardingDomain.form.useCustomIdentity': 'Use custom identity solution',
  'onboardingDomain.form.identitySolutionName': 'Name of identity solution',
  'onboardingDomain.form.identitySolutionTooltip': 'Name of identity solution',
} as const

export default EN_US
