import React, { type FunctionComponent, useMemo } from 'react'
import moment from 'moment'
import { Text, type TimeOfDay } from '@retailer-platform/shared-components'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { type OpenMode } from '../../../edit/components/store-open-mode-select/StoreHoursBulkStoreOpenModeSelect'

interface StoreHoursBulkStoreHourItemProps {
  openTime?: TimeOfDay
  closeTime?: TimeOfDay
  openMode: OpenMode
}

export const StoreHoursBulkStoreHourItem: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkStoreHourItemProps>
> = ({ openTime, closeTime, openMode }) => {
  const i18n = useDomainMessages({
    openAllDay: 'storeHoursBulkDomain.list.modal.openAllDay',
    closed: 'storeHoursBulkDomain.list.modal.closed',
    noHoursSet: 'storeHoursBulkDomain.list.modal.noHoursSet',
    regularHours: 'storeHoursBulkDomain.regularHours',
    day: 'storeHoursBulkDomain.list.day',
  })

  const storeHourItem = useMemo(() => {
    let storeHourElement: JSX.Element
    if (openMode === 'regular-hours') storeHourElement = <Text>{i18n.regularHours}</Text>
    else if (openMode === 'closed') storeHourElement = <Text>{i18n.closed}</Text>
    else if (openMode === 'open-24-hours') storeHourElement = <Text>{i18n.openAllDay}</Text>
    else if (openTime && closeTime) {
      const closeTime24 = {
        hours: closeTime.hours % 24,
        minutes: closeTime.minutes,
      }
      const isCrossDay = openTime?.hours > closeTime24?.hours

      storeHourElement = (
        <Text>
          {moment(openTime).format('h:mm a')} - {moment(closeTime24).format('h:mm a')}
          {isCrossDay && <span css={{ color: 'red' }}>{` (+1 ${i18n.day})`}</span>}
        </Text>
      )
    } else
      storeHourElement = (
        <Text css={{ opacity: '0.6', fontStyle: 'italic' }}>{i18n.noHoursSet}</Text>
      )

    return storeHourElement
  }, [openTime, closeTime, openMode, i18n])
  return storeHourItem
}
