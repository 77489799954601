import { useMemo } from 'react'
import { type ApolloError } from 'apollo-client'
import { useRetailerId } from '@retailer-platform/dashboard/routing'
import { type GetHolidaysStatusResponse, useGetRetailerHolidaysStatusQuery } from '../api'

export type HolidayStatuses = GetHolidaysStatusResponse['holidays']

export interface HolidayStatusContext {
  holidayStatuses?: HolidayStatuses
  isLoading: boolean
  error?: ApolloError
}

export const useRetailerHolidayStatuses = (skip = false) => {
  const retailerId = useRetailerId()

  const { data, loading, error } = useGetRetailerHolidaysStatusQuery({
    variables: {
      retailerId: retailerId,
    },
    skip: skip || !retailerId,
  })

  return useMemo(
    () =>
      ({
        holidayStatuses: data?.retailerStoreHoursGetRetailerHolidaysStatus?.holidays,
        isLoading: loading,
        error: error,
      } as HolidayStatusContext),
    [data, loading, error]
  )
}
