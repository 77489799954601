import { useMemo } from 'react'
import { type RetailerServiceType, type StoreHours, useGetStoreHoursBulkQuery } from '../api'
import { type OpenMode } from '../components/edit/components/store-open-mode-select/StoreHoursBulkStoreOpenModeSelect'
import { type FormData } from '../components/edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'
import { useRetailerHolidayStatuses } from './useRetailerHolidaysStoreHoursStatuses'
import { apiDateToMoment, apiDataToFormData } from './useApiToFormDataMapping.hooks'
import { type DomainMessages, useDomainMessages } from './domain/intl'

export const useGetRetailerStoreHours = (
  retailerLocationId: string,
  serviceTypes: RetailerServiceType[],
  appendRecognizedHolidays = true,
  skip = false
) => {
  const locationIds = useMemo(() => [retailerLocationId], [retailerLocationId])
  const { data, loading, error } = useGetStoreHoursBulkQuery({
    variables: {
      retailerLocationIds: locationIds,
      serviceTypes: serviceTypes,
    },
    fetchPolicy: 'no-cache',
    skip: skip,
  })
  const retailerStoreHoursGetStoreHours = data?.retailerStoreHoursGetStoreHours

  const { holidayStatuses } = useRetailerHolidayStatuses(!appendRecognizedHolidays)
  const domainMessages =
    holidayStatuses?.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.holiday.holidaySlug]:
          `storeHoursBulkDomain.holidays.names.${curr.holiday.holidaySlug}` as DomainMessages,
      }),
      {}
    ) || {}
  // This is some odd code but it pulls all the i18n'd strings for the holidays
  // we've fetched from the backend
  const i18n = useDomainMessages(domainMessages as Record<string, DomainMessages>)

  const retailerStoreHours: FormData = useMemo(() => {
    if (retailerStoreHoursGetStoreHours) {
      const storeHours = retailerStoreHoursGetStoreHours.storeHours.find(
        storeHoursData => storeHoursData.retailerLocationId.toString() === retailerLocationId
      ) as StoreHours

      const formData = apiDataToFormData(storeHours)
      const existingDates = formData.delivery.special.map(specialHours => specialHours.date)

      holidayStatuses
        // take some first holidays
        ?.slice(0, 3)
        // change them into SpecialHour form objects
        .map(holiday => ({
          date: apiDateToMoment(holiday.holiday.date),
          description: i18n[holiday.holiday.holidaySlug] as string,
          openMode: 'regular-hours' as OpenMode,
          openTime: null,
          closeTime: null,
        }))
        //filter out the holidays that already exist as special hours for this store
        .filter(
          holiday =>
            !existingDates.find(existingDate => existingDate.unix() === holiday.date.unix())
        )
        //insert our pre-populated holidays into the form data
        .forEach(holiday => {
          formData.delivery.special?.push(holiday)
        })

      //sort by date
      formData.delivery.special?.sort((h1, h2) => h1.date.unix() - h2.date.unix())
      return formData
    }
  }, [retailerStoreHoursGetStoreHours, retailerLocationId, i18n, holidayStatuses])

  return {
    retailerStoreHours,
    loading,
    error,
  }
}
